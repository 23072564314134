import { USERS_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils'

import query from '../graphql/getUsersFiltered.graphql'
import { Filter, GetUserListFilteredQuery, GetUserListFilteredQueryVariables } from '../interfaces/schemaDefinition'

type FilteredUserList = GetUserListFilteredQuery['userList']['list']
type FromArray<T> = T extends Array<infer U> ? U : never
export type MaybeUser = FromArray<FilteredUserList>

export const fetchUserListFiltered = async (filters: Array<Filter>): Promise<FilteredUserList> => {
  const variables: GetUserListFilteredQueryVariables = {
    filters,
  }
  const response = await request<GetUserListFilteredQuery>(USERS_ENDPOINT, query, variables)
  return response.userList.list
}
