import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { startsWith } from 'lodash/fp'
import React, { Fragment, FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { b2bAdviceRequests as getB2bAdviceRequests, getChancelleries } from 'common/graphql'
import { Route } from 'common/interfaces'
import { IsAuthorized, UserContext } from 'common/user-context'
import { isLawyer } from 'common/utils'
import theme from 'theme/theme'

import { adminRoutes } from '../routes-admins'
import { lawyerRoutes, lawyerB2BRoutes } from '../routes-lawyers'

const routes = [...adminRoutes, ...lawyerRoutes]

export const navWidthCollapsed = '54px'
export const navWidthExpanded = '300px'

const useEntryStyles = makeStyles<typeof theme, { isNested?: boolean; expanded?: boolean }>(muiTheme => ({
  listItem: {
    color: '#9c9c9c',
    '&:hover': {
      color: '#9c9c9c',
    },
  },
  activeMain: {
    backgroundColor: muiTheme.palette.primary.main,
    color: 'white',
  },
  listIcon: {
    color: 'inherit',
    minWidth: props => (props.expanded ? '56px' : '21px'),
    transition: theme.transitions.create('min-width', {
      easing: muiTheme.transitions.easing.sharp,
      duration: muiTheme.transitions.duration.enteringScreen,
    }),
  },
  listItemText: {
    opacity: props => (props.expanded ? 1 : 0),
    transition: theme.transitions.create('opacity', {
      easing: muiTheme.transitions.easing.sharp,
      duration: muiTheme.transitions.duration.enteringScreen,
    }),
  },
  activeSub: {
    color: muiTheme.palette.primary.main,
  },
  nested: {
    paddingLeft: muiTheme.spacing(9),
  },
}))

type NavEntryProps = {
  url: string
  roles?: Array<string>
  label: ReactNode
  customLink?: FunctionComponent
  filter?: FunctionComponent
  svg?: SVG
  subEntries?: Route['children']
  className?: string
  isNested?: boolean
  expanded?: boolean
}

// eslint-disable-next-line complexity
const NavEntry: FunctionComponent<NavEntryProps> = ({
  customLink,
  className,
  filter,
  url,
  svg: SVG,
  roles = [],
  label,
  subEntries,
  isNested,
  expanded = true,
}) => {
  const UsedLink = customLink || NavLink
  const UsedFilter = filter || Fragment
  const classes = useEntryStyles({ expanded, isNested })
  const location = useLocation()
  const activeMain = !isNested && startsWith(url, location.pathname)
  const activeSub = isNested && url === location.pathname

  return (
    <UsedFilter>
      <IsAuthorized roles={roles}>
        <ListItem
          button
          component={UsedLink}
          to={url}
          activeClassName={isNested ? '' : classes.activeMain}
          className={
            activeSub ? classNames(className, classes.listItem, classes.activeSub) : classNames(className, classes.listItem)
          }
        >
          {SVG && (
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <SVG width={21} height={21} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={label}
            primaryTypographyProps={{ variant: isNested ? 'body2' : 'button' }}
            className={classes.listItemText}
          />
        </ListItem>
        {activeMain && subEntries && expanded && (
          <List disablePadding dense>
            {subEntries.map(entry => (
              <NavEntry
                isNested
                key={entry.url}
                className={classes.nested}
                url={entry.url}
                customLink={entry.customLink}
                label={entry.label}
                filter={entry.filter}
              />
            ))}
          </List>
        )}
      </IsAuthorized>
    </UsedFilter>
  )
}

const useHasB2B = (): boolean => {
  const { user } = useContext(UserContext)
  const lawyer = isLawyer(user)
  const [hasB2B, setHasB2B] = useState(false)

  useEffect(() => {
    if (user && lawyer) {
      Promise.all([getChancelleries(), getB2bAdviceRequests()]).then(([chancelleries, b2bAdviceRequests]) => {
        setHasB2B(!!b2bAdviceRequests.adviceRequests.total || !!chancelleries.find(chancellery => chancellery.hasB2B))
      })
    }
  }, [lawyer, user])

  return hasB2B
}

const useStyles = makeStyles<typeof theme, LargeNavigationProps>(muiTheme => ({
  drawer: {
    width: props => (props.expanded ? navWidthExpanded : navWidthCollapsed),
    transition: muiTheme.transitions.create('width', {
      easing: muiTheme.transitions.easing.sharp,
      duration: muiTheme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    boxShadow: props => (props.shadow && props.expanded ? theme.shadows['3'] : 'none'),
    overflowX: 'hidden',
    width: props => (props.expanded ? navWidthExpanded : navWidthCollapsed),
    transition: muiTheme.transitions.create('width', {
      easing: muiTheme.transitions.easing.sharp,
      duration: muiTheme.transitions.duration.enteringScreen,
    }),
  },
}))

type LargeNavigationProps = {
  expanded: boolean
  onMenuToggle?: () => void
  shadow?: boolean
}

const LargeNavigation: FunctionComponent<LargeNavigationProps> = ({ expanded, shadow, onMenuToggle }) => {
  const classes = useStyles({ expanded, shadow, onMenuToggle })
  const hasB2B = useHasB2B()

  return (
    <Drawer
      variant="permanent"
      open={expanded}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      onClick={() => (expanded ? onMenuToggle?.() : undefined)}
    >
      <Toolbar />
      <List>
        {routes
          .filter(route => !!route.nav)
          .map(({ roles, url, children = [], nav: { label, svg, filter, customLink } = {} }) => (
            <NavEntry
              key={url}
              roles={roles}
              url={url}
              label={label}
              svg={svg}
              filter={filter}
              customLink={customLink}
              subEntries={children}
              expanded={expanded}
            />
          ))}
        {hasB2B && (
          <>
            <ListSubheader>B2B</ListSubheader>
            {lawyerB2BRoutes
              .filter(route => !!route.nav)
              .map(({ roles, url, children = [], nav: { label, svg, filter, customLink } = {} }) => (
                <NavEntry
                  key={url}
                  roles={roles}
                  url={url}
                  label={label}
                  svg={svg}
                  filter={filter}
                  customLink={customLink}
                  subEntries={children}
                />
              ))}
          </>
        )}
      </List>
    </Drawer>
  )
}

type SmallNavigationProps = {
  expanded: boolean
  onMenuToggle: () => void
}

const SmallNavigation: FunctionComponent<SmallNavigationProps> = ({ expanded, onMenuToggle }) => {
  const hasB2B = useHasB2B()

  return (
    <Drawer variant="temporary" open={expanded} onClose={onMenuToggle}>
      <List>
        {routes
          .filter(route => !!route.nav)
          .map(({ roles, url, children = [], nav: { label, svg, filter, customLink } = {} }) => (
            <NavEntry
              key={url}
              roles={roles}
              url={url}
              label={label}
              svg={svg}
              filter={filter}
              customLink={customLink}
              subEntries={children}
              expanded={expanded}
            />
          ))}
        {hasB2B && (
          <>
            <ListSubheader>B2B</ListSubheader>
            {lawyerB2BRoutes
              .filter(route => !!route.nav)
              .map(({ roles, url, children = [], nav: { label, svg, filter, customLink } = {} }) => (
                <NavEntry
                  key={url}
                  roles={roles}
                  url={url}
                  label={label}
                  svg={svg}
                  filter={filter}
                  customLink={customLink}
                  subEntries={children}
                />
              ))}
          </>
        )}
      </List>
    </Drawer>
  )
}

type NavigationProps = {
  menuOpen: boolean
  onMenuToggle: () => void
}

export const Navigation: FunctionComponent<NavigationProps> = ({ menuOpen, onMenuToggle }) => {
  const muiTheme = useTheme()
  const isSmall = useMediaQuery(muiTheme.breakpoints.down('md'))
  return (
    <>
      <Box display={{ xs: 'none', maxContentWidth: 'flex' }}>
        <LargeNavigation expanded={true} />
      </Box>
      <Box display={{ xs: 'none', md: 'flex', maxContentWidth: 'none' }}>
        <LargeNavigation expanded={menuOpen} shadow onMenuToggle={onMenuToggle} />
      </Box>
      {isSmall && <SmallNavigation expanded={menuOpen} onMenuToggle={onMenuToggle} />}
    </>
  )
}
