import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import React from 'react'

const API_BASE = process.env.API_BASE || '/api'

const httpLink = createUploadLink({
  uri: `${API_BASE}/advice-requests`,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(token ? { 'x-access-token': token } : {}),
    },
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
})

export const Apollo = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)
