import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { resetPasswordMutation } from 'packages/userauth/actions/resetPassword'

import { PasswordResetView } from '../../components/Password'

type PasswordResetProps = RouteComponentProps<{}>

interface PasswordResetState {
  errors: { [prop: string]: string }
  email: string
  success: boolean
}

export class PasswordResetContainer extends Component<PasswordResetProps, PasswordResetState> {
  state = {
    errors: {},
    email: '',
    success: false,
  }

  onCancel = () => {
    this.props.history.push('/login')
  }

  onSendPassword: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    let errors: { [prop: string]: string } = {}
    if (this.state.email !== '') {
      resetPasswordMutation(this.state.email, `${window.location.origin}/password-set/`)
        .then(() => {
          this.setState({ success: true })
        })
        .catch(error => {
          errors = {
            ...errors,
            invalidEmail: error.message,
          }
          this.setState({ errors })
          throw error
        })
    } else {
      this.setState({
        errors: {
          invalidEmail: 'Ungültige Email',
        },
      })
    }
  }

  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    this.setState({
      email: value,
      errors: {},
    })
  }

  onRedirect = () => {
    this.setState({ success: false })
  }

  render() {
    const { errors, email, success } = this.state
    return (
      <PasswordResetView
        errors={errors}
        email={email}
        onCancel={this.onCancel}
        onSendPassword={this.onSendPassword}
        onChange={this.onChange}
        success={success}
        onRedirect={this.onRedirect}
      />
    )
  }
}
