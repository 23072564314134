import classNames from 'classnames'
import React from 'react'

import { SetFunction, map } from 'common/utils'

import './ElementWithOverlay.scss'

export type OverlayPositioning = 'top' | 'bottom' | 'left' | 'right'

const OVERLAY_CLASSNAME = 'overlay'

const mapToClassNames: SetFunction<OverlayPositioning, string> = map(s => `${OVERLAY_CLASSNAME}-${s}`)

export interface ElementWithOverlayProps {
  overlayElement: React.ReactNode
  positioning?: Array<OverlayPositioning> // if ommitted the element will center
}

export const ElementWithOverlay: React.FC<ElementWithOverlayProps & React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  overlayElement,
  positioning,
  children,
}) => {
  const positionCNs: Array<string> = mapToClassNames(positioning || [])

  return (
    <div className={classNames('overlayContainer', className)}>
      <div className={classNames(OVERLAY_CLASSNAME, ...positionCNs)}>{overlayElement}</div>
      {children}
    </div>
  )
}
