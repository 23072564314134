import {
  GridFilterInputValue,
  getGridStringOperators,
  GridColumns,
  GridFilterOperator,
  GridFilterInputValueProps,
} from '@mui/x-data-grid'
import React, { FunctionComponent } from 'react'

export const getBasicOperators = (): Array<GridFilterOperator> =>
  getGridStringOperators().filter(operator => operator.value !== 'startsWith' && operator.value !== 'endsWith')

export const getBasicGridColumns = (count: number): GridColumns =>
  Array(count).fill({
    filterOperators: getBasicOperators(),
  })

export const createGridColumnsFrom = (columnDefinitions: GridColumns): GridColumns =>
  columnDefinitions.map(col => ({
    ...col,
    filterOperators: col.filterOperators || getBasicOperators(),
  }))

const NumberTextField: FunctionComponent<Omit<GridFilterInputValueProps, 'number'>> = props => (
  <GridFilterInputValue {...props} type="number" />
)

export const addZipAreaOperators = (operators: Array<GridFilterOperator> = getBasicOperators()): Array<GridFilterOperator> =>
  operators.concat([
    {
      label: 'deckt PLZ ab (5-stellig)',
      value: 'zipContains',
      getApplyFilterFn: () => () => true,
      InputComponent: NumberTextField,
    },
    {
      label: 'Liste PLZ-Bereiche enthält (1-5-stellig)',
      value: 'zipEquals',
      getApplyFilterFn: () => () => true,
      InputComponent: NumberTextField,
    },
  ])
