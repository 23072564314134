import React from 'react'
import { Route } from 'react-router-dom'

import { LoginBox, PasswordResetContainer, PasswordSetContainer } from 'packages/login'

import './Login.scss'

export const Login = () => (
  <div className="signwrapper">
    <div className="sign-overlay" />
    <div className="signpanel" />
    <Route exact path="/login" component={LoginBox} />
    <Route exact path="/password-reset" component={PasswordResetContainer} />
    <Route exact path="/password-set/:token?" component={PasswordSetContainer} />
  </div>
)
