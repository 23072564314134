import React, { useEffect, useState, FC } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'

import SVGCheck from 'common/icons/Check.svg'
import { ButtonClear } from 'common/ui/Button'

import { FilterInputProps, FilterOperator } from '../../interfaces'
import { noOp } from '../../utils'

export const TextFilterInput: FC<FilterInputProps> = ({ name, onFilterSubmit, suggestions, placeholder, operator }) => {
  const [value, setValue] = useState('')
  const [usedOperator, setOperator] = useState(operator || FilterOperator.Contains)

  useEffect(() => {
    if (operator) {
      setOperator(operator)
    }
  }, [operator])

  const onValueChange = (value: string) => {
    setValue(value)
  }

  const handleSubmit = (item: any) => {
    const filter = {
      name,
      operator: usedOperator,
      value: item.id ? item.id : item,
    }

    if (item) {
      onFilterSubmit(filter)
    }
  }

  const onValueSubmit = () => {
    handleSubmit(value)
  }

  const onTagAdded = (tag: any) => {
    handleSubmit(tag)
  }

  return (
    <span>
      <ReactTags
        tags={[]}
        suggestions={suggestions}
        handleAddition={onTagAdded}
        handleInputChange={onValueChange}
        handleDelete={noOp}
        minQueryLength={0}
        placeholder={placeholder}
      />
      <ButtonClear iconLeft={<SVGCheck width="16" height="16" />} onClick={onValueSubmit} />
    </span>
  )
}
