import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'

import { UserContextProps, withUser } from 'common/user-context'
import { Chancellery } from 'packages-mui/chancelleries/interfaces/schemaDefinition'
import { UserProfileView } from 'packages/user-profile/components/UserProfileView'

type UserProfileProps = RouteComponentProps<{}> & UserContextProps

interface UserProfileState {
  isMounted: boolean
  chancellery?: Chancellery
}

export const UserProfileContainer = withUser(
  class UserProfileContainer extends Component<UserProfileProps, UserProfileState> {
    state: UserProfileState = {
      isMounted: true,
    }

    componentDidMount = async () => {
      const { user } = this.props
      if (user && user.organisation) {
        const chancellery = await this.fetchChancelleryDetails(user.organisation)

        this.setState({ chancellery })
      }
    }

    componentWillUnmount() {
      this.setState({ isMounted: false })
    }

    fetchChancelleryDetails = async (_chancelleryId: string) => undefined

    render() {
      if (!this.props.user || !this.state.chancellery) {
        return null
      }
      return <UserProfileView user={this.props.user} chancellery={this.state.chancellery} />
    }
  }
)
