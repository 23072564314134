import { BaseTextFieldProps, TextField as MuiTextField } from '@mui/material'
import { useField } from 'formik'
import React, { FunctionComponent, memo } from 'react'

import { useScrollToInvalidField } from '../useScrollToInvalidField'

/**
 * This is for styling and ref-forwarding. No Formik functionality attached. Internal usage in custom Formik components like TextField only.
 */
export const Field: FunctionComponent<
  BaseTextFieldProps & {
    forwardedRef?: React.MutableRefObject<HTMLDivElement | null> | ((instance: HTMLDivElement) => void) | null
  }
> = ({
  forwardedRef,
  // eslint-disable-next-line fp/no-rest-parameters
  ...props
}) => <MuiTextField {...props} ref={forwardedRef} variant="outlined" />

const MemoizedField = memo(Field)

interface TextFieldProps extends Omit<BaseTextFieldProps, 'value'> {
  name: string
}

export const TextField: FunctionComponent<TextFieldProps> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { name, ...rest } = props
  const [field, meta] = useField({ name, type: 'text' })
  const fieldRef = useScrollToInvalidField<HTMLDivElement>(name)

  return (
    <MemoizedField
      id={field.name}
      forwardedRef={fieldRef}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...rest}
      {...field}
    />
  )
}
