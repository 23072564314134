import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { AsyncFunction, Optional, request } from 'common/utils'
import { VerifyUserQuery, VerifyUserQueryVariables } from 'packages/userauth/interfaces/schemaDefinition'

import query from '../graphql/verifyUser.graphql'

export const verifyUser: AsyncFunction<Optional<string>, Optional<VerifyUserQuery['verify']>> = async userToken => {
  if (!userToken) {
    return null
  }
  const variables = {
    jwtToken: userToken,
  }
  const response = await request<VerifyUserQuery, VerifyUserQueryVariables>(USER_AUTH_ENDPOINT, query, variables)
  return response.verify
}
