export * from './active'
export * from './contactType'
export * from './dateOperators'
export * from './deductibleAmount'
export * from './endpoints'
export * from './fieldsOfLaw'
export * from './fieldsOfLawActivities'
export * from './insurer'
export * from './languages'
export * from './months'
export * from './numberOperators'
export * from './partners'
export * from './patterns'
export * from './products'
export * from './protectionAmounts'
export * from './roles'
export * from './status'
export * from './urls'
export * from './weekDays'
export * from './zipAreaOperator'

export const EMPTY_OPTION = {
  label: '---Bitte wählen---',
  value: '',
}
