import React from 'react'

import { numberToLabel } from '../../utils'

import { StaticField } from './StaticField'

interface NumberStaticFieldProps {
  label: string
  value: number | string
  formatter?: (value: number | string) => string
}

export const NumberStaticField = (props: NumberStaticFieldProps) => (
  <StaticField label={props.label} value={numberToLabel(props.value, props.formatter)} />
)
