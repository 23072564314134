import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { CompleteInput, SetToCompleteMutation, SetToCompleteMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/setToComplete.graphql'

export const setToComplete = async (completeInput: CompleteInput): Promise<string> => {
  const variables = {
    completeInput: {
      id: completeInput.id,
      revenue: completeInput.revenue,
      noRevenueReason: completeInput.noRevenueReason,
    },
  }

  const response = await request<SetToCompleteMutation, SetToCompleteMutationVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )
  return response.setToComplete
}
