import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useField } from 'formik'
import React, { FunctionComponent, memo } from 'react'

import { Checkbox, TextField } from 'common/components-mui'

type OpeningHoursSettingsProps = {
  name: string
  label: string
  disabled?: boolean
}

const useOpeningStyles = makeStyles(theme => ({
  checkbox: {
    flex: '0 0 10em',
    marginRight: theme.spacing(1),
  },
  times: {
    flex: '1 0 8em',
    margin: `0 ${theme.spacing(1)}`,
  },
}))

const MemoizedCheckbox = memo(Checkbox)

export const OpeningHoursSettings: FunctionComponent<OpeningHoursSettingsProps> = ({ name, label, disabled }) => {
  const classes = useOpeningStyles()
  const checkboxName = `${name}.enabled`
  const beginName = `${name}.begin`
  const endName = `${name}.end`
  const [checkbox] = useField({ name: checkboxName, type: 'checkbox' })
  return (
    <Box mb={3} display="flex" flexWrap="wrap" justifyContent="space-between">
      <MemoizedCheckbox name={checkboxName} label={label} disabled={disabled} className={classes.checkbox} />
      <Box flex="1 1 auto" display="flex" maxWidth="calc(100% - 10em)">
        <TextField name={beginName} label="Von" disabled={disabled || checkbox.value !== true} className={classes.times} />
        <TextField name={endName} label="Bis" disabled={disabled || checkbox.value !== true} className={classes.times} />
      </Box>
    </Box>
  )
}
