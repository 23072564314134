import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/saveAttachments.graphql'
import { FileInput, SaveAttachmentsMutation } from '../interfaces'

export const saveAttachments = async (adviceRequestId: string, files: Array<FileInput>) => {
  try {
    await request<SaveAttachmentsMutation>(ADVICE_REQUESTS_ENDPOINT, query, {
      adviceRequestId,
      files,
    })
  } catch (error) {
    throw error
  }
}
