const padLeft = (text: string | number, filler = '0', length = 2) => {
  let result = text.toString()
  while (result.length < length) {
    result = `${filler}${result}`
  }
  return result
}

export const formatCallbackTime = (callbackTime: string | Date) => {
  const date = new Date(callbackTime)
  const date2 = new Date(date.getTime() + 1000 * 60 * 30)
  return `${padLeft(date.getDate())}.${padLeft(date.getMonth() + 1)}.${date.getFullYear()} ${date.getHours()}:${padLeft(
    date.getMinutes()
  )} - ${date2.getHours()}:${padLeft(date2.getMinutes())}`
}

export { formatDate, formatDateTime, formatTime } from './date'
