import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AsyncSupplier, request } from 'common/utils'

import query from '../graphql/startTRB.graphql'
import { StartTrbMutation } from '../interfaces/schemaDefinition'

export const startTRB: AsyncSupplier<StartTrbMutation['startTRB']> = async () => {
  const result = await request<StartTrbMutation>(ADVICE_REQUESTS_ENDPOINT, query)
  return result.startTRB
}
