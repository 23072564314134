import classnames from 'classnames'
import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { SearchContainer } from 'common/components/SearchField'
import { URLs } from 'common/constants'
import SVGB2BClosed from 'common/icons/icon-b2b-closed.svg'
import SVGB2BComplete from 'common/icons/icon-b2b-complete.svg'
import SVGB2BOpen from 'common/icons/icon-b2b-open.svg'
import SVGB2BProcessing from 'common/icons/icon-b2b-processing.svg'
import { UserContext } from 'common/user-context'

import { AssignFilter } from '../AssignFilter'

import { ClosedB2BView } from './ClosedB2b'
import { CompleteB2BView } from './CompleteB2b'
import { OpenB2BView } from './OpenB2b'
import { ProcessingB2BView } from './ProcessingB2b'

export const B2bListView = () => {
  const history = useHistory()
  const location = useLocation()
  const { user } = useContext(UserContext)
  const [activeTab, setActiveTab] = useState('')
  const [assignFilter, setAssignFilter] = useState('')

  const configureSetActiveTab = (url: string) => () => {
    history.push(url)
  }

  useEffect(() => {
    setActiveTab(location.pathname)
  }, [location.pathname])

  const onAssignFilterClick = useCallback(() => {
    if (user) {
      setAssignFilter(prevAssignFilter => (prevAssignFilter === user.id ? '' : user.id))
    }
  }, [user])

  return (
    <>
      <div className="lawyer-topbar">
        <Nav tabs>
          <NavItem onClick={configureSetActiveTab(URLs.chancellery.b2b.open)}>
            <NavLink className={classnames({ active: activeTab === URLs.chancellery.b2b.open })}>
              <div style={{ display: 'flex' }}>
                <SVGB2BOpen height="32" width="32" />
              </div>
              <span style={{ marginLeft: '0.5rem' }}>Offen</span>
            </NavLink>
          </NavItem>
          <NavItem onClick={configureSetActiveTab(URLs.chancellery.b2b.processing)}>
            <NavLink className={classnames({ active: activeTab === URLs.chancellery.b2b.processing })}>
              <div style={{ display: 'flex' }}>
                <SVGB2BProcessing height="32" width="32" />
              </div>
              <span style={{ marginLeft: '0.5rem' }}>In Bearbeitung</span>
            </NavLink>
          </NavItem>
          <NavItem onClick={configureSetActiveTab(URLs.chancellery.b2b.complete)}>
            <NavLink className={classnames({ active: activeTab === URLs.chancellery.b2b.complete })}>
              <div style={{ display: 'flex' }}>
                <SVGB2BComplete height="32" width="32" />
              </div>
              <span style={{ marginLeft: '0.5rem' }}>Abgeschlossen</span>
            </NavLink>
          </NavItem>
          <NavItem onClick={configureSetActiveTab(URLs.chancellery.b2b.closed)}>
            <NavLink className={classnames({ active: activeTab === URLs.chancellery.b2b.closed })}>
              <div style={{ display: 'flex' }}>
                <SVGB2BClosed height="32" width="32" />
              </div>
              <span style={{ marginLeft: '0.5rem' }}>Beratung abgebrochen</span>
            </NavLink>
          </NavItem>
        </Nav>
        <div className="lawyer-topbar__filler" />
        <AssignFilter active={!!assignFilter} onClick={onAssignFilterClick} />
        <SearchContainer className="lawyer-topbar__search" />
      </div>
      <TabContent activeTab={activeTab} className="tab">
        <TabPane tabId={URLs.chancellery.b2b.open}>
          {activeTab === URLs.chancellery.b2b.open && <OpenB2BView assignFilter={assignFilter} />}
        </TabPane>
        <TabPane tabId={URLs.chancellery.b2b.processing}>
          {activeTab === URLs.chancellery.b2b.processing && <ProcessingB2BView assignFilter={assignFilter} />}
        </TabPane>
        <TabPane tabId={URLs.chancellery.b2b.complete}>
          {activeTab === URLs.chancellery.b2b.complete && <CompleteB2BView assignFilter={assignFilter} />}
        </TabPane>
        <TabPane tabId={URLs.chancellery.b2b.closed}>
          {activeTab === URLs.chancellery.b2b.closed && <ClosedB2BView assignFilter={assignFilter} />}
        </TabPane>
      </TabContent>
    </>
  )
}
