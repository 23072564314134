import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils/requests'

import query from '../graphql/proposalAccepted.graphql'
import { ProposalAcceptedMutation } from '../interfaces/schemaDefinition'

export const proposalAccepted = async (
  adviceRequestId: string,
  amount: number,
  urlPrefix: string
): Promise<ProposalAcceptedMutation['proposalAccepted']> => {
  const variables = {
    adviceRequestId,
    amount,
    urlPrefix,
  }
  const response = await request<ProposalAcceptedMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.proposalAccepted
}
