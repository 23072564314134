// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IpOW1wFW1sOx7yVeNzWN{font-size:20px;font-weight:500;letter-spacing:.2px;display:inline-flex;flex-direction:row;justify-content:space-between;align-items:center;margin-left:15px}.P1_g1ff9QRyIzs4ExURg{margin-bottom:26px}.XNKWcX_e4uwSq2tu2UNU{display:flex;float:right;margin:0 -0.5rem}.XNKWcX_e4uwSq2tu2UNU>*{margin:0 .5rem}", "",{"version":3,"sources":["webpack://./src/common/ui/Toolbar/Toolbar.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,mBAAA,CACA,mBAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,gBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,YAAA,CACA,WAAA,CACA,gBAAA,CAEA,wBACE,cAAA","sourcesContent":[".title {\n  font-size: 20px;\n  font-weight: 500;\n  letter-spacing: 0.2px;\n  display: inline-flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-left: 15px;\n}\n\n.toolbar {\n  margin-bottom: 26px;\n}\n\n.buttons {\n  display: flex;\n  float: right;\n  margin: 0 -0.5rem;\n\n  > * {\n    margin: 0 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "IpOW1wFW1sOx7yVeNzWN",
	"toolbar": "P1_g1ff9QRyIzs4ExURg",
	"buttons": "XNKWcX_e4uwSq2tu2UNU"
};
export default ___CSS_LOADER_EXPORT___;
