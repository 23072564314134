import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/matchChancelleries.graphql'
import { AdviceRequest } from '../interfaces'
import { MatchChancelleriesQuery, MatchChancelleriesQueryVariables, ContactType } from '../interfaces/schemaDefinition'

export const findChancelleries = async (
  adviceRequest: AdviceRequest,
  contactType?: ContactType
): Promise<MatchChancelleriesQuery['matchChancelleries']> => {
  if (!adviceRequest.fieldOfLaw) {
    throw new Error('cannot find chancelleries without fieldOfLaw')
  }

  const variables = {
    matchInput: {
      premium: !!adviceRequest.product && adviceRequest.product.id === 'premium-advanced',
      address: {
        zip: adviceRequest.zip,
      },
      fieldOfLaw: adviceRequest.fieldOfLaw.id,
      language: adviceRequest.language ? adviceRequest.language.code : 'de',
      productId: adviceRequest.product.id,
      partnerId: adviceRequest.partner?.id,
      contactType,
      preferredCallbackTime: adviceRequest.preferredCallbackTime,
    },
  }

  const response = await request<MatchChancelleriesQuery, MatchChancelleriesQueryVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )

  const { matchChancelleries } = response

  if (adviceRequest.contactType === 'CALLBACK' && matchChancelleries.premiumChancelleries.length > 1) {
    return {
      ...matchChancelleries,
      premiumChancelleries: [matchChancelleries.premiumChancelleries[0]],
    }
  }
  return matchChancelleries
}
