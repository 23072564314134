import { GridFilterModel, GridSortModel, GridLinkOperator, GridFilterItem, GridRowData } from '@mui/x-data-grid'
import { useMemo, useReducer } from 'react'
import useSWR from 'swr'

import { fetchWithBody } from 'common/utils/fetcher'

// Should not stay. Belongs to API schemaDefinition
// BEGIN
export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type Filter = {
  name: string
  operator: FilterOperator
  value: unknown
}

export enum FilterOperator {
  NotEmpty = 'notEmpty',
  Empty = 'empty',
  Equals = 'equals',
  Contains = 'contains',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  ZipAreaEquals = 'zipAreaEquals',
  ZipAreaContains = 'zipAreaContains',
  After = 'after',
  Before = 'before',
  At = 'at',
  And = 'and',
  Or = 'or',
}
// END

export type VariablesState = {
  page: number
  pageSize: number
  sort: {
    sortBy: string
    sortDirection?: 'asc' | 'desc'
  }
  filters?: Array<Filter>
}

type SetPageAction = {
  type: 'setPage'
  payload: number
}

type SetPageSizeAction = {
  type: 'setPageSize'
  payload: number
}

type SetSortAction = {
  type: 'setSort'
  payload: GridSortModel
}

type SetFilterAction = {
  type: 'setFilter'
  payload: {
    preset?: Array<Filter>
    filterModel: GridFilterModel
    tableFields?: Array<string>
  }
}

type SetAPIFilterAction = {
  type: 'setAPIFilter'
  payload: Array<Filter>
}

type VariablesAction = SetPageAction | SetPageSizeAction | SetSortAction | SetFilterAction | SetAPIFilterAction

// This function is used for mapping the filter state from DataGrid's FilterModel to the one which we use in component state and API
const toApiFilterModel = (filterModel: GridFilterModel): Array<Filter> | undefined => {
  if (filterModel.linkOperator === GridLinkOperator.And) {
    const viableGridFilterItems: Array<Required<GridFilterItem>> = filterModel.items.filter(
      (item: GridFilterItem): item is Required<GridFilterItem> =>
        !!item.id && !!item.columnField && !!item.operatorValue && typeof item.value !== 'undefined'
    )
    const filterOperators: { [name: string]: FilterOperator } = {
      contains: FilterOperator.Contains,
      equals: FilterOperator.Equals,
      zipEquals: FilterOperator.ZipAreaEquals,
      zipContains: FilterOperator.ZipAreaContains,
    }
    return viableGridFilterItems.map((item: Required<GridFilterItem>) => ({
      name: item.columnField,
      operator: filterOperators[item.operatorValue] ?? FilterOperator.Equals,
      value: item.value,
    }))
  }
  return undefined
}

/**
 * With this function we make it possible to have filters sent to the API that come from the table's filter element
 * and __additionally__ filters that we add externally with setAPIFilter. We need to differentiate between filters
 * that have names of table columns (the table should be responsible for them) and filters with other names.
 * The user does not need to know about that. They can set extra filters with setAPIFilter like for the "deleted"
 * field in ChancelleryListPage and it will just work. The integration happens between useGridData and MuiDataGrid.
 */
const mergeTableAndOtherFilters = (
  tableFields: Array<string>,
  stateFilters: Array<Filter> | undefined,
  tableFilters?: Array<Filter>
): Array<Filter> | undefined => {
  if (!stateFilters) {
    return tableFilters
  }
  if (!tableFilters) {
    return stateFilters.filter(f => !tableFields.includes(f.name))
  }
  return stateFilters.filter(f => !tableFields.includes(f.name)).concat(tableFilters)
}

// Since we want to implement a composed table state we use useReducer instead of multiple useStates.
// eslint-disable-next-line complexity
export const variablesReducer = (state: VariablesState, action: VariablesAction): VariablesState => {
  switch (action.type) {
    case 'setPage':
      return {
        ...state,
        page: action.payload,
      }
    case 'setPageSize':
      return {
        ...state,
        pageSize: action.payload,
        page: 1,
      }
    case 'setSort':
      // Need to check for equality because otherwise DataGrid triggers an update on every render. *sigh*
      if (action.payload[0]?.sort === state.sort.sortDirection && action.payload[0]?.field === state.sort.sortBy) {
        return state
      }

      if (!action.payload[0]?.sort || !action.payload[0]?.field) {
        return state
      }
      return {
        ...state,
        sort: {
          sortBy: action.payload[0]?.field ?? '',
          sortDirection: action.payload[0]?.sort
            ? {
                asc: SortDirection.Asc,
                desc: SortDirection.Desc,
              }[action.payload[0]?.sort]
            : undefined,
        },
      }
    case 'setFilter':
      // Each case block returns so there is no danger in assigning variables inside them.
      // eslint-disable-next-line no-case-declarations
      const { preset, filterModel, tableFields } = action.payload
      return {
        ...state,
        filters: preset
          ? preset.concat(mergeTableAndOtherFilters(tableFields || [], state.filters, toApiFilterModel(filterModel)) ?? [])
          : mergeTableAndOtherFilters(tableFields || [], state.filters, toApiFilterModel(filterModel)),
      }
    case 'setAPIFilter':
      return {
        ...state,
        filters: action.payload,
      }
    default:
      return state
  }
}

type ApiResponse<T> = {
  data: T
}

type HookReturnType<T> = {
  data?: T
  error: ReturnType<typeof useSWR>['error']
  actions: {
    setPage: (n: number) => void
    setPageSize: (p: number) => void
    setSort: (s: GridSortModel) => void
    setFilter: (f: GridFilterModel, n?: Array<string>) => void
    setAPIFilter: (f: Array<Filter>) => void
  }
  tableState: VariablesState
}

type Options = {
  view?: 'trb' | 'lawyer'
  sort?: VariablesState['sort']
  filters?: VariablesState['filters']
}

type BaseQueryReturnType<Name extends string, T> = {
  [key in Name]: {
    list: Array<T | null>
  }
}

/**
 * useGridData
 *
 * You must provide the real Query Type which describes what the API returns. We generate those types by running npm run gql-gen.
 * Only this way useGridData can make guaranties about what it really returns. Do not provide own "wished" types but always rely
 * on the API.
 *
 * Together with the ObjectDataName which is __always__ the key in the Query type under which the data sits everything will work out.
 */
export const useGridData = <
  ObjectDataName extends string,
  QueryReturnType extends BaseQueryReturnType<ObjectDataName, GridRowData>
>(
  endpoint: string,
  query: string,
  dataName: ObjectDataName,
  options?: Options
): HookReturnType<QueryReturnType[typeof dataName]> => {
  const [tableState, dispatch] = useReducer(variablesReducer, {
    page: 0,
    pageSize: 50,
    sort: options?.sort ?? { sortBy: 'id', sortDirection: 'desc' },
    filters: options?.filters ?? ([] as Array<Filter>), // eslint-disable-line @typescript-eslint/consistent-type-assertions
  })
  const variables = { ...tableState, view: options?.view, page: tableState.page + 1 }
  // SWR (Stale-while-revalidate) is very lightweight, provides us a cache, a silent refresh in the background and
  // consistent checking of the response status of fetch. We don't need to manually manage loading states anymore.
  // If not data and not error => loading. See https://swr.vercel.app/
  // It is much easier and more comprehensive to use then writing everything our own but could be replaced by
  // Apollo Client in the future.
  const { data, error } = useSWR<ApiResponse<QueryReturnType>>(
    [
      endpoint,
      JSON.stringify({
        query,
        variables: variables || undefined,
      }),
    ],
    // Jumping through a loop here to be able to transmit the type variable to the fetcher. x => fx is the same as f but
    // TS doesn't allow us to do fetcher<TypeVariable> without calling it here.
    (_endpoint: string, body: string) => fetchWithBody<ApiResponse<QueryReturnType>>(_endpoint, body)
  )

  const memoizedData = useMemo(
    () =>
      data
        ? {
            ...data.data[dataName],
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            page: ((data.data[dataName] as typeof data.data[typeof dataName] & { page?: number }).page ?? 1) - 1,
          }
        : undefined,
    [data, dataName]
  )
  const actions = useMemo(() => {
    const setPage = (page: number): void => {
      dispatch({
        type: 'setPage',
        payload: page,
      })
    }
    const setPageSize = (payload: number): void => {
      dispatch({
        type: 'setPageSize',
        payload,
      })
    }
    const setSort = (sortModel: GridSortModel): void => {
      dispatch({
        type: 'setSort',
        payload: sortModel,
      })
    }
    const setFilter = (filterModel: GridFilterModel, tableFields?: Array<string>): void => {
      // We make sure that we always keep the initial filter set from options if there is any.
      dispatch({
        type: 'setFilter',
        payload: {
          preset: options?.filters,
          filterModel,
          tableFields,
        },
      })
    }
    const setAPIFilter = (payload: Array<Filter>): void => {
      dispatch({
        type: 'setAPIFilter',
        payload,
      })
    }
    return {
      setPage,
      setPageSize,
      setSort,
      setFilter,
      setAPIFilter,
    }
  }, [dispatch, options?.filters])
  return {
    data: memoizedData,
    error,
    tableState,
    actions,
  }
}
