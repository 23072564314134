import { Column, SortDirection } from 'common/interfaces'

export const DEFAULT_PAGE_SIZE = 50
export const DEFAULT_PAGE = 1
export const DEFAULT_SORT_DIRECTION = 'desc'
export const DEFAULT_SORT_BY = 'createdAt'

export const DEFAULT_LIST_STATE = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  sort: {
    sortBy: DEFAULT_SORT_BY as Column,
    sortDirection: DEFAULT_SORT_DIRECTION as SortDirection,
  },
  filters: [],
}

export const PAGE_URL = '/advice-requests'
export const NEW_URL = '/advice-requests/form'
