import React, { FC, ReactNode } from 'react'

import { ButtonRounded } from 'common/ui/Button'

import './PanelHeading.scss'
import SVGExport from '../images/icon-export.svg'
import SVGNew from '../images/icon-new.svg'

interface Props {
  title?: ReactNode
  titleIcon?: ReactNode
  statusLabel?: ReactNode
  onNew?: () => void
  onExport?: () => void
  buttons?: Array<React.ReactNode>
}

export const PanelHeading: FC<Props> = ({ buttons, titleIcon, statusLabel, title, onNew, onExport }) => (
  <div className="panel-heading">
    {title && (
      <h1 className="panel-title">
        {titleIcon} {title} {statusLabel}
      </h1>
    )}
    <div className="btn-toolbar pull-right">
      {buttons && buttons.map(button => button)}
      {!!onNew && <ButtonRounded key="new-button" iconLeft={<SVGNew height="20" width="20" />} onClick={onNew} />}
      {!!onExport && <ButtonRounded key="export-button" iconLeft={<SVGExport height="20" width="20" />} onClick={onExport} />}
    </div>
  </div>
)
