import classnames from 'classnames'
import React, { ChangeEvent, FC, useState } from 'react'
import { Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import { CheckboxField, RadioField } from 'common/components/Form'
import SVGNew from 'common/icons/New.svg'
import SVGCheck from 'common/icons/icn_check.svg'
import { ButtonPrimary } from 'common/ui/Button'
import { Procedure } from 'common/utils'

import { noRevenueReasons } from '../../constants'
import SVGBack from '../images/back.svg'
import SVGExit from '../images/exit.svg'
import SVGConfirmation from '../images/icon_bestätigung.svg'

import './CompleteSalesModal.scss'

interface CompleteSalesModalProps {
  open: boolean
  status: string
  noRevenue: boolean
  noRevenueReason: string
  success: boolean
  loading: boolean
  onCloseClick: Procedure
  onSuccessCloseClick: Procedure
  onAddSalesClick: Procedure
  onCompleteClick: Procedure
  onNoRevenueReasonChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CompleteSalesModal: FC<CompleteSalesModalProps> = ({
  open,
  status,
  noRevenue,
  noRevenueReason,
  success,
  loading,
  onCloseClick,
  onSuccessCloseClick,
  onAddSalesClick,
  onCompleteClick,
  onNoRevenueReasonChange,
}) => {
  const [noTotalRevenue, setNoTotalRevenue] = useState<boolean>(false)

  if (loading) {
    return (
      <Modal isOpen={open} className="new-modal" onClosed={() => setNoTotalRevenue(false)}>
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  if (success) {
    return (
      <Modal
        toggle={onSuccessCloseClick}
        isOpen={open}
        className="success-modal"
        id="CompleteSuccessModal"
        onClosed={() => setNoTotalRevenue(false)}
      >
        <ModalBody>
          <SVGConfirmation height="75" width="75" className="m-r-10" />
          <Label>
            {noRevenue && status === 'complete'
              ? 'Diese Akte wurde erfolgreich geschlossen.'
              : 'Diese Akte wurde erfolgreich abgelegt und geschlossen.'}
          </Label>
        </ModalBody>
        <ModalFooter>
          <ButtonPrimary onClick={onSuccessCloseClick} iconLeft={<SVGBack width="16" height="16" />}>
            Zurück zur Übersicht
          </ButtonPrimary>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Modal isOpen={open} toggle={onCloseClick} onClosed={() => setNoTotalRevenue(false)}>
      <ModalBody className="complete-sales-modal__body">
        <div className="complete-sales-modal__header">
          <SVGCheck height="20" width="20" />
          <h3 className={classnames('h3 small', 'complete-sales-modal__header__title')}>
            {status === 'complete' && noRevenue ? 'Kein Mandat' : 'Akte schließen'}
          </h3>
        </div>
        <div className="btn-cancel close-modal" onClick={onCloseClick}>
          <SVGExit height="14" width="14" />
        </div>
        <p className="complete-sales-modal__body__text">
          Stellen Sie sicher, dass sämtliche zu diesem Fall gehörigen Umsätze eingetragen wurden!
        </p>
        <ButtonPrimary
          onClick={onAddSalesClick}
          iconLeft={<SVGNew height="20" width="20" />}
          className="complete-sales-modal__body__btn"
        >
          Umsatz hinzufügen
        </ButtonPrimary>
        {noRevenue && (
          <CheckboxField
            name="noRevenue"
            id="checkbox-noRevenue"
            label="Keinen Umsatz eintragen"
            onChange={() => setNoTotalRevenue(!noTotalRevenue)}
            checked={noTotalRevenue}
          />
        )}
        {noTotalRevenue && (
          <RadioField
            vertical
            id="noRevenueReasons-radio"
            name="noRevenueReason"
            onChange={onNoRevenueReasonChange}
            value={noRevenueReason}
            options={noRevenueReasons.map(reason => ({ label: reason, value: reason }))}
          />
        )}
      </ModalBody>
      <ModalFooter className="complete-sales-modal__footer">
        <ButtonPrimary
          onClick={onCompleteClick}
          iconLeft={<SVGCheck height="20" width="20" />}
          disabled={!noTotalRevenue && noRevenue}
        >
          {status === 'complete' && noRevenue ? 'Kein Mandat' : 'Akte schließen'}
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  )
}
