import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Row, Table } from 'reactstrap'

import { FilterTags, SortFilterTh, Spinner } from 'common/components'
import { DatePickerInput, TextFilterInput } from 'common/components/FilterInput'
import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { ListProps, withList } from 'common/container'
import { formatDate } from 'common/utils'
import { fetchDataWith } from 'common/utils/fetchDataWith'

import { fetchAssignableUsers } from '../../actions'
import query from '../../graphql/adviceRequests.graphql'
import { LawyerRequest } from '../../interfaces'
import { FilterOperator } from '../../interfaces/schemaDefinition'
import { AdviceMenuButton } from '../AdviceMenu'
import { PanelTabs } from '../PanelTabs/PanelTabs'
import { ToolbarTabs } from '../ToolbarTabs'

const useAssignableUsers = fetchDataWith(fetchAssignableUsers)

type RedirectRequestsProps = ListProps<LawyerRequest> & {
  assignFilter?: string
}

const createOnSort = (func: (id: string) => void, id: string) => () => func(id)

const ClosedB2BViewInternal: FC<RedirectRequestsProps> = ({
  list,
  sort,
  page,
  pageSize,
  total,
  filters,
  onReload,
  onSort,
  onFilterChange,
  onFilterRemove,
  onNext,
  onPrev,
  loading,
}) => {
  const history = useHistory()
  const { data: assignableUsers } = useAssignableUsers()

  const onRowClick = (rowId: string) => () => {
    if (rowId) {
      history.push(`/advice-request/detail?adviceId=${rowId}`)
    }
  }

  return (
    <>
      <PanelTabs>
        <ToolbarTabs
          pagination={{ page, pageSize, total, label: 'Anträge' }}
          onNext={onNext}
          onPrev={onPrev}
          title="Gekaufte Pakete"
        />
        <Row>
          <Col sm="12">
            <Spinner condition={loading} center>
              <FilterTags filters={filters} onTagRemove={onFilterRemove} partners={[]} />
              {list.length > 0 ? (
                <Table hover responsive>
                  <thead>
                    <tr>
                      <SortFilterTh
                        id="redirectRequestsAdviceId"
                        name="adviceId"
                        sort={sort}
                        onSort={createOnSort(onSort, 'adviceId')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Beratungs ID..."
                      >
                        ID
                      </SortFilterTh>
                      <th>Produkt</th>
                      <SortFilterTh
                        id="redirectRequestsPersonName"
                        name="personName"
                        sort={sort}
                        onSort={createOnSort(onSort, 'personName')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Name..."
                      >
                        Kundenname
                      </SortFilterTh>
                      <SortFilterTh
                        id="redirectRequestsCompanyName"
                        name="companyName"
                        sort={sort}
                        onSort={createOnSort(onSort, 'companyName')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Name..."
                      >
                        Unternehmensname
                      </SortFilterTh>
                      <SortFilterTh
                        id="redirectRequestsCreatedAt"
                        name="createdAt"
                        sort={sort}
                        onSort={createOnSort(onSort, 'createdAt')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={DatePickerInput}
                        placeholder="Erstellt..."
                      >
                        Erstellt
                      </SortFilterTh>
                      <th>In Bearbeitung durch</th>
                      <th className="text-center action-header">Aktion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((adviceRequest, index) => (
                      <tr
                        key={index}
                        className={classnames('row-hover', 'row-relative', {
                          bold: !adviceRequest.read,
                        })}
                        onClick={onRowClick(adviceRequest.adviceId)}
                      >
                        <td scope="row" className="text-center">
                          {adviceRequest.adviceId}
                        </td>
                        <td className="text-center">{adviceRequest.product?.name}</td>
                        <td className="text-center">
                          {adviceRequest.person.firstname} {adviceRequest.person.lastname}
                        </td>
                        <td className="text-center">
                          {adviceRequest.company?.name} {adviceRequest.company?.companyType}
                        </td>
                        <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
                        <td className="text-center">
                          {adviceRequest.assignedTo && adviceRequest.assignedTo.name ? (
                            adviceRequest.assignedTo.name
                          ) : (
                            <span>
                              <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="inline-icon" /> Nicht zugewiesen
                            </span>
                          )}
                        </td>
                        <td className="text-center action-cell">
                          <AdviceMenuButton
                            adviceRequest={adviceRequest}
                            onAssignTo={onReload}
                            assignableUsers={assignableUsers}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h3 className="no-data">Hier gibts es noch keine Anträge</h3>
              )}
            </Spinner>
          </Col>
        </Row>
      </PanelTabs>
    </>
  )
}

export const ClosedB2BView = withList<LawyerRequest, RedirectRequestsProps>({
  query,
  endpoint: ADVICE_REQUESTS_ENDPOINT,
  responseKey: 'adviceRequests',
  searchPrefix: 'b2bclosed',
  pageSize: 10,
  queryMapper: (listState, props) => ({
    ...listState,
    filters: [
      ...listState.filters,
      ...(props.assignFilter
        ? [
            {
              name: 'assignedTo',
              operator: FilterOperator.Equals,
              value: props.assignFilter,
            },
          ]
        : []),
      {
        name: 'product.type',
        operator: FilterOperator.Equals,
        value: 'BUSINESS',
      },
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'closed',
      },
    ],
  }),
})(ClosedB2BViewInternal)
