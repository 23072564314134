import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faFileExcel,
  faFilter,
  faInfoCircle,
  faLock,
  faMinus,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faSearch,
  faSortDown,
  faSortUp,
  faTimes,
  faTrash,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faPlus,
  faMinus,
  faPencilAlt,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faFileExcel,
  faTimes,
  faUser,
  faLock,
  faFilter,
  faSortUp,
  faSortDown,
  faInfoCircle,
  faTrash,
  faPlusCircle,
  faEnvelope,
  faTimesCircle,
  faCheckCircle
)
