import { configureButton, applyProps, ButtonProps as _ButtonProps } from '@grapes-agency/ui'
import classnames from 'classnames'
import React, { FC } from 'react'

import SVGBack from 'common/icons/back.svg'
import SVGEdit from 'common/icons/edit-regular.svg'
import SVGExit from 'common/icons/exit.svg'
import SVGCheck from 'common/icons/icn_check.svg'
import SVGSave from 'common/icons/save-regular.svg'

import classes from './Button.module.scss'

const InternalButton = configureButton({ classes })

export type ButtonProps = _ButtonProps
export const Button: FC<ButtonProps> = applyProps({ type: 'button' }, InternalButton)
export const ButtonPrimary: FC<ButtonProps> = applyProps({ type: 'submit', color: 'primary' }, InternalButton)
export const ButtonSecondary: FC<ButtonProps> = applyProps({ type: 'button' }, InternalButton)
export const ButtonRounded: FC<ButtonProps> = ({ className, ...props }) => (
  <InternalButton type="button" className={classnames(classes['button--rounded'], className)} {...props} />
)
export const ButtonClear: FC<ButtonProps> = applyProps({ type: 'button', color: 'clear' }, InternalButton)
export const ButtonEdit: FC<ButtonProps> = applyProps(
  { type: 'button', color: 'primary', iconLeft: <SVGEdit height="20" width="20" /> },
  InternalButton
)
export const ButtonSave: FC<ButtonProps> = applyProps(
  { type: 'button', color: 'primary', iconLeft: <SVGSave height="20" width="20" /> },
  InternalButton
)
export const ButtonConfirm: FC<ButtonProps> = applyProps(
  { type: 'submit', color: 'primary', iconLeft: <SVGCheck height="20" width="20" /> },
  InternalButton
)
export const ButtonCancel: FC<ButtonProps> = applyProps(
  { type: 'button', iconLeft: <SVGExit height="13" width="13" /> },
  InternalButton
)
export const ButtonBack: FC<ButtonProps> = applyProps(
  { type: 'button', iconLeft: <SVGBack width="14" height="14" /> },
  InternalButton
)

/**
 * @deprecated Use ButtonSecondary
 */
export const ButtonOutline: FC<
  ButtonProps & {
    theme?: 'default' | 'danger'
  }
> = ({ className, theme = 'default', ...props }) => (
  <InternalButton
    type="button"
    className={classnames(
      {
        [classes['button--outline']]: true,
        [classes[`button--outline-${theme}`]]: true,
      },
      className
    )}
    {...props}
  />
)
