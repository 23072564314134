import React, { ReactNode } from 'react'

import { Product } from '../interfaces/schemaDefinition'

type ProductTooltips = {
  [key in keyof Product]: ReactNode
}

export const PRODUCT_TOOLTIPS: ProductTooltips = {
  id: 'Eindeutige Identifikationsnummer des Produktes',
  parentId: 'Eindeutige Identifikationsnummer des Hauptproduktes',
  name: 'Produktname für interne Prozesse',
  type: 'Produkttyp (Business/ Consumer)',
  title: 'Beworbener Produktname',
  subtitle: 'Beworbener Zusatz für den Produktnamen',
  slug: 'URL Pfad/Fragment',
  contactTypes: 'Kontaktarten für das Produkt (Direktanruf/ Rückruf/ Mail)',
  labelPrice: 'Beworbene Unverbindliche Preisempfehlung',
  price: 'Preis inkl. Steuer',
  netto: 'Preis exkl. Steuer',
  tax: 'Steuer',
  active: 'Verfügbarkeit (Aktiviert/ Deaktiviert)',
  firstActive: 'Zeitpunkt der ersten Verfügbarkeit',
  fieldsOfLaw: 'Zugewiesene Rechtsgebiete',
  features: 'Beworbene Primärfeatures (B2C) - Inhalte des Paketes (B2B)',
  secondaryFeatures:
    'Informationen für die Darstellung der Produktunterschiede (B2C) - Themen, die nicht im Paket erhalten sind (B2B)',
  uniqueSellingPoints: 'Alleinstellungsmerkmale des Produktes (maximal 4, nur B2B)',
  description: 'Beworbene Beschreibung des Produktes',
  shortDescription: 'Beschreibung des Produktes auf Rechnungen',
  categories: (
    <table>
      <tr>
        <th>Kategorie</th>
        <th>B2C</th>
        <th>B2B</th>
      </tr>
      <tr>
        <td>Highlight</td>
        <td>Beliebt-Badge</td>
        <td>Produkt wird hervorgehoben</td>
      </tr>
      <tr>
        <td>Hauptseite</td>
        <td>-</td>
        <td>Produkt wird auf der Startseite unterhalb des Hero angezeigt</td>
      </tr>
      <tr>
        <td>Festpreis</td>
        <td>Standard</td>
        <td>Produkt ist ein Festpreispaket</td>
      </tr>
      <tr>
        <td>Indiviudal</td>
        <td>-</td>
        <td>Produkt ist ein Individual-Paket</td>
      </tr>
    </table>
  ),
  createdBy: 'Ersteller des Produktes',
  services: 'ToDo-List für den Anwalt in der Fallverwaltung',
  image: 'Absolute Url zum Produktbild (https://... , nur B2B)',
  metaDescription: 'Meta Beschreibung für die Seite auf dem das Produkt dargestellt wird',
  metaTitle: 'Meta Title für die Seite auf dem das Produkt dargestellt wird',
  variants: 'Produktvarianten',
}
