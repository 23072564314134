import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandAllIcon from '@mui/icons-material/OpenWith'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import makeStyles from '@mui/styles/makeStyles'
import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'

import { PageLayout, useGridData, FilterOperator, PageInfoline, HeaderButtonGroup, ExportButton } from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import getCSVExportQuery from '../graphql/getCSVExport.graphql'
import orderVolumesQuery from '../graphql/getChancelleriesOrderVolumes.graphql'
import {
  ExportVariant,
  GetChancelleriesOrderVolumesQuery,
  GetCsvExportQuery,
  GetCsvExportQueryVariables,
} from '../interfaces/schemaDefinition'

const FILE_BASE = process.env.FILE_BASE || ''
const getExportUrl = (): Promise<string> =>
  request<GetCsvExportQuery, GetCsvExportQueryVariables>(CHANCELLERIES_ENDPOINT, getCSVExportQuery, {
    variant: ExportVariant.OrderVolume,
  }).then(result => `${FILE_BASE}/${result.createExport}`)

const useStyles = makeStyles({
  pagination: {
    display: 'inline',
    '& > ul': {
      display: 'inline-flex',
    },
  },
  accordionSummary: {
    fontWeight: 500,
  },
  accordionDetails: {
    flexWrap: 'wrap',
  },
  tableRow: {
    display: 'flex',
    '&:last-of-type td': {
      fontWeight: 500,
    },
  },
  tableCell: {
    flex: '1',
  },
})

export const ChancelleryLocationsVolumesListPage: FunctionComponent = () => {
  const { data, error, actions, tableState } = useGridData<'chancelleries', GetChancelleriesOrderVolumesQuery>(
    CHANCELLERIES_ENDPOINT,
    orderVolumesQuery,
    'chancelleries',
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      filters: [
        {
          name: 'deleted',
          operator: FilterOperator.Equals,
          value: false,
        },
      ],
    }
  )
  const [openPanels, setOpenPanels] = useState<Array<string>>([])
  const isEverythingExpanded = openPanels.length === (data?.list.length ?? 0)
  const classes = useStyles()
  const totalChancelleries = data ? data.total - data.totalDeleted : 0
  const totalPages = data ? Math.ceil(totalChancelleries / data.pageSize) : 1
  const isLoading = !data && !error
  return (
    <PageLayout heading="Standortvolumen" error={error}>
      <HeaderButtonGroup>
        <ExportButton
          getExportUrl={getExportUrl}
          hoverText="Liste aller Kanzleien als CSV-Datei herunterladen"
          dialogTitle="CSV-Export"
        />
        <Button
          variant={isEverythingExpanded ? 'outlined' : 'contained'}
          color="primary"
          onClick={() => (isEverythingExpanded ? setOpenPanels([]) : data && setOpenPanels(data.list.map(c => c.id)))}
        >
          <ExpandAllIcon titleAccess={`Alle ${isEverythingExpanded ? 'schließen' : 'aufklappen'}`} />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>Insgesamt {totalChancelleries} Kanzleien</PageInfoline>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      {data ? (
        <>
          {data.list.map(chancellery => (
            <Accordion
              key={chancellery.name}
              expanded={openPanels.includes(chancellery.id)}
              onChange={(_, expanded) =>
                expanded
                  ? setOpenPanels(openPanels.concat(chancellery.id))
                  : setOpenPanels(openPanels.filter(panelId => panelId !== chancellery.id))
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.accordionSummary }}>
                {chancellery.name}
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {chancellery.orderVolumes.length === 0 ? (
                  <Box width="100%" mb={3}>
                    <Alert severity="info">Kein Auftragsvolumen eingerichtet</Alert>
                  </Box>
                ) : (
                  <Box mb={3} width="100%">
                    <Table>
                      <TableHead>
                        <TableRow classes={{ root: classes.tableRow }}>
                          <TableCell classes={{ root: classes.tableCell }}>Rechtsgebiet</TableCell>
                          <TableCell classes={{ root: classes.tableCell }}>Wöchentlich mind.</TableCell>
                          <TableCell classes={{ root: classes.tableCell }}>Wöchentlich max.</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chancellery.orderVolumes.map(entry => (
                          <TableRow key={entry.fieldOfLaw.id} classes={{ root: classes.tableRow }}>
                            <TableCell classes={{ root: classes.tableCell }}>{entry.fieldOfLaw.name}</TableCell>
                            <TableCell classes={{ root: classes.tableCell }}>{entry.weeklyMin?.toString() ?? ''}</TableCell>
                            <TableCell classes={{ root: classes.tableCell }}>{entry.weeklyMax?.toString() ?? ''}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow classes={{ root: classes.tableRow }}>
                          <TableCell classes={{ root: classes.tableCell }}>Summe</TableCell>
                          <TableCell classes={{ root: classes.tableCell }}>
                            {chancellery.orderVolumes.reduce((sum, entry) => sum + (entry.weeklyMin || 0), 0)}
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCell }}>
                            {chancellery.orderVolumes.reduce((sum, entry) => sum + (entry.weeklyMax || 0), 0)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                )}
                <Box width="100%" textAlign="right">
                  <Button component={Link} to={`/chancelleries/edit/${chancellery.id}`} variant="contained" color="primary">
                    Ändern
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
          <Box display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              page={tableState.page}
              count={totalPages}
              rowsPerPage={tableState.pageSize}
              onPageChange={(_, page) => {
                actions.setPage(page)
                setOpenPanels([])
              }}
              onRowsPerPageChange={event => {
                actions.setPageSize(parseInt(event.target.value, 10))
                actions.setPage(0)
                setOpenPanels([])
              }}
            />
          </Box>
        </>
      ) : null}
    </PageLayout>
  )
}
