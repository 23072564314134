import { noOp } from '@grapes-agency/universal'

import { Consumer, Procedure } from 'common/utils'
import { createWebSocket } from 'common/utils/ws'

import adviceRequestAddedSubscription from '../graphql/adviceRequestAdded.graphql'
import { AdviceRequestAddedSubscriptionVariables, AdviceRequestAddedSubscription } from '../interfaces'

export const subscribeAdviceRequestAdded: (
  variables: AdviceRequestAddedSubscriptionVariables,
  onMessage: Consumer<AdviceRequestAddedSubscription>
) => Promise<Procedure> = async ({ filters }, onMessage) => {
  const client = createWebSocket()
  // eslint-disable-next-line fp/no-let
  let unsubscribe = noOp
  await new Promise<void>((resolve, reject) => {
    // eslint-disable-next-line fp/no-mutation
    unsubscribe = client.subscribe<AdviceRequestAddedSubscription>(
      {
        query: adviceRequestAddedSubscription,
        variables: { filters },
      },
      {
        next: data => {
          if (data.data) {
            onMessage(data.data)
          }
        },
        error: reject,
        complete: resolve,
      }
    )
  })

  return () => {
    unsubscribe()
    client.dispose()
  }
}
