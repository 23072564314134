import classNames from 'classnames'
import { FieldProps } from 'formik'
import React from 'react'
import Select, { OptionsType } from 'react-select'
import { FormFeedback, FormGroup, Label } from 'reactstrap'

import styles from './CustomSelect.module.scss'

export interface CustomSelectOption {
  label: string
  value: string
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<CustomSelectOption>
  isMulti?: boolean
  className?: string
  placeholder?: string
  label?: string
  error?: string
}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  label,
  form,
  options,
  error,
  isMulti = false,
}: CustomSelectProps): JSX.Element => {
  const onChange = (option: CustomSelectOption | Array<CustomSelectOption>): void => {
    form.setFieldValue(
      field.name,
      option
        ? isMulti
          ? (option as Array<CustomSelectOption>).map((item: CustomSelectOption) => item.value)
          : (option as CustomSelectOption).value
        : isMulti
        ? []
        : ''
    )
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value)
    } else {
      return isMulti ? [] : ('' as any)
    }
  }

  return (
    <FormGroup
      className={classNames(styles.select, {
        [styles.invalid]: error,
      })}
    >
      {!!label && (
        <Label for={field.name} className={styles.label}>
          {label}
        </Label>
      )}
      <Select
        className={classNames(styles.input, className)}
        name={field.name}
        value={getValue()}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
      />
      {error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  )
}
