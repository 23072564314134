import React, { Component } from 'react'
import { Omit } from 'utility-types'

import { ConfirmModal, ConfirmModalProps, disabledConfirmModalProps } from 'common/components/ConfirmModal/ConfirmModal'
import { Adjuster, Procedure, noOp } from 'common/utils'

export type showConfirmModalProps = Omit<ConfirmModalProps, 'open'>

type showConfirmModalFunc = (confirmModalProps: showConfirmModalProps) => void

export interface GlobalContextProps {
  showConfirmModal: showConfirmModalFunc
}
interface GlobalContextState {
  confirmModal: ConfirmModalProps
}

const initialState: GlobalContextState = {
  confirmModal: disabledConfirmModalProps(),
}

const initialContext: GlobalContextProps = {
  showConfirmModal: () => {},
}

export const GlobalContext: React.Context<GlobalContextProps> = React.createContext(initialContext)

export class GlobalContextProvider extends Component<{}, GlobalContextState> {
  state: GlobalContextState = { ...initialState }

  showConfirmModal: showConfirmModalFunc = confirmModalProps => {
    if (this.state.confirmModal.open) {
      throw new Error('ConfirmModal is already open')
    }
    const { question, description, onComplete, onCancel } = confirmModalProps
    const closeAndCallback: Adjuster<Procedure> = cb => () =>
      this.setState({ confirmModal: { ...initialState.confirmModal } }, cb)
    this.setState({
      confirmModal: {
        question,
        description,
        open: true,
        onComplete: closeAndCallback(onComplete),
        onCancel: closeAndCallback(onCancel || noOp),
      },
    })
  }

  render() {
    const providerState: GlobalContextProps = {
      showConfirmModal: this.showConfirmModal,
    }
    return (
      <GlobalContext.Provider value={providerState}>
        {this.props.children}
        <ConfirmModal {...this.state.confirmModal} />
      </GlobalContext.Provider>
    )
  }
}
