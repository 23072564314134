import { FC } from 'react'

import { DevkForm } from '../components/Partner/devk/DevkForm'
import { DevkSteuerrungstarifForm } from '../components/Partner/devk/DevkSteuerrungstarifForm'
import { DevkView } from '../components/Partner/devk/DevkView'
import { AdviceRequest } from '../interfaces/index'

interface PartnerFormProps {
  adviceRequest: AdviceRequest
  errors: { [key: string]: string }
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
}

interface PartnerViewProps {
  adviceRequest: Partial<AdviceRequest>
}

interface PartnerFormView {
  form: FC<PartnerFormProps>
  view: FC<PartnerViewProps>
}

export const customPartners: { [partnerId: string]: PartnerFormView } = {
  devk: {
    form: DevkForm,
    view: DevkView,
  },
  'devk-direktsteuerung': {
    form: DevkForm,
    view: DevkView,
  },
  'devk-direktsteuerung-2': {
    form: DevkForm,
    view: DevkView,
  },
  'devk-diesel': {
    form: DevkForm,
    view: DevkView,
  },
  'devk-rechtskunden': {
    form: DevkForm,
    view: DevkView,
  },
  'devk-steuerungstarif': {
    form: DevkSteuerrungstarifForm,
    view: DevkView,
  },
}

export const customPartnerIds = Object.keys(customPartners)
