import { Effect } from '@grapes-agency/universal'

import { UserContextProps } from 'common/user-context'

import { assignTo } from '../../actions'

interface AssignToMeInput {
  adviceRequestId: string
}

export const assignToMe: Effect<AssignToMeInput, void, UserContextProps> = async ({ adviceRequestId }, { user }) => {
  await assignTo(adviceRequestId, user!.id)
}
