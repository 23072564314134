import classnames from 'classnames'
import React, { CSSProperties, FC } from 'react'
import { FormFeedback, CustomInput, FormGroup } from 'reactstrap'

import './CheckboxField.scss'

interface CheckboxFieldProps {
  id: string
  name: string
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  label: string
  error?: string
  disabled?: boolean
  required?: boolean
  className?: string
  width?: string
  style?: CSSProperties
}

export const CheckboxField: FC<CheckboxFieldProps> = ({
  id,
  name,
  checked,
  onChange,
  onBlur,
  label,
  error,
  disabled = false,
  required = false,
  width,
  className,
  style,
}) => (
  <FormGroup
    className={classnames('checkbox-field', className, {
      'checkbox-field--width': !!width,
      'checkbox-field--checked': checked,
      'checkbox-field--disabled': disabled,
      'checkbox-field--invalid': error,
    })}
    style={{ flexBasis: width, ...style }}
  >
    <CustomInput
      type="checkbox"
      id={id}
      label={required ? `${label} *` : label}
      name={name}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
    {error && <FormFeedback>{error}</FormFeedback>}
  </FormGroup>
)
