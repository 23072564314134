import classnames from 'classnames'
import React, { FC } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

import './TextAreaField.scss'

interface TextAreaFieldProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string
  name: string
  value: string
  label?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  theme?: 'default' | 'white'
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

export const TextAreaField: FC<TextAreaFieldProps> = ({
  id,
  name,
  value,
  label,
  onChange,
  theme = 'default',
  required = false,
  disabled = false,
  ...htmlProps
}) => (
  <FormGroup className={classnames('textarea-field', { 'textarea-field--white': theme === 'white' })}>
    {!!label && <Label htmlFor={id}>{required ? `${label} *` : label}</Label>}
    <Input onChange={onChange} value={value} type="textarea" name={name} id={id} disabled={disabled} {...htmlProps} />
  </FormGroup>
)
