import { USERS_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils'

import query from '../graphql/getUser.graphql'
import { UserQuery } from '../interfaces/schemaDefinition'

export const fetchUser: (userId: string) => Promise<UserQuery['user']> = async userId => {
  const variables = {
    id: userId,
  }
  const response = await request<UserQuery>(USERS_ENDPOINT, query, variables)
  return response.user
}
