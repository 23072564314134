import React from 'react'

import { dateToLabel } from '../../utils'

import { StaticField } from './StaticField'

interface DateStaticFieldProps {
  label: string
  value: Date
}

export const DateStaticField = (props: DateStaticFieldProps) => (
  <StaticField label={props.label} value={dateToLabel(props.value)} />
)
