import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { useFormik } from 'formik'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Col, Input, Label, Row, Table } from 'reactstrap'

import { StaticField } from 'common/components'
import { InputField, SelectField } from 'common/components/Form'
import SVGNew from 'common/icons/New.svg'
import SVGAnhang from 'common/icons/anhang.svg'
import SVGEdit from 'common/icons/edit-regular.svg'
import SVGCheck from 'common/icons/icn_check.svg'
import SVGDelete from 'common/icons/icon-trash.svg'
import { ButtonCancel, ButtonEdit, Panel, ButtonPrimary, ButtonClear, ButtonSave } from 'common/ui'
import { currencyFormatter, formatDate, Procedure } from 'common/utils'
import { fetchDataWith } from 'common/utils/fetchDataWith'

import { assignTo, saveAdviceRequest, fetchAssignableUsers, createSalesDetailsExport } from '../actions'
import { AdviceRequest, Revenue, SaveAdviceRequestInput } from '../interfaces/schemaDefinition'
import { PARTNER_LOGOS } from '../utils/getPartnerLogos'

import { SalesStatusLabel } from './SalesStatusLabel'
import SVGExport from './images/icon_export.svg'
import SaveSVG from './images/save.svg'

import './SalesDetailView.scss'

const API_BASE = process.env.API_BASE || '/api'
const FILE_BASE = process.env.FILE_BASE || ''

interface SalesDetailViewProps {
  adviceRequest: AdviceRequest
  onAddSalesClick: Procedure
  onEditSalesClick: (sales: Revenue) => void
  onDeleteSalesClick: (sales: Revenue) => void
  onCompleteClick: Procedure
  onAddFilesChange: (event: ChangeEvent<HTMLInputElement>) => void
  onDeleteFileClick: (fileId: string) => void
}

const useAssignableUsers = fetchDataWith(fetchAssignableUsers)

// eslint-disable-next-line complexity
export const SalesDetailView: FC<SalesDetailViewProps> = ({
  adviceRequest,
  onAddSalesClick,
  onEditSalesClick,
  onDeleteSalesClick,
  onCompleteClick,
  onAddFilesChange,
  onDeleteFileClick,
}) => {
  const history = useHistory()
  const { data: assignableUsers } = useAssignableUsers()
  const [assignTarget, setAssignTarget] = useState<{ id: string; name: string } | undefined>(
    adviceRequest.assignedTo || undefined
  )
  const [editMode, setEditMode] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [adviceDetail, setAdviceDetail] = useState<SaveAdviceRequestInput>()
  const [link, setLink] = useState<string>('')

  const formik = useFormik({
    initialValues: {
      mandateName: adviceRequest.mandateName,
      assignedTo: adviceRequest.assignedTo ? adviceRequest.assignedTo.id : '',
      person: adviceRequest.person,
    },
    onSubmit: async values => {
      setSaving(true)
      const newAdviceDetail = {
        ...adviceDetail,
        mandateName: values.mandateName,
        person: {
          ...values.person,
          firstname: values.person.firstname,
          lastname: values.person.lastname,
        },
      }
      if (values.assignedTo) {
        await assignTo(adviceRequest.id, values.assignedTo)
        const assignedTo = assignableUsers.find(user => user.id === values.assignedTo)
        setAssignTarget(assignedTo)
      }
      await saveAdviceRequest(newAdviceDetail as SaveAdviceRequestInput)
      setAdviceDetail(newAdviceDetail as SaveAdviceRequestInput)
      setSaving(false)
      setEditMode(false)
    },
  })

  const { setValues } = formik

  useEffect(() => {
    setAdviceDetail({
      id: adviceRequest.id,
      fieldOfLawId: adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.id : '',
      language: 'de',
      description: adviceRequest.description,
      person: {
        foa: adviceRequest.person.foa,
        firstname: adviceRequest.person.firstname,
        lastname: adviceRequest.person.lastname,
        address: {
          street: adviceRequest.person.address.street,
          streetNr: adviceRequest.person.address.streetNr,
          city: adviceRequest.person.address.city,
          zip: adviceRequest.person.address.zip,
        },
        phone: adviceRequest.person.phone,
        email: adviceRequest.person.email || '',
      },
      company: adviceRequest.company,
      billingCompany: adviceRequest.billingCompany,
      externalNotes: adviceRequest.externalNotes,
      internalNotes: adviceRequest.internalNotes,
      resultNotes: adviceRequest.resultNotes,
      lawyerNotes: adviceRequest.lawyerNotes,
      insured: adviceRequest.insured,
      insuranceNumber: adviceRequest.insuranceNumber,
      legalProtectionInsurer: adviceRequest.legalProtectionInsurer,
      caseData: adviceRequest.caseData,
      agbsAccepted: adviceRequest.agbsAccepted,
      dataAccepted: adviceRequest.dataAccepted,
      partnerId: adviceRequest.partner ? adviceRequest.partner.id : '',
      mandateName: adviceRequest.mandateName,
    })
    setValues({
      mandateName: adviceRequest.mandateName,
      assignedTo: adviceRequest.assignedTo ? adviceRequest.assignedTo.id : '',
      person: adviceRequest.person,
    })
  }, [adviceRequest, setValues])

  const getLogo = () => {
    if (!adviceRequest.partner) {
      return '-'
    }

    const Logo = PARTNER_LOGOS[adviceRequest.partner.id]

    return !!Logo ? <Logo /> : adviceRequest.partner.name
  }

  const handleExportSalesDetails = async () => {
    const link = await createSalesDetailsExport({ adviceRequestId: adviceRequest.id })
    setLink(link)
  }

  return (
    <>
      <div className="header-container">
        <h2>Umsatzverwaltung für {adviceRequest.adviceId}</h2>
        <div className="header-container__filler" />
        <ButtonCancel onClick={() => history.push('/chancellery/sales')} />
      </div>
      <Panel>
        <form onSubmit={formik.handleSubmit}>
          <div className="case-details__header">
            <h3 className={classnames('h3 small', 'case-details__header__title')}>
              {editMode ? (
                <>
                  <SVGEdit width="20" height="20" style={{ marginRight: '8px' }} />
                  Falldetails bearbeiten
                </>
              ) : (
                'Falldetails'
              )}
            </h3>
            <div className="case-details__btn-container">
              {!editMode ? (
                <ButtonEdit iconLeft={<SVGEdit width="20" height="20" />} onClick={() => setEditMode(true)} />
              ) : (
                <>
                  <ButtonSave
                    loading={saving}
                    type="submit"
                    iconLeft={<SaveSVG height="20" width="20" />}
                    className="case-details__save-btn"
                  />
                  <ButtonCancel
                    onClick={() => {
                      formik.resetForm()
                      setEditMode(false)
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="case-details__details-container">
            {!editMode ? (
              <>
                <StaticField label="Partner" value={getLogo()} />
                <StaticField label="Beratungs-ID" value={adviceRequest.adviceId} />
                <StaticField label="Aktenname" value={adviceDetail?.mandateName || '-'} />
                <StaticField label="Bearbeiter" value={assignTarget?.name || '-'} />
                <StaticField label="Mandantenname" value={`${adviceDetail?.person.firstname} ${adviceDetail?.person.lastname}`} />
                <StaticField label="Rechtsgebiet" value={adviceRequest.fieldOfLaw?.name || '-'} />
                <StaticField
                  label="Status"
                  value={<SalesStatusLabel status={adviceRequest.status} revenue={adviceRequest.revenue!} />}
                />
              </>
            ) : (
              <>
                <InputField label="Aktenname" id="sales-input-mandateName" {...formik.getFieldProps('mandateName')} />
                <SelectField
                  options={[
                    ...(assignTarget && !assignTarget.id ? [{ value: '', label: '---Bitte wählen---' }] : []),
                    ...assignableUsers.map(user => ({
                      value: user.id,
                      label: user.name,
                    })),
                  ]}
                  label="Bearbeiter"
                  id="sales-input-assignedTo"
                  {...formik.getFieldProps('assignedTo')}
                />
                <InputField label="Mandantenvorname" id="sales-input-firstname" {...formik.getFieldProps('person.firstname')} />
                <InputField label="Mandantennachname" id="sales-input-lastname" {...formik.getFieldProps('person.lastname')} />
              </>
            )}
          </div>
        </form>
      </Panel>
      <Panel>
        <div className="sales-details__header">
          <h3 className="h3 small">Umsatzdetails</h3>
        </div>
        {adviceRequest.sales && adviceRequest.sales.entries.length > 0 ? (
          <Row>
            <Col>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th className="text-center">Rechnungsnummer</th>
                    <th className="text-center">Erfassungsdatum</th>
                    <th className="text-center">Betrag (netto)</th>
                    <th className="text-center action-header">Optionen</th>
                  </tr>
                </thead>
                <tbody className="sales-details__table-body">
                  {adviceRequest.sales.entries.map(sales => (
                    <tr key={sales.id}>
                      <td className="text-center">{sales.invoiceNumber || '-'}</td>
                      <td className="text-center">{formatDate(new Date(sales.createdAt))}</td>
                      <td className="text-center">{currencyFormatter(sales.revenue)}</td>
                      <td className="text-center action-cell">
                        <ButtonClear
                          iconLeft={<SVGEdit width="20" height="20" />}
                          onClick={() => {
                            setLink('')
                            onEditSalesClick(sales)
                          }}
                        />
                        <ButtonClear
                          iconLeft={<SVGDelete width="20" height="20" />}
                          onClick={() => {
                            setLink('')
                            onDeleteSalesClick(sales)
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td />
                    <td className="text-center">Gesamtumsatz</td>
                    <td className="text-center">{currencyFormatter(adviceRequest.revenue)}</td>
                    <td />
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <h3 className="no-data">Keine Umsätze vorhanden</h3>
        )}
        <div className="sales-details__action-buttons">
          <ButtonPrimary
            onClick={() => {
              setLink('')
              onAddSalesClick()
            }}
            iconLeft={<SVGNew height="20" width="20" />}
            className="sales-details__action-buttons__btn"
          >
            Umsatz hinzufügen
          </ButtonPrimary>
          <ButtonPrimary
            onClick={() => {
              setLink('')
              onCompleteClick()
            }}
            iconLeft={<SVGCheck height="20" width="20" />}
            disabled={!!(adviceRequest.status === 'complete' && (adviceRequest.revenue === 0 || adviceRequest.revenue))}
            className="sales-details__action-buttons__btn"
          >
            {adviceRequest.status === 'complete' && !adviceRequest.revenue ? 'Kein Mandat' : 'Akte schließen'}
          </ButtonPrimary>
          <ButtonPrimary
            onClick={handleExportSalesDetails}
            iconLeft={<SVGExport height="18" width="18" />}
            className={classnames(
              'sales-details__action-buttons__btn',
              'sales-details__action-buttons__btn__svg',
              !adviceRequest.sales && 'sales-details__action-buttons__btn__svg__disabled'
            )}
            disabled={!adviceRequest.sales}
          >
            Umsätze exportieren
          </ButtonPrimary>
        </div>
        <div className={classnames('sales-details__export', !link && 'sales-details__export__hidden')}>
          {link && (
            <a href={`${FILE_BASE}/${link}`} download onClick={() => setLink('')} className="sales-details__export__link">
              <FontAwesomeIcon icon="file-excel" size="3x" />
              <p className="sales-details__export__info">CSV-Export herunterladen</p>
            </a>
          )}
        </div>
      </Panel>
      <Panel>
        <div className="sales-files__header">
          <h3 className="h3 small">Anhänge (optional)</h3>
        </div>
        {adviceRequest.sales && adviceRequest.sales.files.length > 0 ? (
          <Row>
            <Col>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th className="text-center">Anhänge</th>
                    <th className="text-center action-header">Download</th>
                    <th className="text-center action-header">Löschen</th>
                  </tr>
                </thead>
                <tbody>
                  {adviceRequest.sales.files.map(file => (
                    <tr key={file.id}>
                      <td className="text-center">{file.name}</td>
                      <td className="text-center action-cell">
                        <div className="sales-files__file-btn">
                          <a href={`${API_BASE}/files/${file.id}/${file.name}`}>
                            <SVGExport height="20" width="20" />
                          </a>
                        </div>
                      </td>
                      <td className="text-center action-cell">
                        <ButtonClear iconLeft={<SVGDelete width="20" height="20" />} onClick={() => onDeleteFileClick(file.id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <h3 className="no-data">Keine Anhänge vorhanden</h3>
        )}
        <div className="sales-files__btn-container">
          <Label
            for="salesModalFileInput"
            className={classnames('btn btn-primary m-b-10 input-file', 'sales-modal__body__file-input', 'sales-modal__btn')}
            onClick={e => e.stopPropagation()}
          >
            <Input
              type="file"
              name="salesModalFiles"
              id="salesModalFileInput"
              data-multiple-caption="{count} files selected"
              multiple
              onChange={onAddFilesChange}
            />
            <SVGAnhang width="20" height="20" />
            Anhang hinzufügen
          </Label>
        </div>
      </Panel>
    </>
  )
}
