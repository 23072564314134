import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { PanelBody } from './PanelBody/PanelBody'
import { PanelHeading } from './PanelHeading/PanelHeading'

import './Panel.scss'

interface Props {
  title?: React.ReactNode
  subtitle?: string
  statusLabel?: ReactNode
  titleIcon?: ReactNode
  onNew?: () => void
  onExport?: () => void
  buttons?: Array<ReactNode>
  className?: string
}

export const Panel: FC<Props> = ({
  title,
  statusLabel,
  titleIcon,
  onNew,
  onExport,
  buttons = [],
  subtitle,
  children,
  className,
}) => (
  <div className={classnames('panel', className)}>
    {(title || titleIcon || buttons.length || onNew || onExport) && (
      <PanelHeading
        title={title}
        titleIcon={titleIcon}
        statusLabel={statusLabel}
        onNew={onNew}
        onExport={onExport}
        buttons={buttons}
      >
        <p>{subtitle}</p>
      </PanelHeading>
    )}
    <PanelBody>{children}</PanelBody>
  </div>
)
