export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  FilterValue: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  AccessBitSet: any;
};

export type Query = {
  __typename?: 'Query';
  chancelleries: ChancelleryList;
  chancellery: Chancellery;
  chancelleryLocation: ChancelleryLocation;
  chancelleryLocations: ChancelleryLocationList;
  createExport: Scalars['String'];
  /** Used to check if any given E-Mail is available for registration */
  emailAvailable: Scalars['Boolean'];
  groupList: Array<Group>;
  /**
   * Used for authenticating a user. Returns a jwt token in response that can be used to authenticate other requests.
   * To authenticate requests add an http header of the following form
   * ```
   *  x-access-token: <jwtToken>
   * ```
   */
  login?: Maybe<LoginResponse>;
  matchingConfig: MatchingConfig;
  matchingConfigs: MatchingConfigList;
  partner: Partner;
  partners: PartnerList;
  /** Contains high level access rights of this user */
  rights: Array<Right>;
  /** Contains high level roles of this user */
  roles: Array<Role>;
  user: User;
  userList: UserList;
  /** Used to verify an existing jwt token. Return corresponding User on success */
  verify?: Maybe<User>;
};


export type QueryChancelleriesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
};


export type QueryChancelleryArgs = {
  id: Scalars['String'];
};


export type QueryChancelleryLocationArgs = {
  id: Scalars['String'];
};


export type QueryChancelleryLocationsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
};


export type QueryEmailAvailableArgs = {
  email: Scalars['String'];
};


export type QueryLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  registerToken?: Maybe<Scalars['String']>;
};


export type QueryMatchingConfigArgs = {
  id: Scalars['String'];
};


export type QueryMatchingConfigsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
};


export type QueryPartnerArgs = {
  id: Scalars['String'];
};


export type QueryPartnersArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserListArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
};


export type QueryVerifyArgs = {
  jwtToken: Scalars['String'];
};

export type Chancellery = {
  __typename?: 'Chancellery';
  id: Scalars['String'];
  active: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  phone: Scalars['String'];
  contactPerson: ContactPerson;
  name: Scalars['String'];
  eConsult?: Maybe<EConsultSettings>;
  secupay?: Maybe<SecupaySettings>;
  powerBI?: Maybe<Array<Scalars['String']>>;
  taxId?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['Date'];
  status: ChancelleryStatus;
  orderVolumes: Array<OrderVolume>;
  chancelleryLocations: Array<ChancelleryLocation>;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  foa: Scalars['String'];
};

export type EConsultSettings = {
  __typename?: 'EConsultSettings';
  active: Scalars['Boolean'];
  customerId: Scalars['String'];
};

export type SecupaySettings = {
  __typename?: 'SecupaySettings';
  active: Scalars['Boolean'];
  contractId: Scalars['String'];
};


export enum ChancelleryStatus {
  Active = 'active',
  Inactive = 'inactive',
  Limited = 'limited',
  Archived = 'archived'
}

export type OrderVolume = {
  __typename?: 'OrderVolume';
  fieldOfLaw: FieldOfLaw;
  weeklyMin?: Maybe<Scalars['Int']>;
  weeklyMax?: Maybe<Scalars['Int']>;
};

export type FieldOfLaw = {
  __typename?: 'FieldOfLaw';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ChancelleryLocation = {
  __typename?: 'ChancelleryLocation';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  address: Address;
  callbacksDisabled?: Maybe<Scalars['Boolean']>;
  openingHours: Array<OpeningHoursValue>;
  fallbackFor: Array<FieldOfLaw>;
  fieldOfLawPhones: Array<FieldOfLawPhone>;
  holidays: Array<Holiday>;
  chancelleryId: Scalars['String'];
  chancellery: Chancellery;
  phone: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  zip: Scalars['String'];
  street: Scalars['String'];
};

export type OpeningHoursValue = {
  __typename?: 'OpeningHoursValue';
  enabled: Scalars['Boolean'];
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export type FieldOfLawPhone = {
  __typename?: 'FieldOfLawPhone';
  fieldOfLaw: FieldOfLaw;
  phone: Scalars['String'];
};

export type Holiday = {
  __typename?: 'Holiday';
  start: Scalars['Date'];
  end: Scalars['Date'];
  global?: Maybe<Scalars['Boolean']>;
  fieldsOfLaw?: Maybe<Array<FieldOfLaw>>;
};

export type Sort = {
  sortBy: Scalars['String'];
  sortDirection: SortDirection;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Filter = {
  name: Scalars['String'];
  operator: FilterOperator;
  value: Scalars['FilterValue'];
};

export enum FilterOperator {
  After = 'after',
  And = 'and',
  At = 'at',
  Before = 'before',
  Contains = 'contains',
  Empty = 'empty',
  Equals = 'equals',
  Gt = 'gt',
  Gte = 'gte',
  HasRole = 'hasRole',
  Lt = 'lt',
  Lte = 'lte',
  NotEmpty = 'notEmpty',
  Or = 'or',
  ZipAreaContains = 'zipAreaContains',
  ZipAreaEquals = 'zipAreaEquals'
}


export type ChancelleryList = {
  __typename?: 'ChancelleryList';
  list: Array<Chancellery>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
  totalInactive: Scalars['Int'];
  totalDeleted: Scalars['Int'];
  totalLimited: Scalars['Int'];
};

export type ChancelleryLocationList = {
  __typename?: 'ChancelleryLocationList';
  list: Array<ChancelleryLocation>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type MatchingConfig = {
  __typename?: 'MatchingConfig';
  id: Scalars['String'];
  chancelleryLocationId: Scalars['String'];
  chancelleryLocation: ChancelleryLocation;
  active: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  fallback: Scalars['Boolean'];
  fieldsOfLaw: FieldsOfLaw;
  zipAreas: Array<Scalars['String']>;
  products: Products;
  partners: Partners;
  priority?: Maybe<Scalars['Int']>;
  weight: ConfigWeight;
};

export type FieldsOfLaw = {
  __typename?: 'FieldsOfLaw';
  included: Array<FieldOfLaw>;
  excluded?: Maybe<Array<FieldOfLaw>>;
};

export type Products = {
  __typename?: 'Products';
  included: Array<Product>;
  excluded?: Maybe<Array<Product>>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<ProductType>;
};

export enum ProductType {
  Business = 'BUSINESS',
  Consumer = 'CONSUMER'
}

export type Partners = {
  __typename?: 'Partners';
  included: Array<Partner>;
  excluded?: Maybe<Array<Partner>>;
};

export type Partner = {
  __typename?: 'Partner';
  /** Is Partner acitve or deactivated */
  active: Scalars['Boolean'];
  /** Indicates if Partner has already accepted agb */
  agbsPreAccepted: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  /** Indicates if customer of Partner is eligible to get a discount */
  discount: Scalars['Boolean'];
  /** uuid of Partner */
  id: Scalars['String'];
  /** Legal protection insurer that is automatically set on each AdviceRequest coming from this partner */
  insurer?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Date']>;
  /** Visible name of Partner */
  name: Scalars['String'];
  /** Validation configuration for discount i.e. input of valid customer id */
  partnerValidation?: Maybe<PartnerValidation>;
  /** Visible phone of Partner */
  phone?: Maybe<Scalars['String']>;
  /** On which platforms (Business, Consumer) is Partner showed */
  platform: Platform;
};

export enum ConfigWeight {
  Much = 'MUCH',
  More = 'MORE',
  Default = 'DEFAULT',
  Less = 'LESS',
  Few = 'FEW'
}

export type MatchingConfigList = {
  __typename?: 'MatchingConfigList';
  list: Array<MatchingConfig>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAvv: Scalars['Boolean'];
  confirmAccount: Scalars['Boolean'];
  deletePartner: DeletionSuccess;
  deleteUser?: Maybe<Scalars['Int']>;
  disableUser?: Maybe<Scalars['Int']>;
  enableUser?: Maybe<Scalars['Int']>;
  googleLogin?: Maybe<LoginResponse>;
  register: Scalars['String'];
  resendConfirm: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetPasswordRequest: Scalars['Boolean'];
  saveChancellery?: Maybe<Chancellery>;
  saveChancelleryLocation?: Maybe<ChancelleryLocation>;
  saveGroups: Array<Group>;
  saveMatchingConfig?: Maybe<MatchingConfig>;
  savePartner: Partner;
  saveUser?: Maybe<User>;
  sendClientMessage: Scalars['Boolean'];
  setPassword: Scalars['Boolean'];
};


export type MutationAcceptAvvArgs = {
  userId: Scalars['String'];
};


export type MutationConfirmAccountArgs = {
  token: Scalars['String'];
};


export type MutationDeletePartnerArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationDisableUserArgs = {
  userId: Scalars['String'];
};


export type MutationEnableUserArgs = {
  userId: Scalars['String'];
};


export type MutationGoogleLoginArgs = {
  token: Scalars['String'];
  registerToken?: Maybe<Scalars['String']>;
};


export type MutationRegisterArgs = {
  user: RegisterInput;
  urlPrefix: Scalars['String'];
};


export type MutationResendConfirmArgs = {
  email: Scalars['String'];
  urlPrefix: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  resetToken: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationResetPasswordRequestArgs = {
  email: Scalars['String'];
  urlPrefix: Scalars['String'];
};


export type MutationSaveChancelleryArgs = {
  chancellery: ChancelleryInput;
};


export type MutationSaveChancelleryLocationArgs = {
  chancelleryLocation: ChancelleryLocationInput;
};


export type MutationSaveGroupsArgs = {
  groups: Array<Maybe<Scalars['String']>>;
};


export type MutationSaveMatchingConfigArgs = {
  matchingConfig: MatchingConfigInput;
};


export type MutationSavePartnerArgs = {
  partner: PartnerInput;
};


export type MutationSaveUserArgs = {
  user: UserInput;
};


export type MutationSendClientMessageArgs = {
  message: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};


export type MutationSetPasswordArgs = {
  id: Scalars['String'];
  current: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChancelleryInput = {
  id?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  email: Scalars['String'];
  contactPerson: ContactPersonInput;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  deleted?: Maybe<Scalars['Boolean']>;
  taxId?: Maybe<Scalars['String']>;
  powerBI?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  eConsult: EConsultSettingsInput;
  secupay: SecupaySettingsInput;
  orderVolumes?: Maybe<Array<OrderVolumeInput>>;
};

export type ContactPersonInput = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  foa: Scalars['String'];
};

export type EConsultSettingsInput = {
  active: Scalars['Boolean'];
  customerId: Scalars['String'];
};

export type SecupaySettingsInput = {
  active: Scalars['Boolean'];
  contractId: Scalars['String'];
};

export type OrderVolumeInput = {
  fieldOfLawId: Scalars['String'];
  weeklyMin?: Maybe<Scalars['Int']>;
  weeklyMax?: Maybe<Scalars['Int']>;
};

export type ChancelleryLocationInput = {
  id?: Maybe<Scalars['ID']>;
  chancelleryId: Scalars['String'];
  active: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  address: AddressInput;
  callbacksDisabled?: Maybe<Scalars['Boolean']>;
  openingHours: Array<OpeningHoursValueInput>;
  fallbackFor: Array<Scalars['String']>;
  fieldOfLawPhones: Array<FieldOfLawPhoneInput>;
  holidays: Array<HolidayInput>;
  phone: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  street: Scalars['String'];
  streetNr?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type OpeningHoursValueInput = {
  enabled: Scalars['Boolean'];
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export type FieldOfLawPhoneInput = {
  fieldOfLawId: Scalars['String'];
  phone: Scalars['String'];
};

export type HolidayInput = {
  start: Scalars['Date'];
  end: Scalars['Date'];
  global?: Maybe<Scalars['Boolean']>;
  fieldOfLawIds?: Maybe<Array<Scalars['String']>>;
};

export type MatchingConfigInput = {
  id?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  fallback: Scalars['Boolean'];
  chancelleryLocationId: Scalars['String'];
  zipAreas: Array<Scalars['String']>;
  fieldOfLawIds: FieldOfLawIds;
  productIds: ProductIds;
  partnerIds: PartnerIds;
  priority?: Maybe<Scalars['Int']>;
  weight: ConfigWeight;
};

export type FieldOfLawIds = {
  included: Array<Scalars['String']>;
  excluded?: Maybe<Array<Scalars['String']>>;
};

export type ProductIds = {
  included: Array<Scalars['String']>;
  excluded?: Maybe<Array<Scalars['String']>>;
};

export type PartnerIds = {
  included: Array<Scalars['String']>;
  excluded?: Maybe<Array<Scalars['String']>>;
};

export type Period = {
  __typename?: 'Period';
  daily?: Maybe<Scalars['Int']>;
  weekly?: Maybe<Scalars['Int']>;
  monthly?: Maybe<Scalars['Int']>;
};

export type PeriodInput = {
  daily?: Maybe<Scalars['Int']>;
  weekly?: Maybe<Scalars['Int']>;
  monthly?: Maybe<Scalars['Int']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}


export type PartnerList = {
  __typename?: 'PartnerList';
  list: Array<Partner>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PartnerValidation = {
  __typename?: 'PartnerValidation';
  enabled: Scalars['Boolean'];
  regex: Scalars['String'];
  intro: Scalars['String'];
};

export type Platform = {
  __typename?: 'Platform';
  business: Scalars['Boolean'];
  consumer: Scalars['Boolean'];
};

export type PartnerInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  agbsPreAccepted: Scalars['Boolean'];
  partnerValidation?: Maybe<PartnerValidationInput>;
  platform: PlatformInput;
  insurer?: Maybe<Scalars['String']>;
  discount: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
};

export type PartnerValidationInput = {
  enabled: Scalars['Boolean'];
  regex: Scalars['String'];
  intro: Scalars['String'];
};

export type PlatformInput = {
  business: Scalars['Boolean'];
  consumer: Scalars['Boolean'];
};

export type DeletionSuccess = {
  __typename?: 'DeletionSuccess';
  success: Scalars['Boolean'];
};

/** Return parameter of login query */
export type LoginResponse = {
  __typename?: 'LoginResponse';
  /** JWT Token usable for authenticating other requests. */
  jwtToken: Scalars['String'];
  user: User;
  avvRequired?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  accessBitSet?: Maybe<Scalars['AccessBitSet']>;
  active: Scalars['Boolean'];
  billingCompany?: Maybe<Company>;
  channel?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  excludedRights?: Maybe<Array<Right>>;
  groups: Array<Group>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  lastLogin?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  organisation?: Maybe<Scalars['String']>;
  organisationType?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  registeredAt: Scalars['Date'];
  rights: Array<Right>;
  roles: Array<RoleRef>;
};

export type RoleRef = {
  __typename?: 'RoleRef';
  id: Scalars['String'];
  name: Scalars['String'];
};


export type Person = {
  __typename?: 'Person';
  city?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  foa?: Maybe<Scalars['String']>;
  insuranceNumber?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  legalProtectionInsurer?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type Right = {
  __typename?: 'Right';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['String'];
  name: Scalars['String'];
  rights: Array<Right>;
};

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  foa?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  legalProtectionInsurer?: Maybe<Scalars['String']>;
};

export type RightRef = {
  __typename?: 'RightRef';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AccessTuple = {
  __typename?: 'AccessTuple';
  topic: Scalars['String'];
  action: Scalars['Int'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  name: Scalars['String'];
  companyType?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type UserList = {
  __typename?: 'UserList';
  list: Array<Maybe<User>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type UserInput = {
  id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
  organisationType?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  rights?: Maybe<Array<Scalars['String']>>;
  excludedRights?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  sendRegisterEmail?: Maybe<Scalars['Boolean']>;
  person?: Maybe<PersonInput>;
  company?: Maybe<CompanyInput>;
  billingCompany?: Maybe<CompanyInput>;
};

export type PersonInput = {
  email?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  foa?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  mobile?: Maybe<Scalars['String']>;
  insuranceNumber?: Maybe<Scalars['String']>;
  legalProtectionInsurer?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  name: Scalars['String'];
  companyType?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  taxId?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GetChancelleriesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetChancelleriesQuery = (
  { __typename?: 'Query' }
  & { chancelleries: (
    { __typename?: 'ChancelleryList' }
    & Pick<ChancelleryList, 'total'>
    & { list: Array<(
      { __typename?: 'Chancellery' }
      & Pick<Chancellery, 'id' | 'name'>
      & { chancelleryLocations: Array<(
        { __typename?: 'ChancelleryLocation' }
        & Pick<ChancelleryLocation, 'id'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'street' | 'city'>
        ) }
      )> }
    )> }
  ) }
);

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = (
  { __typename?: 'Query' }
  & { groupList: Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type GetUserListFilteredQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
}>;


export type GetUserListFilteredQuery = (
  { __typename?: 'Query' }
  & { userList: (
    { __typename?: 'UserList' }
    & Pick<UserList, 'page' | 'pageSize' | 'total'>
    & { list: Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'parentId' | 'name' | 'email' | 'active' | 'registeredAt' | 'lastLogin' | 'organisation'>
      & { roles: Array<(
        { __typename?: 'RoleRef' }
        & Pick<RoleRef, 'name'>
      )> }
    )>> }
  ) }
);

export type RightsQueryVariables = Exact<{ [key: string]: never; }>;


export type RightsQuery = (
  { __typename?: 'Query' }
  & { rights: Array<(
    { __typename?: 'Right' }
    & Pick<Right, 'id' | 'name' | 'description'>
  )> }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
    & { rights: Array<(
      { __typename?: 'Right' }
      & Pick<Right, 'name' | 'id'>
    )> }
  )> }
);

export type GetUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { userList: (
    { __typename?: 'UserList' }
    & Pick<UserList, 'page' | 'pageSize' | 'total'>
    & { list: Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'parentId' | 'name' | 'email' | 'active' | 'registeredAt' | 'lastLogin' | 'organisation'>
      & { roles: Array<(
        { __typename?: 'RoleRef' }
        & Pick<RoleRef, 'name'>
      )>, groups: Array<(
        { __typename?: 'Group' }
        & Pick<Group, 'id' | 'name'>
      )>, rights: Array<(
        { __typename?: 'Right' }
        & Pick<Right, 'id'>
      )>, excludedRights?: Maybe<Array<(
        { __typename?: 'Right' }
        & Pick<Right, 'id'>
      )>> }
    )>> }
  ) }
);

export type SaveGroupsMutationVariables = Exact<{
  groups: Array<Maybe<Scalars['String']>>;
}>;


export type SaveGroupsMutation = (
  { __typename?: 'Mutation' }
  & { saveGroups: Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type SaveUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type SaveUserMutation = (
  { __typename?: 'Mutation' }
  & { saveUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'parentId' | 'name' | 'email' | 'active' | 'organisation' | 'organisationType' | 'channel' | 'registeredAt' | 'lastLogin' | 'deleted'>
    & { roles: Array<(
      { __typename?: 'RoleRef' }
      & Pick<RoleRef, 'id' | 'name'>
    )>, groups: Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, rights: Array<(
      { __typename?: 'Right' }
      & Pick<Right, 'id' | 'name' | 'description'>
    )>, excludedRights?: Maybe<Array<(
      { __typename?: 'Right' }
      & Pick<Right, 'id' | 'name' | 'description'>
    )>> }
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'parentId' | 'name' | 'email' | 'active' | 'organisation' | 'organisationType' | 'channel' | 'registeredAt' | 'lastLogin' | 'deleted'>
    & { roles: Array<(
      { __typename?: 'RoleRef' }
      & Pick<RoleRef, 'id' | 'name'>
    )>, groups: Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, rights: Array<(
      { __typename?: 'Right' }
      & Pick<Right, 'id' | 'name' | 'description'>
    )>, excludedRights?: Maybe<Array<(
      { __typename?: 'Right' }
      & Pick<Right, 'id' | 'name' | 'description'>
    )>> }
  ) }
);
