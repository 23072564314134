export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  FilterValue: any;
};

export enum ContactType {
  Callback = 'CALLBACK',
  Email = 'EMAIL',
  NotSet = 'NOT_SET',
  Redirect = 'REDIRECT'
}

export type Filter = {
  name: Scalars['String'];
  operator: FilterOperator;
  value: Scalars['FilterValue'];
};

export enum FilterOperator {
  And = 'and',
  At = 'at',
  Contains = 'contains',
  Empty = 'empty',
  Equals = 'equals',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  NotEmpty = 'notEmpty',
  Or = 'or'
}

export type Mutation = {
  __typename?: 'Mutation';
  saveProduct: Scalars['String'];
};


export type MutationSaveProductArgs = {
  product: ProductInput;
};

export type Product = {
  __typename?: 'Product';
  /** Indicates if Product is available for purchase */
  active: Scalars['Boolean'];
  /** Categories of Product */
  categories: Array<ProductCategory>;
  /** Available ways of initiating the contact */
  contactTypes: Array<ContactType>;
  /** ID of user that created Product */
  createdBy: User;
  /** Visibile description of Product */
  description: Scalars['String'];
  /** List of visible primary features Product has to offer */
  features: Array<Scalars['String']>;
  /** Available fields of law to choose from */
  fieldsOfLaw: Array<Scalars['String']>;
  /** Date of Product being active the first time */
  firstActive?: Maybe<Scalars['Date']>;
  /** uuid of Product */
  id: Scalars['String'];
  /** Path to image of product */
  image: Scalars['String'];
  /** Descriptive price of Product e.g. recommended retail price */
  labelPrice: Scalars['Float'];
  /** Meta description */
  metaDescription: Scalars['String'];
  /** Meta title */
  metaTitle: Scalars['String'];
  /** Visible name of Product */
  name: Scalars['String'];
  /** Netto price of Product */
  netto: Scalars['Float'];
  /** id of parent Product */
  parentId?: Maybe<Scalars['String']>;
  /** Brutto price of Product */
  price: Scalars['Float'];
  /** List of visible secondary features Product has to offer */
  secondaryFeatures: Array<Scalars['String']>;
  /** List of services provided by the lawyer */
  services: Array<Scalars['String']>;
  /** Visibile short description of Product used for billing */
  shortDescription: Scalars['String'];
  /** URL part of Product */
  slug: Scalars['String'];
  /** Descriptive subtitle of Product */
  subtitle: Scalars['String'];
  /** Absolute tax amount */
  tax: Scalars['Float'];
  /** Descriptive title of Product - Visible name of Product for customers */
  title: Scalars['String'];
  /** Type of Product based on customer */
  type: ProductType;
  /** List of visible unique selling points Product has to offer */
  uniqueSellingPoints: Array<Scalars['String']>;
  /** Product variants */
  variants: Array<Product>;
};

export enum ProductCategory {
  Featured = 'FEATURED',
  Festpreis = 'FESTPREIS',
  Frontpage = 'FRONTPAGE',
  Individual = 'INDIVIDUAL'
}

export type ProductInput = {
  /** Indicates if Product is available for purchase */
  active: Scalars['Boolean'];
  /** Categories of Product */
  categories: Array<ProductCategory>;
  /** Available ways of initiating the contact */
  contactTypes: Array<ContactType>;
  /** Visibile description of Product */
  description: Scalars['String'];
  /** List of visible primary features Product has to offer */
  features: Array<Scalars['String']>;
  /** Available fields of law to choose from */
  fieldsOfLaw: Array<Scalars['String']>;
  /** uuid of Product */
  id: Scalars['String'];
  /** Path to image of product */
  image: Scalars['String'];
  /** Descriptive price of Product e.g. recommended retail price */
  labelPrice: Scalars['Float'];
  /** Meta description */
  metaDescription: Scalars['String'];
  /** Meta title */
  metaTitle: Scalars['String'];
  /** Name of Product */
  name: Scalars['String'];
  /** Actual net price of Product */
  netto?: InputMaybe<Scalars['Float']>;
  /** id of parent Product */
  parentId?: InputMaybe<Scalars['String']>;
  /** Actual pre-tax price of Product */
  price?: InputMaybe<Scalars['Float']>;
  /** List of visible secondary features Product has to offer */
  secondaryFeatures: Array<Scalars['String']>;
  /** List of services provided by the lawyer */
  services: Array<Scalars['String']>;
  /** Visibile short description of Product used for billing */
  shortDescription: Scalars['String'];
  /** URL part of Product */
  slug: Scalars['String'];
  /** Descriptive subtitle of Product */
  subtitle: Scalars['String'];
  /** Descriptive title of Product - Visible name of Product for customers */
  title: Scalars['String'];
  /** Type of Product based on customer */
  type: ProductType;
  /** List of visible unique selling points Product has to offer */
  uniqueSellingPoints: Array<Scalars['String']>;
};

export enum ProductType {
  Business = 'BUSINESS',
  Consumer = 'CONSUMER'
}

export type ProductsList = {
  __typename?: 'ProductsList';
  list: Array<Product>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** Check if single Product is available based on id */
  isProductIdAvailable: Scalars['Boolean'];
  /** Return a single Product based on id */
  product: Product;
  /** Return a paginated list of Products */
  products: ProductsList;
};


export type QueryIsProductIdAvailableArgs = {
  id: Scalars['String'];
};


export type QueryProductArgs = {
  id: Scalars['String'];
};


export type QueryProductsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};

export type Sort = {
  /** Name of a sortable field */
  sortBy: Scalars['String'];
  /** Sorting direction. Either ascending or descending */
  sortDirection: SortDirection;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IsProductIdAvailableQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type IsProductIdAvailableQuery = { __typename?: 'Query', isProductIdAvailable: boolean };

export type ProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, parentId?: string | null, name: string, type: ProductType, title: string, subtitle: string, slug: string, contactTypes: Array<ContactType>, labelPrice: number, price: number, netto: number, tax: number, active: boolean, firstActive?: any | null, fieldsOfLaw: Array<string>, features: Array<string>, secondaryFeatures: Array<string>, uniqueSellingPoints: Array<string>, description: string, shortDescription: string, categories: Array<ProductCategory>, services: Array<string>, image: string, metaDescription: string, metaTitle: string, createdBy: { __typename?: 'User', id: string, name: string }, variants: Array<{ __typename?: 'Product', id: string, parentId?: string | null, name: string, type: ProductType, title: string, subtitle: string, slug: string, contactTypes: Array<ContactType>, labelPrice: number, price: number, netto: number, tax: number, active: boolean, firstActive?: any | null, fieldsOfLaw: Array<string>, features: Array<string>, secondaryFeatures: Array<string>, uniqueSellingPoints: Array<string>, description: string, shortDescription: string, categories: Array<ProductCategory>, services: Array<string>, image: string, metaDescription: string, metaTitle: string, createdBy: { __typename?: 'User', id: string, name: string } }> } };

export type SaveProductMutationVariables = Exact<{
  product: ProductInput;
}>;


export type SaveProductMutation = { __typename?: 'Mutation', saveProduct: string };
