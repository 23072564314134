import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import React, { FC, useState } from 'react'

import { FieldSet, RadioField, FieldRow } from 'common/components/Form'
import { Modal, ModalBody, ModalFooter } from 'common/components/Modal'
import SVGExit from 'common/icons/exit.svg'
import { ButtonPrimary } from 'common/ui/Button'
import { LoggedInUser } from 'common/user-context'
import { isAdmin, isChannel, isEmployee, isTRB, isTRBManager } from 'common/utils/roles'

import { createExport } from '../actions'
import { ExportType, Filter, Sort } from '../interfaces'

interface ExportModalProps {
  open: boolean
  filters: Array<Filter>
  sort: Sort
  onCancelClick: () => void
  user?: LoggedInUser
}

export const ExportModal: FC<ExportModalProps> = ({ open, filters, sort, user, onCancelClick }) => {
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState('')

  const trbMode = isTRB(user) || isTRBManager(user) || isChannel(user)
  const employeeMode = isAdmin(user) || isEmployee(user)

  const fetchAndStoreExportLink = async (values: { exportType: ExportType }) => {
    setLoading(true)

    const link = await createExport({
      sort,
      filters,
      type: values.exportType as ExportType,
      view: trbMode ? 'trb' : undefined,
    })

    setLink(link)
    setLoading(false)
  }

  const formik = useFormik({
    initialValues: { exportType: ExportType.Controlling },
    onSubmit: fetchAndStoreExportLink,
  })

  const onClose = () => {
    formik.resetForm()
    setLink('')
    onCancelClick()
  }

  return (
    <Modal toggle={onClose} className="new-modal medium-size" isOpen={open}>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <div className="btn-cancel close-modal" onClick={onClose}>
            <SVGExit height="14" width="14" />
          </div>
          {!!link && (
            <a href={`/${link}`} download>
              <FontAwesomeIcon icon="file-excel" size="3x" />
            </a>
          )}
          {!link && (
            <FieldSet label="Export Typ">
              <FieldRow>
                <RadioField
                  id="exportType-radio"
                  options={
                    employeeMode
                      ? [
                          { label: 'Controlling', value: ExportType.Controlling },
                          { label: 'Ekomi', value: ExportType.Ekomi },
                          { label: 'ASCII', value: ExportType.Ascii },
                        ]
                      : [{ label: 'Auswertung', value: ExportType.Controlling }]
                  }
                  {...formik.getFieldProps('exportType')}
                />
              </FieldRow>
            </FieldSet>
          )}
        </ModalBody>
        <ModalFooter>
          {!link && (
            <ButtonPrimary type="submit" loading={loading}>
              Absenden
            </ButtonPrimary>
          )}

          {!!link && <ButtonPrimary onClick={onClose}>Zurück</ButtonPrimary>}
        </ModalFooter>
      </form>
    </Modal>
  )
}
