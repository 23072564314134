import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, IconButton, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FunctionComponent } from 'react'

import SVGKlugoLogo from 'common/icons/klugo-logo-white.svg'
import { ConfigurationTestDialogButton } from 'packages-mui/chancelleries'

import { Profile } from './profile/Profile'

interface HeaderPanelProps {
  onMenuToggle: () => void
}

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  button: {
    [theme.breakpoints.up('maxContentWidth')]: {
      display: 'none',
    },
    [`&:focus`]: {
      outline: 'none',
    },
  },
  logo: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  toolbarGutters: {
    paddingLeft: '16px',
  },
}))

export const HeaderPanel: FunctionComponent<HeaderPanelProps> = ({ onMenuToggle }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isXL = useMediaQuery(theme.breakpoints.up('maxContentWidth'))
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar classes={{ gutters: classes.toolbarGutters }}>
        <IconButton
          className={classes.button}
          edge="start"
          color="inherit"
          onClick={e => {
            e.stopPropagation()
            onMenuToggle()
          }}
          size="large"
        >
          <MenuIcon titleAccess="Seitenmenü aufklappen" />
        </IconButton>
        <ConfigurationTestDialogButton color="inherit" edge={isXL ? 'start' : false} />
        <SVGKlugoLogo className={classes.logo} width={93} height={30} style={{ fill: '#fff' }} />
        <Profile />
      </Toolbar>
    </AppBar>
  )
}
