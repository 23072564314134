import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import { Spinner } from 'common/components'
import { SearchContainer } from 'common/components/SearchField'
import { Roles, ROLES, ROLE_IDS } from 'common/constants'
import { Role } from 'common/interfaces'
import { LoggedInUser, UserContextProps, withUser } from 'common/user-context'
import { isLawyer } from 'common/utils'
import { fetchDataWith } from 'common/utils/fetchDataWith'

import { groupAdviceRequests } from '../actions/groupAdviceRequests'
import { searchAdviceRequest } from '../actions/searchAdviceRequest'

import { AllRequests } from './AllRequests'
import { ClosedRequests } from './ClosedRequests'
import { CompleteMandateRequests } from './CompleteMandateRequests'
import { MandateRequests } from './MandateRequests'
import { OpenCallbackRequests } from './OpenCallbackRequests'
import { OpenEmailRequets } from './OpenEmailRequests'
import { OpenRedirectRequests } from './OpenRedirectRequests'
import { PotentialMandateRequests } from './PotentialMandateRequests'
import './Search.scss'

const useSearch = fetchDataWith(searchAdviceRequest)

const mapRoleNameToRole: (user?: LoggedInUser) => Role | undefined = user => {
  let statusRole: Role | undefined
  if (user) {
    user.roles.forEach(role => {
      switch (role) {
        case Roles.Administrator:
          statusRole = ROLES[ROLE_IDS.Administrator]
          return
        case Roles.Employee:
          statusRole = ROLES[ROLE_IDS.Employee]
          return
        case Roles.Callcenter:
          statusRole = statusRole || ROLES[ROLE_IDS.Callcenter]
          break
        case Roles.Channel:
          statusRole = statusRole || ROLES[ROLE_IDS.Channel]
          break
        case Roles.TRB:
          statusRole = statusRole || ROLES[ROLE_IDS.TRB]
          break
        case Roles.TRBManager:
          statusRole = statusRole || ROLES[ROLE_IDS.TRBManager]
          break
        case Roles.Lawyer:
          statusRole = statusRole || ROLES[ROLE_IDS.Lawyer]
          break
        default:
          break
      }
    })
  }
  return statusRole
}

const SearchInternal: FC<RouteComponentProps<{ searchTerm: string }> & UserContextProps> = ({ user, match }) => {
  const isTrendUser = user ? user.roles.includes(Roles.Channel) : false
  const statusRole: Role | undefined = mapRoleNameToRole(user)
  const { searchTerm } = match.params
  const { data, isLoading } = useSearch({ search: searchTerm })
  const { list = [] } = data || { list: [] }
  const groups = groupAdviceRequests(list)

  if (!statusRole) {
    return null
  }

  return (
    <>
      <SearchContainer value={searchTerm} live className="m-b-10">
        <span className="search-results">Ihre Suchergebnisse: {list.length}</span>
      </SearchContainer>
      <Row className="m-b-20">
        <Col sm="12">
          <div className="panel-tabs">
            {searchTerm && (
              <Spinner condition={isLoading} center>
                {isLawyer(user) ? (
                  <>
                    <OpenRedirectRequests adviceRequests={groups.redirect} />
                    <OpenCallbackRequests adviceRequests={groups.callback} />
                    <OpenEmailRequets adviceRequests={groups.email} />
                    <PotentialMandateRequests adviceRequests={groups.potential} />
                    <ClosedRequests adviceRequests={groups.closed} />
                    <MandateRequests adviceRequests={groups.mandate} />
                    <CompleteMandateRequests adviceRequests={groups.complete} />
                  </>
                ) : (
                  <AllRequests role={statusRole} fixedStatus={isTrendUser} adviceRequests={list} />
                )}
              </Spinner>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export const Search = withUser(SearchInternal)
