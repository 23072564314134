export const WEEK_DAYS: { [key: string]: string } = {
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donerstag',
  5: 'Freitag',
  6: 'Samstag',
  0: 'Sonntag',
}

export const WEEK_DAY_ENTRIES = Object.entries(WEEK_DAYS).map(([k, v]) => ({ id: k, name: v }))
