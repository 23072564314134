import * as R from 'ramda'

import { ISO_3166_EU } from './iso3165Eu'

const sortByName = R.sortBy(R.prop('name'))

export const COUNTRY_OPTIONS: Array<{ id: string; name: string }> = sortByName(
  ISO_3166_EU.map(alpha2 => ({
    id: alpha2,
    name: new Intl.DisplayNames(['de'], { type: 'region' }).of(alpha2) || 'ERROR',
  }))
)
