import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/chancelleryLocations.graphql'
import { ChancelleryLocationsQuery } from '../interfaces/schemaDefinition'

export const getChancelleryLocations = async (): Promise<ChancelleryLocationsQuery['chancelleryLocations']['list']> => {
  const response = await request<ChancelleryLocationsQuery>(CHANCELLERIES_ENDPOINT, query)
  return response.chancelleryLocations.list
}
