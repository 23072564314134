export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  FilterValue: any;
  AnyPrimitive: any;
  /** A Standard Javascript Date in ISO-8601 format */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  /** Returns a paginated list of AdviceRequests. Will contain only AdviceRequests that the user has access to */
  adviceRequests: AdviceRequestList;
  /** Returns a single AdviceRequest */
  adviceRequest: AdviceRequest;
  /** Creates a .csv export of all AdviceRequests matching the given filters and returns a path to the file for download */
  createExport: Scalars['String'];
  /** Creates a .csv export of all sales matching the given filters and returns a path to the file for download */
  createSalesExport: Scalars['String'];
  /** Creates a .csv export of all sales entries of an AdviceRequest and returns a path to the file for download */
  createSalesDetailsExport: Scalars['String'];
  /** @Deprecated counting API for outdated controlling pages */
  adviceRequestsCount: AdviceRequestsCount;
  /** Performs a full-text search over all AdviceRequests. Will only find AdviceRequests that the user has access to */
  searchAdviceRequest: AdviceRequestList;
  /** Returns all users that your User could assign to an AdviceRequest */
  assignableUsers: Array<User>;
  /** Public API to check if a given field of law has premium chancelleries assigned to it */
  hasPremium: Scalars['Boolean'];
  /** Public API to check if a given input would be matchable */
  canMatch: Scalars['Boolean'];
  /** Returns an object containing several sets of chancelleries that matched the given input. See MatchResult for details */
  matchChancelleries: MatchResult;
};


export type QueryAdviceRequestsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
  interval?: Maybe<Interval>;
  view?: Maybe<Scalars['String']>;
};


export type QueryAdviceRequestArgs = {
  adviceId: Scalars['String'];
};


export type QueryCreateExportArgs = {
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
  type?: Maybe<ExportType>;
  view?: Maybe<Scalars['String']>;
  includeSensitveData?: Maybe<Scalars['Boolean']>;
};


export type QueryCreateSalesExportArgs = {
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
  view?: Maybe<Scalars['String']>;
};


export type QueryCreateSalesDetailsExportArgs = {
  adviceRequestId: Scalars['String'];
  view?: Maybe<Scalars['String']>;
};


export type QueryAdviceRequestsCountArgs = {
  filters?: Maybe<Array<Filter>>;
  interval?: Maybe<Interval>;
};


export type QuerySearchAdviceRequestArgs = {
  search: Scalars['String'];
};


export type QueryHasPremiumArgs = {
  fieldOfLawId: Scalars['String'];
};


export type QueryCanMatchArgs = {
  matchInput: MatchInput;
};


export type QueryMatchChancelleriesArgs = {
  matchInput: MatchInput;
};

/** Sorting parameter */
export type Sort = {
  /** Name of a sortable field */
  sortBy: Scalars['String'];
  /** Sorting direction. Either ascending or descending */
  sortDirection: SortDirection;
};

/** Sorting direction */
export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Filter = {
  name: Scalars['String'];
  operator: FilterOperator;
  value: Scalars['FilterValue'];
};

export enum FilterOperator {
  NotEmpty = 'notEmpty',
  Empty = 'empty',
  Equals = 'equals',
  Contains = 'contains',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  ZipAreaEquals = 'zipAreaEquals',
  ZipAreaContains = 'zipAreaContains',
  Before = 'before',
  After = 'after',
  At = 'at',
  Or = 'or',
  And = 'and',
  NotEquals = 'notEquals'
}


export enum Interval {
  Hour = 'hour',
  DayOfMonth = 'dayOfMonth',
  DayOfWeek = 'dayOfWeek',
  WeekOfYear = 'weekOfYear',
  Month = 'month'
}

/** List of AdviceRequests */
export type AdviceRequestList = {
  __typename?: 'AdviceRequestList';
  /** Array ot the actual AdviceRequest Objects */
  list: Array<AdviceRequest>;
  /** Page used in generating array contents */
  page: Scalars['Int'];
  /** Pagesize used in generating array contents */
  pageSize: Scalars['Int'];
  /** Total number of documents available */
  total: Scalars['Int'];
};

/** Main Type of this service. Contains all data about the created advice request. */
export type AdviceRequest = {
  __typename?: 'AdviceRequest';
  /** Generated unique uuid */
  id: Scalars['String'];
  /** Generated 4-character adviceId that can be given to clients */
  adviceId: Scalars['String'];
  /**
   * Field of law for this AdviceRequest. Must be one of currently supported:
   *   * Verkehrsrecht
   *   * Arbeitsrecht
   *   * Familienrecht
   *   * Fahrradrecht
   *   * Steuerrecht
   *   * Urheberrecht
   *   * Medizinrecht
   *   * Mietrecht
   *   * Erbrecht
   *   * Versicherungsrecht
   *   * Baurecht
   *   * Bankrecht
   *   * DSGVO / Datenschutz
   *   * Vertragsrecht
   *   * Strafrecht
   *   * Sonstige Rechtsfragen
   *   * Musterfeststellungsklage
   *   * Wohnungseigentumsrecht
   *   * Reiserecht
   *   * Dieselskandal
   *   * Schadensersatz
   *   * Sozialrecht
   *   * Asylrecht
   *   * Ordnungswidrigkeiten
   *   * Wettbewerbsrecht
   *   * Gesellschaftsrecht
   *   * Markenrecht
   *   * Widerruf Darlehen/Kredit
   * 
   * Note that for these id and name are equal.
   */
  fieldOfLaw?: Maybe<FieldOfLaw>;
  /** Clients description of this case */
  description?: Maybe<Scalars['String']>;
  /** The client information related to this AdviceRequest */
  person: Person;
  /** The company information related to this AdviceRequest */
  company?: Maybe<Company>;
  /** The billing company information related to this AdviceRequest */
  billingCompany?: Maybe<Company>;
  /** Additional notes that will be visible to the lawyer that receives this AdviceRequest */
  externalNotes?: Maybe<Scalars['String']>;
  /** Additional notes that will NOT be visible to lawyer */
  internalNotes?: Maybe<Scalars['String']>;
  /** Insurance number of the client if he has one */
  insuranceNumber?: Maybe<Scalars['String']>;
  /** The way of initiating the contact to the Chancellery. See ContactType for details */
  contactType?: Maybe<ContactType>;
  /** The chancellery assigned to this AdviceRequest */
  chancellery?: Maybe<Chancellery>;
  /** The partner associated to this AdviceRequest. Mainly indicates where the client is coming from before arriving at KLUGO. Must be an existing Partner */
  partner?: Maybe<Partner>;
  /** Contains any additional semi-structured field data. Mainly used for additional data required by some Partners. */
  caseData?: Maybe<Array<CaseField>>;
  /** Status of the AdviceRequest */
  status: Scalars['String'];
  /** Indicates if client has a legal protection */
  insured: Scalars['Boolean'];
  /** Name of clients insurance company if he has one contracted */
  legalProtectionInsurer?: Maybe<Scalars['String']>;
  /** Indicates if client whiches to receive KLUGO newsletter */
  newsletter: Scalars['Boolean'];
  /** Indicates if client accepted recovation policy. Must be true, otherwise AdviceRequest cannot be created */
  revocation?: Maybe<Scalars['Boolean']>;
  /** Date of creation of this AdviceRequest */
  createdAt: Scalars['Date'];
  /** @Deprecated will be removed and can be found in history */
  forwardedAt?: Maybe<Scalars['Date']>;
  /** Indicates if client has accepted the KLUGO AGBs. Must be true, otherwise AdviceRequest cannot be created */
  agbsAccepted: Scalars['Boolean'];
  /** Indicates if client has accepted the KLUGO 'Einwilligungserklärung'. If true KLUGO gets access to more of the clients data */
  dataAccepted?: Maybe<Scalars['Boolean']>;
  /** Array of events that happend on this AdviceRequest. Mainly used for controlling */
  history?: Maybe<Array<History>>;
  /** Lawyers notes describing the result of first contact to client */
  resultNotes?: Maybe<Scalars['String']>;
  /** Additional lawyers notes not related to some event */
  lawyerNotes?: Maybe<Scalars['String']>;
  /** The reason for closing this AdviceRequest */
  closeReason?: Maybe<Scalars['String']>;
  /** Date at which this AdviceRequest should be picked up again by lawyer. Used by lawyers 'setToPotential' usecase */
  reminderDate?: Maybe<Scalars['Date']>;
  /** Mandate ID. Only used by lawyer when upgrading AdviceRequest to Mandate */
  mandateId?: Maybe<Scalars['String']>;
  /** Mandate Name. Only used by lawyer when upgrading AdviceRequest to Mandate */
  mandateName?: Maybe<Scalars['String']>;
  /** Additional disputeAmount field. Used by lawyers */
  disputeAmount?: Maybe<Scalars['Float']>;
  /** Total revenue generated by Mandate. Input by lawyers */
  revenue?: Maybe<Scalars['Float']>;
  /** List of all sales (generated by Mandate) and related documents. Input by lawyers */
  sales?: Maybe<Sales>;
  /** Date and time when client whiches to be called back. Only used when ContactType is CALLBACK */
  preferredCallbackTime?: Maybe<Scalars['Date']>;
  /** Array of files attached by client. (May be modified by lawyer later) */
  files?: Maybe<Array<File>>;
  /** KLUGO Product associated to this AdviceRequest */
  product?: Maybe<Product>;
  /** User that created this AdviceRequest. Only used when AdviceRequest is created by backend application. Only used when AdviceRequest is created by backend application */
  createdBy?: Maybe<User>;
  /** Indicates if AdviceRequest has been opened at least once by lawyer */
  read?: Maybe<Scalars['Boolean']>;
  /** @Deprecated */
  lockedBy?: Maybe<Scalars['String']>;
  /** Can be used by lawyer to assigned AdviceRequest to other lawyers inside his Chancellery */
  assignedTo?: Maybe<User>;
  /** Generated KLUGO invoice. Only present if product has a price greater 0 */
  invoice?: Maybe<Invoice>;
  /** The actual price after discounts including tax that the client paid for this AdviceRequest */
  actualPrice?: Maybe<Scalars['Float']>;
  proposal?: Maybe<Proposal>;
  payment?: Maybe<Payment>;
};

/** Field of law reference object */
export type FieldOfLaw = {
  __typename?: 'FieldOfLaw';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Client that as initiated an AdviceRequest */
export type Person = {
  __typename?: 'Person';
  /** Herr / Frau */
  foa?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  address: MatchAddress;
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

/** Address of client. Usable for MatchInput */
export type MatchAddress = {
  __typename?: 'MatchAddress';
  /** Also known as PostalCode */
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
};

/** Company that as initiated an AdviceRequest */
export type Company = {
  __typename?: 'Company';
  name: Scalars['String'];
  companyType?: Maybe<Scalars['String']>;
  address?: Maybe<MatchAddress>;
  taxId?: Maybe<Scalars['String']>;
};

/** Indicates way of contacting matched Chancellery. */
export enum ContactType {
  /** Default value */
  NotSet = 'NOT_SET',
  /** Means a phone call that is redirect to the matched Chancellery immediately */
  Redirect = 'REDIRECT',
  /** Client will receive a phone call at set date and time and receive his advice */
  Callback = 'CALLBACK',
  /** Client will receive a phone call from the matched Chancellery at some point in a time-window after matching */
  Email = 'EMAIL'
}

export type Chancellery = {
  __typename?: 'Chancellery';
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  specialities: Array<Speciality>;
  activities: Array<Activity>;
  languages: Array<Language>;
  address: Address;
};

/** @Deprecated */
export type Speciality = {
  __typename?: 'Speciality';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** @Deprecated */
export type Activity = {
  __typename?: 'Activity';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Language type */
export type Language = {
  __typename?: 'Language';
  /** ISO-639-1 language code */
  code: Scalars['String'];
};

/** Address of chancellery */
export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  zip: Scalars['String'];
  street: Scalars['String'];
};

export type Partner = {
  __typename?: 'Partner';
  id: Scalars['String'];
  name: Scalars['String'];
  insured?: Maybe<Scalars['Boolean']>;
  agbsPreAccepted: Scalars['Boolean'];
  phone: Scalars['String'];
};

export type CaseField = {
  __typename?: 'CaseField';
  name: Scalars['String'];
  value: Scalars['AnyPrimitive'];
};



export type History = {
  __typename?: 'History';
  event: Scalars['String'];
  date: Scalars['Date'];
  user: Scalars['String'];
};

export type Sales = {
  __typename?: 'Sales';
  entries: Array<Revenue>;
  files: Array<File>;
};

export type Revenue = {
  __typename?: 'Revenue';
  id: Scalars['String'];
  createdAt: Scalars['Date'];
  revenue: Scalars['Float'];
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['String'];
  name: Scalars['String'];
  data?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  netto?: Maybe<Scalars['Float']>;
  type?: Maybe<ProductType>;
  services?: Maybe<Array<Scalars['String']>>;
};

export enum ProductType {
  Business = 'BUSINESS',
  Consumer = 'CONSUMER'
}

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  invoiceNumber: Scalars['String'];
  fileId: Scalars['String'];
  fileName: Scalars['String'];
};

export type Proposal = {
  __typename?: 'Proposal';
  amount: Scalars['Float'];
  status: ProposalStatus;
};

export enum ProposalStatus {
  InvoiceSent = 'invoiceSent',
  Accepted = 'accepted',
  Rejected = 'rejected'
}

export type Payment = {
  __typename?: 'Payment';
  receiverAccount?: Maybe<PaymentAccount>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  iban: Scalars['String'];
  bic: Scalars['String'];
  owner: Scalars['String'];
  bankname: Scalars['String'];
  purpose: Scalars['String'];
};

export enum ExportType {
  Controlling = 'controlling',
  Ekomi = 'ekomi',
  Ascii = 'ascii'
}

export type AdviceRequestsCount = {
  __typename?: 'AdviceRequestsCount';
  total: Scalars['Int'];
  intervalCounts: Array<IntervalCount>;
};

export type IntervalCount = {
  __typename?: 'IntervalCount';
  date: Scalars['Date'];
  value: Scalars['Int'];
};

/** Parameter object of matchChancelleries  */
export type MatchInput = {
  /** Changes matching to only contain chancelleries that have this productId configured */
  productId?: Maybe<Scalars['String']>;
  /** Indicates if premium match should be included. Only used for 'premium-advanded' products */
  premium: Scalars['Boolean'];
  /** Address of MatchInput. Must contain at least a valid PostalCode / Zip */
  address: MatchAddressInput;
  /** Field of law ID used for Matching. See AdviceRequest for supported IDs */
  fieldOfLaw: Scalars['String'];
  /** Language used for Matching. Only a soft filter. Matching will fall back to default language if needed. */
  language: Scalars['String'];
  /** ContactType for Matching. If ContactType is REDIRECT matching will include opening times of chancellieries. Use if intention is to redirect client to chancellery per phone after matching */
  contactType?: Maybe<ContactType>;
  /** Partner ID used for Matching. Must be one of partners returned by partners API */
  partnerId?: Maybe<Scalars['String']>;
};

/** Address usable for Matching. At least a zip code is required. Only german addresses are available. If Matching with full address returns 'address_not_found' error, a Matching using only zip code can be tried */
export type MatchAddressInput = {
  name?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

/** Result of a match query */
export type MatchResult = {
  __typename?: 'MatchResult';
  /** Contains premium chancelleries. Currentyl only used for 'premium-advanced' product */
  premiumChancelleries: Array<MatchedChancellery>;
  /** Local chancelleries that matched given MatchInput */
  chancelleries: Array<MatchedChancellery>;
  /** Contains matching chancelleries associated to lawyer. Only used if matching User has reference to Chancelleries */
  ownChancelleries: Array<MatchedChancellery>;
  /** Central chancelleries that matched given MatchInput. Should be used fallback if locals are not sufficient */
  centralChancelleries?: Maybe<Array<MatchedChancellery>>;
  /** Central chancellery that matched given MatchInput. Should be used fallback if locals are not sufficient */
  centralChancellery?: Maybe<MatchedChancellery>;
};

/** Chancellery returned by matching */
export type MatchedChancellery = {
  __typename?: 'MatchedChancellery';
  /** Unique uuid string */
  id: Scalars['String'];
  /** Phone number of chancellery used for redirecting AdviceRequest to chancellery */
  phone: Scalars['String'];
  /** Name of the chancellery */
  name: Scalars['String'];
  /** Address of the chancellery */
  address: Address;
  /** Fields of law that this chancellery supports */
  fieldsOfLaw: Array<FieldOfLaw>;
  /** @Deprecated */
  specialities: Array<Speciality>;
  /** @Deprecated */
  activities: Array<Activity>;
  /** Languages that this chancellery supports */
  languages: Array<Language>;
  /** Calculated distance of Address in MatchInput to Address of this Chancellery */
  distance: Scalars['Float'];
  /** Indicates if this Chancellery handles callbacks themselves or if callbacks should be given to callcenter */
  callbacksDisabled?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Public API to create an AdviceRequest. Mainly used in klugo.de/erstberatung. See CreateResponse for return type */
  createAdviceRequest: CreateResponse;
  /** Saves given partial AdviceRequest. See SaveAdviceRequestInput for details which fields can be saved. Returns id of AdviceRequest on success */
  saveAdviceRequest: Scalars['String'];
  /** Finishes a matching, assigns the adviceRequest to the chancelleryId given in FinishMatchingInput and sets the status to 'at_chancellery'. After this the adviceRequest will show up in the views of the assigned chancellery. See FinishMatchingInput for more details. Returns id of AdviceRequest on success */
  finishMatching: Scalars['String'];
  /** Creates an empty AdviceRequest with a newly generated adviceId, stores in the database and returns if. As name suggests this is used for the TRB usecase */
  startTRB: AdviceRequest;
  /** Saves the given internal note on the AdviceRequest reference in InternalNoteInput. Returns id of AdviceRequest on success */
  saveInternalNote: Scalars['String'];
  /** Sets the status of the referenced AdviceRequest to 'cancelled'. Use for misclicked or otherwise falsly create AdviceRequests. This will also take the AdviceRequest away from the chancellery, so they cannot see it anymore. Returns id of AdviceRequest on success */
  cancelRequest: Scalars['String'];
  /** Will send the KLUGO AGBs and 'Einwilligungserklärung' to the given clients E-Mail for review. See SendAGBsInput for details */
  sendAGBs: Scalars['String'];
  createResubmission: Scalars['String'];
  /** Sets the status of the referenced AdviceRequest to 'closed'. Use for standard closing process. Returns id of AdviceRequest on success */
  setToClosed: Scalars['String'];
  setToPotential: Scalars['String'];
  setToMandate: Scalars['String'];
  sendToEConsult: Scalars['String'];
  setToComplete: Scalars['String'];
  setToProcessing: Scalars['String'];
  redirectRequest: Scalars['String'];
  saveAttachments: Scalars['String'];
  readAdviceRequest: Scalars['String'];
  lockAdviceRequest: Scalars['String'];
  unlockAdviceRequest: Scalars['String'];
  assignTo: Scalars['String'];
  contactInsurer: Scalars['String'];
  createPayment: PaymentResponse;
  updatePayment: Scalars['Boolean'];
  confirmAdvice: Scalars['Date'];
  rematch: Scalars['String'];
  createSales: Scalars['String'];
  updateSales: Scalars['String'];
  deleteSales: Scalars['String'];
  saveSalesAttachments: Scalars['String'];
  deleteSalesAttachment: Scalars['String'];
  proposalAccepted: PaymentAccount;
  proposalInvoiceSent: Scalars['String'];
  proposalRejected: Scalars['String'];
};


export type MutationCreateAdviceRequestArgs = {
  adviceRequest: AdviceRequestInput;
  overridePerson?: Maybe<Scalars['Boolean']>;
  urlPrefix?: Maybe<Scalars['String']>;
};


export type MutationSaveAdviceRequestArgs = {
  adviceRequest: SaveAdviceRequestInput;
};


export type MutationFinishMatchingArgs = {
  finishMatching: FinishMatchingInput;
};


export type MutationSaveInternalNoteArgs = {
  internalNote: InternalNoteInput;
};


export type MutationCancelRequestArgs = {
  cancelRequestInput: CancelRequestInput;
};


export type MutationSendAgBsArgs = {
  sendAGBsInput: SendAgBsInput;
};


export type MutationCreateResubmissionArgs = {
  adviceRequestId: Scalars['String'];
};


export type MutationSetToClosedArgs = {
  closeInput: CloseInput;
  unassign?: Maybe<Scalars['Boolean']>;
};


export type MutationSetToPotentialArgs = {
  potentialInput: PotentialInput;
};


export type MutationSetToMandateArgs = {
  mandateInput: MandateInput;
  sendToEConsult?: Maybe<Scalars['Boolean']>;
};


export type MutationSendToEConsultArgs = {
  adviceRequestId: Scalars['String'];
};


export type MutationSetToCompleteArgs = {
  completeInput: CompleteInput;
};


export type MutationSetToProcessingArgs = {
  processingInput: ProcessingInput;
};


export type MutationRedirectRequestArgs = {
  adviceRequestId: Scalars['String'];
  chancelleryId: Scalars['String'];
};


export type MutationSaveAttachmentsArgs = {
  adviceRequestId: Scalars['String'];
  files: Array<FileInput>;
};


export type MutationReadAdviceRequestArgs = {
  adviceRequestId: Scalars['String'];
  read: Scalars['Boolean'];
};


export type MutationLockAdviceRequestArgs = {
  adviceRequestId: Scalars['String'];
};


export type MutationUnlockAdviceRequestArgs = {
  adviceRequestId: Scalars['String'];
};


export type MutationAssignToArgs = {
  adviceRequestId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationContactInsurerArgs = {
  contactInput: ContactInput;
};


export type MutationCreatePaymentArgs = {
  payment: CreatePaymentInput;
  urlPrefix: Scalars['String'];
};


export type MutationUpdatePaymentArgs = {
  adviceRequest: AdviceRequestInput;
  paymentId: Scalars['String'];
  paymentMethod: PaymentMethodInput;
};


export type MutationConfirmAdviceArgs = {
  adviceRequestId: Scalars['String'];
};


export type MutationRematchArgs = {
  adviceRequestId: Scalars['String'];
  chancelleryId: Scalars['String'];
  fieldOfLawId: Scalars['String'];
};


export type MutationCreateSalesArgs = {
  adviceRequestId: Scalars['String'];
  revenue: RevenueInput;
};


export type MutationUpdateSalesArgs = {
  adviceRequestId: Scalars['String'];
  revenueId: Scalars['String'];
  revenue: RevenueInput;
};


export type MutationDeleteSalesArgs = {
  adviceRequestId: Scalars['String'];
  revenueId: Scalars['String'];
};


export type MutationSaveSalesAttachmentsArgs = {
  adviceRequestId: Scalars['String'];
  files: Array<FileInput>;
};


export type MutationDeleteSalesAttachmentArgs = {
  adviceRequestId: Scalars['String'];
  fileId: Scalars['String'];
};


export type MutationProposalAcceptedArgs = {
  adviceRequestId: Scalars['String'];
  amount: Scalars['Float'];
  urlPrefix: Scalars['String'];
};


export type MutationProposalInvoiceSentArgs = {
  adviceRequestId: Scalars['String'];
};


export type MutationProposalRejectedArgs = {
  adviceRequestId: Scalars['String'];
};

export type AdviceRequestInput = {
  fieldOfLawId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  person: PersonInput;
  company?: Maybe<CompanyInput>;
  billingCompany?: Maybe<CompanyInput>;
  files: Array<FileInput>;
  insured: Scalars['Boolean'];
  newsletter: Scalars['Boolean'];
  adviceType: AdviceType;
  insuranceNumber?: Maybe<Scalars['String']>;
  legalProtectionInsurer?: Maybe<Scalars['String']>;
  contactType: ContactType;
  preferredCallbackTime?: Maybe<Scalars['Date']>;
  partnerId: Scalars['String'];
  productId: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  clientNumber?: Maybe<Scalars['String']>;
  agbsAccepted: Scalars['Boolean'];
  dataAccepted: Scalars['Boolean'];
  revocation: Scalars['Boolean'];
  payment?: Maybe<PaymentInput>;
};

/** Input type for an AdviceRequest client */
export type PersonInput = {
  /** Herr / Frau */
  foa?: Maybe<Scalars['String']>;
  /** Firstname of the client. Only optional when Product is free */
  firstname?: Maybe<Scalars['String']>;
  /** Lastname of the client. Only optional when Product is free */
  lastname?: Maybe<Scalars['String']>;
  /** Matchable address of the client */
  address: MatchAddressInput;
  /** Phone number of client. Required because Lawyer needs it to contact Client */
  phone: Scalars['String'];
  /** E-Mail address of client. Required because Lawyer needs it to contact Client */
  email: Scalars['String'];
};

/** Input type for an AdviceRequest company */
export type CompanyInput = {
  name: Scalars['String'];
  companyType?: Maybe<Scalars['String']>;
  address?: Maybe<MatchAddressInput>;
  taxId?: Maybe<Scalars['String']>;
};

export type FileInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum AdviceType {
  NotSet = 'NOT_SET',
  First = 'FIRST',
  Second = 'SECOND',
  Change = 'CHANGE'
}

export type PaymentInput = {
  id?: Maybe<Scalars['String']>;
  payerId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  method: PaymentMethodInput;
  provider: PaymentProvider;
  authToken?: Maybe<Scalars['String']>;
  account?: Maybe<BankAccountInput>;
};

export type PaymentMethodInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum PaymentProvider {
  Klarna = 'KLARNA',
  Secupay = 'SECUPAY',
  Paypal = 'PAYPAL'
}

export type BankAccountInput = {
  iban: Scalars['String'];
  bic: Scalars['String'];
  owner: Scalars['String'];
};

/** Response of createAdviceRequest */
export type CreateResponse = {
  __typename?: 'CreateResponse';
  /** Genrated 4-character adviceId */
  id: Scalars['String'];
  /** Phone number that should be displayed to client. Only used if ContactType is REDIRECT */
  redirectPhone?: Maybe<Scalars['String']>;
  /** Token used for registration after creating an AdviceRequest. Must be used if the created AdviceRequest should be associated to the new Account. */
  token?: Maybe<Scalars['String']>;
};

export type SaveAdviceRequestInput = {
  /** uuid of the AdviceRequest that should be saved. If undefined a new AdviceRequest is created */
  id?: Maybe<Scalars['String']>;
  /** Field of law ID used for this AdviceRequest. See AdviceRequest for supported IDs */
  fieldOfLawId: Scalars['String'];
  /** Preferred language of the client */
  language: Scalars['String'];
  /** Clients description of this case */
  description?: Maybe<Scalars['String']>;
  /** The client information related to this AdviceRequest */
  person: PersonInput;
  /** The company information related to this AdviceRequest */
  company?: Maybe<CompanyInput>;
  /** The billing company information related to this AdviceRequest */
  billingCompany?: Maybe<CompanyInput>;
  /** Additional notes that will be visible to the lawyer that receives this AdviceRequest */
  externalNotes?: Maybe<Scalars['String']>;
  /** Additional notes that will NOT be visible to lawyer */
  internalNotes?: Maybe<Scalars['String']>;
  /** Lawyers notes describing the result of first contact to client */
  resultNotes?: Maybe<Scalars['String']>;
  /** dviceRequestAdditional lawyers notes not related to some event */
  lawyerNotes?: Maybe<Scalars['String']>;
  /** Indicates if client has a legal protection */
  insured?: Maybe<Scalars['Boolean']>;
  /** Insurance number of the client if he has one */
  insuranceNumber?: Maybe<Scalars['String']>;
  /** Name of clients insurance company if he has one contracted */
  legalProtectionInsurer?: Maybe<Scalars['String']>;
  /** Contains any additional semi-structured field data. Mainly used for additional data required by some Partners. */
  caseData?: Maybe<Array<CaseFieldInput>>;
  /** Indicates if client has accepted the KLUGO AGBs. Must be true, otherwise AdviceRequest cannot be created. When creating AdviceRequests with certain Partner IDs, this can be set to true automatically. Defaults to false */
  agbsAccepted?: Maybe<Scalars['Boolean']>;
  /** Indicates if client has accepted the KLUGO 'Einwilligungserklärung'. If true KLUGO gets access to more of the clients data. Defaults to false */
  dataAccepted?: Maybe<Scalars['Boolean']>;
  /** id of the Partner that should associated to this AdviceRequest */
  partnerId?: Maybe<Scalars['String']>;
  /** Mandate Name. Only used by lawyer when upgrading AdviceRequest to Mandate */
  mandateName?: Maybe<Scalars['String']>;
  /** Starting timestamp of the preferred callback time range */
  preferredCallbackTime?: Maybe<Scalars['Date']>;
};

export type CaseFieldInput = {
  name: Scalars['String'];
  value: Scalars['AnyPrimitive'];
};

export type FinishMatchingInput = {
  /** uuid of an existing AdviceRequests if one exists. If undefined is given, a new AdviceRequest will be created */
  id?: Maybe<Scalars['String']>;
  /** Field of law ID used for this AdviceRequest. See AdviceRequest for supported IDs */
  fieldOfLawId: Scalars['String'];
  /** Preferred language of the client */
  language: Scalars['String'];
  /** Clients description of this case */
  description?: Maybe<Scalars['String']>;
  /** The client information related to this AdviceRequest */
  person: PersonInput;
  /** The company information related to this AdviceRequest */
  company?: Maybe<CompanyInput>;
  /** The billing company information related to this AdviceRequest */
  billingCompany?: Maybe<CompanyInput>;
  /** Additional notes that will be visible to the lawyer that receives this AdviceRequest */
  externalNotes?: Maybe<Scalars['String']>;
  /** Additional notes that will NOT be visible to lawyer */
  internalNotes?: Maybe<Scalars['String']>;
  /** Indicates if client has a legal protection */
  insured?: Maybe<Scalars['Boolean']>;
  /** Insurance number of the client if he has one */
  insuranceNumber?: Maybe<Scalars['String']>;
  /** Name of clients insurance company if he has one contracted */
  legalProtectionInsurer?: Maybe<Scalars['String']>;
  /** The way of initiating the contact to the Chancellery. See ContactType for details */
  contactType?: Maybe<ContactType>;
  /** uuid of the Chancellery that should be matched to this AdviceRequest */
  chancelleryId?: Maybe<Scalars['String']>;
  /** id of the Partner that should associated to this AdviceRequest */
  partnerId: Scalars['String'];
  /** Contains any additional semi-structured field data. Mainly used for additional data required by some Partners. */
  caseData?: Maybe<Array<CaseFieldInput>>;
  /** Indicates if client has accepted the KLUGO AGBs. Must be true, otherwise AdviceRequest cannot be created. When creating AdviceRequests with certain Partner IDs, this can be set to true automatically. */
  agbsAccepted: Scalars['Boolean'];
  /** Indicates if client has accepted the KLUGO 'Einwilligungserklärung'. If true KLUGO gets access to more of the clients data */
  dataAccepted: Scalars['Boolean'];
  /** Date and time when client whiches to be called back. Only used when ContactType is CALLBACK */
  preferredCallbackTime?: Maybe<Scalars['Date']>;
};

/** Parameter object for saveInternalNote */
export type InternalNoteInput = {
  /** 4-character adviceId */
  adviceId: Scalars['String'];
  /** Text field for internal notes */
  internalNotes: Scalars['String'];
};

/** Parameter object for cancelRequest */
export type CancelRequestInput = {
  /** 4-character adviceId */
  adviceId: Scalars['String'];
};

/** Parameter object for sendAGBs. Contains only information needed to fill an E-Mail text to send to the client */
export type SendAgBsInput = {
  /** A clients E-Mail address */
  email: Scalars['String'];
  /** 4-character adviceId of the corresponding AdviceRequest */
  adviceId: Scalars['String'];
  /** Herr / Frau */
  foa?: Maybe<Scalars['String']>;
  /** Firstname of the client */
  firstname?: Maybe<Scalars['String']>;
  /** Lastname of the client */
  lastname?: Maybe<Scalars['String']>;
};

/** Parameter object for setToClosed */
export type CloseInput = {
  /** uuid of the AdviceRequest */
  id: Scalars['String'];
  /** Lawyes result notes. Can be empty string */
  resultNotes: Scalars['String'];
  /** Additional lawyers notes. Can be empty string */
  lawyerNotes: Scalars['String'];
  /** Reason for closing */
  closeReason: Scalars['String'];
};

export type PotentialInput = {
  id: Scalars['String'];
  resultNotes: Scalars['String'];
  lawyerNotes: Scalars['String'];
  reminderDate: Scalars['Date'];
};

export type MandateInput = {
  id: Scalars['String'];
  resultNotes: Scalars['String'];
  lawyerNotes: Scalars['String'];
  mandateName: Scalars['String'];
};

export type CompleteInput = {
  id: Scalars['String'];
  revenue?: Maybe<Scalars['Float']>;
  noRevenueReason?: Maybe<Scalars['String']>;
};

export type ProcessingInput = {
  id: Scalars['String'];
};

export type ContactInput = {
  messageType: MessageType;
  adviceId: Scalars['String'];
  fieldOfLaw: Scalars['String'];
  description: Scalars['String'];
  person: PersonInput;
  company?: Maybe<CompanyInput>;
  billingCompany?: Maybe<CompanyInput>;
  caseData: Array<CaseFieldInput>;
  insuranceNumber: Scalars['String'];
  message: Scalars['String'];
  attachments: Array<FileInput>;
  chancellery: ContactChancelleryInput;
  blindCarbonCopy?: Maybe<Array<Scalars['String']>>;
  caseId?: Maybe<Scalars['String']>;
  partyName?: Maybe<Scalars['String']>;
};

export enum MessageType {
  CoverageRequest = 'COVERAGE_REQUEST',
  Invoice = 'INVOICE',
  Contact = 'CONTACT'
}

export type ContactChancelleryInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
  address: Scalars['String'];
  zip: Scalars['String'];
  city: Scalars['String'];
};

export type CreatePaymentInput = {
  person: PersonInput;
  company?: Maybe<CompanyInput>;
  billingCompany?: Maybe<CompanyInput>;
  productId: Scalars['String'];
  payment: PaymentInput;
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  paypal?: Maybe<Paypal>;
  klarna?: Maybe<Klarna>;
  secupay?: Maybe<Secupay>;
  price: Scalars['Float'];
};

export type Paypal = {
  __typename?: 'Paypal';
  paymentId: Scalars['String'];
  approvalUrl?: Maybe<Scalars['String']>;
};

export type Klarna = {
  __typename?: 'Klarna';
  sessionId: Scalars['String'];
  clientToken: Scalars['String'];
  paymentMethods?: Maybe<Array<KlarnaPaymentMethod>>;
};

export type KlarnaPaymentMethod = {
  __typename?: 'KlarnaPaymentMethod';
  identifier: Scalars['String'];
  name: Scalars['String'];
  icon: Scalars['String'];
};

export type Secupay = {
  __typename?: 'Secupay';
  id: Scalars['String'];
  iFrameUrl?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
};

export type RevenueInput = {
  revenue: Scalars['Float'];
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  adviceRequestAdded: AdviceRequest;
};


export type SubscriptionAdviceRequestAddedArgs = {
  filters?: Maybe<Array<Filter>>;
};

export enum HistoryEventType {
  AdviceConfirmed = 'adviceConfirmed',
  SetToComplete = 'setToComplete',
  SetToMandate = 'setToMandate',
  SetToPotential = 'setToPotential',
  SetToClosed = 'setToClosed',
  SetToProcessing = 'setToProcessing',
  Cancelled = 'cancelled',
  AssignedToChancellery = 'assignedToChancellery',
  CoverageRequest = 'coverageRequest',
  AwaitingCallback = 'awaitingCallback',
  RedirectedAdviceRequest = 'redirectedAdviceRequest',
  SentToEConsult = 'sentToEConsult',
  Rematched = 'rematched',
  ProposalAccepted = 'proposalAccepted',
  ProposalInvoiceSent = 'proposalInvoiceSent',
  ProposalRejected = 'proposalRejected'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type CreateExportQueryVariables = Exact<{
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
  type?: Maybe<ExportType>;
  view?: Maybe<Scalars['String']>;
}>;


export type CreateExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'createExport'>
);

export type AdviceRequestsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Sort>;
  filters?: Maybe<Array<Filter>>;
  view?: Maybe<Scalars['String']>;
}>;


export type AdviceRequestsQuery = (
  { __typename?: 'Query' }
  & { adviceRequests: (
    { __typename?: 'AdviceRequestList' }
    & Pick<AdviceRequestList, 'page' | 'pageSize' | 'total'>
    & { list: Array<(
      { __typename?: 'AdviceRequest' }
      & Pick<AdviceRequest, 'id' | 'adviceId' | 'contactType' | 'status' | 'createdAt'>
      & { fieldOfLaw?: Maybe<(
        { __typename?: 'FieldOfLaw' }
        & Pick<FieldOfLaw, 'id' | 'name'>
      )>, chancellery?: Maybe<(
        { __typename?: 'Chancellery' }
        & Pick<Chancellery, 'id' | 'name'>
      )>, partner?: Maybe<(
        { __typename?: 'Partner' }
        & Pick<Partner, 'id' | 'name'>
      )>, person: (
        { __typename?: 'Person' }
        & Pick<Person, 'foa' | 'firstname' | 'lastname' | 'phone' | 'email'>
        & { address: (
          { __typename?: 'MatchAddress' }
          & Pick<MatchAddress, 'zip' | 'city' | 'street' | 'streetNr'>
        ) }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )>, product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      )> }
    )> }
  ) }
);
