import { Box, Alert, AlertTitle } from '@mui/material'
import dayjs from 'dayjs'
import React, { FunctionComponent } from 'react'

import { useFallbackCoverage } from './useFallbackCoverage'

export const FallbackIssueWarnings: FunctionComponent = () => {
  const result = useFallbackCoverage()
  if (result.data) {
    const { missingProblems, temporaryMissingProblems } = result.data
    const fallbacksMissing = missingProblems?.map(p => p.fieldOfLaw?.name ?? '')
    const fallbacksOnHolidays = temporaryMissingProblems?.map(p => ({
      ...p,
      fieldOfLaw: p.fieldOfLaw?.name ?? '',
      info: {
        // eslint-disable-next-line fp/no-mutating-methods
        periods: p.info.periods.sort((a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()),
      },
    }))
    return (
      <Box mb={3}>
        {fallbacksMissing.length ? (
          <Alert severity="warning">
            <AlertTitle>Es gibt keine Fallback-Konfigurationen für folgende Rechtsgebiete:</AlertTitle>
            {fallbacksMissing.join(', ')}
          </Alert>
        ) : null}
        {fallbacksOnHolidays?.length ? (
          <Alert severity="warning">
            <AlertTitle>
              Wegen Urlaubszeiten der beteiligten Standorte sind folgende Rechtsgebiete nicht immer abgedeckt:
            </AlertTitle>
            {fallbacksOnHolidays.flatMap(issue =>
              issue.info.periods.map(period => (
                <Box key={issue.fieldOfLaw + period.start} display="grid" gridTemplateColumns="180px 1fr">
                  <div>{issue.fieldOfLaw}</div>
                  <div>
                    {dayjs(period.start).format('DD.MM.YYYY')}–{dayjs(period.end).format('DD.MM.YYYY')}
                  </div>
                </Box>
              ))
            )}
          </Alert>
        ) : null}
      </Box>
    )
  }
  return null
}
