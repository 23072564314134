export enum FilterOperator {
  NotEmpty = 'notEmpty',
  Empty = 'empty',
  Equals = 'equals',
  NotEquals = 'notEquals',
  Contains = 'contains',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  ZipAreaEquals = 'zipAreaEquals',
  ZipAreaContains = 'zipAreaContains',
  Before = 'before',
  After = 'after',
  At = 'at',
  Or = 'or',
  And = 'and',
}
