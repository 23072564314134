/**
 * This component makes sure that a user sees the correct text representation for a given advice status
 * depending on the user's intended role.
 *
 * Note that the role is not necessary the actual user's role, but what role the visible page is made for.
 * I.E. a user that is both TRB and lawyer would see TRB label text on the TRB page, but laywer label text
 * on the lawyer page.
 *
 * @see https://klugo-gmbh.atlassian.net/wiki/spaces/KLUG/pages/618791061/Status+Konzept
 */

import React from 'react'

import { Role } from 'common/api/types'
import { Badge, BadgeProps } from 'common/components-mui'
import { ROLES, ROLE_IDS } from 'common/constants'

import { AdviceRequestsQuery } from '../interfaces'

type AdviceRequestStatus = (AdviceRequestsQuery['adviceRequests']['list'] extends Array<infer U> ? U : never)['status']

interface AdviceStatusLabel {
  color: BadgeProps['color']
  label: string
}

interface StatusTextEntry {
  [key: string]: string
}

/* Role-Id -> status -> label text */
export const STATUS_TEXT: { [key: string]: StatusTextEntry } = {
  [ROLES[ROLE_IDS.Administrator].id]: {
    new: 'Neu',
    awaiting_call: 'Erwarte Anruf',
    awaiting_callback: 'Erwarte Rückruf',
    resubmission: 'Terminerinnerung',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    at_chancellery: 'Offen',
    potential: 'Wiedervorlage',
    mandate: 'Mandat',
    processing: 'In Bearbeitung',
    closed: 'Geschlossen',
    complete: 'Abgelegt',
  },
  [ROLES[ROLE_IDS.Employee].id]: {
    new: 'Neu',
    awaiting_call: 'Erwarte Anruf',
    awaiting_callback: 'Erwarte Rückruf',
    resubmission: 'Terminerinnerung',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    at_chancellery: 'Offen',
    potential: 'Wiedervorlage',
    mandate: 'Mandat',
    processing: 'In Bearbeitung',
    closed: 'Geschlossen',
    complete: 'Abgelegt',
  },
  [ROLES[ROLE_IDS.Lawyer].id]: {
    at_chancellery: 'Offen',
    awaiting_callback: 'Erwarte Anruf',
    potential: 'Wiedervorlage',
    mandate: 'Mandat',
    processing: 'In Bearbeitung',
    closed: 'Geschlossen',
    complete: 'Abgelegt',
  },
  [ROLES[ROLE_IDS.Callcenter].id]: {
    new: 'Neu',
    editing: 'In Bearbeitung',
    awaiting_call: 'Erwarte Anruf',
    awaiting_callback: 'Erwarte Rückruf',
    resubmission: 'Terminerinnerung',
    cancelled: 'Storniert',
    at_chancellery: 'Zugeordnet',
    potential: 'Zugeordnet',
    mandate: 'Zugeordnet',
    processing: 'Zugeordnet',
    closed: 'Zugeordnet',
    complete: 'Zugeordnet',
  },
  [ROLES[ROLE_IDS.TRB].id]: {
    new: 'Neu',
    resubmission: 'Wiedervorlage',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    awaiting_callback: 'Erwarte Rückruf',
    at_chancellery: 'Zugeordnet',
    potential: 'Zugeordnet',
    mandate: 'Zugeordnet',
    processing: 'Zugeordnet',
    closed: 'Geschlossen',
    complete: 'Zugeordnet',
  },
  [ROLES[ROLE_IDS.TRBManager].id]: {
    new: 'Neu',
    resubmission: 'Terminerinnerung',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    awaiting_callback: 'Erwarte Rückruf',
    at_chancellery: 'Zugeordnet',
    potential: 'Zugeordnet',
    mandate: 'Zugeordnet',
    processing: 'Zugeordnet',
    closed: 'Geschlossen',
    complete: 'Zugeordnet',
  },
  [ROLES[ROLE_IDS.Channel].id]: {
    new: 'Neu',
    resubmission: 'Wiedervorlage',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    at_chancellery: 'Zugeordnet',
    awaiting_callback: 'Zugeordnet',
    potential: 'Zugeordnet',
    mandate: 'Zugeordnet',
    processing: 'Zugeordnet',
    closed: 'Geschlossen',
    complete: 'Zugeordnet',
  },
}

export const STATUS_COLORS: { [key: string]: BadgeProps['color'] } = {
  new: 'warning',
  awaiting_call: 'warning',
  awaiting_callback: 'info',
  resubmission: 'info',
  editing: 'warning',
  cancelled: 'default',
  at_chancellery: 'success',
  processing: 'success',
  potential: 'success',
  mandate: 'success',
  closed: 'success',
  complete: 'success',
}

const toStatusLabel: (role: Role, status: AdviceRequestStatus) => AdviceStatusLabel | undefined = (role, status) => {
  const label = STATUS_TEXT[role.id][status]
  const color = label === 'Zugeordnet' ? 'success' : STATUS_COLORS[status]
  if (color && label) {
    return { color, label }
  }
  return undefined
}

export interface AdviceListStatusProps {
  role?: Role
  status: AdviceRequestStatus
}

export const AdviceListStatusLabel: React.FunctionComponent<AdviceListStatusProps> = ({ role, status }) => {
  const isValidRole = role && ROLES.some(r => r.id === role.id)
  if (!role || !isValidRole) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('Supplied role for AdviceListStatusLabel is not a valid role:', role)
    }
    return null
  }
  const statusLabel = toStatusLabel(role, status)

  if (!statusLabel) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`Illegal combination of role (${role.id}) and status (${status})`)
    }
    return null
  }

  return (
    <Badge className="badge-status" color={statusLabel.color}>
      {statusLabel.label}
    </Badge>
  )
}
