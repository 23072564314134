import { omit, pickAll } from 'ramda'
import React, { ChangeEventHandler, MouseEventHandler, ReactEventHandler } from 'react'
import { NavLink } from 'react-router-dom'
import { Input } from 'reactstrap'

import { CheckboxField } from 'common/components/Form'
import { GlobalContext, GlobalContextProps, showConfirmModalProps } from 'common/components/GlobalContext'
import { Button, ButtonRounded } from 'common/ui/Button'

const confirmAction: (cp: showConfirmModalProps) => <T>(gmcp: GlobalContextProps) => ReactEventHandler<T> =
  ({ question, description, onComplete, onCancel }) =>
  ({ showConfirmModal }) =>
  event => {
    showConfirmModal({
      question,
      description,
      onComplete,
      onCancel,
    })
    event.preventDefault()
    event.stopPropagation()
  }

const extractConfirmProps = pickAll(['question', 'description', 'onComplete', 'onCancel'])
const extractComponentProps = omit(['question', 'description', 'onComplete', 'onCancel'])

interface ClickComponent {
  onClick?: MouseEventHandler
}

// since typescript 3.5.1 this does not compile which makes no sense to me
// any input/research of how to get rid of these anys is very welcome
const ConfirmClickElement: <C extends ClickComponent>(CT: React.ComponentType<C>) => React.FC<C & showConfirmModalProps> =
  (CT: any) => props =>
    (
      <GlobalContext.Consumer>
        {context => <CT {...extractComponentProps(props)} onClick={confirmAction(extractConfirmProps(props))(context)} />}
      </GlobalContext.Consumer>
    )

interface ChangeComponent {
  onChange?: ChangeEventHandler
}

const ConfirmInputElement: <C extends ChangeComponent>(CT: React.ComponentType<C>) => React.FC<C & showConfirmModalProps> =
  (CT: any) => props =>
    (
      <GlobalContext.Consumer>
        {context => <CT {...extractComponentProps(props)} onChange={confirmAction(extractConfirmProps(props))(context)} />}
      </GlobalContext.Consumer>
    )

export const ConfirmButton = ConfirmClickElement(ButtonRounded)
export const ConfirmSecondaryButton = ConfirmClickElement(Button)
export const ConfirmNavLink = ConfirmClickElement(NavLink)
export const ConfirmInput = ConfirmInputElement(Input)
export const ConfirmCheckbox = ConfirmInputElement(CheckboxField)
