import { ADVICE_REQUESTS_ENDPOINT, STATUS_AT_CHANCELLERY } from 'common/constants'
import { LoggedInUser } from 'common/user-context'
import { request } from 'common/utils'
import { getChancelleryFilter } from 'packages/lawyers/utils'

import query from '../graphql/adviceTotalCount.graphql'
import { AdviceTotalCountQuery, FilterOperator } from '../interfaces'

export const getOpenRequestsCount = async (user: LoggedInUser, chancelleryLocations: Array<{ id: string }>): Promise<number> => {
  const response: AdviceTotalCountQuery = await request<AdviceTotalCountQuery>(ADVICE_REQUESTS_ENDPOINT, query, {
    filters: [
      getChancelleryFilter(user, chancelleryLocations),
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: STATUS_AT_CHANCELLERY,
      },
    ],
  })

  return response.adviceRequestsCount.total
}
