import React, { useCallback, ChangeEventHandler, FC } from 'react'
import { FormFeedback, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import SVGTrash from 'common/icons/icon-trash.svg'
import { ButtonPrimary } from 'common/ui'

import { File } from '../../interfaces'
import Anhang from '../images/anhang.svg'
import SVGExit from '../images/exit.svg'
import Export from '../images/icon_export.svg'

import './FilesModal.scss'

interface FilesModalProps {
  files: Array<File>
  open: boolean
  loading?: boolean
  error?: string
  onCancelClick: () => void
  onRemoveFile?: (name: string) => void
  onAddFiles: ChangeEventHandler<HTMLInputElement>
  accept?: string
}

const FileItem: FC<{ id?: string; name: string; onDelete?: (name: string) => void }> = ({ id, name, children, onDelete }) => {
  const deleteCallback = useCallback(() => {
    onDelete && onDelete(name)
  }, [name, onDelete])
  return id ? (
    <a className="btn btn-secondary btn-upload" href={`/api/files/${id}/${name}`}>
      {children}
      <Export className="float-right" width="18" height="18" />
    </a>
  ) : (
    <div className="file-item">
      {children}
      <button className="file-item__delete" type="button" onClick={deleteCallback}>
        <SVGTrash />
      </button>
    </div>
  )
}

export const FilesModal: FC<FilesModalProps> = ({
  files,
  open,
  error,
  loading,
  onCancelClick,
  onRemoveFile,
  onAddFiles,
  accept,
}) => {
  if (loading || !open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} className="new-modal large-size" id="FilesModal">
      <ModalBody>
        <div className="btn-cancel close-modal" onClick={onCancelClick}>
          <SVGExit height="14" width="14" />
        </div>
        <Form className="form">
          {files.length ? (
            <>
              <FormGroup>
                <FormGroup>
                  <h3 className="h3 small">Es sind folgende Anhänge vorhanden:</h3>
                </FormGroup>
                {files.map(file => (
                  <FormGroup key={file.name}>
                    <FileItem id={file.id} name={file.name} onDelete={onRemoveFile}>
                      <Label className="file-label">{file.name}</Label>
                    </FileItem>
                  </FormGroup>
                ))}
              </FormGroup>
              {!!error && <FormFeedback>{error}</FormFeedback>}
            </>
          ) : (
            <h3 className="h3 small">Bitte fügen Sie einen Anhang hinzu.</h3>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Label for="modalFileInput" className="btn btn-primary m-b-10 input-file" onClick={e => e.stopPropagation()}>
          <Input
            type="file"
            name="modalFiles"
            id="modalFileInput"
            data-multiple-caption="{count} files selected"
            multiple
            onChange={onAddFiles}
            accept={accept}
          />
          <Anhang className="m-r-10" width="11" height="22" />
          Anhang hinzufügen
        </Label>
        <ButtonPrimary onClick={onCancelClick}>Fertig</ButtonPrimary>
      </ModalFooter>
    </Modal>
  )
}
