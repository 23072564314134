import classnames from 'classnames'
import React, { FC } from 'react'

import SVGLiveReload from './LiveReload.svg'
import SVGReload from './Reload.svg'
import './LiveReloadButton.scss'

interface LiveReloadButtonProps {
  addedLength: number
  onClick: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

export const LiveReloadButton: FC<LiveReloadButtonProps> = ({ addedLength, onClick, className }) => (
  <div
    className={classnames(
      'live-reload-button',
      {
        'live-reload-button--open': addedLength,
      },
      className
    )}
  >
    <button className="live-reload-button__button" onClick={onClick} type="button">
      <span className="live-reload-button__label live-reload-button__left">
        <SVGLiveReload width="24px" height="24px" />
        {addedLength === 1 ? '1 neue Anfrage' : `${addedLength} neue Anfragen`}
      </span>
      <span className="live-reload-button__label live-reload-button__right">
        <SVGReload width="22px" height="22px" />
        Zum Aktualisieren klicken
      </span>
    </button>
  </div>
)
