import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { CancelRequestMutation, CancelRequestMutationVariables } from 'packages/chancellery-search/interfaces/schemaDefinition'

import query from '../graphql/cancelRequest.graphql'

export const cancelRequest = async (variables: CancelRequestMutationVariables): Promise<CancelRequestMutation> => {
  const response = await request<CancelRequestMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response
}
