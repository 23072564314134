import React, { FC, useContext } from 'react'

import { ADVICE_REQUEST_FORM } from 'common/constants/routes'
import { useNavigate } from 'common/hooks'
import SVGNew from 'common/icons/New.svg'
import { ButtonRounded } from 'common/ui'
import { UserContext } from 'common/user-context'
import { isTRB } from 'common/utils'

export const NewButton: FC = () => {
  const { user } = useContext(UserContext)
  const onClick = useNavigate(ADVICE_REQUEST_FORM)

  // trb lawyers do not get this button as it would be very confusing for them having two slighty different advice
  // request forms
  if (isTRB(user)) {
    return null
  }

  return (
    <ButtonRounded
      iconLeft={<SVGNew height="20" width="20" />}
      title="Neuen Antrag erfassen"
      className="m-l-10 m-r-10"
      onClick={onClick}
    />
  )
}
