import React from 'react'

import { Partner } from 'common/api/types'
import { SortFilterTh } from 'common/components'
import { DatePickerInput, TextFilterInput } from 'common/components/FilterInput'
import { CONTACT_TYPE_ENTRIES, FIELDS_OF_LAW_ENTRIES, Roles } from 'common/constants'
import { FilterOperator, Column, Filter, Sort, Role } from 'common/interfaces'
import { convertToTagValues } from 'common/utils'

import { STATUS_TEXT } from './AdviceListRoleStatusLabel'

interface AdviceListHeaderProps {
  partners: Array<Partner>
  sort: Sort
  onSort: (sortBy: Column) => void
  onFilterChange: (filter: Filter) => void
  hidePartners?: boolean
  hideActions?: boolean
  role: Role
}

const createOnSort = (func: (id: string) => void, id: string) => () => func(id)

export const AdviceListHeader = ({
  partners,
  hidePartners,
  hideActions,
  sort,
  onSort,
  onFilterChange,
  role,
}: AdviceListHeaderProps) => (
  <thead>
    <tr>
      <SortFilterTh
        id="adviceId"
        name="adviceId"
        sort={sort}
        onSort={createOnSort(onSort, 'adviceId')}
        onFilterSubmit={onFilterChange}
        FilterInput={TextFilterInput}
        placeholder="Beratungs ID..."
      >
        ID
      </SortFilterTh>
      {!hidePartners && <th>Produkt</th>}
      <SortFilterTh
        id="chancelleryName"
        name="chancelleryName"
        sort={sort}
        onSort={createOnSort(onSort, 'chancelleryName')}
        onFilterSubmit={onFilterChange}
        FilterInput={TextFilterInput}
        placeholder="Kanzlei..."
      >
        Kanzlei
      </SortFilterTh>
      <SortFilterTh
        id="fieldOfLaw"
        name="fieldOfLaw"
        sort={sort}
        onSort={createOnSort(onSort, 'fieldOfLaw')}
        onFilterSubmit={onFilterChange}
        suggestions={convertToTagValues(FIELDS_OF_LAW_ENTRIES)}
        FilterInput={TextFilterInput}
        placeholder="Rechtsgebiet..."
      >
        Rechtsgebiet
      </SortFilterTh>
      <SortFilterTh
        id="personName"
        name="personName"
        sort={sort}
        onSort={createOnSort(onSort, 'personName')}
        onFilterSubmit={onFilterChange}
        FilterInput={TextFilterInput}
        placeholder="Name..."
      >
        Name
      </SortFilterTh>
      <SortFilterTh
        id="personAddress"
        name="personAddress"
        sort={sort}
        onSort={createOnSort(onSort, 'personAddress')}
        onFilterSubmit={onFilterChange}
        FilterInput={TextFilterInput}
        placeholder="Adresse..."
      >
        Adresse
      </SortFilterTh>
      <SortFilterTh
        id="email"
        name="email"
        sort={sort}
        onSort={createOnSort(onSort, 'email')}
        onFilterSubmit={onFilterChange}
        FilterInput={TextFilterInput}
        placeholder="E-Mail..."
      >
        E-Mail
      </SortFilterTh>
      <SortFilterTh
        id="phone"
        name="phone"
        sort={sort}
        onSort={createOnSort(onSort, 'phone')}
        onFilterSubmit={onFilterChange}
        FilterInput={TextFilterInput}
        placeholder="Telefon..."
      >
        Telefon
      </SortFilterTh>
      <SortFilterTh
        id="contactType"
        name="contactType"
        sort={sort}
        onSort={createOnSort(onSort, 'contactType')}
        onFilterSubmit={onFilterChange}
        suggestions={convertToTagValues(CONTACT_TYPE_ENTRIES)}
        FilterInput={TextFilterInput}
        placeholder="Typ..."
      >
        Typ
      </SortFilterTh>
      {!hidePartners && (
        <SortFilterTh
          id="partnerId"
          name="partnerId"
          sort={sort}
          onSort={createOnSort(onSort, 'partnerId')}
          onFilterSubmit={onFilterChange}
          operator={FilterOperator.Equals}
          suggestions={convertToTagValues(partners)}
          FilterInput={TextFilterInput}
          placeholder="Partner..."
        >
          Partner
        </SortFilterTh>
      )}
      {role.name === Roles.TRBManager && (
        <SortFilterTh
          id="createdBy"
          name="createdBy"
          sort={sort}
          onSort={createOnSort(onSort, 'createdBy')}
          onFilterSubmit={onFilterChange}
          suggestions={convertToTagValues(partners)}
          FilterInput={TextFilterInput}
          placeholder="Erstellt von..."
        >
          Erstellt von
        </SortFilterTh>
      )}
      <SortFilterTh
        id="createdAt"
        name="createdAt"
        sort={sort}
        onSort={createOnSort(onSort, 'createdAt')}
        onFilterSubmit={onFilterChange}
        FilterInput={DatePickerInput}
        placeholder="Erstellt..."
      >
        Erstellt
      </SortFilterTh>
      <SortFilterTh
        id="status"
        name="status"
        sort={sort}
        onSort={createOnSort(onSort, 'status')}
        onFilterSubmit={onFilterChange}
        suggestions={convertToTagValues(Object.entries(STATUS_TEXT[role.id]).map(([k, v]) => ({ id: k, name: v })))}
        FilterInput={TextFilterInput}
        placeholder="Status..."
      >
        Status
      </SortFilterTh>
      {!hideActions && role.name !== Roles.TRBManager && <th className="text-center">Aktion</th>}
    </tr>
  </thead>
)
