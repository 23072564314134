import {
  DesktopDatePicker as MuiDesktopDatePicker,
  DatePickerProps as MuiDatePickerProps,
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
  DesktopDateTimePicker as MuiDesktopDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers'
import { useField } from 'formik'
import React, { FunctionComponent } from 'react'

import { Field } from '../TextField'
import { useScrollToInvalidField } from '../useScrollToInvalidField'

type DatePickerProps = Omit<MuiDatePickerProps, 'value' | 'onChange' | 'renderInput'> & {
  name: string
}

// eslint-disable-next-line fp/no-rest-parameters
export const DatePicker: FunctionComponent<DatePickerProps> = ({ name, ...rest }) => {
  const [field, meta, helpers] = useField({ name })
  const fieldRef = useScrollToInvalidField<HTMLDivElement>(name)
  return (
    <MuiDesktopDatePicker
      {...field}
      onChange={(dateio: any) => helpers.setValue(dateio?.toDate())}
      inputFormat="D. MMM YYYY"
      onError={error => {
        if (error && error !== meta.error) {
          helpers.setError(error)
        }
      }}
      {...rest}
      renderInput={props => <Field {...props} forwardedRef={fieldRef} />}
    />
  )
}

type TimePickerProps = Omit<MuiTimePickerProps, 'value' | 'onChange' | 'renderInput'> & {
  name: string
}

// eslint-disable-next-line fp/no-rest-parameters
export const TimePicker: FunctionComponent<TimePickerProps> = ({ name, ...rest }) => {
  const [field, meta, helpers] = useField({ name })
  return (
    <MuiTimePicker
      {...field}
      ampm={false}
      onChange={(dateio: any) => helpers.setValue(dateio?.toDate())}
      onError={error => {
        if (error && error !== meta.error) {
          helpers.setError(error)
        }
      }}
      {...rest}
      renderInput={props => <Field {...props} />}
    />
  )
}

type DateTimePickerProps = Omit<MuiDateTimePickerProps, 'value' | 'onChange' | 'renderInput'> & {
  name: string
}

// eslint-disable-next-line fp/no-rest-parameters
export const DateTimePicker: FunctionComponent<DateTimePickerProps> = ({ name, ...rest }) => {
  const [field, meta, helpers] = useField({ name })
  const fieldRef = useScrollToInvalidField<HTMLDivElement>(name)
  return (
    <MuiDesktopDateTimePicker
      {...field}
      ampm={false}
      onChange={(dateio: any) => helpers.setValue(dateio?.toDate())}
      inputFormat="D. MMM YYYY HH:mm"
      onError={error => {
        if (error && error !== meta.error) {
          helpers.setError(error)
        }
      }}
      {...rest}
      renderInput={props => <Field {...props} forwardedRef={fieldRef} />}
    />
  )
}
