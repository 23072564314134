import Chance from 'chance'

import { randomInt, randomIntGen } from './_utils'
import { Function, Supplier } from './functional'

const SPECIALS = '123456789!?$§-+€' // no 0 as it looks too much like an O
const SPECIAL_CHANCE = 0.15
const CAPITAL_CHANCE = 0.25

const randomCharGen: Function<string, Supplier<string>> = chars => () => chars[randomIntGen(chars.length)()]
const randomSpecial = randomCharGen(SPECIALS)

const LEET_MAP: { [key: string]: string } = {
  i: '1',
  I: '1',
  a: '4',
  A: '4',
  e: '€',
  E: '€',
  s: '§',
  S: '§',
  g: '6',
  G: '6',
  t: '7',
  T: '7',
}

const chance = new Chance()

/**
 * Generates a semi-readable random password with a specified length and length-variance.
 */
export const generatePassword = (length: number, variance = 0) => {
  const actualLength = variance > 0 ? length - variance + randomInt(variance * 2) : length
  const result = chance.word({ length: actualLength }).split('')

  let indices = []
  for (let i = 0; i < result.length; ++i) {
    // tslint:disable-next-line
    indices[i] = i
  }
  indices = chance.shuffle(indices) // this way we can randomly access the string, but modify each index only once

  let specials = Math.max(1.0, result.length * SPECIAL_CHANCE) // at least 1 special char

  // special pass 1: try to leetify chars
  let i = 0
  const filter = (_: never, index: number) => index !== i
  while (specials > 0 && i < indices.length) {
    if (specials > 0 || Math.random() <= specials) {
      const leetChar = LEET_MAP[result[indices[i]]]
      if (leetChar) {
        specials -= 1.0
        // tslint:disable-next-line
        result[indices[i]] = leetChar
        indices = indices.filter(filter)
      } else {
        ++i
      }
    }
  }

  // special pass 2: force remaining special chars
  while (specials > 0) {
    // tslint:disable-next-line
    result[indices[0]] = randomSpecial()
    indices = indices.slice(1)
    specials -= 1.0
  }

  // capitalize pass: change random not-yet-changed chars to capital letters
  i = 0
  let capitalized = CAPITAL_CHANCE
  for (i = 0; i < indices.length; ++i) {
    if (capitalized > 0 || Math.random() <= capitalized) {
      capitalized -= 1.0
      // tslint:disable-next-line
      result[indices[i]] = result[indices[i]].toUpperCase()
    } else {
      capitalized += CAPITAL_CHANCE
    }
  }

  return result.join('')
}
