import React, { FC } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

import { ButtonCancel, ButtonConfirm } from 'common/ui/Button'
import { Procedure } from 'common/utils'

import SVGExit from '../images/exit.svg'

interface ConfirmAdviceModalProps {
  open: boolean
  loading: boolean
  title: string
  confirmBtnTitle?: string
  onConfirmClick?: Procedure
  onCloseClick: Procedure
  confirmBtn?: JSX.Element
}

export const ConfirmAdviceModal: FC<ConfirmAdviceModalProps> = ({
  open,
  onConfirmClick,
  onCloseClick,
  loading,
  title,
  confirmBtnTitle,
  confirmBtn,
}) => (
  <Modal isOpen={open} toggle={onCloseClick}>
    <ModalBody>
      <div className="btn-cancel close-modal" onClick={onCloseClick}>
        <SVGExit height="14" width="14" />
      </div>
      <h3>{title}</h3>
    </ModalBody>
    <ModalFooter>
      <ButtonCancel onClick={onCloseClick}>Abbrechen</ButtonCancel>
      {confirmBtn ? (
        <>{confirmBtn}</>
      ) : (
        <ButtonConfirm onClick={onConfirmClick} loading={loading}>
          {confirmBtnTitle}
        </ButtonConfirm>
      )}
    </ModalFooter>
  </Modal>
)
