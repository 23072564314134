import { History } from 'history'
import { compose, defaults, get } from 'lodash/fp'
import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import {
  addFilter,
  mapListStateToSearch,
  mapSearchToListState,
  nextPage,
  prevPage,
  removeFilter,
  resetPage,
  setSort,
} from 'common/actions'
import { Partner, fetchPartners } from 'common/api/getPartners'
import { Column, Filter, ListState } from 'common/interfaces'
import { UserContextProps, withUser } from 'common/user-context'
import { pushSearch } from 'common/utils'

import { AdviceList } from '../components/AdviceListView'
import { DEFAULT_LIST_STATE, NEW_URL } from '../constants'
import { AdviceRequestsQuery } from '../interfaces'

type AdviceListContainerProps = RouteComponentProps<{}> & UserContextProps

interface AdviceListContainerState extends ListState<Column> {
  list: AdviceRequestsQuery['adviceRequests']['list']
  partners: Array<Partner>
  total: number
  exportOpen: boolean
  loading: boolean
  isMounted: boolean
}

const getSearch: (props: Partial<AdviceListContainerProps>) => string = get('location.search')
const withDefaultListState = defaults(DEFAULT_LIST_STATE)
const updateStateFromSearch = compose(withDefaultListState, mapSearchToListState, getSearch)
const updateSearch = (history: History) => (listState: ListState) => compose(pushSearch(history), mapListStateToSearch)(listState)

export const AdviceListContainer = withUser(
  class AdviceListContainer extends Component<AdviceListContainerProps, AdviceListContainerState> {
    state = withDefaultListState({
      list: [],
      partners: [],
      total: 0,
      exportOpen: false,
      loading: false,
      isMounted: true,
    })

    componentDidMount() {
      this.handleProps()
    }

    componentDidUpdate(prevProps: AdviceListContainerProps) {
      this.handleProps(prevProps)
    }

    componentWillUnmount() {
      this.setState({ isMounted: false })
    }

    handleProps = (prevProps: Partial<AdviceListContainerProps> = {}) => {
      const searchChanged = getSearch(this.props) !== getSearch(prevProps)
      if (searchChanged && this.state.isMounted) {
        this.setState({ loading: true })
        this.setState(updateStateFromSearch(this.props), () => {
          this.fetchAndStoreData()
        })
      }
    }

    fetchAndStoreData = async () => {
      const partners = await fetchPartners()
      this.setState({
        partners,
      })
    }

    onNew = () => {
      this.props.history.push(NEW_URL)
    }

    onExport = () => {
      this.setState(prevState => ({
        exportOpen: !prevState.exportOpen,
      }))
    }

    onNext = () => {
      this.setState(
        prevState => nextPage(prevState),
        () => {
          updateSearch(this.props.history)(this.state)
        }
      )
    }

    onPrev = () => {
      if (this.state.page > 1) {
        this.setState(
          prevState => prevPage(prevState),
          () => {
            updateSearch(this.props.history)(this.state)
          }
        )
      }
    }

    onSort = (sortBy: Column) => {
      if (sortBy) {
        this.setState(
          prevState => setSort(prevState, sortBy),
          () => {
            updateSearch(this.props.history)(this.state)
          }
        )
      }
    }

    onTagRemove = (filter: Filter<Column>) => {
      this.setState(
        prevState => removeFilter(filter, prevState.filters),
        () => {
          updateSearch(this.props.history)(this.state)
        }
      )
    }

    onFilterChange = (filter: Filter<Column>) => {
      this.setState(
        prevState => resetPage(addFilter(filter, prevState)),
        () => {
          updateSearch(this.props.history)(this.state)
        }
      )
    }

    render() {
      const { partners } = this.state

      return (
        <Fragment>
          <AdviceList user={this.props.user} partners={partners} onNew={this.onNew} onExport={this.onExport} />
        </Fragment>
      )
    }
  }
)
