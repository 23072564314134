import React, { useContext, FC } from 'react'

import { LawyerContext } from 'packages/lawyer-controlling'

export const ControllingNavFilter: FC = ({ children }) => {
  const { powerBI } = useContext(LawyerContext)

  if (!powerBI || !powerBI.filter(link => !!link).length) {
    return null
  }
  return <>{children}</>
}
