import { useFormikContext } from 'formik'
import { FunctionComponent, useEffect, useState } from 'react'

import { AdviceForeignForm } from '../interfaces'
import { getAdviceRequest } from '../utils/getAdviceRequest'
import { mapResponseToAdviceRequest } from '../utils/mappings'

type GetAPIDataProps = {
  adviceId?: string
  setLoading: ReturnType<typeof useState>[1]
  setAdviceRequest: ReturnType<typeof useState>[1]
}

export const GetAdviceAPIData: FunctionComponent<GetAPIDataProps> = ({ adviceId, setLoading, setAdviceRequest }) => {
  const { setValues } = useFormikContext<AdviceForeignForm>()

  useEffect(() => {
    if (!adviceId) {
      setLoading?.(false)
      return
    }

    setLoading?.(true)

    getAdviceRequest({ adviceId })
      .then(({ adviceRequest }) => {
        setValues({
          damageNumber: adviceRequest.caseData?.find(e => e.name === 'damageNumber')?.value || '',
          country: adviceRequest.person.address.country || '',
          fieldOfLaw: adviceRequest.fieldOfLaw?.id || '',
          description: adviceRequest.description || '',
        })
        setAdviceRequest(mapResponseToAdviceRequest(adviceRequest))
      })
      .then(() => setLoading?.(false))
  }, [adviceId, setValues, setLoading, setAdviceRequest])
  return null
}
