// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LZHjgtqRwcDKhLK8ovGN{display:flex;align-items:center}.LZHjgtqRwcDKhLK8ovGN .p7LM0Mpeo3aN9DdbzTnQ{fill:#0a1343;margin-left:.4rem;cursor:pointer}.fazJjuZkkA70_rHF20Rs tr>th{text-align:left}.fazJjuZkkA70_rHF20Rs tr>th:not(:first-child),.fazJjuZkkA70_rHF20Rs tr>td:not(:first-child){padding-left:1rem}", "",{"version":3,"sources":["webpack://./src/packages/products/components/TooltipLabel/TooltipLabel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAEA,4CACE,YAAA,CACA,iBAAA,CACA,cAAA,CAKF,4BACE,eAAA,CAGF,4FAEE,iBAAA","sourcesContent":[".center {\n  display: flex;\n  align-items: center;\n\n  .icon {\n    fill: #0a1343;\n    margin-left: 0.4rem;\n    cursor: pointer;\n  }\n}\n\n.panel {\n  tr > th {\n    text-align: left;\n  }\n\n  tr > th:not(:first-child),\n  tr > td:not(:first-child) {\n    padding-left: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "LZHjgtqRwcDKhLK8ovGN",
	"icon": "p7LM0Mpeo3aN9DdbzTnQ",
	"panel": "fazJjuZkkA70_rHF20Rs"
};
export default ___CSS_LOADER_EXPORT___;
