import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    borderRadius: 24 / 2,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey['700'],
    color: 'white',
    padding: theme.spacing(0, 1),
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  colorWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  colorError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  colorInfo: {
    backgroundColor: theme.palette.info.main,
    color: 'white',
  },
}))

export type BadgeProps = {
  color?: 'primary' | 'secondary' | 'warning' | 'error' | 'success' | 'info' | 'default'
  className?: string
  title?: string
}

const capitalize = (s: string): string => s.slice(0, 1).toUpperCase().concat(s.slice(1))

export const Badge: FunctionComponent<BadgeProps> = ({ title, children, className, color = 'default' }) => {
  const classes = useStyles()
  return (
    <div
      title={title}
      className={classNames(
        classes.root,
        {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          [classes[`color${capitalize(color)}` as keyof typeof classes]]: color !== 'default',
        },
        className
      )}
    >
      {children}
    </div>
  )
}
