import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { ProcessingInput, SetToProcessingMutation, SetToProcessingMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/setToProcessing.graphql'

export const setToProcessing = async (processingInput: ProcessingInput): Promise<string> => {
  const variables = {
    processingInput: {
      id: processingInput.id,
    },
  }

  const response = await request<SetToProcessingMutation, SetToProcessingMutationVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )
  return response.setToProcessing
}
