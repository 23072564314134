import { CASE_DATA_FIELDS } from '../../constants'
import { ProductType, SaveAdviceRequestMutationVariables, AdviceRequestFlattened } from '../../interfaces'

export const mapAdviceRequestToSaveRequest = (
  adviceRequest: AdviceRequestFlattened,
  saveBillingCompany?: boolean
): SaveAdviceRequestMutationVariables => ({
  adviceRequest: {
    id: adviceRequest.id,
    fieldOfLawId: adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw : '',
    language: adviceRequest.language ? adviceRequest.language.code : 'de',
    person: {
      foa: adviceRequest.foa,
      firstname: adviceRequest.firstname,
      lastname: adviceRequest.lastname,
      email: adviceRequest.email,
      phone: adviceRequest.phone,
      address: {
        street: adviceRequest.street,
        streetNr: adviceRequest.streetNr,
        city: adviceRequest.city,
        zip: adviceRequest.zip,
        country: adviceRequest.country,
      },
    },
    company:
      adviceRequest.product.type === ProductType.Business
        ? {
            name: adviceRequest.companyName,
            companyType: adviceRequest.companyCompanyType,
            address: {
              street: adviceRequest.companyAddressStreet,
              streetNr: adviceRequest.companyAddressStreetNr,
              zip: adviceRequest.companyAddressZip,
              city: adviceRequest.companyAddressCity,
            },
            taxId: adviceRequest.companyTaxId,
          }
        : null,
    billingCompany:
      saveBillingCompany && adviceRequest.product.type === ProductType.Business
        ? {
            name: adviceRequest.billingCompanyName,
            companyType: adviceRequest.billingCompanyCompanyType,
            address: {
              street: adviceRequest.billingCompanyAddressStreet,
              streetNr: adviceRequest.billingCompanyAddressStreetNr,
              zip: adviceRequest.billingCompanyAddressZip,
              city: adviceRequest.billingCompanyAddressCity,
            },
            taxId: adviceRequest.billingCompanyTaxId,
          }
        : null,
    resultNotes: adviceRequest.resultNotes,
    lawyerNotes: adviceRequest.lawyerNotes,
    legalProtectionInsurer: adviceRequest.legalProtectionInsurer,
    description: adviceRequest.description,
    preferredCallbackTime: adviceRequest.preferredCallbackTime,
    internalNotes: adviceRequest.internalNotes,
    externalNotes: adviceRequest.externalNotes,
    insured: adviceRequest.insured,
    insuranceNumber: adviceRequest.insuranceNumber || undefined,
    agbsAccepted: adviceRequest.agbsAccepted,
    dataAccepted: adviceRequest.dataAccepted,
    partnerId: adviceRequest.partner ? adviceRequest.partner.id : undefined,
    caseData: CASE_DATA_FIELDS.map(field => ({
      name: field,
      value: (adviceRequest as { [key: string]: any })[field],
    })),
  },
})
