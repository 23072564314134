import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useField } from 'formik'
import React, { FC, memo } from 'react'
import useSWR from 'swr'

import { USERS_ENDPOINT } from 'common/constants'

import { fetchGroups } from '../actions'
import { Group } from '../interfaces/schemaDefinition'

interface GroupsSelectProps {
  name: string
  className?: string
}

const MemoizedTextField = memo(TextField)

export const GroupsSelect: FC<GroupsSelectProps> = ({ name, className }) => {
  const { data: groups, error } = useSWR([USERS_ENDPOINT, 'groups'], fetchGroups)
  const isLoading = !groups && !error
  const [field, , helpers] = useField({ name, type: 'text' })
  return (
    <Autocomplete
      className={className}
      multiple
      filterSelectedOptions
      id={name}
      options={groups?.map(val => val.name) ?? []}
      value={field.value.map((el: Group) => el.name)}
      onChange={(_event, newValue) => {
        if (groups) {
          helpers.setValue(groups.filter(el => newValue.includes(el.name)))
        }
      }}
      getOptionLabel={(option: string) => option}
      renderInput={params => (
        <MemoizedTextField
          {...params}
          name={name}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          label="Nutzergruppen"
          placeholder="Hinzufügen…"
        />
      )}
      disabled={isLoading}
    />
  )
}
