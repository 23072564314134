import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getOr } from 'lodash/fp'
import { pathOr } from 'ramda'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { PRODUCT_NAMES, CONTACT_TYPES, Roles } from 'common/constants'
import { Role } from 'common/interfaces'
import { ButtonClear } from 'common/ui'
import { formatDateTime } from 'common/utils'
import { AdviceRequestStatus } from 'packages/chancellery-search/interfaces/AdviceRequest'

import { AdviceRequestsQuery, ContactType } from '../interfaces'

import { AdviceListRoleStatusLabel } from './AdviceListRoleStatusLabel'

const getOrDefault = pathOr('')

interface AdviceListBodyProps {
  list: AdviceRequestsQuery['adviceRequests']['list']
  hidePartners?: boolean
  fixedStatus?: boolean
  hideActions?: boolean
  role: Role
}

export const AdviceListBody = ({ list, hidePartners, fixedStatus, hideActions, role }: AdviceListBodyProps) => {
  const AdviceListStatusLabel = AdviceListRoleStatusLabel(role)
  const history = useHistory()

  return (
    <tbody>
      {list.map((adviceRequest, index) => {
        const contactTypeText = CONTACT_TYPES[adviceRequest.contactType || ContactType.NotSet]

        const status: AdviceRequestStatus =
          (fixedStatus && adviceRequest.status !== 'editing' && 'closed') || (adviceRequest.status as AdviceRequestStatus)

        return (
          <tr key={index}>
            <th scope="row" className="text-center">
              {hideActions ? (
                <span>{adviceRequest.adviceId}</span>
              ) : (
                <Link to={`/advice-requests/form?adviceId=${adviceRequest.adviceId}`}>{adviceRequest.adviceId}</Link>
              )}
            </th>
            {!hidePartners && (
              <td>{adviceRequest.product ? PRODUCT_NAMES[adviceRequest.product.id] || adviceRequest.product?.name : 'Basic'}</td>
            )}
            <td>{adviceRequest.chancellery && adviceRequest.chancellery.name}</td>
            <td>{getOrDefault(['fieldOfLaw', 'name'], adviceRequest)}</td>
            <td>
              {getOrDefault(['person', 'firstname'], adviceRequest)} {getOrDefault(['person', 'lastname'], adviceRequest)}
            </td>
            <td>
              {getOrDefault(['person', 'address', 'street'], adviceRequest)}{' '}
              {getOrDefault(['person', 'address', 'streetNr'], adviceRequest)}{' '}
              {getOrDefault(['person', 'address', 'zip'], adviceRequest)}{' '}
              {getOrDefault(['person', 'address', 'city'], adviceRequest)}
            </td>
            <td>{getOrDefault(['person', 'email'], adviceRequest)}</td>
            <td>{getOrDefault(['person', 'phone'], adviceRequest)}</td>
            <td className="text-center">{contactTypeText}</td>
            {!hidePartners && <td>{getOr('', 'partner.name', adviceRequest)}</td>}
            {role.name === Roles.TRBManager && (
              <td className="text-center">{getOrDefault(['createdBy', 'name'], adviceRequest)}</td>
            )}
            <td className="text-center">{formatDateTime(new Date(adviceRequest.createdAt))}</td>
            <td className="text-center">
              <AdviceListStatusLabel status={status} />
            </td>
            {!hideActions && role.name !== Roles.TRBManager && (
              <td className="text-center">
                <ButtonClear
                  iconLeft={<FontAwesomeIcon icon="pencil-alt" />}
                  onClick={() => history.push(`/advice-requests/form?adviceId=${adviceRequest.adviceId}`)}
                />
              </td>
            )}
          </tr>
        )
      })}
    </tbody>
  )
}
