import { Effect } from '@grapes-agency/universal'

import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils/requests'

import query from '../../graphql/proposalInvoiceSent.graphql'
import { ProposalInvoiceSentMutation, ProposalInvoiceSentMutationVariables } from '../../interfaces/schemaDefinition'

export const proposalInvoiceSent: Effect<ProposalInvoiceSentMutationVariables, string> = async (
  variables
): Promise<ProposalInvoiceSentMutation['proposalInvoiceSent']> => {
  const response = await request<ProposalInvoiceSentMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.proposalInvoiceSent
}
