import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { deDE } from '@mui/x-data-grid'

// Add custom breakpoint to type
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    maxContentWidth: true
  }
}

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#0a1343',
      },
    },
    typography: {
      fontFamily: 'Poppins, Arial',
    },
    breakpoints: {
      values: {
        // MUI defaults
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        // Custom:
        maxContentWidth: 1760,
      },
    },
  }),
  deDE
)

export default theme
