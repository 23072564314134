import React, { Fragment, RefObject, FC } from 'react'

import { Partner } from 'common/api/types'
import { LoggedInUser } from 'common/user-context'

import { ChancelleryLocationsQuery } from '../interfaces/schemaDefinition'

import { CallbackEmailRequestsView } from './CallbackEmailRequestsView'
import { RedirectRequests } from './RedirectRequestsView'

import './LawyerListView.scss'

interface OpenRequestsProps {
  onRowClick: (rowId: string) => React.EventHandler<React.FormEvent<HTMLTableRowElement>>
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  assignableUsers: Array<{ id: string; name: string }>
  assignFilter?: string
  user?: LoggedInUser
  partners: Array<Partner>
  node: RefObject<HTMLDivElement>
  addedAdviceRequestIds: Array<string>
  hasPremium: boolean
}

export const OpenRequests: FC<OpenRequestsProps> = ({
  user,
  partners,
  chancelleryLocations,
  assignableUsers,
  assignFilter,
  onRowClick,
  node,
  addedAdviceRequestIds,
  hasPremium,
}) => (
  <Fragment>
    <RedirectRequests
      hasPremium={hasPremium}
      user={user}
      onRowClick={onRowClick}
      partners={partners}
      chancelleryLocations={chancelleryLocations}
      assignableUsers={assignableUsers}
      assignFilter={assignFilter}
      addedAdviceRequestIds={addedAdviceRequestIds}
    />
    <CallbackEmailRequestsView
      hasPremium={hasPremium}
      user={user}
      onRowClick={onRowClick}
      partners={partners}
      chancelleryLocations={chancelleryLocations}
      assignableUsers={assignableUsers}
      node={node}
      assignFilter={assignFilter}
    />
  </Fragment>
)
