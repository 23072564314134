import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'

import './ToolbarTabs.scss'
import { PaginationProps } from 'common/interfaces'
import { Function, Optional, Procedure } from 'common/utils'

import SVGAnhang from '../images/anhang.svg'
import ImgBemerkung from '../images/bemerkung.svg'
import ImgCall from '../images/call.svg'
import ImgMail from '../images/mail.svg'

export enum ToolbarImage {
  imgCall = 1,
  imgBemerkung,
  imgMail,
  imgDocument,
}

const ToolbarImageURL: { [key in ToolbarImage]: SVG } = {
  [ToolbarImage.imgBemerkung]: ImgBemerkung,
  [ToolbarImage.imgMail]: ImgMail,
  [ToolbarImage.imgCall]: ImgCall,
  [ToolbarImage.imgDocument]: SVGAnhang,
}

const toSVGImage: Function<Optional<ToolbarImage>, Optional<SVG>> = img => img && ToolbarImageURL[img]

interface ToolbarTabsProps {
  pagination?: PaginationProps
  onNext?: Procedure
  onPrev?: Procedure
  title?: string
  imgSource?: ToolbarImage
}

function lowerLimit(pagination: PaginationProps) {
  return (pagination.page - 1) * pagination.pageSize + 1
}

function upperLimit(pagination: PaginationProps) {
  return Math.min(pagination.page * pagination.pageSize, pagination.total)
}

function hasNextPage(pagination: PaginationProps) {
  return pagination.page <= Math.floor(pagination.total / pagination.pageSize)
}

function hasPrevPage(pagination: PaginationProps) {
  return pagination.page > 1
}

export const ToolbarTabs = ({ pagination, onNext, onPrev, title, imgSource }: ToolbarTabsProps) => {
  const TBImage: Optional<SVG> = toSVGImage(imgSource)
  return (
    <div className="toolbar-tabs">
      <div className="people-options-tabs clearfix">
        <div className="btn-toolbar-tabs pull-left m-t-10">
          <h4 className="title">
            {TBImage && <TBImage width="20" height="20" />}
            {title}
          </h4>
        </div>
        {pagination && (
          <Fragment>
            <div className="btn-group pull-right people-pager">
              {!!onPrev && (
                <button disabled={!hasPrevPage(pagination)} type="button" className="btn prev" onClick={onPrev}>
                  <FontAwesomeIcon icon="chevron-left" />
                </button>
              )}
              {!!onNext && (
                <button disabled={!hasNextPage(pagination)} type="button" className="btn next" onClick={onNext}>
                  <FontAwesomeIcon icon="chevron-right" />
                </button>
              )}
            </div>
            <span className="people-count pull-right">
              Zeige{' '}
              <strong>
                {lowerLimit(pagination)}-{upperLimit(pagination)}
              </strong>{' '}
              von <strong>{pagination.total}</strong> {pagination.label}
            </span>
          </Fragment>
        )}
      </div>
    </div>
  )
}
