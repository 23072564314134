export const STATUS_AT_CHANCELLERY = 'at_chancellery'

export const STATUS: { [key: string]: string } = {
  awaiting_call: 'Erwartet Anruf',
  at_chancellery: 'Offen',
  cancelled: 'Storniert',
  editing: 'In Bearbeitung',
  resubmission: 'Terminerinnerung',
  potential: 'Wiedervorlage',
  mandate: 'Mandat',
  closed: 'Geschlossen',
  complete: 'Abgelegt',
}

export const STATUS_ENTRIES = Object.entries(STATUS).map(([k, v]) => ({ id: k, name: v }))

export const SALES_STATUS: { [key: string]: string } = {
  complete: 'Abgeschlossen',
  filed: 'Akte abgelegt',
  noMandate: 'Kein Mandat',
  mandate: 'Offen',
}

export const SALES_STATUS_ENTRIES = Object.entries(SALES_STATUS).map(([k, v]) => ({ id: k, name: v }))
