import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/contactInsurer.graphql'
import { ContactInput, ContactInsurerMutation, ContactInsurerMutationVariables } from '../interfaces'

export const contactInsurer = async (contactInput: ContactInput): Promise<void> => {
  const variables: ContactInsurerMutationVariables = { contactInput }
  await request<ContactInsurerMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
}
