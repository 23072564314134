import React, { FC, ReactElement } from 'react'

import styles from './Status.module.scss'

interface StatusProps {
  status: boolean
}

export const Status: FC<StatusProps> = ({ status }): ReactElement =>
  status ? (
    <span className={styles['status-activated']}>Aktiviert</span>
  ) : (
    <span className={styles['status-deactivated']}>Deaktiviert</span>
  )
