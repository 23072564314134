export const LANGUAGES = [
  {
    code: 'de',
    label: 'Deutsch',
  },
  {
    code: 'en',
    label: 'Englisch',
  },
  {
    code: 'fr',
    label: 'Französisch',
  },
  {
    code: 'zh',
    label: 'Chinesisch',
  },
  {
    code: 'es',
    label: 'Spanisch',
  },
  {
    code: 'hi',
    label: 'Hindi',
  },
  {
    code: 'ar',
    label: 'Arabisch',
  },
  {
    code: 'pt',
    label: 'Portugiesisch',
  },
  {
    code: 'bn',
    label: 'Bengalisch',
  },
  {
    code: 'ru',
    label: 'Russisch',
  },
  {
    code: 'ja',
    label: 'Japanisch',
  },
  {
    code: 'ko',
    label: 'Koreanisch',
  },
  {
    code: 'tr',
    label: 'Türkisch',
  },
  {
    code: 'vi',
    label: 'Vietnamesisch',
  },
]
