import React from 'react'

import { DamageNumberField } from 'common/components/Devk'
import { TextAreaField, SelectField, CheckboxField, FieldSet, FieldRow, InputField } from 'common/components/Form'
import { DEDUCTIBLE_AMOUNTS, PROTECTION_AMOUNTS } from 'common/constants'
import SVGCaseData from 'common/icons/CaseData.svg'
import { currencyFormatter } from 'common/utils'

import { AdviceRequest } from '../../../interfaces'

interface DEVKFormProps {
  adviceRequest: AdviceRequest
  errors: { [key: string]: string }
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
}

const EMPTY_OPTION = {
  label: '---Bitte wählen---',
  value: '',
}

export const DevkForm = ({ adviceRequest, onFieldChange, onCheckboxChange, errors }: DEVKFormProps) => (
  <FieldSet className="devk-fieldset" label="DEVK Falldaten" icon={<SVGCaseData />}>
    <div className="devk-background">
      <FieldRow>
        <DamageNumberField
          label="Schadensnummer"
          disabled={adviceRequest.damageNumberDisabled}
          value={adviceRequest.damageNumber}
          onChange={onFieldChange}
          name="damageNumber"
          id="input-damageNumber"
          error={errors.damageNumber}
          width="24rem"
        />
        <CheckboxField
          className="padding-top-1-5rem"
          onChange={onCheckboxChange}
          checked={!!adviceRequest.damageNumberDisabled}
          name="damageNumberDisabled"
          id="checkbox-damageNumberDisabled"
          label="Keine Schadensnummer vorhanden"
        />
      </FieldRow>
      <FieldRow>
        <InputField
          label="Streitwert"
          onChange={onFieldChange}
          value={adviceRequest.disputeAmount}
          name="disputeAmount"
          id="disputeAmount"
          width="24rem"
        />
      </FieldRow>
      <FieldRow>
        <CheckboxField
          className="padding-top-1-5rem"
          onChange={onCheckboxChange}
          checked={!!adviceRequest.deductible}
          name="deductible"
          id="checkbox-deductible"
          label="Selbstbeteiligung"
          width="12.5rem"
        />
        <SelectField
          label="Höhe der Selbstbeteiligung"
          disabled={!adviceRequest.deductible}
          onChange={onFieldChange}
          value={adviceRequest ? adviceRequest.deductibleAmount : ''}
          name="deductibleAmount"
          id="deductibleAmount"
          error={errors.deductibleAmount}
          width="12.5rem"
          options={[
            EMPTY_OPTION,
            ...DEDUCTIBLE_AMOUNTS.map(v => ({
              value: v,
              label: currencyFormatter(v),
            })),
          ]}
        />
      </FieldRow>
      <FieldRow>
        <CheckboxField
          className="padding-top-1-5rem"
          onChange={onCheckboxChange}
          checked={adviceRequest.protection}
          name="protection"
          id="checkbox-protection"
          label="Kostenschutz"
          width="12.5rem"
        />
        <SelectField
          label="Umfang Kostenschutz"
          disabled={!adviceRequest.protection}
          onChange={onFieldChange}
          value={adviceRequest ? adviceRequest.protectionAmount : ''}
          name="protectionAmount"
          id="select-protectionAmount"
          error={errors.protectionAmount}
          width="12.5rem"
          options={[
            EMPTY_OPTION,
            ...PROTECTION_AMOUNTS.map(v => ({
              value: v,
              label: v,
            })),
          ]}
        />
      </FieldRow>
      <FieldRow>
        <TextAreaField
          onChange={onFieldChange}
          value={adviceRequest.internalPartnerNotes}
          name="internalPartnerNotes"
          id="internalPartnerNotes"
          label="DEVK interne Bemerkungen"
        />
      </FieldRow>
    </div>
  </FieldSet>
)
