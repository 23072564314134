import { Effect } from '@grapes-agency/universal'

import { assignTo } from '../../actions'

interface AssignInput {
  adviceRequestId: string
  assignTarget: string
}

export const assign: Effect<AssignInput, void> = async ({ adviceRequestId, assignTarget }) => {
  await assignTo(adviceRequestId, assignTarget)
}
