import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { useState, FunctionComponent, useEffect } from 'react'

import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import getChancelleryQuery from '../graphql/getChancellery.graphql'
import getChancelleryLocationQuery from '../graphql/getChancelleryLocation.graphql'
import matchingConfigQuery from '../graphql/getMatchingConfiguration.graphql'
import { ChancelleryFormValues, ConfigurationFormValues, LocationFormValues } from '../interfaces/formSchemas'
import {
  ChancelleryLocation,
  GetChancelleryLocationQuery,
  GetChancelleryQuery,
  GetMatchingConfigurationQuery,
  Holiday,
} from '../interfaces/schemaDefinition'

// eslint-disable-next-line complexity
export const mapChancelleryAPIDataToForm = (data: GetChancelleryQuery['chancellery']): ChancelleryFormValues => ({
  active: data.active,
  deleted: data.deleted,
  name: data.name,
  contactPersonFamilyName: data.contactPerson?.lastname ?? '',
  contactPersonGivenName: data.contactPerson?.firstname ?? '',
  contactPersonSalutation: data.contactPerson?.foa ?? '',
  email: data.email,
  phone: data.phone,
  chancelleryId: data.id, // On edit value from API
  ustId: data.taxId ?? '',
  iban: data.iban ?? '',
  eConsultId: data.eConsult?.active ? data.eConsult?.customerId ?? '' : '',
  secupay: data.secupay?.active ? data.secupay.contractId ?? '' : '',
  powerBI1: data.powerBI?.[0] ?? '',
  powerBI2: data.powerBI?.[1] ?? '',
  callbacks: Boolean(data.callbacks),
  users: data.users,
  orderVolumes:
    data.orderVolumes?.map(entry => ({
      ...entry,
      weeklyMin: entry.weeklyMin?.toString() ?? '',
      weeklyMax: entry.weeklyMax?.toString() ?? '',
    })) ?? [],
})

type GetAPIDataProps = {
  id?: string
  setLoading?: ReturnType<typeof useState>[1]
}

export const GetChancelleryAPIData: FunctionComponent<GetAPIDataProps> = ({ id, setLoading }) => {
  const { setValues } = useFormikContext<ChancelleryFormValues>()

  useEffect(() => {
    if (!id) {
      setLoading?.(false)
      return
    }

    setLoading?.(true)

    request<GetChancelleryQuery>(CHANCELLERIES_ENDPOINT, getChancelleryQuery, { id })
      .then(({ chancellery }) => {
        setValues(mapChancelleryAPIDataToForm(chancellery))
      })
      .catch(() => undefined) // TODO: Do we need to do anything real here?
      .then(() => setLoading?.(false))
  }, [id, setValues, setLoading])
  return null
}

const ensureSevenDays = (openingHours: ChancelleryLocation['openingHours']): ChancelleryLocation['openingHours'] => {
  if (openingHours.length === 7) return openingHours
  const missingDays = 7 - openingHours.length
  const filler: typeof openingHours = Array(missingDays).fill({
    enabled: false,
    begin: '',
    end: '',
  })
  return openingHours.concat(filler)
}

export const notOverYet = (holiday: Holiday): boolean => dayjs().isBefore(dayjs(holiday.end))

export const mapChancelleryLocationAPIDataToForm = (
  apiResponse: GetChancelleryLocationQuery['chancelleryLocation']
): LocationFormValues => ({
  locationId: apiResponse.id,
  chancelleryId: apiResponse.chancelleryId,
  phone: apiResponse.phone,
  zip: apiResponse.address.zip,
  city: apiResponse.address.city,
  streetAddress: apiResponse.address.street,
  fallbackFor: apiResponse.fallbackFor.map(fol => fol.id),
  openingHours: ensureSevenDays(apiResponse.openingHours).map(day => {
    const begin = day.start ? dayjs.utc(day.start) : undefined
    const end = day.end ? dayjs.utc(day.end) : undefined
    return {
      enabled: day.enabled,
      // Take UTC times from API and convert to local, then print formatted.
      begin: begin ? `${begin.local().format('HH')}:${begin.local().format('mm')}` : '',
      end: end ? `${end.local().format('HH')}:${end.local().format('mm')}` : '',
    }
  }),
  holidays:
    apiResponse.holidays
      .filter(notOverYet)
      .map(holidayEntry => ({ ...holidayEntry, fieldsOfLaw: holidayEntry.fieldsOfLaw ?? [] })) ?? [],
  fieldOfLawPhones: apiResponse.fieldOfLawPhones ?? [],
  deleted: apiResponse.deleted,
  users: apiResponse.users,
})

export const GetChancelleryLocationAPIData: FunctionComponent<GetAPIDataProps> = ({ id, setLoading }) => {
  const { setValues } = useFormikContext<LocationFormValues>()

  useEffect(() => {
    if (!id) {
      setLoading?.(false)
      return
    }

    setLoading?.(true)

    request<GetChancelleryLocationQuery>(CHANCELLERIES_ENDPOINT, getChancelleryLocationQuery, { id })
      .then(({ chancelleryLocation }) => {
        setValues(mapChancelleryLocationAPIDataToForm(chancelleryLocation))
      })
      .catch(() => undefined) // TODO: Do we need to do anything real here?
      .then(() => setLoading?.(false))
  }, [id, setValues, setLoading])
  return null
}

export const mapMatchingConfigAPIDataToForm = (
  apiResponse: GetMatchingConfigurationQuery['matchingConfig']
): ConfigurationFormValues => ({
  configurationId: apiResponse.id,
  locationId: apiResponse.chancelleryLocation.id,
  active: apiResponse.active,
  deleted: apiResponse.deleted,
  zipCodes: apiResponse.zipAreas,
  fieldsOfLaw: apiResponse.fieldsOfLaw.included.map(f => f.name),
  partners: apiResponse.partners.included,
  products: apiResponse.products.included,
  priority: apiResponse.priority ?? 0,
  weight: apiResponse.weight,
})

export const GetConfigurationAPIData: FunctionComponent<GetAPIDataProps> = ({ id, setLoading }) => {
  const { setValues } = useFormikContext<ConfigurationFormValues>()

  useEffect(() => {
    if (!id) {
      setLoading?.(false)
      return
    }

    setLoading?.(true)

    request<GetMatchingConfigurationQuery>(CHANCELLERIES_ENDPOINT, matchingConfigQuery, { id })
      .then(({ matchingConfig }) => {
        setValues(mapMatchingConfigAPIDataToForm(matchingConfig))
      })
      .catch(() => undefined) // TODO: Do we need to do anything real here?
      .then(() => setLoading?.(false))
  }, [id, setValues, setLoading])
  return null
}
