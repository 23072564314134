import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/chancelleries.graphql'
import { ChancelleriesQuery } from '../interfaces/schemaDefinition'

export const getChancelleries = async () => {
  const response = await request<ChancelleriesQuery>(CHANCELLERIES_ENDPOINT, query)
  return response.chancelleries.list
}
