import React from 'react'
import useSWR from 'swr'

import { fetchPartners } from 'common/api/getPartners'
import { Select } from 'common/components-mui'
import { PARTNERS_ENDPOINT } from 'common/constants'

interface ChannelSelectionProps {
  className?: string
}

export const ChannelSelection: React.FC<ChannelSelectionProps> = ({ className }) => {
  const { data: partners, error } = useSWR(PARTNERS_ENDPOINT, fetchPartners)
  const isLoading = !partners && !error
  return (
    <Select name="channel" label="Kanal" fullWidth className={className} disabled={isLoading}>
      <option value="" />
      {partners &&
        partners.map(partner => (
          <option key={partner.id} value={partner.id}>
            {partner.name}
          </option>
        ))}
    </Select>
  )
}
