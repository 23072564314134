import { parseQuery } from '../utils'

export const mapSearchToListState = (search: string) => {
  const { page, pageSize, sortBy, sortDirection, filters } = parseQuery(search)

  const queryVariables = {
    page: page ? parseInt(page, 10) : undefined,
    pageSize: pageSize ? parseInt(pageSize, 10) : undefined,
    sort:
      sortBy && sortDirection
        ? {
            sortBy,
            sortDirection,
          }
        : undefined,
    filters: filters ? JSON.parse(decodeURI(filters)) : [],
  }

  return queryVariables
}
