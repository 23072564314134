import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, TextField as MuiTextField, Autocomplete } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useField } from 'formik'
import { remove } from 'ramda'
import React, { FunctionComponent, memo } from 'react'

import { TextField } from 'common/components-mui'
import { FIELDS_OF_LAW_ENTRIES } from 'common/constants/fieldsOfLaw'

import { EmptyOrderVolume, LocalOrderVolume } from '../interfaces/formSchemas'

const MemoizedTextField = memo(MuiTextField)

type OrderVolumeSettingProps = {
  name: string
  index: number
  disabled?: boolean
}

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(3),
  },
  minMaxField: {
    marginRight: theme.spacing(3),
  },
}))

export const OrderVolumeSetting: FunctionComponent<OrderVolumeSettingProps> = ({ name, index, disabled }) => {
  const classes = useStyles()
  const folName = `${name}[${index}].fieldOfLaw`
  const minName = `${name}[${index}].weeklyMin`
  const maxName = `${name}[${index}].weeklyMax`
  const minMaxClasses = `${classes.field} ${classes.minMaxField}`
  const [, globalMeta, globalHelpers] = useField<Array<LocalOrderVolume | EmptyOrderVolume>>(name)
  const [, meta, helpers] = useField<{ id: string; name: string } | null>(folName)
  const [, minMeta] = useField(minName)
  const [, maxMeta] = useField(maxName)
  const handleDelete = (): void => globalHelpers.setValue(remove(index, 1, globalMeta.value))

  // Remove fieldsOfLaw that are already set from the options list.
  const filteredOptions = FIELDS_OF_LAW_ENTRIES.filter(entry => !globalMeta.value.map(ov => ov.fieldOfLaw?.id).includes(entry.id))
  // Re-add the one that is chosen here in this instance. Otherwise MUI Autocomplete can't find the selected option in the list.
  const availableOptions = meta.value ? [meta.value].concat(filteredOptions) : filteredOptions

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between" mb={3}>
      <Box flexBasis="100%">
        <Autocomplete
          id={folName}
          options={availableOptions}
          getOptionLabel={f => f.name}
          isOptionEqualToValue={
            (option, value) => option.id === value.id && option.name === value.name /* Default is strict equality */
          }
          onChange={(___, v) => helpers.setValue(v)}
          value={meta.value}
          fullWidth
          disabled={disabled}
          className={classes.field}
          renderInput={params => (
            <MemoizedTextField
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              label="Rechtsgebiet"
              variant="outlined"
              {...params}
            />
          )}
        />
      </Box>
      <Box>
        <TextField
          disabled={disabled}
          name={minName}
          label="Wöchentlich min"
          error={minMeta.touched && Boolean(minMeta.error)}
          helperText={minMeta.touched && minMeta.error}
          className={minMaxClasses}
        />
        <TextField
          disabled={disabled}
          name={maxName}
          label="Wöchentlich max"
          error={maxMeta.touched && Boolean(maxMeta.error)}
          helperText={maxMeta.touched && maxMeta.error}
          className={minMaxClasses}
        />
      </Box>
      <Box mt={0.5}>
        <IconButton onClick={handleDelete} disabled={disabled} size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
