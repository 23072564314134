import React, { FC } from 'react'
import { Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import { ButtonCancel, ButtonConfirm } from 'common/ui'

import Lock from '../images/lock.svg'

import './LockModal.scss'

interface LockModalProps {
  open: boolean
  onContinueClick: () => void
  onCancelClick: () => void
}

export const LockModal: FC<LockModalProps> = ({ open, onContinueClick, onCancelClick }) => {
  if (!open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal isOpen={open} className="success-modal" id="LockModal">
      <ModalBody>
        <Lock height="94" width="71.5" className="m-20" id="LockIcon" />
        <Label>
          Dieser Antrag befindet sich momentan durch einen anderen User in Bearbeitung. Möchten Sie den Antrag trotzdem
          bearbeiten?
        </Label>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={onCancelClick}>Zurück</ButtonCancel>
        <ButtonConfirm onClick={onContinueClick}>Trotzdem bearbeiten</ButtonConfirm>
      </ModalFooter>
    </Modal>
  )
}
