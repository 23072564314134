import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { Sort, Column, SortDirection } from '../../interfaces'

import './Th.scss'
import SVGOutlineFilter from './image/outline-filter_list.svg'

interface ThProps {
  id: Column
  onSort: () => void
  popoverOpen: boolean
  onToggleFilterPopover: (column: Column) => void
  sort: Sort
  children?: ReactNode
}

const getSortIcon = (sortDirection: SortDirection) => {
  if (sortDirection === 'asc') {
    return 'sort-up'
  }
  if (sortDirection === 'desc') {
    return 'sort-down'
  }
  return 'sort'
}

const createOnToggleFilter = (func: (id: string) => void, id: string) => () => func(id)

export const Th = ({ id, onSort, popoverOpen, onToggleFilterPopover, sort, children }: ThProps) => {
  const sortActive = id === sort.sortBy
  const sortIcon = sort.sortDirection && getSortIcon(sort.sortDirection)
  return (
    <th className="text-center">
      <span
        id={id}
        className={classNames('th-filter', { 'th-filter--active': popoverOpen })}
        onClick={createOnToggleFilter(onToggleFilterPopover, id)}
      >
        <SVGOutlineFilter width={21} height={21} />
      </span>
      <span className="th-sort" onClick={onSort}>
        {children}
        {!sortActive && (
          <span className="th-sort__ic--inactive">
            <span className="th-sort__ic">
              <FontAwesomeIcon icon="sort-up" size="lg" />
            </span>
            <span className="th-sort__ic--right">
              <FontAwesomeIcon icon="sort-down" size="lg" />
            </span>
          </span>
        )}
        {sortActive && (
          <span className="th-sort__ic">
            <FontAwesomeIcon icon={sortIcon} size="lg" />
          </span>
        )}
      </span>
    </th>
  )
}
