import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import React, { FunctionComponent } from 'react'

import { UserContextProps, withUser } from 'common/user-context'

import SVGExit from '../images/Exit.svg'
import SVGUserNegative from '../images/User-Negative.svg'

import SVGLogout from './images/logout.svg'

const useStyles = makeStyles(theme => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
  },
  logout: {
    marginLeft: theme.spacing(2),
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  profileWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

type ProfileProps = UserContextProps

const Profile: FunctionComponent<ProfileProps> = ({
  user,
  signOut,
  userProfile,
  onConfirmSignOut,
  confirmSignOut,
  onCancelSignOut,
}: UserContextProps) => {
  const classes = useStyles()
  if (!user) {
    return null
  }

  return (
    <div className={classes.profileWrapper}>
      <MuiButton
        // eslint-disable-next-line fp/no-mutating-methods
        onClick={userProfile}
        startIcon={<SVGUserNegative width={16} height={16} />}
        color="primary"
        variant="contained"
        disableElevation
        className={classes.button}
      >
        <Typography variant="button">{user.name}</Typography>
      </MuiButton>
      <IconButton onClick={signOut} className={classnames(classes.button, classes.logout)} color="inherit" size="large">
        <SVGExit width={18} height={18} />
      </IconButton>
      <Dialog open={confirmSignOut}>
        <DialogTitle>Ausloggen</DialogTitle>
        <DialogContent>
          <DialogContentText>Möchten Sie sich wirklich ausloggen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton color="primary" variant="outlined" onClick={onConfirmSignOut} endIcon={<SVGLogout width="22" height="22" />}>
            Ja, ausloggen
          </MuiButton>
          <MuiButton onClick={onCancelSignOut}>Zurück</MuiButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const ProfileWithUser = withUser(Profile)

export { ProfileWithUser as Profile }
