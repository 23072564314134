import { formatDateTime } from './date'

type Mapping = { [key: string]: string } | ((key: any) => string)

function valueFromMap(key: any, map?: Mapping) {
  if (!map) {
    return key
  }
  if (typeof map === 'function') {
    return map(key)
  }
  return map[key]
}

export function dateToLabel(value: Date, formatter = formatDateTime) {
  if (value instanceof Date) {
    return formatter(value)
  }
  throw `cannot convert to label: ${value} is no date`
}

export function booleanToLabel(value: boolean, map: Mapping = { true: 'Ja', false: 'Nein' }) {
  if (typeof value === 'boolean' || ['true', 'false'].indexOf(value) !== -1) {
    return valueFromMap(value.toString(), map)
  }
  throw `cannot convert to label: ${value} is no boolean`
}

export function toLabel(value: any, map?: Mapping) {
  if (typeof value === 'string') {
    return valueFromMap(value, map)
  }

  if (typeof value === 'boolean') {
    return booleanToLabel(value, map)
  }

  if (value instanceof Date) {
    return dateToLabel(value)
  }

  return ''
}

export function numberToLabel(value: number | string, map?: Mapping) {
  if (typeof value === 'string') {
    return valueFromMap(value, map)
  }
  if (typeof value === 'number') {
    return valueFromMap(value, map)
  }
  throw `cannot convert to label: ${value} is no number`
}
