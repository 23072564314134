import React, { FC, ReactElement, ReactNode, useRef, useState } from 'react'

import { Icon } from 'common/components/Icon'
import { Popover, Panel } from 'common/ui'

import SvgInfo from '../../icons/info.svg'

import styles from './TooltipLabel.module.scss'

interface TooltipLabelProps {
  label: ReactNode
  tooltip: ReactNode
}

const TooltipIcon: FC<{
  children: (open: boolean, element: HTMLElement | null, setOpen: (openNoShadow: boolean) => void) => JSX.Element
}> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const element = useRef(null)

  const toggle = (): void => {
    setOpen(!open)
  }

  return (
    <span ref={element} onClick={toggle} className={styles.center}>
      <Icon svg={SvgInfo} className={styles.icon} />
      {children(open, element.current, setOpen)}
    </span>
  )
}

export const TooltipLabel: FC<TooltipLabelProps> = ({ label, tooltip }): ReactElement => (
  <span className={styles.center}>
    {label}
    <TooltipIcon>
      {(open, element, setOpen) => (
        <Popover open={open} target={element} onClose={() => setOpen(false)}>
          <Panel className={styles.panel}>{tooltip}</Panel>
        </Popover>
      )}
    </TooltipIcon>
  </span>
)
