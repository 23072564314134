import { SearchAdviceRequest } from '../interfaces'

type Status =
  | 'callback'
  | 'redirect'
  | 'email'
  | 'potential'
  | 'closed'
  | 'mandate'
  | 'complete'
  | 'cancelled'
  | 'resubmission'
  | 'not_set'

type GroupedAdviceRequests = {
  [status in Status]: Array<SearchAdviceRequest>
}

export const groupAdviceRequests: (adviceRequests: Array<SearchAdviceRequest>) => GroupedAdviceRequests = adviceRequests => {
  const grouped: GroupedAdviceRequests = {
    resubmission: [],
    callback: [],
    cancelled: [],
    redirect: [],
    email: [],
    potential: [],
    closed: [],
    mandate: [],
    complete: [],
    not_set: [],
  }

  return adviceRequests.reduce((acc, adviceRequest) => {
    if (adviceRequest.status && adviceRequest.contactType) {
      if (adviceRequest.status === 'at_chancellery') {
        acc[adviceRequest.contactType.toLowerCase() as Status].push(adviceRequest)
      } else if (adviceRequest.status in acc) {
        acc[adviceRequest.status as Status].push(adviceRequest)
      }
    }
    return acc
  }, grouped)
}
