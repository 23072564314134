import React, { FC } from 'react'
import { Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { StaticRow } from 'common/components/StaticRow'
import { ButtonConfirm, ButtonCancel } from 'common/ui/Button'

interface CancelModalProps {
  open: boolean
  reason: string
  onReasonChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCompleteClick: () => void
  onCancelClick: () => void
  errors?: { [props: string]: string }
  title?: string
  closeReasons?: Array<string>
}

export const CancelModal: FC<CancelModalProps> = ({
  open,
  reason,
  onReasonChange,
  onCompleteClick,
  onCancelClick,
  title,
  closeReasons = [],
  errors = {},
}) => (
  <Modal toggle={onCancelClick} isOpen={open} id="CloseModal">
    <ModalBody>
      <Form className="form">
        <FormGroup>
          <h3 className="h3 small">{title}</h3>
        </FormGroup>
        <FormGroup>
          {closeReasons.map(availableReason => (
            <StaticRow key={availableReason}>
              <Col md={11}>
                <Label for={availableReason}>{availableReason}</Label>
              </Col>
              <Col md={1}>
                <Input
                  id={availableReason}
                  type="radio"
                  name="closeReason"
                  value={availableReason}
                  checked={availableReason === reason}
                  onChange={onReasonChange}
                />
              </Col>
            </StaticRow>
          ))}
          {!!errors.closeReason && <FormFeedback>{errors.closeReason}</FormFeedback>}
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <ButtonCancel onClick={onCancelClick}>Zurück</ButtonCancel>
      <ButtonConfirm onClick={onCompleteClick}>Antrag schließen</ButtonConfirm>
    </ModalFooter>
  </Modal>
)

CancelModal.defaultProps = {
  title: 'Bitte wählen Sie einen Grund aus, warum die Telefonische Rechtsberatung abgeschlossen wird.',
  closeReasons: [
    'Mit telefonischer Rechtsberatung erledigt',
    'Kunde hat bereits einen Anwalt und wollte eine zweite Meinung',
    'Kunde will RA vor Ort',
  ],
}
