// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tq6HkM4nqInFJ4D5MbAE{color:green}.ZkZClC239YwZq58vBUVS{color:red}", "",{"version":3,"sources":["webpack://./src/packages/products/components/Status/Status.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,sBACE,SAAA","sourcesContent":[".status-activated {\n  color: green;\n}\n\n.status-deactivated {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-activated": "Tq6HkM4nqInFJ4D5MbAE",
	"status-deactivated": "ZkZClC239YwZq58vBUVS"
};
export default ___CSS_LOADER_EXPORT___;
