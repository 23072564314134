import React, { FC } from 'react'

import { OpenIndividual } from './OpenIndividual'
import { OpenProductsView } from './OpenProducts'

type OpenB2BViewProps = {
  assignFilter?: string
}

export const OpenB2BView: FC<OpenB2BViewProps> = ({ assignFilter }) => (
  <>
    <OpenProductsView assignFilter={assignFilter} />
    <OpenIndividual assignFilter={assignFilter} />
  </>
)
