import { Box } from '@mui/material'
import { GridColumns, DataGrid, GridRenderCellParams } from '@mui/x-data-grid'
import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

import { Badge, CenterBox, createGridColumnsFrom } from 'common/components-mui'

export interface SimpleUser {
  id: string
  name: string
  email: string
  active: boolean
}

type SimpleUserTableProps = {
  users: Array<SimpleUser>
  title: string
}

const columns: GridColumns = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  { field: 'name', headerName: 'Name', flex: 0.33 },
  {
    field: 'email',
    headerName: 'E-Mail',
    flex: 0.33,
  },
  {
    field: 'active',
    headerName: 'Status',
    flex: 0.1,
    renderCell: (params: GridRenderCellParams) => {
      const active: boolean = params.value as boolean
      return (
        <CenterBox>
          <Badge color={active ? 'success' : 'default'}>{active ? 'Aktiv' : 'Inaktiv'}</Badge>
        </CenterBox>
      )
    },
  },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

export const SimpleUserTable: FunctionComponent<SimpleUserTableProps> = ({ users, title }) => {
  const history = useHistory()

  return (
    <Box mb={3}>
      <fieldset>
        <legend>{title}</legend>

        <DataGrid
          autoHeight
          columns={columns}
          rows={users}
          pageSize={50}
          // eslint-disable-next-line fp/no-mutating-methods
          onRowClick={r => (r.row.id ? history.push(`/users/edit/${r.row.id}`) : undefined)}
        />
      </fieldset>
    </Box>
  )
}
