import { chainEffects, Procedure, formatCurrency } from '@grapes-agency/universal'
import classnames from 'classnames'
import { useFormik } from 'formik'
import { concat } from 'lodash/fp'
import React, { useEffect, useState, useContext, ChangeEventHandler, Fragment, FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, FormFeedback, Row, Col, FormGroup, Input, Label } from 'reactstrap'

import { DamageNumberField, InsuranceNumberField } from 'common/components/Devk'
import { CheckboxField, FieldRow, InputField, SelectField } from 'common/components/Form'
import { Panel } from 'common/components/Panel'
import { BooleanStaticField } from 'common/components/StaticField'
import { StaticRow } from 'common/components/StaticRow'
import { B2B_INDIVIDUAL_PRODUCT, URLs, legalProtectionInsurer, ROLES, ROLE_IDS } from 'common/constants'
import { getChancelleries } from 'common/graphql'
import { createContextTrigger, createTriggerButton, useConnectEffectTrigger } from 'common/hooks/Effects'
import { usePopoverMenu } from 'common/hooks/Menu'
import { useExternalModalTrigger } from 'common/hooks/ModalTrigger'
import SVGEllipsisVSolid from 'common/icons/EllipsisVSolid.svg'
import Transfer from 'common/icons/Transfer.svg'
import SVGBusiness from 'common/icons/business.svg'
import SVGCheckCircle from 'common/icons/check-circle-regular.svg'
import SVGEditRegular from 'common/icons/edit-regular.svg'
import SVGEdit from 'common/icons/edit-solid.svg'
import SVGCheck from 'common/icons/icn_check.svg'
import SVGSave from 'common/icons/save-regular.svg'
import SVGSecureQuestion from 'common/icons/secure-question.svg'
import { ButtonPrimary, ButtonRounded, ButtonSecondary } from 'common/ui/Button'
import { Toolbar, ToolbarProps } from 'common/ui/Toolbar'
import { UserContext } from 'common/user-context'
import { formatDate } from 'common/utils'
import { fetchDataWith } from 'common/utils/fetchDataWith'
import { formatCallbackTime } from 'common/utils/formatter'
import { STATUS_TEXT } from 'packages-mui/advice'
import { PartnerView } from 'packages/chancellery-search/components/SearchView'
import { formatAddress, formatName } from 'packages/chancellery-search/utils/person'
import { ProductType } from 'packages/lawyers/interfaces'

import { setToProcessing, setToComplete, fetchAssignableUsers, getChancelleryLocations } from '../actions'
import { CONTACT_TYPE_LABELS, PERSON_DATA_LABELS } from '../constants'
import { InvoiceForm, GeneralContactForm, CoverageForm } from '../coverage-requests/components/ContactForms'
import { AdviceRequest } from '../interfaces/AdviceRequest'
import { ProposalStatus } from '../interfaces/schemaDefinition'
import {
  proposalRejected,
  proposalInvoiceSent,
  saveAdviceRequest,
  assignToMe,
  ProposalAmountForm,
  AssignForm,
  RedirectForm,
} from '../lawyer-actions'
import { isAssignable, isAssignableToMe } from '../utils'
import { validateOpenContact, validateOpenCoverageRequest } from '../utils/validators'

import { AccountDisplay } from './AccountDisplay'
import { ConsultationPanel, ConsultationPanelStep } from './ConsultationPanel'
import { InsuranceFieldSet } from './InsuranceFieldSet'
import { ProductPanel } from './ProduktPanel'
import { StaticFieldRow } from './StaticFieldRow/StaticFieldRow'
import { TextAreaRow } from './TextAreaRow/TextAreaRow'
import { B2BCompleteModal } from './b2b/CompleteModal'
import SVGAnhang from './images/anhang.svg'
import SVGCloseRequest from './images/antrag_close.svg'
import SVGRequestData from './images/antragsdaten.svg'
import SVGRemark from './images/bemerkung.svg'
import SVGResultNote from './images/bemerkung_intern.svg'
import SVGCall from './images/call.svg'
import SVGCallback from './images/callback.svg'
import SVGExit from './images/exit.svg'
import SVGDescription from './images/fallbeschreibung.svg'
import SVGMail from './images/mail.svg'
import SVGNote from './images/notiz.svg'
import SVGPersonData from './images/personendaten.svg'

import './LawyerListView.scss'

interface AdviceDetailProps {
  adviceDetail: AdviceRequest
  onBack: Procedure
  onCloseAdviceRequest: Procedure
  onOpenAppointmentModal: Procedure
  onOpenMandateModal: Procedure
  onOpenCompleteModal: Procedure
  onOpenCloseModal: Procedure
  onOpenFilesModal: Procedure
  onOpenEConsultModal: Procedure
  onOpenAdviceConfirmModal: Procedure
  onOpenFinishModal: Procedure
  onAddFiles: ChangeEventHandler<HTMLInputElement>
  onAdviceSaved: (adviceDetail: AdviceRequest) => void
  fileButton: string
  roleLawyer: boolean
  hasEConsult?: boolean
  hasPremium?: boolean
}

const useAssignableUsers = fetchDataWith(fetchAssignableUsers)
const useChancelleries = fetchDataWith(getChancelleryLocations)

const ActionsButton = createTriggerButton(ButtonRounded, {
  children: 'Weitere Aktionen',
  iconLeft: <SVGEllipsisVSolid width="16" height="16" />,
})

const useEffectTrigger = createContextTrigger(UserContext)

const hasSentCoverage = (adviceDetail: AdviceRequest): boolean =>
  !!adviceDetail.history.find(historyEvent => historyEvent.event === 'coverageRequest')

const getSentCoverageDate = (adviceDetail: AdviceRequest): string => {
  const event = adviceDetail.history.find(historyEvent => historyEvent.event === 'coverageRequest')
  return event ? formatDate(new Date(event.date)) : ''
}

interface StaticFieldRowData {
  label: string
  data: Array<{
    name: string
    value: string
  }>
  value: string
}

// eslint-disable-next-line complexity
export const AdviceDetailView: FC<AdviceDetailProps> = ({
  adviceDetail: originalAdviceDetail,
  onBack,
  onOpenCompleteModal,
  onOpenCloseModal,
  onOpenFilesModal,
  onAddFiles,
  onOpenEConsultModal,
  onOpenAdviceConfirmModal,
  onOpenFinishModal,
  onAdviceSaved,
  fileButton,
  hasEConsult,
  roleLawyer,
}) => {
  const history = useHistory()
  const [adviceDetail, setAdviceDetail] = useState(originalAdviceDetail)
  const [errors, setErrors] = useState<{ [prop: string]: string }>({})
  const [saving, setSaving] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [billingCompany, setbillingCompany] = useState<boolean>(false)
  const [completeLoading, setCompleteLoading] = useState<boolean>(false)
  const [completeOpen, setCompleteOpen] = useState<boolean>(false)
  const { user } = useContext(UserContext)
  const { data: assignableUsers } = useAssignableUsers()
  const { data: chancelleries } = useChancelleries()
  const [iban, setIban] = useState<string>('')

  useEffect(() => {
    if (user) {
      getChancelleries().then(userChancelleries => {
        if (userChancelleries[0].iban) {
          setIban(userChancelleries[0].iban)
        }
      })
    }
  }, [user])

  const setEditModeTrue = () => setEditMode(true)

  const isBusinessProduct = adviceDetail.product.type === ProductType.Business
  const isIndividualProduct = adviceDetail.product.id === B2B_INDIVIDUAL_PRODUCT
  const proposalAccepted = adviceDetail.proposal?.status === ProposalStatus.Accepted

  const getIconField = (value: any) => {
    if (value === 'REDIRECT') {
      return <SVGCall height="20" width="20" />
    }
    if (value === 'CALLBACK') {
      return <SVGCallback height="20" width="20" />
    }
    if (value === 'EMAIL' || value === 'MAIL') {
      return <SVGMail height="20" width="20" />
    }
    return false
  }

  let RequestDataArray = [
    { label: 'Beratungs-ID', value: adviceDetail.adviceId },
    {
      label: 'Status',
      value:
        isBusinessProduct && adviceDetail.status === 'complete'
          ? 'Abgeschlossen'
          : STATUS_TEXT[ROLES[ROLE_IDS.Lawyer].id][adviceDetail.status],
    },
    { label: 'Erstellt', value: adviceDetail.createdAt },
    {
      label: 'Kontaktart',
      value: adviceDetail.contactType && CONTACT_TYPE_LABELS[adviceDetail.contactType],
      icon: getIconField(adviceDetail.contactType),
    },
    { label: 'Rechtsgebiet', value: adviceDetail.fieldOfLaw && adviceDetail.fieldOfLaw.name },
    { label: 'Sprache', value: adviceDetail.language ? adviceDetail.language.language : 'Deutsch' },
    {
      label: 'Partner',
      value:
        adviceDetail.partner &&
        (adviceDetail.partner.id === 'devk-direktsteuerung-2' ? 'DEVK Direktsteuerung' : adviceDetail.partner.name),
    },
  ]

  if (!isBusinessProduct && adviceDetail.mandateName) {
    RequestDataArray = concat(RequestDataArray, { label: 'Aktenname', value: adviceDetail.mandateName })
  }
  if (!isBusinessProduct && adviceDetail.status === 'complete') {
    RequestDataArray = concat(RequestDataArray, { label: 'Umsatz', value: `${adviceDetail.revenue} €` })
  }

  RequestDataArray = RequestDataArray.filter(data => !isBusinessProduct || data.label !== 'Kontaktart')

  const PersonDataArray: Array<StaticFieldRowData> = [
    {
      label: 'Name',
      data: [
        { name: PERSON_DATA_LABELS.firstname, value: adviceDetail.firstname },
        { name: PERSON_DATA_LABELS.lastname, value: adviceDetail.lastname },
      ],
      value: formatName(adviceDetail.foa, adviceDetail.firstname, adviceDetail.lastname),
    },
    !isBusinessProduct
      ? {
          label: 'Adresse',
          data: [
            { name: PERSON_DATA_LABELS.street, value: adviceDetail.street },
            { name: PERSON_DATA_LABELS.streetNr, value: adviceDetail.streetNr },
            { name: PERSON_DATA_LABELS.city, value: adviceDetail.city },
            { name: PERSON_DATA_LABELS.zip, value: adviceDetail.zip },
          ],
          value: formatAddress(adviceDetail),
        }
      : null,
    {
      label: 'E-Mail',
      data: [{ name: PERSON_DATA_LABELS.email, value: adviceDetail.email }],
      value: adviceDetail.email,
    },
    {
      label: 'Telefon',
      data: [{ name: PERSON_DATA_LABELS.phone, value: adviceDetail.phone }],
      value: adviceDetail.phone,
    },
  ].filter((e): e is StaticFieldRowData => e !== null)

  const BillingCompanyDataArray: Array<StaticFieldRowData> = adviceDetail.billingCompany
    ? [
        {
          label: 'Unternehmensname / Name',
          data: [{ name: 'Unternehmensname / Name', value: adviceDetail.billingCompany.name }],
          value: adviceDetail.billingCompany.name,
        },
        {
          label: 'Unternehmensform',
          data: [{ name: 'Unternehmensform', value: adviceDetail.billingCompany.companyType }],
          value: adviceDetail.billingCompany.companyType,
        },
        {
          label: 'Adresse',
          data: [
            { name: 'Straße', value: adviceDetail.billingCompany.address.street },
            { name: 'Haus-Nr', value: adviceDetail.billingCompany.address.streetNr },
            { name: 'Postleitzahl', value: adviceDetail.billingCompany.address.city },
            { name: 'Stadt', value: adviceDetail.billingCompany.address.zip },
          ],
          value: formatAddress(adviceDetail.billingCompany.address),
        },
        {
          label: 'USt-IdNr. / Steuernummer',
          data: [{ name: 'USt-IdNr./ Steuernummer', value: adviceDetail.billingCompany.taxId }],
          value: adviceDetail.billingCompany.taxId,
        },
      ]
    : []

  const CompanyDataArray: Array<StaticFieldRowData> = adviceDetail.company
    ? [
        {
          label: 'Unternehmensname / Name ',
          data: [{ name: 'Unternehmensname / Name', value: adviceDetail.company.name }],
          value: adviceDetail.company.name,
        },
        {
          label: 'Unternehmensform',
          data: [{ name: 'Unternehmensform', value: adviceDetail.company.companyType }],
          value: adviceDetail.company.companyType,
        },
        {
          label: 'Adresse',
          data: [
            { name: 'Straße', value: adviceDetail.company.address.street },
            { name: 'Haus-Nr', value: adviceDetail.company.address.streetNr },
            { name: 'Postleitzahl', value: adviceDetail.company.address.city },
            { name: 'Stadt', value: adviceDetail.company.address.zip },
          ],
          value: formatAddress(adviceDetail.company.address),
        },
        {
          label: 'USt-IdNr. / Steuernummer',
          data: [{ name: 'USt-IdNr./ Steuernummer', value: adviceDetail.company.taxId }],
          value: adviceDetail.company.taxId,
        },
      ]
    : []

  const isPreview = adviceDetail.status === 'awaiting_callback'
  const disableCoverageRequest = hasSentCoverage(adviceDetail) || !iban
  const premiumPanel =
    adviceDetail.status === 'at_chancellery' ||
    adviceDetail.status === 'potential' ||
    adviceDetail.status === 'closed' ||
    adviceDetail.status === 'processing' ||
    (adviceDetail.status === 'complete' && isBusinessProduct)

  const showPanelActions = !isBusinessProduct || (adviceDetail.status !== 'complete' && adviceDetail.status !== 'closed')

  const [AssignModal, AssignModalTrigger] = useExternalModalTrigger(close => (
    <AssignForm onSuccess={close} adviceRequestId={adviceDetail.id || ''} users={assignableUsers} />
  ))
  const [RedirectModal, RedirectModalTrigger] = useExternalModalTrigger(close => (
    <RedirectForm onSuccess={close} adviceRequestId={adviceDetail.id || ''} chancelleries={chancelleries} />
  ))
  const [GeneralContactModal, GeneralContactTrigger] = useExternalModalTrigger(close => (
    <GeneralContactForm onSuccess={close} adviceRequest={adviceDetail} />
  ))
  const [InvoiceModal, InvoiceTrigger] = useExternalModalTrigger(close => (
    <InvoiceForm onSuccess={close} adviceRequest={adviceDetail} />
  ))

  const [ProposalAmountModal, ProposalAmountTrigger] = useExternalModalTrigger(close => (
    <ProposalAmountForm
      onSuccess={account => {
        setAdviceDetail({
          ...adviceDetail,
          proposal: {
            amount: 0,
            status: ProposalStatus.Accepted,
          },
          payment: {
            ...adviceDetail.payment,
            receiverAccount: account,
          },
        })
        close()
      }}
      adviceRequestId={adviceDetail.id || ''}
    />
  ))

  const [CoverageFormModal, CoverageFormModalTrigger] = useExternalModalTrigger(close => (
    <CoverageForm
      onSuccess={() => {
        setAdviceDetail({
          ...adviceDetail,
          history: [...adviceDetail.history, { event: 'coverageRequest', date: new Date() }],
        })
        close()
      }}
      adviceRequest={adviceDetail}
      iban={iban}
    />
  ))

  const ProposalRejectedTrigger = useConnectEffectTrigger(
    useEffectTrigger(
      chainEffects(proposalRejected, () => {
        setAdviceDetail({ ...adviceDetail, status: 'closed' })
      })
    ),
    {
      input: { adviceRequestId: adviceDetail.id },
    }
  )

  const ProposalInvoiceSentTrigger = useConnectEffectTrigger(
    useEffectTrigger(
      chainEffects(proposalInvoiceSent, () => {
        setAdviceDetail({ ...adviceDetail, status: 'complete' })
      })
    ),
    {
      input: { adviceRequestId: adviceDetail.id },
    }
  )

  const ProposalAmountButton = createTriggerButton(ButtonPrimary, {
    children: 'Angebot angenommen',
  })

  const ProposalInvoiceSentButton = createTriggerButton(ButtonPrimary, {
    children: 'Rechnung versendet',
  })

  const ProposalRejectedButton = createTriggerButton(ButtonPrimary, {
    children: 'Angebot abgelehnt',
  })

  const [InvoiceSentConfirmModal, InvoiceSentConfirmTrigger] = useExternalModalTrigger(close => (
    <Form className="form">
      <FormGroup>
        <h3 className="h3 small">Versand der Rechnung bestätigen?</h3>
      </FormGroup>
      <FormGroup>
        <p>Bestätigen Sie den Versand der Rechnung. Der Fall wird danach in unter &quot;Abgeschlossen&quot; zu finden sein.</p>
      </FormGroup>
      <ProposalInvoiceSentTrigger
        key={2}
        disabled={!adviceDetail.adviceConfirmed || editMode}
        renderTrigger={props => <ProposalInvoiceSentButton style={{ marginRight: '1rem' }} {...props} />}
        onSuccess={close}
      />
    </Form>
  ))

  const [ProposalRejectedConfirmModal, ProposalRejectedConfirmTrigger] = useExternalModalTrigger(close => (
    <Form className="form">
      <FormGroup>
        <h3 className="h3 small">Wurde das Angebot abelehnt?</h3>
      </FormGroup>
      <FormGroup>
        <p>
          Bestätigen Sie, dass das Angebot abgelehnt wurde. Der Fall wird danach unter &quot;Beratung abgebrochen&quot; zu finden
          sein.
        </p>
      </FormGroup>
      <ProposalRejectedTrigger
        key={2}
        disabled={!adviceDetail.adviceConfirmed || editMode}
        renderTrigger={props => <ProposalRejectedButton style={{ marginRight: '1rem' }} {...props} />}
        onSuccess={close}
      />
    </Form>
  ))

  const CoverageFormButton = createTriggerButton(ButtonRounded, {
    children: 'Deckungsanfrage versenden',
    iconLeft: <SVGSecureQuestion width="20px" height="20px" />,
    title: disableCoverageRequest ? `Deckungsanfrage wurde bereits am ${getSentCoverageDate(adviceDetail)} versendet` : undefined,
  })

  const AssignToMeTrigger = useConnectEffectTrigger(useEffectTrigger(assignToMe), {
    input: { adviceRequestId: adviceDetail.id },
  })

  const isDEVKinsured = adviceDetail.legalProtectionInsurer === 'DEVK'

  const MenuTrigger = usePopoverMenu({
    id: 'advice-actions',
    title: 'Weitere Aktionen',
    actions: [
      {
        label: 'Antrag weiterleiten',
        EventSource: RedirectModalTrigger,
      },
      {
        label: 'Antrag zuweisen an...',
        EventSource: AssignModalTrigger,
        disabled: !isAssignable(adviceDetail, user!),
      },
      {
        label: 'Antrag zuweisen an mich',
        EventSource: AssignToMeTrigger,
        disabled: !isAssignableToMe(adviceDetail),
      },
      ...(isDEVKinsured
        ? [
            'separator' as const,
            {
              label: 'Versicherer kontaktieren',
              EventSource: GeneralContactTrigger,
              validator: validateOpenContact(setErrors, adviceDetail),
            },
            {
              label: 'Rechnung/Vorschuss versenden',
              EventSource: InvoiceTrigger,
              validator: validateOpenContact(setErrors, adviceDetail),
            },
            {
              label: 'Deckungsanfrage versenden',
              EventSource: CoverageFormModalTrigger,
              validator: validateOpenCoverageRequest(setErrors, adviceDetail),
              disabled: disableCoverageRequest,
            },
          ]
        : []),
    ],
  })

  const defaultFormikCompany = () => ({
    name: '',
    companyType: '',
    address: {
      street: '',
      streetNr: '',
      city: '',
      zip: '',
    },
    taxId: '',
  })

  const formik = useFormik({
    initialValues: {
      insuranceNumber: adviceDetail.insuranceNumber || '',
      legalProtectionInsurer: adviceDetail.legalProtectionInsurer || '',
      damageNumber: adviceDetail.damageNumber || '',
      disputeAmount: adviceDetail.disputeAmount || '',
      firstname: adviceDetail.firstname || '',
      lastname: adviceDetail.lastname || '',
      street: adviceDetail.street || '',
      streetNr: adviceDetail.streetNr || '',
      city: adviceDetail.city || '',
      zip: adviceDetail.zip || '',
      phone: adviceDetail.phone || '',
      email: adviceDetail.email || '',
      lawyerNotes: adviceDetail.lawyerNotes || '',
      resultNotes: adviceDetail.resultNotes || '',
      company: adviceDetail.company || defaultFormikCompany(),
      billingCompany: adviceDetail.billingCompany || defaultFormikCompany(),
    },
    validate: values => {
      const errors: { [prop: string]: string } = {}
      if (values.legalProtectionInsurer === 'DEVK') {
        if (values.insuranceNumber && values.insuranceNumber.replace(/[^\d]/g, '').length !== 9) {
          errors.insuranceNumber = 'Die DEVK-Versicherungsscheinnummer muss, falls vorhanden, aus 9 Ziffern bestehen'
        }

        if (values.damageNumber && values.damageNumber.replace(/[^\d]/g, '').length !== 14) {
          errors.damageNumber = 'Die DEVK-Schadensnummer muss, falls vorhanden, aus 14 Ziffern bestehen'
        }

        if (values.disputeAmount && !/^\d{1,3}(?:\.?\d{3})*(?:,\d{2})?$/.test(values.disputeAmount)) {
          errors.disputeAmount = 'Bitte einen gültigen Euro-Betrag als Streitwert angeben.'
        }
      }

      return errors
    },
    onSubmit: async values => {
      setSaving(true)

      const newAdviceDetail = {
        ...adviceDetail,
        ...values,
        ...{ company: !isBusinessProduct ? undefined : values.company },
        ...{ billingCompany: !isBusinessProduct || !billingCompany ? undefined : values.billingCompany },
      }

      await saveAdviceRequest(
        {
          adviceRequest: newAdviceDetail,
        },
        {}
      )
      setErrors({})
      setAdviceDetail(newAdviceDetail)
      onAdviceSaved(newAdviceDetail)
      setSaving(false)
      setEditMode(false)
    },
  })

  const { setValues } = formik

  useEffect(() => {
    setAdviceDetail(originalAdviceDetail)
    setbillingCompany(!!originalAdviceDetail.billingCompany)
    setValues({
      insuranceNumber: originalAdviceDetail.insuranceNumber || '',
      legalProtectionInsurer: originalAdviceDetail.legalProtectionInsurer || '',
      damageNumber: originalAdviceDetail.damageNumber || '',
      disputeAmount: originalAdviceDetail.disputeAmount || '',
      firstname: originalAdviceDetail.firstname || '',
      lastname: originalAdviceDetail.lastname || '',
      street: originalAdviceDetail.street || '',
      streetNr: originalAdviceDetail.streetNr || '',
      city: originalAdviceDetail.city || '',
      zip: originalAdviceDetail.zip || '',
      phone: originalAdviceDetail.phone || '',
      email: originalAdviceDetail.email || '',
      lawyerNotes: originalAdviceDetail.lawyerNotes || '',
      resultNotes: originalAdviceDetail.resultNotes || '',
      company: originalAdviceDetail.company || defaultFormikCompany(),
      billingCompany: originalAdviceDetail.billingCompany || defaultFormikCompany(),
    })
  }, [setValues, originalAdviceDetail])

  const isMandate = adviceDetail.status === 'mandate' || adviceDetail.status === 'complete'

  const toolbarProps: ToolbarProps = {
    title: isBusinessProduct
      ? `Antragsdetails ${adviceDetail.adviceId}`
      : isMandate
      ? `Akte ${adviceDetail.mandateName || ''}`
      : `Antragsdetails ${adviceDetail.adviceId}`,
    buttons: [
      editMode ? (
        <ButtonRounded key="save-button" loading={saving} iconLeft={<SVGSave width="20" height="20" />} type="submit">
          Änderungen speichern
        </ButtonRounded>
      ) : (
        <ButtonRounded key="edit-button" onClick={setEditModeTrue} iconLeft={<SVGEditRegular width="20" height="20" />}>
          Bearbeiten
        </ButtonRounded>
      ),
      ...(isDEVKinsured
        ? [
            <CoverageFormModalTrigger
              key="coverage-form-button"
              disabled={editMode || disableCoverageRequest || !!adviceDetail.damageNumber}
              validator={validateOpenCoverageRequest(setErrors, adviceDetail)}
              renderTrigger={CoverageFormButton}
            />,
          ]
        : []),
      ...(!isPreview ? [<MenuTrigger key="actions-button" disabled={editMode} renderTrigger={ActionsButton} />] : []),
      <ButtonRounded key="close-button" onClick={onBack} iconLeft={<SVGExit height="15" width="15" />} />,
    ],
  }

  const emptyPanelProps = {
    title: '',
    buttons: [],
  }

  const isProcessing = adviceDetail.status === 'processing'

  const onProcessingClick = async () => {
    if (adviceDetail.id) {
      await setToProcessing({ id: adviceDetail.id })
      history.push(URLs.chancellery.b2b.processing)
    }
  }

  const onCompleteClick = async () => {
    if (adviceDetail.id) {
      setCompleteLoading(true)
      await setToComplete({ id: adviceDetail.id })
      setCompleteLoading(false)
      setCompleteOpen(true)
    }
  }

  const onCompleteModalClick = () => {
    setCompleteOpen(false)
    history.push(URLs.chancellery.b2b.processing)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} style={{ position: 'relative' }}>
        {premiumPanel && (
          <>
            <Toolbar {...toolbarProps} />
            <ProductPanel
              title={
                isBusinessProduct && !isIndividualProduct
                  ? `Leistungsübersicht: ${adviceDetail.product.name} für ${formatCurrency({ unit: 'EUR' })(
                      adviceDetail.product.netto
                    )}`
                  : `Leistungsübersicht : ${adviceDetail.product.name}`
              }
              features={adviceDetail.product.services}
            />
            {showPanelActions && (
              <ConsultationPanel>
                <ConsultationPanelStep
                  text="Bitte bestätigen Sie den Erstkontakt und besprechen Sie mit dem Kunden das weitere Vorgehen."
                  title="1. Beratung beginnen"
                  callBackTime={
                    adviceDetail.preferredCallbackTime ? formatCallbackTime(adviceDetail.preferredCallbackTime) : undefined
                  }
                  completed={adviceDetail.adviceConfirmed}
                  buttons={[
                    <ButtonPrimary
                      key={1}
                      onClick={onOpenAdviceConfirmModal}
                      disabled={!!adviceDetail.adviceConfirmed || editMode}
                      color={!!adviceDetail.adviceConfirmed || editMode ? 'disabled' : 'primary'}
                      iconLeft={
                        adviceDetail.adviceConfirmed ? (
                          <SVGCheckCircle width="16" height="16" />
                        ) : (
                          <SVGCheck width="16" height="16" />
                        )
                      }
                    >
                      {adviceDetail.adviceConfirmed ? 'Bestätigt' : 'Bestätigen'}
                    </ButtonPrimary>,
                  ]}
                />
                {isIndividualProduct ? (
                  <ConsultationPanelStep
                    text={
                      proposalAccepted
                        ? 'Versenden Sie im nächsten Schritt die Rechnung mit nachfolgenden Kontodaten an den Kunden.'
                        : 'Erstellen Sie das individuelle Angebot für den Kunden.'
                    }
                    content={proposalAccepted ? <AccountDisplay account={adviceDetail.payment?.receiverAccount} /> : undefined}
                    title="2. Angebot"
                    buttons={[
                      !proposalAccepted ? (
                        <ProposalAmountTrigger
                          key={1}
                          disabled={!adviceDetail.adviceConfirmed || editMode}
                          renderTrigger={props => <ProposalAmountButton style={{ marginRight: '1rem' }} {...props} />}
                        />
                      ) : (
                        <InvoiceSentConfirmTrigger
                          key={2}
                          disabled={!adviceDetail.adviceConfirmed || editMode}
                          renderTrigger={props => <ProposalInvoiceSentButton style={{ marginRight: '1rem' }} {...props} />}
                        />
                      ),
                      <ProposalRejectedConfirmTrigger
                        key={3}
                        disabled={!adviceDetail.adviceConfirmed || editMode}
                        renderTrigger={props => <ProposalRejectedButton {...props} />}
                      />,
                    ]}
                  />
                ) : (
                  <ConsultationPanelStep
                    text="Schließen Sie den Fall ab oder verschieben Sie ihn zur weiteren Bearbeitung"
                    title="2. Weitere Bearbeitung vornehmen"
                    buttons={
                      isBusinessProduct
                        ? [
                            isProcessing ? (
                              <ButtonPrimary
                                key={1}
                                loading={completeLoading}
                                onClick={onCompleteClick}
                                disabled={!adviceDetail.adviceConfirmed || editMode}
                                style={{ marginRight: '1rem' }}
                              >
                                Beratung abschließen
                              </ButtonPrimary>
                            ) : (
                              <ButtonPrimary
                                key={1}
                                onClick={onProcessingClick}
                                disabled={!adviceDetail.adviceConfirmed || editMode}
                                color={!adviceDetail.adviceConfirmed || editMode ? 'disabled' : 'primary'}
                              >
                                Beratung starten
                              </ButtonPrimary>
                            ),
                            <ButtonPrimary
                              key={2}
                              onClick={onOpenCloseModal}
                              disabled={!adviceDetail.adviceConfirmed || editMode}
                              color={!adviceDetail.adviceConfirmed || editMode ? 'disabled' : 'primary'}
                            >
                              Beratung abbrechen
                            </ButtonPrimary>,
                          ]
                        : [
                            <ButtonPrimary
                              key={1}
                              onClick={onOpenFinishModal}
                              disabled={!adviceDetail.adviceConfirmed || editMode}
                              color={!adviceDetail.adviceConfirmed || editMode ? 'disabled' : 'primary'}
                            >
                              Weitere Fallbearbeitung
                            </ButtonPrimary>,
                          ]
                    }
                  />
                )}
              </ConsultationPanel>
            )}
          </>
        )}
        <Panel {...(premiumPanel ? emptyPanelProps : toolbarProps)}>
          <FormGroup>
            <StaticFieldRow
              image={<SVGRequestData width="18" height="20" className="icons-label m-r-10" />}
              title="Antragsdaten"
              fields={RequestDataArray}
            />
          </FormGroup>
          {editMode ? (
            <>
              <SVGEdit width="18" height="20" className="icons-label" style={{ marginRight: '8px' }} />
              <h3 className="h3 small">Antrag bearbeiten</h3>
              <div style={{ maxWidth: '53.5rem', marginLeft: '25px' }}>
                <FieldRow>
                  <SelectField
                    width="26rem"
                    options={legalProtectionInsurer.map(insurer => ({
                      value: insurer,
                      label: !insurer ? 'Nicht bekannt' : insurer,
                    }))}
                    label="Rechtsschutzversicherer"
                    id="input-legalProtectionInsurer"
                    {...formik.getFieldProps('legalProtectionInsurer')}
                  />
                  {formik.values.legalProtectionInsurer === 'DEVK' ? (
                    <InsuranceNumberField
                      label="Versicherungsscheinnummer"
                      id="input-insuranceNumber"
                      error={formik.touched.insuranceNumber ? formik.errors.insuranceNumber : undefined}
                      {...formik.getFieldProps('insuranceNumber')}
                    />
                  ) : (
                    <InputField
                      label="Versicherungsscheinnummer"
                      id="input-insuranceNumber"
                      {...formik.getFieldProps('insuranceNumber')}
                    />
                  )}
                </FieldRow>
                <FieldRow>
                  {formik.values.legalProtectionInsurer === 'DEVK' ? (
                    <DamageNumberField
                      label="Schadensnummer"
                      id="input-damageNumber"
                      error={formik.touched.damageNumber ? formik.errors.damageNumber : undefined}
                      {...formik.getFieldProps('damageNumber')}
                    />
                  ) : (
                    <InputField label="Schadensnummer" id="input-damageNumber" {...formik.getFieldProps('damageNumber')} />
                  )}
                  <InputField
                    label="Streitwert"
                    id="input-disputeAmount"
                    error={formik.touched.disputeAmount ? formik.errors.disputeAmount : undefined}
                    {...formik.getFieldProps('disputeAmount')}
                  />
                </FieldRow>
                <FieldRow>
                  <InputField label="Vorname" id="input-firstname" {...formik.getFieldProps('firstname')} />
                  <InputField label="Name" id="input-lastname" {...formik.getFieldProps('lastname')} />
                </FieldRow>
                {!isBusinessProduct && (
                  <>
                    <FieldRow>
                      <InputField label="Straße" id="input-street" {...formik.getFieldProps('street')} />
                      <InputField label="Hausnr." id="input-streetNr" width="5rem" {...formik.getFieldProps('streetNr')} />
                    </FieldRow>
                    <FieldRow>
                      <InputField label="Postleitzahl" id="input-zip" width="7rem" {...formik.getFieldProps('zip')} />
                      <InputField label="Stadt" id="input-city" {...formik.getFieldProps('city')} />
                    </FieldRow>
                  </>
                )}
                <FieldRow>
                  <InputField label="Email" id="input-email" {...formik.getFieldProps('email')} />
                  <InputField label="Telefon Nr." id="input-phone" {...formik.getFieldProps('phone')} />
                </FieldRow>
              </div>
            </>
          ) : (
            <>
              <FormGroup>
                <InsuranceFieldSet adviceRequest={adviceDetail} />
                <StaticRow>
                  <Col>
                    <Row className="m-l-5 row-margin">
                      <Col>
                        <div style={{ marginLeft: '10px' }}>
                          {!!errors.insuranceNumber && <FormFeedback>{errors.insuranceNumber}</FormFeedback>}
                          {!!errors.legalProtectionInsurer && <FormFeedback>{errors.legalProtectionInsurer}</FormFeedback>}
                          {!!errors.disputeAmount && <FormFeedback>{errors.disputeAmount}</FormFeedback>}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </StaticRow>
              </FormGroup>
              {adviceDetail.legalProtectionInsurer === 'DEVK' && (
                <FormGroup>
                  <PartnerView adviceRequest={adviceDetail} />
                </FormGroup>
              )}
              <FormGroup>
                <StaticFieldRow
                  image={<SVGPersonData width="18" height="20" className="icons-label m-r-10" />}
                  title="Personendaten"
                  fields={PersonDataArray}
                />
                <StaticRow>
                  <Row className="row-margin">
                    <Col>
                      <BooleanStaticField value={adviceDetail.dataAccepted} label="Einwilligungserklärung aktzeptiert" />
                    </Col>
                  </Row>
                </StaticRow>
              </FormGroup>
            </>
          )}
          {isBusinessProduct &&
            (editMode ? (
              <>
                <SVGBusiness width="21" height="20" className="icons-label" style={{ marginRight: '8px' }} />
                <h3 className="h3 small">Unternehmensinformationen</h3>
                <div style={{ maxWidth: '53.5rem', marginLeft: '25px' }}>
                  <FieldRow>
                    <InputField
                      label="Unternehmensname / Name"
                      id="input-companyName"
                      {...formik.getFieldProps('company.name')}
                    />
                    <InputField
                      label="Unternehmensform"
                      id="input-companyType"
                      {...formik.getFieldProps('company.companyType')}
                    />
                  </FieldRow>
                  <FieldRow>
                    <InputField label="Straße" id="input-companyStreet" {...formik.getFieldProps('company.address.street')} />
                    <InputField
                      label="Hausnr."
                      width="5rem"
                      id="input-companyStreetNr"
                      {...formik.getFieldProps('company.address.streetNr')}
                    />
                  </FieldRow>
                  <FieldRow>
                    <InputField
                      label="Postleitzahl"
                      width="7rem"
                      id="input-companyZip"
                      {...formik.getFieldProps('company.address.zip')}
                    />
                    <InputField label="Stadt" id="input-companyCity" {...formik.getFieldProps('company.address.city')} />
                  </FieldRow>
                  <FieldRow>
                    <InputField
                      label="USt-IdNr. / Steuernummer"
                      id="input-companyTaxId"
                      {...formik.getFieldProps('company.taxId')}
                    />
                  </FieldRow>
                  <FieldRow>
                    <CheckboxField
                      onChange={() => setbillingCompany(!billingCompany)}
                      checked={!!billingCompany}
                      name="damageNumbillingCompanyAddressberDisabled"
                      id="checkbox-billingCompanyAddress"
                      label="Abweichende Rechnungsadresse"
                    />
                  </FieldRow>
                  {billingCompany && (
                    <>
                      <FieldRow>
                        <InputField
                          label="Unternehmensname / Name"
                          id="input-billingCompanyName"
                          {...formik.getFieldProps('billingCompany.name')}
                        />
                        <InputField
                          label="Unternehmensform"
                          id="input-billingCompanyType"
                          {...formik.getFieldProps('billingCompany.companyType')}
                        />
                      </FieldRow>
                      <FieldRow>
                        <InputField
                          label="Straße"
                          id="input-billingCompanyStreet"
                          {...formik.getFieldProps('billingCompany.address.street')}
                        />
                        <InputField
                          label="Hausnr."
                          width="5rem"
                          id="input-billingCompanyStreetNr"
                          {...formik.getFieldProps('billingCompany.address.streetNr')}
                        />
                      </FieldRow>
                      <FieldRow>
                        <InputField
                          label="Postleitzahl"
                          width="7rem"
                          id="input-billingCompanyCity"
                          {...formik.getFieldProps('billingCompany.address.city')}
                        />
                        <InputField
                          label="Stadt"
                          id="input-billingCompanyZip"
                          {...formik.getFieldProps('billingCompany.address.zip')}
                        />
                      </FieldRow>
                      <FieldRow>
                        <InputField
                          label="USt-IdNr. / Steuernummer"
                          id="input-billingCompanyTaxId"
                          {...formik.getFieldProps('billingCompany.taxId')}
                        />
                      </FieldRow>
                    </>
                  )}
                </div>
              </>
            ) : (
              <FormGroup>
                <StaticFieldRow
                  image={<SVGBusiness width="21" height="20" className="icons-label" style={{ marginRight: '8px' }} />}
                  title="Unternehmensinformationen"
                  fields={CompanyDataArray}
                />
                {!!BillingCompanyDataArray.length && (
                  <StaticFieldRow
                    image={<SVGBusiness width="21" height="20" className="icons-label" style={{ marginRight: '8px' }} />}
                    title="Abweichende Rechnungsinformationen"
                    fields={BillingCompanyDataArray}
                  />
                )}
              </FormGroup>
            ))}
          {adviceDetail.product.type !== 'BUSINESS' && (
            <>
              <FormGroup>
                <TextAreaRow
                  id="text-area-description"
                  value={adviceDetail.description}
                  title="Fallbeschreibung"
                  label=""
                  image={<SVGDescription width="16" height="20" className="icons-label m-r-10" />}
                />
              </FormGroup>
              <FormGroup>
                <TextAreaRow
                  id="text-area-externalNotes"
                  value={adviceDetail.externalNotes}
                  title="Bemerkung für den Anwalt aus Erstkontakt"
                  label=""
                  image={<SVGRemark width="18" height="18" className="icons-label m-r-10" />}
                />
              </FormGroup>
            </>
          )}
          {editMode ? (
            <>
              <FormGroup>
                <TextAreaRow
                  id="text-area-lawyerNotes"
                  title="Interne Notiz"
                  label=""
                  image={<SVGNote width="22" height="19" className="icons-label m-r-10" />}
                  disabled={!roleLawyer}
                  {...formik.getFieldProps('lawyerNotes')}
                />
              </FormGroup>
              {!isBusinessProduct && (
                <FormGroup>
                  <TextAreaRow
                    id="text-area-resultNotes"
                    title="Ergebnis der Erstberatung"
                    label=""
                    image={<SVGResultNote width="18" height="18" className="icons-label m-r-10" />}
                    disabled={!roleLawyer}
                    {...formik.getFieldProps('resultNotes')}
                  />
                </FormGroup>
              )}
            </>
          ) : (
            <>
              <FormGroup>
                <TextAreaRow
                  id="text-area-lawyerNotes"
                  value={adviceDetail.lawyerNotes}
                  title="Interne Notiz"
                  label=""
                  image={<SVGNote width="22" height="19" className="icons-label m-r-10" />}
                />
              </FormGroup>
              {!isBusinessProduct && (
                <FormGroup>
                  <TextAreaRow
                    id="text-area-resultNotes"
                    value={adviceDetail.resultNotes}
                    title="Ergebnis der Erstberatung"
                    label=""
                    image={<SVGResultNote width="18" height="18" className="icons-label m-r-10" />}
                  />
                </FormGroup>
              )}
            </>
          )}
          <FormGroup>
            {roleLawyer && (
              <div className="bottom-bar">
                {adviceDetail.status !== 'closed' && (
                  <div className="m-l-20">
                    <ButtonSecondary
                      iconLeft={
                        <SVGAnhang
                          width="14"
                          height="16"
                          className={classnames({ 'lock-attachment-icon': !!adviceDetail.lockedBy })}
                        />
                      }
                      disabled={!!adviceDetail.lockedBy}
                    >
                      <Label for="fileInput" className="input-file" disabled={!!adviceDetail.lockedBy}>
                        <Input
                          type="file"
                          name="fileInput"
                          id="fileInput"
                          data-multiple-caption="{count} files selected"
                          multiple
                          onChange={onAddFiles}
                          disabled={!!adviceDetail.lockedBy}
                        />
                        {fileButton}
                      </Label>
                    </ButtonSecondary>
                  </div>
                )}
                <div className="bottom-bar__stretch">
                  {!!adviceDetail.files.length && (
                    <Label
                      className={classnames('row-hover label', { 'lock-label': !!adviceDetail.lockedBy })}
                      onClick={onOpenFilesModal}
                    >
                      {adviceDetail.files.length === 1 ? (
                        <Fragment>1 Anhang</Fragment>
                      ) : (
                        <Fragment>{adviceDetail.files.length} Anhänge</Fragment>
                      )}
                    </Label>
                  )}
                </div>
                {adviceDetail.status === 'mandate' && (
                  <div className="m-r-20">
                    {!hasEConsult && (
                      <ButtonPrimary
                        iconLeft={<Transfer width="20" height="20" />}
                        onClick={onOpenEConsultModal}
                        className="m-r-20"
                        disabled={!!adviceDetail.lockedBy}
                      >
                        Akte übertragen
                      </ButtonPrimary>
                    )}
                    <ButtonPrimary
                      iconLeft={<SVGCloseRequest width="20" height="20" />}
                      onClick={onOpenCompleteModal}
                      disabled={!!adviceDetail.lockedBy}
                    >
                      {adviceDetail.revenue === 0 || adviceDetail.revenue ? 'Akte ablegen und schließen' : 'Akte ablegen'}
                    </ButtonPrimary>
                  </div>
                )}
              </div>
            )}
          </FormGroup>
        </Panel>
      </form>
      <AssignModal />
      <RedirectModal />
      <GeneralContactModal />
      <InvoiceModal />
      <CoverageFormModal />
      <ProposalAmountModal />
      <InvoiceSentConfirmModal />
      <ProposalRejectedConfirmModal />
      <B2BCompleteModal open={completeOpen} onCompleteClick={onCompleteModalClick} />
    </>
  )
}
