import React, { FC } from 'react'

import { customPartners } from '../../constants'
import { AdviceRequest } from '../../interfaces'

interface PartnerViewProps {
  adviceRequest: AdviceRequest
  errors: { [key: string]: string }
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
}

export const PartnerForm: FC<PartnerViewProps> = ({ adviceRequest, errors, onFieldChange, onCheckboxChange }) => {
  if (!adviceRequest.partner) {
    return null
  }
  const InnerPartnerForm = customPartners[adviceRequest.partner.id!].form
  return (
    <div className="m-t-20">
      <InnerPartnerForm
        adviceRequest={adviceRequest}
        errors={errors}
        onFieldChange={onFieldChange}
        onCheckboxChange={onCheckboxChange}
      />
    </div>
  )
}
