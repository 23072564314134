import { Roles } from '../constants/roles'
import { User } from '../interfaces/User'

import { containsSome } from './functional'

export const isTRBManager = (user?: User) => !!user && containsSome([Roles.TRBManager])(user.roles)
export const isTRB = (user?: User) => !!user && (containsSome([Roles.TRB])(user.roles) || isTRBManager(user))
export const isLawyer = (user?: User) => !!user && containsSome([Roles.Lawyer])(user.roles)
export const isChannel = (user?: User) => !!user && containsSome([Roles.Channel])(user.roles)
export const isAdmin = (user?: User) => !!user && containsSome([Roles.Administrator])(user.roles)
export const isCallcenter = (user?: User) => !!user && containsSome([Roles.Callcenter])(user.roles)
export const isEmployee = (user?: User) => !!user && containsSome([Roles.Employee])(user.roles)
