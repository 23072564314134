import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { MutationSaveSalesAttachmentsArgs } from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/saveSalesAttachments.graphql'
import { FileInput } from '../interfaces'

export const saveSalesAttachments = async (adviceRequestId: string, files: Array<FileInput>) => {
  try {
    await request<MutationSaveSalesAttachmentsArgs>(ADVICE_REQUESTS_ENDPOINT, query, {
      adviceRequestId,
      files,
    })
  } catch (error) {
    throw error
  }
}
