import classnames from 'classnames'
import React, { ChangeEventHandler, Fragment } from 'react'
import { Col, Input } from 'reactstrap'

import { InputTextValue } from 'common/interfaces'

import './InputTextField.scss'

interface InputTextFieldProps {
  data: Array<InputTextValue>
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
}

export const InputTextField = (props: InputTextFieldProps) => {
  const inputTextFieldClass = classnames('input-text-field', props.className)
  const { data } = props

  return (
    <Fragment>
      {data.map(key => (
        <Col md={3} key={key.name}>
          <div className={inputTextFieldClass} key={key.name}>
            <Input className="input-text-field__value" value={key.value} name={key.name} onChange={props.onChange} />
            <div className="horizontal-line" />
            <div className="input-text-field__label">{key.name}</div>
          </div>
        </Col>
      ))}
    </Fragment>
  )
}
