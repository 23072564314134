import SaveIcon from '@mui/icons-material/Save'
import { Button, ButtonProps } from '@mui/material'
import React, { FunctionComponent } from 'react'

import { useFormActionButtonStyles } from '../helpers'

// eslint-disable-next-line fp/no-rest-parameters
export const SaveButton: FunctionComponent<ButtonProps> = ({ children, ...rest }) => {
  const { button } = useFormActionButtonStyles()
  return (
    <Button variant="contained" color="primary" type="submit" startIcon={<SaveIcon />} classes={{ root: button }} {...rest}>
      {children || 'Speichern'}
    </Button>
  )
}
