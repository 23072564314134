import React from 'react'

import { booleanToLabel } from '../../utils'

import { StaticField } from './StaticField'

interface BooleanStaticFieldProps {
  label: string
  value: boolean
}

export const BooleanStaticField = (props: BooleanStaticFieldProps) => (
  <StaticField label={props.label} value={booleanToLabel(props.value)} />
)
