import { useEffect, useRef, useState } from 'react'

export const useSequence = (sequence: string): boolean => {
  const [currentSequence, setCurrentSequence] = useState<Array<string>>([])
  const [solved, setSolved] = useState(false)
  const lastKeystroke = useRef(Date.now())

  useEffect(() => {
    const saveKeystrokes = (e: KeyboardEvent): void => {
      const latestKeystroke = Date.now()
      if (latestKeystroke - lastKeystroke.current > 1500) {
        setCurrentSequence([])
      }

      if (typeof e.key !== 'undefined' && /[a-zA-Z\d]/.test(e.key)) {
        setCurrentSequence(seq => seq.concat(e.key.toLowerCase()))
        // eslint-disable-next-line fp/no-mutation
        lastKeystroke.current = latestKeystroke
      }
    }
    window.addEventListener('keyup', saveKeystrokes)
    return () => window.removeEventListener('keyup', saveKeystrokes)
  }, [currentSequence])

  useEffect(() => {
    if (currentSequence.join('') === sequence.toLowerCase()) {
      setSolved(true)
    }
  }, [currentSequence, sequence])
  return solved
}
