import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/coverageForms.graphql'
import { CoverageFormsQuery } from '../interfaces/schemaDefinition'

export const getCoverageForms = async (): Promise<CoverageFormsQuery['coverageForms']> => {
  const response = await request<CoverageFormsQuery>(ADVICE_REQUESTS_ENDPOINT, query)
  return response.coverageForms
}
