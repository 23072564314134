import { Card, CardHeader, CardContent, Typography, List, ListItem, ListItemText, Avatar } from '@mui/material'
import { lighten, darken } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FunctionComponent } from 'react'

import appTheme from 'theme/theme'

type ErrorListCardProps = {
  list: Array<string>
  severity: 'error' | 'warning' | 'success'
  title: string
  showNumber?: boolean
  maxHeight?: number
  className?: string
}

const useStyles = makeStyles<typeof appTheme, { maxHeight: number; severity: 'error' | 'warning' | 'success' }>(theme => ({
  errorCardHeader: {
    backgroundColor: props => lighten(theme.palette[props.severity].main, 0.9),
    color: props => darken(theme.palette[props.severity].main, 0.6),
  },
  errorCardHeaderTitle: {
    fontWeight: 'bold',
  },
  errorAvatar: {
    backgroundColor: props => theme.palette[props.severity].main,
  },
  problemsList: {
    maxHeight: props => `${props.maxHeight}px`,
    overflowY: 'auto',
  },
}))

export const ErrorListCard: FunctionComponent<ErrorListCardProps> = ({
  children,
  severity,
  className,
  list,
  title,
  showNumber = true,
  maxHeight = 500,
}) => {
  const classes = useStyles({ maxHeight, severity })
  return (
    <Card className={className}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          className: classes.errorCardHeaderTitle,
          variant: 'body2',
        }}
        avatar={
          showNumber ? <Avatar className={classes.errorAvatar}>{list.length < 100 ? list.length : '99+'}</Avatar> : undefined
        }
        className={classes.errorCardHeader}
      />
      <CardContent>
        <Typography>{children}</Typography>
        <List className={classes.problemsList}>
          {list.map(item => (
            <ListItem key={item}>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}
