import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { PotentialInput, SetToPotentialMutation, SetToPotentialMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/setToPotential.graphql'

export const setToPotential = async (potentialInput: PotentialInput): Promise<string> => {
  const variables = {
    potentialInput: {
      id: potentialInput.id,
      resultNotes: potentialInput.resultNotes,
      lawyerNotes: potentialInput.lawyerNotes,
      reminderDate: potentialInput.reminderDate,
    },
  }

  const response = await request<SetToPotentialMutation, SetToPotentialMutationVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )
  return response.setToPotential
}
