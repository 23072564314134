import classNames from 'classnames'
import React, { FC } from 'react'

import { Icon } from 'common/components/Icon'

import styles from './PanelSegment.module.scss'

interface PanelSegmentProps {
  title?: string
  svg?: SVG
  iconClassName?: string
  className?: string
}

export const PanelSegment: FC<PanelSegmentProps> = ({ title, svg, iconClassName, className, children }) => (
  <>
    <span className={className}>
      {title && (
        <div className={classNames(styles.header, { [styles.wihtoutIcon]: !svg })}>
          {svg && <Icon svg={svg} size="medium" className={classNames(styles.icon, iconClassName)} />}
          <h3 className="h3 small">{title}</h3>
        </div>
      )}
      <div className={styles.children}>{children}</div>
    </span>
  </>
)
