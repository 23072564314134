/* tslint:disable: no-object-mutation no-array-mutation */

import { SortDirection, ListState } from 'common/interfaces'

export const DEFAULT_LIST_STATE: ListState = {
  page: 1,
  pageSize: 100,
  sort: {
    sortBy: 'name',
    sortDirection: SortDirection.Asc,
  },
  filters: [],
}
