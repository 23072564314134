import { lowerCase, split, startCase } from 'lodash/fp'

import { ListState, Sort, Filters } from '../../../common/interfaces'

export const buildFilterQueryString = (filters: Filters) => {
  const filtersJson = JSON.stringify(filters)
  return `filters=${filtersJson}`
}

export const buildPaginationQueryStrings = (page: number, pageSize: number) => `page=${page}&pageSize=${pageSize}`

export const buildSortQueryStrings = (sort: Sort) => `sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}`

export const buildTableNameQueryString = (filters: Filters) => {
  const tableName = lowerCase(split(' ', startCase(filters[0].name))[0])
  return `tableName=${tableName}`
}

export const buildQueryStrings = ({ page, pageSize, sort, filters }: ListState) => {
  const paginationQueryStrings = page && pageSize ? buildPaginationQueryStrings(page, pageSize) : ''
  const sortQueryStrings = sort ? buildSortQueryStrings(sort) : ''
  const filterQueryString = filters ? buildFilterQueryString(filters) : ''
  const tableNameQueryString = filters.length ? buildTableNameQueryString(filters) : ''
  if (tableNameQueryString !== '') {
    return `${tableNameQueryString}&${paginationQueryStrings}&${sortQueryStrings}&${filterQueryString}`
  }
  return `${paginationQueryStrings}&${sortQueryStrings}&${filterQueryString}`
}

export const mapListStateToSearch = (listState: ListState, prefix = '') => {
  const json = JSON.stringify(listState)
  return prefix ? `${prefix}List=${json}` : `list=${json}`
}
