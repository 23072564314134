import React, { FC } from 'react'
import { Form, FormGroup, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import { ButtonPrimary, ButtonSecondary, ButtonBack } from 'common/ui'

import SVGDelete from '../images/delete.svg'
import SVGConfirmation from '../images/icon_bestätigung.svg'
import SVGTrashBin from '../images/icon_papierkorb.svg'
import SVGSave from '../images/save.svg'
import SVGNext from '../images/weiter.svg'

interface SaveModalProps {
  open: boolean
  loading: boolean
  saveSuccess: boolean
  withOutSaveSuccess: boolean
  onCancelClick: () => void
  onWithoutSaveClick: () => void
  onContinueSaveClick: () => void
  onCanceledSaveClick: () => void
  onFinishedSaveClick: () => void
}

export const SaveModal: FC<SaveModalProps> = ({
  open,
  loading,
  saveSuccess,
  withOutSaveSuccess,
  onCancelClick,
  onWithoutSaveClick,
  onContinueSaveClick,
  onCanceledSaveClick,
  onFinishedSaveClick,
}) => {
  if (loading || !open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  if (withOutSaveSuccess) {
    return (
      <Modal isOpen={open} className="success-modal">
        <ModalBody>
          <SVGTrashBin height="80" width="80" />
          <Label>Ihre Änderungen wurden verworfen.</Label>
        </ModalBody>
        <ModalFooter>
          <ButtonPrimary onClick={onCanceledSaveClick} iconLeft={<SVGNext width="18" height="18" />}>
            Weiter
          </ButtonPrimary>
        </ModalFooter>
      </Modal>
    )
  }

  if (saveSuccess) {
    return (
      <Modal isOpen={open} className="success-modal">
        <ModalBody>
          <SVGConfirmation height="80" width="80" />
          <Label>Ihre Änderungen wurden erfolgreich gespeichert.</Label>
        </ModalBody>
        <ModalFooter>
          <ButtonPrimary onClick={onFinishedSaveClick} iconLeft={<SVGNext width="18" height="18" />}>
            Weiter
          </ButtonPrimary>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} className="new-modal large-size" id="SaveModal">
      <ModalBody>
        <Form className="form">
          <FormGroup>
            <h3 className="h3 small">Möchten Sie Ihre Änderungen speichern?</h3>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary className="m-b-15" onClick={onContinueSaveClick} iconLeft={<SVGSave width="18" height="18" />}>
          Änderungen speichern
        </ButtonPrimary>
        <ButtonSecondary className="m-b-15" onClick={onWithoutSaveClick} iconLeft={<SVGDelete width="14" height="18" />}>
          Änderungen verwerfen
        </ButtonSecondary>
        <ButtonBack onClick={onCancelClick}>Zurück</ButtonBack>
      </ModalFooter>
    </Modal>
  )
}
