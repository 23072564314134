import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { MutationDeleteSalesAttachmentArgs } from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/deleteSalesAttachment.graphql'

export const deleteSalesAttachment = async (adviceRequestId: string, fileId: string) => {
  try {
    await request<MutationDeleteSalesAttachmentArgs>(ADVICE_REQUESTS_ENDPOINT, query, {
      adviceRequestId,
      fileId,
    })
  } catch (error) {
    throw error
  }
}
