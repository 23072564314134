export const remove = (elem: string | RegExp, value: string): string => value.replace(elem, '')

export const startsWith = (start: string, value: string): boolean => new RegExp(`^${start}.*?`).test(value)

export const endsWith = (ending: string, value: string): boolean => new RegExp(`.*?${ending}$`).test(value)

export const ensureEndsWith = (ending: string, value: string): string => {
  if (endsWith(ending, value)) {
    return value
  }
  return `${value}${ending}`
}
