import { Select as MuiSelect, FormControl, FormHelperText, InputLabel, SelectProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useField } from 'formik'
import React, { FunctionComponent } from 'react'

import theme from 'theme/theme'

import { useScrollToInvalidField } from '../useScrollToInvalidField'

type FormikSelectProps = SelectProps & { name: string; label: string }

const useStyles = makeStyles<typeof theme, FormikSelectProps>({
  container: {
    width: props => (props.fullWidth ? '100%' : undefined),
  },
})

export const Select: FunctionComponent<FormikSelectProps> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { name, label, children, ...rest } = props
  if (!name) {
    throw new Error('The "Select" component needs a "name" that is not the empty string.')
  }
  const [field, meta] = useField(name)
  const classes = useStyles(props)
  const shouldDisplayError = meta.touched && Boolean(meta.error)
  const fieldRef = useScrollToInvalidField<HTMLDivElement>(name)
  return (
    <FormControl ref={fieldRef} variant="outlined" error={shouldDisplayError} className={classes.container}>
      <InputLabel htmlFor={field.name} shrink={Boolean(field.value?.length)}>
        {label}
      </InputLabel>
      <MuiSelect
        native
        label={label}
        // 'notched' is valid for an outlined Select but TS doesn't know that so we cheat.
        {...{ notched: Boolean(field.value?.length) }}
        inputProps={{ id: field.name }}
        error={shouldDisplayError}
        {...rest}
        {...field}
      >
        {children}
      </MuiSelect>
      {shouldDisplayError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}
