import { Function } from '@grapes-agency/universal'
import classnames from 'classnames'
import React, { useMemo, useState, useCallback, ReactNode, FC } from 'react'

import { Popover, PopoverBody, PopoverHeader } from 'common/components/Popover'
import { EventSourceProps, EventSourceComponent } from 'common/hooks/interfaces'

/**
 * A single item in the menu
 * @prop label Display label
 * @prop icon an optional icon element displayed before the label
 * @prop EventSource The EventSourceComponent that implements what should happen when the menu item is clicked
 * @prop disabled If true this menu item is disabled
 * @prop validator if validator returns false the event will not be triggered. You can use high order functions to
 * construct a validator that shows any ui feedback in case of invalid state
 */
export interface MenuAction {
  label: string
  icon?: ReactNode
  EventSource: EventSourceComponent
  disabled?: boolean
  validator?: () => boolean
}

interface MenuProps {
  id: string
  title: string
  actions: Array<MenuAction | 'separator'>
  disabled?: boolean
}

const hasId = (id: string, element: HTMLElement): boolean =>
  element.id === id || (!!element.parentElement && hasId(id, element.parentElement))

const isDomElement = (eventTarget: EventTarget | null | HTMLElement): eventTarget is HTMLElement =>
  !!eventTarget && eventTarget instanceof HTMLElement // eslint-disable-line no-prototype-builtins

const isSeparator = (action: MenuAction | 'separator'): action is 'separator' => typeof action === 'string'

/**
 * Hook that creates an EventSourceComponent that opens a PopoverMenu when triggered.
 * @param MenuProps for configuring the menu.
 * @param MenuProps.id DOM id that should be used for popover
 * @param MenuProps.title Popover title
 * @param MenuProps.actions Actions array that define the menu content. See MenuAction
 * @param MenuProps.disabled If true the menu is disabled
 * @returns EventSourceComponent Opens the popover when triggered
 */
export const usePopoverMenu: Function<MenuProps, FC<EventSourceProps>> = ({ id, title, actions }) => {
  const Trigger: FC<EventSourceProps> = ({ renderTrigger, disabled }) => {
    const [isOpen, setIsOpen] = useState(false)

    const buttonId = `menu-${id}`
    const popoverId = `popover-${id}`

    const trigger = useCallback(
      (event?: React.SyntheticEvent) => {
        setIsOpen(prevIsOpen => !prevIsOpen)
        if (event) {
          event.stopPropagation()
        }
      },
      [setIsOpen]
    )

    // this makes it so that the popover is closed whenever something is clicked without preventing the intended click
    // action, but if the button itself is clicked again it the click action has to be prevented, otherwise the popover
    // would stay open
    const close = useCallback(
      (event: MouseEvent) => {
        if (isDomElement(event.target) && hasId(popoverId, event.target)) {
          return true
        }
        setIsOpen(false)
        if (isDomElement(event.target) && hasId(buttonId, event.target)) {
          event.stopPropagation()
          return false
        }
        return true
      },
      [setIsOpen, buttonId, popoverId]
    )

    if (isOpen) {
      window.addEventListener('click', close, { capture: true, once: true })
    }

    const triggerElement = useMemo(
      () => renderTrigger({ trigger, disabled, id: buttonId }),
      [renderTrigger, trigger, disabled, buttonId]
    )

    return (
      <>
        {triggerElement}
        <Popover placement="left-start" id={popoverId} target={buttonId} isOpen={isOpen} className="advice-menu__popover">
          <PopoverHeader className="advice-menu__header">{title}</PopoverHeader>
          <PopoverBody className="advice-menu__body">
            <ul className="advice-menu__actions">
              {actions.map((action, index) => {
                if (isSeparator(action)) {
                  return (
                    <li key={`separator-${index}`}>
                      <hr />
                    </li>
                  )
                } else {
                  const { label, disabled, EventSource, icon, validator } = action
                  return (
                    <EventSource
                      validator={validator}
                      key={label}
                      disabled={disabled}
                      renderTrigger={({ trigger, disabled }) => (
                        <li
                          onClick={disabled ? () => {} : trigger}
                          className={classnames('advice-menu__action', { 'advice-menu__action--disabled': disabled })}
                        >
                          {icon}
                          {label}
                        </li>
                      )}
                    />
                  )
                }
              })}
            </ul>
          </PopoverBody>
        </Popover>
      </>
    )
  }
  return Trigger
}
