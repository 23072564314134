import { Effect } from '@grapes-agency/universal'
import React, { useMemo, useContext, useState, Context, FC } from 'react'

import { EventSourceProps } from '../interfaces'

/**
 * Effect specific TriggerProps
 * @extends EventSourceProps
 * @prop input The Effects input argument
 * @prop onError callback for error result
 * @prop onSuccess callback for success result
 */
export interface EffectTriggerProps<Input, Result> extends EventSourceProps {
  input: Input
  onError?: (error: any) => void
  onSuccess?: (result: Result) => void
}

const effectTrigger: <I, R>(effect: Effect<I, R>) => FC<EffectTriggerProps<I, R>> =
  effect =>
  ({ input, renderTrigger, disabled, validator = () => true, onSuccess, onError }) => {
    const [loading, setLoading] = useState(false)

    const trigger = async () => {
      setLoading(true)
      try {
        if (!validator()) {
          throw new Error('invalid input')
        }
        const result = await effect(input, {})
        setLoading(false)
        if (onSuccess) {
          onSuccess(result)
        }
      } catch (error) {
        setLoading(false)
        if (onError) {
          onError(error)
        }
      }
    }

    return <>{renderTrigger({ trigger, loading, disabled })}</>
  }

const effectTriggerContext: <C>(context: Context<C>) => <I, R>(effect: Effect<I, R, C>) => FC<EffectTriggerProps<I, R>> =
  context =>
  effect =>
  ({ input, renderTrigger, disabled, onSuccess, onError }) => {
    const contextValue = useContext(context)
    const [loading, setLoading] = useState(false)

    const trigger = async () => {
      setLoading(true)
      try {
        const result = await effect(input, contextValue)
        setLoading(false)
        if (onSuccess) {
          onSuccess(result)
        }
      } catch (error) {
        setLoading(false)
        if (onError) {
          onError(error)
        }
      }
    }

    return <>{renderTrigger({ trigger, loading, disabled })}</>
  }

/**
 * Extended EventSourceComponent that executes an Effect when triggered
 */
export type EffectTriggerComponent<I, R> = FC<EffectTriggerProps<I, R>>

/**
 * Hook that takes an Effect and returns an EffectTriggerComponent that can trigger the effect
 * @param effect any Effect as defined in @grapes-agency/universal
 * @returns EffectTriggerComponent See EffectTriggerComponent
 */
export const useEffectTrigger = <I, R>(effect: Effect<I, R>) => useMemo(() => effectTrigger(effect), [effect])

/**
 * High order function that takes a React.Context and returns an useEffectTrigger hook that will apply the the value of
 * the React.Context as the Context argument of the triggered effect. See @grapes-agency/universal for information on
 * effect arguments
 * @param context Any React.Context
 * @returns useEffectTrigger hook, see useEffectTrigger
 */
export const createContextTrigger = <C,>(context: Context<C>) => {
  const partial = effectTriggerContext(context)
  return <I, R>(effect: Effect<I, R, C>) => useMemo(() => partial(effect), [effect])
}
