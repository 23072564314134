import { debounce } from 'lodash/fp'
import React, { useState, useEffect, useCallback, FC } from 'react'
import { Form } from 'reactstrap'

import { SEARCH } from 'common/constants/routes'
import { useNavigate } from 'common/hooks'

import { SearchField } from './SearchField'

const debounceSearch = debounce(500)

interface SearchContainerProps {
  value?: string
  live?: boolean
  className?: string
}

export const SearchContainer: FC<SearchContainerProps> = ({ value = '', live, children, className }) => {
  const [searchTerm, setSearchTerm] = useState(value)
  const doSearch = useNavigate(SEARCH)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const liveSearch = useCallback(debounceSearch(doSearch), [value])

  useEffect(() => {
    if (live && searchTerm) {
      liveSearch({ searchTerm })
    }
  }, [live, liveSearch, searchTerm])

  const submitSearch = (event: React.FormEvent) => {
    !live && doSearch({ searchTerm })
    event.preventDefault()
  }
  return (
    <Form className={className} onSubmit={submitSearch}>
      <SearchField onChange={setSearchTerm} value={searchTerm} onSearchClick={submitSearch} />
      {children}
    </Form>
  )
}
