import { AdviceRequestFlattened, MatchInput } from '../../interfaces'

export const mapAdviceRequestToMatchInput = (adviceRequest: AdviceRequestFlattened): MatchInput => ({
  productId: adviceRequest.product.id,
  premium: false,
  fieldOfLaw: adviceRequest.fieldOfLaw || '',
  language: adviceRequest.language?.code || 'de',
  contactType: adviceRequest.contactType,
  partnerId: adviceRequest.partner?.id,
  time: new Date(),
  address: {
    zip: adviceRequest.zip,
    city: adviceRequest.city,
    street: adviceRequest.street,
    streetNr: adviceRequest.streetNr,
    country: adviceRequest.country,
  },
})
