import classnames from 'classnames'
import React, { FC } from 'react'
import { FormGroup } from 'reactstrap'

import './FieldSet.scss'

interface FieldSetProps {
  label: string
  icon?: React.ReactNode
  className?: string
}

export const FieldSet: FC<FieldSetProps> = ({ label, icon, className, children }) => (
  <FormGroup tag="fieldset" className={classnames('fieldset', className)}>
    <legend>
      <span className="fieldset__legend">
        {icon}
        <span>{label}</span>
      </span>
    </legend>
    <div className="fieldset__content">{children}</div>
  </FormGroup>
)
