import { Base64 } from 'js-base64'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { setPasswordMutation } from 'packages/userauth/actions/setPassword'

import { PasswordSetView } from '../../components/Password'

type PasswordSetProps = RouteComponentProps<{ token: string }>

interface PasswordSetState {
  password: string
  confirmPassword: string
  errors: { [prop: string]: string }
  success: boolean
  token: string
}

export class PasswordSetContainer extends Component<PasswordSetProps, PasswordSetState> {
  state = {
    password: '',
    confirmPassword: '',
    errors: {},
    success: false,
    token: '',
  }

  componentDidMount() {
    this.setState({ token: this.props.match.params.token })
  }

  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    if (!name) {
      throw 'missing name on event target'
    } else {
      let { password } = this.state
      let { confirmPassword } = this.state
      if (name === 'password') {
        password = value
      } else if (name === 'confirmPassword') {
        confirmPassword = value
      }
      this.setState({
        password,
        confirmPassword,
        errors: {},
      })
    }
  }

  onSetPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    let errors: { [prop: string]: string } = {}
    const newPassword = this.state.password
    const confirmPasswowrd = this.state.confirmPassword
    const { token } = this.state

    if (confirmPasswowrd !== newPassword) {
      errors = {
        ...errors,
        invalidPassword: 'Passwörter stimmen nicht überein.',
      }
    } else if (newPassword === '') {
      errors = {
        ...errors,
        invalidPassword: 'Bitte tragen Sie ein Passwort ein.',
      }
    } else if (token) {
      const decodedToken = Base64.decode(token)

      setPasswordMutation(decodedToken, newPassword)
        .then(() => {
          this.setState({ success: true })
        })
        .catch(error => {
          errors = {
            ...errors,
            invalidPassword: error.message,
          }
          this.setState({ errors })
          throw error
        })
    } else {
      errors = {
        ...errors,
        invalidPassword: 'Sie haben kein Token.',
      }
    }
    this.setState({ errors })
  }

  onRedirectSuccess = () => {
    this.props.history.push('/login')
  }

  render() {
    return (
      <PasswordSetView
        onChange={this.onChange}
        password={this.state.password}
        confirmPassword={this.state.confirmPassword}
        errors={this.state.errors}
        onSetPassword={this.onSetPassword}
        onRedirectSuccess={this.onRedirectSuccess}
        success={this.state.success}
      />
    )
  }
}
