import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { CheckInsuranceNumberQuery } from 'packages/chancellery-search/interfaces/schemaDefinition'

import query from '../graphql/checkInsuranceNumber.graphql'

export const checkInsuranceNumber = async (
  insuranceNumber: string
): Promise<CheckInsuranceNumberQuery['checkInsuranceNumber']> => {
  const response = await request<CheckInsuranceNumberQuery>(ADVICE_REQUESTS_ENDPOINT, query, { insuranceNumber })
  return response.checkInsuranceNumber
}
