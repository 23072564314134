import { ContactType } from '../interfaces'

export const CONTACT_TYPES: { [key in ContactType]: string } = {
  [ContactType.Redirect]: 'Anruf',
  [ContactType.Callback]: 'Rückruf',
  [ContactType.Email]: 'E-Mail',
  [ContactType.NotSet]: 'Nicht gesetzt',
}

export const CONTACT_TYPE_ENTRIES = Object.entries(CONTACT_TYPES).map(([k, v]) => ({ id: k, name: v }))
