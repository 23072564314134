import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { MandateInput } from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/setToMandate.graphql'
import { SetToMandateMutation, SetToMandateMutationVariables } from '../interfaces'

export const setToMandate = async (mandateInput: MandateInput, sendToEConsult: boolean): Promise<string> => {
  const variables = {
    sendToEConsult,
    mandateInput: {
      id: mandateInput.id,
      resultNotes: mandateInput.resultNotes,
      lawyerNotes: mandateInput.lawyerNotes,
      mandateName: mandateInput.mandateName,
    },
  }
  const response = await request<SetToMandateMutation, SetToMandateMutationVariables>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.setToMandate
}
