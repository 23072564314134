import React from 'react'

import { customPartners } from '../../constants'
import { AdviceRequest } from '../../interfaces'

interface PartnerViewProps {
  adviceRequest: Partial<AdviceRequest>
}

export const PartnerView = ({ adviceRequest }: PartnerViewProps): JSX.Element | null => {
  const partner =
    adviceRequest.legalProtectionInsurer === 'DEVK'
      ? {
          id: 'devk',
        }
      : adviceRequest.partner

  if (!partner) {
    return null
  }

  const PartnerViewComponent = customPartners[partner.id].view
  return <PartnerViewComponent adviceRequest={adviceRequest} />
}
