import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Form, Formik } from 'formik'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import {
  AbortButton,
  Autocomplete,
  ConfirmDialog,
  createGridColumnsFrom,
  FormActionBox,
  HeaderButtonGroup,
  LoadingMessage,
  PageLayout,
  SaveButton,
  TextField,
} from 'common/components-mui'
import { StaticField } from 'common/components-mui/StaticField'
import { useRedirect } from 'common/hooks'
import { useUser } from 'common/user-context'
import { getQueryParam } from 'common/utils'

import { AdviceListStatusLabel } from '../components/AdviceListRoleStatusLabel'
import { COUNTRY_OPTIONS, EMPTY_ADVICE_REQUEST } from '../constants'
import { GetAdviceAPIData } from '../hooks'
import { ContactType, Partner, Status, AdviceRequestFlattened, RowChancellery, AdviceForeignForm } from '../interfaces'
import {
  cancelRequest,
  getAdviceRequest,
  getFieldsOfLaw,
  getMostImportantRole,
  getPartner,
  matchChancelleries,
  finishMatching,
  saveAdviceRequest,
} from '../utils'
import { mapAdviceRequestToMatchInput, mapResponseToRowChancelleries, mapResponseToAdviceRequest } from '../utils/mappings'

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(3),
  },
  dataGrid: {
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
}))

const adviceForeignFormInitialValues: AdviceForeignForm = {
  damageNumber: '',
  country: '',
  fieldOfLaw: '',
  description: '',
}

const adviceForeignFormValidationSchema = Yup.object().shape({
  damageNumber: Yup.string()
    .min(14, '14-stellige Schadensnummer ist erforderlich')
    .max(14, '14-stellige Schadensnummer ist erforderlich')
    .required('Schadensnummer ist erforderlich'),
  country: Yup.string().required('Land ist erforderlich').nullable(),
  fieldOfLaw: Yup.string().required('Ereignisart ist erforderlich').nullable(),
  description: Yup.string().required('Ursache ist erforderlich'),
})

export const AdviceForeignFormPage: FunctionComponent = () => {
  const adviceId = getQueryParam(useLocation(), 'adviceId')
  const classes = useStyles()
  const { user } = useUser()
  const redirect = useRedirect()
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)
  const [isMatchingDialogOpen, setIsMatchingDialogOpen] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const [chancelleries, setChancelleries] = useState<Array<RowChancellery> | []>([])
  const [selectedChancellery, setSelectedChancellery] = useState<RowChancellery | null>(null)

  // fetch fields of law
  const [fieldsOfLaw, setFieldsOfLaw] = useState<Array<{ id: string; name: string }>>([])
  const [isLoadingFieldsOfLaw, setIsLoadingFieldsOfLaw] = useState<boolean>(true)
  const fetchFieldsOfLaw = async (): Promise<void> => {
    getFieldsOfLaw({}).then(e => {
      setFieldsOfLaw(e.fieldsOfLaw.list)
      setIsLoadingFieldsOfLaw(false)
    })
  }
  useEffect(() => {
    if (isLoadingFieldsOfLaw) fetchFieldsOfLaw()
  }, [isLoadingFieldsOfLaw])

  // fetch advice request
  const [adviceRequest, setAdviceRequest] = useState<AdviceRequestFlattened>(EMPTY_ADVICE_REQUEST)
  const [isLoadingAdviceRequest, setLoadingAdviceRequest] = useState<boolean>(true)
  const fetchAdviceRequest = async (id: string): Promise<void> => {
    getAdviceRequest({ adviceId: id }).then(e => {
      setAdviceRequest(mapResponseToAdviceRequest(e.adviceRequest))
      setLoadingAdviceRequest(false)
    })
  }

  // fetch partner
  const [partner, setPartner] = useState<Partner>()
  const [isLoadingPartner, setIsLoadingPartner] = useState<boolean>(true)
  const fetchPartner = async (): Promise<void> => {
    getPartner({ id: 'devk-ausland' }).then(e => {
      setPartner(e.partner)
      setIsLoadingPartner(false)
    })
  }
  useEffect(() => {
    if (isLoadingPartner) fetchPartner()
  }, [isLoadingPartner])

  const isLoading = isLoadingFieldsOfLaw || isLoadingPartner || (isLoadingAdviceRequest && !!adviceId)

  const handleCancel = async (): Promise<void> => {
    await cancelRequest({ cancelRequestInput: { adviceId: adviceRequest?.adviceId } })
    await fetchAdviceRequest(adviceRequest?.adviceId)
    redirect(`/advice-requests/form-foreign?adviceId=${adviceRequest?.adviceId}`)
  }

  const updateAdviceRequest = (values: AdviceForeignForm): AdviceRequestFlattened => {
    const updatedAdviceRequest = {
      ...adviceRequest,
      ...values,
      zip: adviceRequest.zip || '50667',
      contactType: adviceRequest.contactType || ContactType.Redirect,
      partner,
    }
    setAdviceRequest(updatedAdviceRequest)
    return updatedAdviceRequest
  }

  const handleSubmit = async (values: AdviceForeignForm): Promise<void> => {
    const updatedAdviceRequest = updateAdviceRequest(values)
    matchChancelleries({
      matchInput: mapAdviceRequestToMatchInput(updatedAdviceRequest),
    }).then(e => {
      setChancelleries(mapResponseToRowChancelleries(e.matchChancelleries))
    })
    setIsSubmitted(true)
  }

  const handleAbort = (): void => {
    adviceId ? redirect('/advice-requests') : redirect('/advice-requests/form')
  }

  const matchAdvice = async (chancelleryId: string): Promise<void> => {
    const adviceRequestId = await finishMatching({ ...adviceRequest, chancelleryId })
    redirect(`/advice-requests/form-foreign?adviceId=${adviceRequestId}`)
  }

  const saveAdvice = async (values: AdviceForeignForm): Promise<void> => {
    const updatedAdviceRequest = updateAdviceRequest(values)
    const adviceRequestId = await saveAdviceRequest({ ...updatedAdviceRequest })
    !adviceId && redirect(`/advice-requests/form-foreign?adviceId=${adviceRequestId}`)
  }

  const handleOpenMatchingModal = (chancellery: RowChancellery): void => {
    setSelectedChancellery(chancellery)
    setIsMatchingDialogOpen(true)
  }

  const columns: Array<GridColDef> = createGridColumnsFrom([
    { field: 'name', headerName: 'Kanzlei', sortable: false, filterable: false, flex: 0.15 },
    {
      field: 'address',
      headerName: 'Adresse',
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    { field: 'total', sortable: false, filterable: false, headerName: 'Zähler' },
    {
      field: 'id',
      sortable: false,
      filterable: false,
      headerName: '',
      flex: 0.05,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Button variant="contained" color="primary" onClick={() => handleOpenMatchingModal(params.row)}>
          Weiterleiten
        </Button>
      ),
    },
  ])

  const displayComponent = {
    buttonCancel:
      adviceRequest.status === Status.AT_CHANCELLERY ||
      adviceRequest.status === Status.AWAITING_CALL ||
      adviceRequest.status === Status.AWAITING_CALLBACK ||
      adviceRequest.status === Status.EDITING ||
      adviceRequest.status === Status.RESUBMISSION,
    buttonDraft: adviceRequest.status === Status.NEW || adviceRequest.status === Status.EDITING,
    buttonForward: adviceRequest.status === Status.NEW || adviceRequest.status === Status.EDITING,
    tableMatching: adviceRequest.status === Status.NEW || adviceRequest.status === Status.EDITING,
    formFields: adviceRequest.status === Status.NEW || adviceRequest.status === Status.EDITING,
  }

  const disableInput = !(adviceRequest.status === Status.NEW || adviceRequest.status === Status.EDITING)

  const adviceStaticFields = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <StaticField label="Schadensnummer" value={adviceRequest.damageNumber} />
      </Grid>
      <Grid item xs={6}>
        <StaticField label="Land" value={adviceRequest.country} />
      </Grid>
      <Grid item xs={6}>
        <StaticField label="Ereignisart" value={adviceRequest.fieldOfLaw} />
      </Grid>
      <Grid item xs={6}>
        <StaticField label="Ursache" value={adviceRequest.description} />
      </Grid>
    </Grid>
  )

  const chancelleryStaticFields = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <StaticField label="Name" value={selectedChancellery?.name} />
      </Grid>
      <Grid item xs={6}>
        <StaticField label="Adresse" value={selectedChancellery?.address} />
      </Grid>
      <Grid item xs={12}>
        <StaticField label="Telefon" value={selectedChancellery?.phone} />
      </Grid>
    </Grid>
  )

  return (
    <Formik
      validationSchema={adviceForeignFormValidationSchema}
      initialValues={adviceForeignFormInitialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setSubmitting, isValid, values }) => (
        <PageLayout
          heading={adviceId ? `Auslandsantrag ${adviceId}` : 'Auslandsantrag erfassen'}
          headingExtension={<AdviceListStatusLabel status={adviceRequest.status} role={getMostImportantRole(user)} />}
        >
          <HeaderButtonGroup>
            {displayComponent.buttonDraft && (
              <Button onClick={() => saveAdvice(values)}>
                <SaveIcon titleAccess="Entwurf speichern" />
              </Button>
            )}
            {displayComponent.buttonCancel && (
              <Button onClick={() => setIsCancelDialogOpen(true)}>
                <DoNotDisturbIcon titleAccess="Antrag stornieren" />
              </Button>
            )}
          </HeaderButtonGroup>
          <ConfirmDialog
            isOpen={isCancelDialogOpen}
            confirmAction={() => handleCancel()}
            onClose={() => setIsCancelDialogOpen(false)}
            title={`Antrag ${adviceId} wirklich stornieren?`}
            content="Sie sind dabei, den Antrag zu stornieren. Dies kann nicht rückgängig gemacht werden. Soll der Antrag wirklich storniert werden?"
          />
          <ConfirmDialog
            isOpen={isMatchingDialogOpen}
            confirmAction={() => matchAdvice(selectedChancellery?.id || '')}
            onClose={() => setIsMatchingDialogOpen(false)}
            title="Auslandsantrag an Kanzlei weiterleiten"
            content={
              <>
                <Typography variant="h6" sx={{ pb: 1 }}>
                  Antragsdaten
                </Typography>
                {adviceStaticFields}
                <Divider sx={{ pt: 4 }} />
                <Typography variant="h6" sx={{ pb: 1, pt: 2 }}>
                  Kanzleidaten
                </Typography>
                {chancelleryStaticFields}
              </>
            }
          />
          <Form>
            <GetAdviceAPIData adviceId={adviceId} setLoading={setLoadingAdviceRequest} setAdviceRequest={setAdviceRequest} />
            <LoadingMessage isLoading={isLoading} />
            <Box display="flex" flexWrap="wrap" width={{ xs: '100%', sm: '45%' }}>
              {displayComponent.formFields ? (
                <>
                  <TextField
                    name="damageNumber"
                    label="Schadensnummer"
                    disabled={isLoading || isSubmitting || disableInput}
                    className={classes.field}
                    fullWidth
                  />
                  <Autocomplete
                    name="country"
                    label="Land"
                    disabled={isLoading || isSubmitting || disableInput}
                    className={classes.field}
                    fullWidth
                    options={COUNTRY_OPTIONS.map(f => f.name)}
                    textfieldProps={{ variant: 'outlined' }}
                  />
                  <Autocomplete
                    name="fieldOfLaw"
                    label="Ereignisart"
                    disabled={isLoading || isSubmitting || disableInput}
                    className={classes.field}
                    fullWidth
                    options={fieldsOfLaw.map(f => f.name)}
                    textfieldProps={{ variant: 'outlined' }}
                  />
                  <TextField
                    name="description"
                    label="Ursache"
                    disabled={isLoading || isSubmitting || disableInput}
                    className={classes.field}
                    fullWidth
                    multiline
                  />
                </>
              ) : (
                adviceStaticFields
              )}
            </Box>
            <FormActionBox>
              <AbortButton onClick={() => (isSubmitting ? setSubmitting(false) : handleAbort())} disabled={isLoading}>
                Abbrechen
              </AbortButton>
              {displayComponent.buttonForward && <SaveButton disabled={isSubmitting}>Weiterleiten an Kanzlei</SaveButton>}
            </FormActionBox>
          </Form>
          {!isSubmitting && isValid && isSubmitted && displayComponent.tableMatching && (
            <DataGrid
              className={classes.dataGrid}
              rows={chancelleries}
              columns={columns}
              hideFooter
              autoHeight
              disableSelectionOnClick
            />
          )}
        </PageLayout>
      )}
    </Formik>
  )
}
