import React from 'react'

import './Tag.scss'

interface TagProps {
  onRemove: () => void
  children?: any
  otherProps?: any
}

export const Tag = ({ onRemove, children, otherProps }: TagProps) => (
  <span className="tag" {...otherProps}>
    {children}
    <span className="tag__remove" onClick={onRemove}>
      ×
    </span>
  </span>
)
