import React, { FC } from 'react'
import { Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import { ButtonPrimary } from 'common/ui'

import SVGRedirectRequest from '../images/antrag_weiterleiten.svg'
import SVGBack from '../images/back.svg'
import SVGExit from '../images/exit.svg'
import SVGRedirect from '../images/weiterleiten.svg'

interface Address {
  city: string
  street: string
  zip: string
}

interface RedirectModalProps {
  chancelleries: Array<{ name: string; id: string; address: Address }>
  open: boolean
  loading: boolean
  success: boolean
  redirectTarget: string
  onCancelClick: () => void
  onCompleteClick: () => void
  onRedirectClick: () => void
  setRedirectTarget: (event: React.FormEvent<HTMLInputElement>) => void
  errors?: { [props: string]: string }
}

const getAddress = (chancellery: { address: Address }): string =>
  `${chancellery.address.street}, ${chancellery.address.zip} ${chancellery.address.city}`.trim()

export const RedirectModal: FC<RedirectModalProps> = ({
  chancelleries,
  open,
  loading,
  success,
  redirectTarget,
  onCancelClick,
  onCompleteClick,
  onRedirectClick,
  setRedirectTarget,
  errors = {},
}) => {
  if (loading || !open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  if (success) {
    const chancellery = chancelleries.find(c => c.id === redirectTarget)
    return (
      <Modal toggle={onCompleteClick} isOpen={open} className="success-modal" id="RedirectSuccessModal">
        <ModalBody>
          <SVGRedirectRequest height="160" width="200" />
          <Label>
            Dieser Antrag wurde erfolgreich an <span>&quot; {chancellery!.name} &quot;</span> weitergeleitet.
          </Label>
        </ModalBody>
        <ModalFooter>
          <ButtonPrimary onClick={onCompleteClick} iconLeft={<SVGBack width="16" height="16" />}>
            Zurück zu Ihren Anträgen
          </ButtonPrimary>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} className="new-modal medium-size" id="RedirectModal">
      <ModalBody>
        <div className="btn-cancel close-modal" onClick={onCancelClick}>
          <SVGExit height="14" width="14" />
        </div>
        <Form className="form">
          <FormGroup>
            <h3 className="h3 small">An wen soll der Antrag weitergeleitet werden ?</h3>
            <Input type="select" name="redirectTarget" id="redirectTarget" onChange={setRedirectTarget} value={redirectTarget}>
              <option value="">---Bitte wählen---</option>
              {chancelleries.map(chancellery => (
                <option key={chancellery.id} value={chancellery.id}>
                  {`${chancellery.name}, ${getAddress(chancellery)}`}
                </option>
              ))}
            </Input>
            {!!errors.redirectTarget && <FormFeedback>{errors.redirectTarget}</FormFeedback>}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary onClick={onRedirectClick} iconLeft={<SVGRedirect width="18" height="15" />}>
          Antrag weiterleiten
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  )
}
