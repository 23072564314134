import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { MutationCreateSalesArgs, RevenueInput } from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/createSales.graphql'

export const createSales = async (adviceRequestId: string, revenue: RevenueInput) => {
  try {
    await request<MutationCreateSalesArgs>(ADVICE_REQUESTS_ENDPOINT, query, {
      adviceRequestId,
      revenue,
    })
  } catch (error) {
    throw error
  }
}
