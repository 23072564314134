// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CmJifBMisLSNxkUrYWzb{margin:0 .75rem;flex:1 1 auto}.CmJifBMisLSNxkUrYWzb label{color:#0a1343;font-weight:500;font-size:14px;margin:0 .75rem .25rem .75rem}.CmJifBMisLSNxkUrYWzb.RgXF1Xg6qnBVIysrx37s label{color:#ff6464}.CmJifBMisLSNxkUrYWzb.RgXF1Xg6qnBVIysrx37s .qlabqqIW_dRWKv5KMrkO{border-color:#ff6464}.CmJifBMisLSNxkUrYWzb.RgXF1Xg6qnBVIysrx37s .qlabqqIW_dRWKv5KMrkO:focus{border-color:#ff6464}", "",{"version":3,"sources":["webpack://./src/common/components/CustomSelect/CustomSelect.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,aAAA,CAEA,4BACE,aAAA,CACA,eAAA,CACA,cAAA,CACA,6BAAA,CAIA,iDACE,aAAA,CAGF,iEACE,oBAAA,CAEA,uEACE,oBAAA","sourcesContent":[".select {\n  margin: 0 0.75rem;\n  flex: 1 1 auto;\n\n  label {\n    color: #0a1343;\n    font-weight: 500;\n    font-size: 14px;\n    margin: 0 0.75rem 0.25rem 0.75rem;\n  }\n\n  &.invalid {\n    label {\n      color: #ff6464;\n    }\n\n    .input {\n      border-color: #ff6464;\n\n      &:focus {\n        border-color: #ff6464;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "CmJifBMisLSNxkUrYWzb",
	"invalid": "RgXF1Xg6qnBVIysrx37s",
	"input": "qlabqqIW_dRWKv5KMrkO"
};
export default ___CSS_LOADER_EXPORT___;
