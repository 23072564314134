import React, { FC } from 'react'

import './PanelTabs.scss'

interface Props {
  title?: string
  subtitle?: string
}

export const PanelTabs: FC<Props> = ({ children }) => <div className="panel-tabs">{children}</div>
