export const MONTHS: { [key: string]: string } = {
  0: 'Januar',
  1: 'Februar',
  2: 'März',
  3: 'April',
  4: 'Mai',
  5: 'Juni',
  6: 'Juli',
  7: 'August',
  8: 'September',
  9: 'Oktober',
  10: 'November',
  11: 'Dezember',
}

export const MONTH_ENTRIES = Object.entries(MONTHS).map(([k, v]) => ({ id: k, name: v }))
