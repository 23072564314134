// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ID5p2rDP5ypmks4FBV7w{padding:0;flex-basis:100%}.ID5p2rDP5ypmks4FBV7w .rXZWJEKteDfdAww32shP{font-weight:500;text-align:left;color:#0a1343;opacity:1}.ID5p2rDP5ypmks4FBV7w .aNvABukLMBCY3h48s5ex{display:flex;justify-content:center;flex-wrap:wrap}.ID5p2rDP5ypmks4FBV7w .aNvABukLMBCY3h48s5ex>div{color:#563fae;margin:1.25rem 0;font-weight:500;display:flex;align-items:center}.ID5p2rDP5ypmks4FBV7w .aNvABukLMBCY3h48s5ex>div>svg{margin-right:.5rem}.ID5p2rDP5ypmks4FBV7w .HIRlCX9RSKxssNW9eWCL{font-weight:bold;font-size:13px;color:#3fab64;text-align:center;margin:1rem 0}", "",{"version":3,"sources":["webpack://./src/packages/lawyers/components/ConsultationPanel/ConsultationPanelStep/ConsultationPanelStep.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,eAAA,CAEA,4CACE,eAAA,CACA,eAAA,CACA,aAAA,CACA,SAAA,CAGF,4CACE,YAAA,CACA,sBAAA,CACA,cAAA,CAEA,gDACE,aAAA,CACA,gBAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CAEA,oDACE,kBAAA,CAKN,4CACE,gBAAA,CACA,cAAA,CACA,aAAA,CACA,iBAAA,CACA,aAAA","sourcesContent":[".content {\n  padding: 0;\n  flex-basis: 100%;\n\n  .title {\n    font-weight: 500;\n    text-align: left;\n    color: #0a1343;\n    opacity: 1;\n  }\n\n  .time {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n\n    > div {\n      color: #563fae;\n      margin: 1.25rem 0;\n      font-weight: 500;\n      display: flex;\n      align-items: center;\n\n      > svg {\n        margin-right: 0.5rem;\n      }\n    }\n  }\n\n  .completed {\n    font-weight: bold;\n    font-size: 13px;\n    color: #3fab64;\n    text-align: center;\n    margin: 1rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "ID5p2rDP5ypmks4FBV7w",
	"title": "rXZWJEKteDfdAww32shP",
	"time": "aNvABukLMBCY3h48s5ex",
	"completed": "HIRlCX9RSKxssNW9eWCL"
};
export default ___CSS_LOADER_EXPORT___;
