import { Box } from '@mui/material'
import React, { FunctionComponent } from 'react'

import { navWidthCollapsed, navWidthExpanded } from './Navigation'

const appBarHeight = '65px'

export const Main: FunctionComponent = ({ children }) => (
  <Box
    component="section"
    bgcolor="#f7f7f7"
    paddingTop={appBarHeight}
    paddingLeft={{ xs: '0px', md: navWidthCollapsed, maxContentWidth: navWidthExpanded }}
  >
    {children}
  </Box>
)

export const Content: FunctionComponent = ({ children }) => (
  <Box padding="20px" maxWidth="110rem" margin="auto">
    {children}
  </Box>
)
