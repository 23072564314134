import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AsyncBiFunction, request } from 'common/utils'
import { ReadAdviceRequestMutation, ReadAdviceRequestMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/readAdviceRequest.graphql'

export const readAdviceRequest: AsyncBiFunction<string, boolean, string> = async (adviceRequestId, read) => {
  const variables: ReadAdviceRequestMutationVariables = { adviceRequestId, read }
  const response = await request<ReadAdviceRequestMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.readAdviceRequest
}
