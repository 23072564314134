import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AsyncFunction, request } from 'common/utils'
import { LockAdviceRequestMutation, LockAdviceRequestMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/lockAdviceRequest.graphql'

export const lockAdviceRequest: AsyncFunction<string, string> = async adviceRequestId => {
  const variables: LockAdviceRequestMutationVariables = { adviceRequestId }
  const response = await request<LockAdviceRequestMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.lockAdviceRequest
}
