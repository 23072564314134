import { isNotEmpty } from '@grapes-agency/universal'
import { FieldAttributes, Formik, useField, useFormikContext } from 'formik'
import { Persist } from 'formik-persist'
import { pickBy, append, prepend } from 'ramda'
import React, { useState, useCallback, useEffect, FC } from 'react'
import { FormFeedback } from 'reactstrap'

import { CustomSelect, CustomSelectOption } from 'common/components/CustomSelect'
import { TextAreaField, InputField, CheckboxField, SelectField } from 'common/components/Form'
import { InputError } from 'common/errors'
import SVGAnhang from 'common/icons/anhang.svg'
import SVGSecureQuestion from 'common/icons/secure-question.svg'
import { ButtonPrimary, ButtonSecondary } from 'common/ui/Button'

import { getCoverageForms } from '../../../actions'
import { FilesModal } from '../../../components/FilesModal'
import SVGBack from '../../../components/images/back.svg'
import { AdviceRequest } from '../../../interfaces'
import { CoverageFieldType } from '../../../interfaces/schemaDefinition'
import { ContactInsurerInput, useSendCoverageRequest } from '../../effects/sendCoverageRequest'

import styles from './CoverageForm.module.scss'
import { CoverageFormEntries, CoverageEntry, Field, Fields } from './coverageForms'

import './CoverageForm.scss'

interface CoverageModalProps {
  adviceRequest: AdviceRequest
  iban: string
  onSuccess: () => void
}

interface FormikFormFields {
  [key: string]: any
}

interface FormikFormData {
  topic: string
  fields: FormikFormFields
  caseId: string
  partyName: string
  bccActive: boolean
  bccMail: string
  message: string
}

const initialFormData: FormikFormData = {
  topic: '',
  fields: {},
  caseId: '',
  partyName: '',
  bccActive: false,
  bccMail: '',
  message: '',
}

const ALLOWED_FILES = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf']
const MAX_FILES_SIZE = 26214400

const validateFiles = (files: Array<File>) => {
  if (files.length > 0) {
    const fileSize = files.reduce((sum, file) => sum + file.size, 0)
    if (fileSize >= MAX_FILES_SIZE) {
      return 'Die Größe der angehängten Dateien darf 25 MB nicht überschreiten'
    }

    if (!!files.find(file => !ALLOWED_FILES.includes(file.type))) {
      return 'Es dürfen nur Dateien im Format pdf, jpeg, jpg oder png angehangen werden'
    }

    if (!!files.find(file => /\d{14}\.\w+/.test(file.name))) {
      return 'Dateien mit einer Schadensnummer als Namen dürfen nicht angehangen werden'
    }
  }
  return ''
}

const CustomField = (
  props: FieldAttributes<any> & {
    formTopic: string
    coverageFormEntries: CoverageFormEntries
    setFormTopic: (topic: string) => void
  }
): JSX.Element => {
  const {
    values: { topic },
    touched,
    setFieldValue,
  } = useFormikContext<{ topic: string }>()

  // eslint-disable-next-line fp/no-rest-parameters
  const { formTopic, coverageFormEntries, setFormTopic, ...rest } = props

  const [field] = useField(rest)

  // TODO: Input elements should not switch from uncontrolled to controlled (or vice versa).

  useEffect(() => {
    const { fields } = coverageFormEntries[topic] || 0
    if (formTopic !== topic && touched && Object.keys(fields).length > 0) {
      setFormTopic(topic)
      const formikFieldsObject: FormikFormFields = Object.fromEntries(
        Object.keys(fields).map((question: string) => [
          [question],
          fields[question].type === CoverageFieldType.Checkbox ? false : '',
        ])
      )
      setFieldValue('fields', formikFieldsObject)
    }
  })

  return <SelectField {...props} {...field} />
}

const CoverageForm: FC<CoverageModalProps> = ({ adviceRequest, iban, onSuccess }) => {
  const coverageFormId = `coverageForm-${adviceRequest.adviceId}`

  const [success, setSuccess] = useState(false)
  const [files, setFiles] = useState<Array<File>>([])
  const [filesErrors, setFilesErrors] = useState<{ [name: string]: string }>({})
  const [filesModalError, setFilesModalError] = useState('')
  const [filesModalOpen, setFilesModalOpen] = useState(false)
  const [formErrors, setFormErrors] = useState<{ [name: string]: string }>({})
  const [formOptions, setFormOptions] = useState<Array<CustomSelectOption>>([])
  // const [formTopic, setFormTopic] = useState<string>(
  //   JSON.parse(window.localStorage.getItem(coverageFormId) || '{}').values?.topic || ''
  // )
  const [formTopic, setFormTopic] = useState<string>('')

  const [coverageFormEntries, setCoverageFormEntries] = useState<CoverageFormEntries>({})

  const fieldOfLaw = adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.id : ''

  useEffect(() => {
    getCoverageForms().then(coverageForms => {
      const newCoverageFormEntries: CoverageFormEntries = {}

      coverageForms.reduce((entries, coverageForm) => {
        const fields: Fields = {}
        const newCoverageForm = {
          ...coverageForm,
          fields: coverageForm.fields.reduce((aggregateFields, field) => {
            // eslint-disable-next-line fp/no-mutation, no-param-reassign
            aggregateFields[field.label] = field
            return aggregateFields
          }, fields),
        }
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        entries[coverageForm.label] = newCoverageForm
        return entries
      }, newCoverageFormEntries)

      setCoverageFormEntries(newCoverageFormEntries)
    })
  }, [])

  // TODO: add "Sonstige" and "Bitte wählen..." as constants ... or implement a better solution

  useEffect(() => {
    const availableOptions = append(
      { label: 'Sonstige', value: 'Sonstige' },
      Object.keys(pickBy((v: CoverageEntry) => v.fieldsOfLaw.includes(fieldOfLaw), coverageFormEntries)).map(key => ({
        label: key,
        value: key,
      }))
    )
    setFormOptions(prepend({ label: 'Bitte wählen...', value: 'Bitte wählen...' }, availableOptions))
  }, [coverageFormEntries, fieldOfLaw])

  const sendCoverageRequest = useSendCoverageRequest()

  const onFormikSubmit = async (e: React.FormEvent<HTMLFormElement>, handleSubmit: React.FormEventHandler<HTMLFormElement>) => {
    e.preventDefault()
    e.persist()
    handleSubmit(e)
  }

  const onAddFile: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.currentTarget.files) {
        const newFiles = files.concat([...event.currentTarget.files])
        setFiles(newFiles)
        setFilesModalError(validateFiles(newFiles))
      }
    },
    [setFiles, files]
  )

  const onRemoveFile = useCallback(
    (name: string): void => {
      const index = files.findIndex(file => file.name === name)
      const newFiles = [...files.slice(0, index), ...files.slice(index + 1)]
      setFiles(newFiles)
      setFilesModalError(validateFiles(newFiles))
    },
    [files, setFiles]
  )

  const onCloseFiles = useCallback(() => {
    setFilesModalOpen(false)
  }, [setFilesModalOpen])

  const toogleFilesModal = useCallback(() => {
    setFilesModalOpen(!filesModalOpen)
  }, [setFilesModalOpen, filesModalOpen])

  const onFilesErrors = (error: InputError): void => {
    setFilesErrors(error.errors)
  }

  if (success) {
    return (
      <>
        <SVGSecureQuestion width="48px" height="48px" />
        <h3 className="h3 small" style={{ marginBottom: '2rem' }}>
          Ihre Deckungsanfrage wurde erfolgreich versand.
        </h3>
        <ButtonPrimary onClick={onSuccess} iconLeft={<SVGBack width="16" height="16" />}>
          Zurück
        </ButtonPrimary>
      </>
    )
  }

  return (
    <>
      <SVGSecureQuestion width="48px" height="48px" />
      <h3 className="h3 small" style={{ marginBottom: '0' }}>
        Um eine Deckungsanfrage zu versenden, hängen Sie bitte ein entsprechendes Dokument an.
      </h3>
      <h3 className="h3 small" style={{ marginBottom: '0' }}>
        Die Falldaten werden automatisch an die Versicherung übertragen.
      </h3>
      <div style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
        <Formik
          initialValues={initialFormData}
          validate={({ topic, fields, bccActive, bccMail }: FormikFormData) => {
            const errors: { [prop: string]: string } = {}
            if (bccActive && (!isNotEmpty(bccMail) || !/(.+)@(.+){2,}\.(.+){2,}/.test(bccMail))) {
              errors.bccMail = 'Bitte geben Sie eine gültige E-Mail-Adresse an.'
            }
            if ((formOptions.length > 2 && !isNotEmpty(topic)) || topic === 'Bitte wählen...') {
              errors.topic = 'Bitte wählen Sie ein Formular aus.'
            }
            if (isNotEmpty(topic) && Object.keys(fields).length > 0) {
              Object.keys(coverageFormEntries[topic].fields).forEach(question => {
                const field: Field = coverageFormEntries[topic].fields[question]
                switch (field.type) {
                  case CoverageFieldType.Select:
                    if (!isNotEmpty(fields[question])) {
                      errors[`fields-${question}`] = 'Bitte treffen Sie eine Auswahl.'
                    }
                    break
                  case CoverageFieldType.Input:
                    if (!isNotEmpty(fields[question])) {
                      errors[`fields-${question}`] = 'Bitte geben Sie eine Antwort an.'
                    }
                    break
                  case CoverageFieldType.InputCurrency:
                    if (!isNotEmpty(fields[question]) || !/^(\d{1,3}(?:\.\d{3})*|\d+)(?:,\d{2})?$/.test(fields[question])) {
                      errors[`fields-${question}`] = 'Bitte geben Sie eine gültige Zahl ein.'
                    }
                    break
                  case CoverageFieldType.Date:
                    if (
                      !isNotEmpty(fields[question]) ||
                      !/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/.test(fields[question])
                    ) {
                      errors[`fields-${question}`] = 'Bitte geben Sie ein gültiges Datum an.'
                    }
                    break
                  default:
                    break
                }
              })
            }
            setFormErrors(errors)
          }}
          onSubmit={async (values: FormikFormData) => {
            const validEmptyForm: boolean = formOptions.length <= 2 || (formOptions.length > 2 && values.topic === 'Sonstige')

            if (Object.keys(formErrors).length === 0 || validEmptyForm) {
              const formData =
                Object.keys(values.fields).length > 0 && !validEmptyForm
                  ? Object.keys(values.fields).map((question: string) => {
                      const field: Field = coverageFormEntries[values.topic].fields[question]
                      switch (field.type) {
                        case CoverageFieldType.InputCurrency:
                          return { question, answer: `${values.fields[question]}€` }
                        case CoverageFieldType.Checkbox:
                          return { question, answer: values.fields[question] ? 'Ja' : 'Nein' }
                        default:
                          return { question, answer: values.fields[question] }
                      }
                    })
                  : []

              const input: ContactInsurerInput = {
                adviceRequest,
                iban,
                attachments: files,
                coverageDecription: values.message,
                partyName: values.partyName,
                caseId: values.caseId,
                formData,
                topic: values.topic,
                blindCarbonCopy: values.bccActive ? [values.bccMail] : [''],
              }

              try {
                await sendCoverageRequest(input, {})
                setSuccess(true)
                window.localStorage.removeItem(coverageFormId)
              } catch (error) {
                onFilesErrors(error)
              }
            }
          }}
        >
          {({ values, touched, handleChange, handleSubmit, handleBlur }) => (
            <>
              <h3 className="h3 small" style={{ marginTop: '0' }}>
                Bitte füllen Sie das nachfolgende Formular vollständig aus, um die Bearbeitungszeit zu verkürzen.
              </h3>
              <form onSubmit={e => onFormikSubmit(e, handleSubmit)}>
                {formOptions.length > 2 && (
                  <div className={styles.formRow}>
                    <CustomField
                      name="topic"
                      options={formOptions}
                      component={CustomSelect}
                      placeholder="Themenfeld auswählen"
                      label="Themenfeld:"
                      onChange={handleChange}
                      error={formErrors.topic}
                      setFormTopic={setFormTopic}
                      formTopic={formTopic}
                      coverageFormEntries={coverageFormEntries}
                    />
                  </div>
                )}
                {!!values.topic && (
                  <div className={styles.fields}>
                    {Object.keys(coverageFormEntries[values.topic].fields).map((question: string, i: number) => {
                      const field: Field = coverageFormEntries[values.topic].fields[question]

                      // TODO: add DatePicker/ CurrencyInput

                      switch (field.type) {
                        case CoverageFieldType.Select:
                          return (
                            <div className={styles.formRow} key={i}>
                              <SelectField
                                id={`fields.${question}`}
                                name={`fields.${question}`}
                                value={values.fields[question]}
                                options={prepend(
                                  { label: 'Bitte wählen...', value: '' },
                                  (field.options || []).map(option => ({ label: option, value: option }))
                                )}
                                label={question}
                                error={touched.fields && touched.fields[question] ? formErrors[`fields-${question}`] : undefined}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          )
                        case CoverageFieldType.Input:
                          return (
                            <div className={styles.formRow} key={i}>
                              <InputField
                                id={`fields.${question}`}
                                name={`fields.${question}`}
                                label={question}
                                value={values.fields[question]}
                                onChange={handleChange}
                                error={touched.fields && touched.fields[question] ? formErrors[`fields-${question}`] : undefined}
                                onBlur={handleBlur}
                              />
                            </div>
                          )
                        case CoverageFieldType.InputCurrency:
                          return (
                            <div className={styles.formRow} key={i}>
                              <InputField
                                id={`fields.${question}`}
                                name={`fields.${question}`}
                                label={`${question} (z.B. 1.000,00)`}
                                value={values.fields[question]}
                                onChange={handleChange}
                                error={touched.fields && touched.fields[question] ? formErrors[`fields-${question}`] : undefined}
                                onBlur={handleBlur}
                              />
                            </div>
                          )
                        case CoverageFieldType.Date:
                          return (
                            <div className={styles.formRow} key={i}>
                              <InputField
                                id={`fields.${question}`}
                                name={`fields.${question}`}
                                label={`${question} (DD.MM.YYYY)`}
                                value={values.fields[question]}
                                onChange={handleChange}
                                error={touched.fields && touched.fields[question] ? formErrors[`fields-${question}`] : undefined}
                                onBlur={handleBlur}
                              />
                            </div>
                          )
                        case CoverageFieldType.Checkbox:
                          return (
                            <div className={styles.formRow} key={i}>
                              <CheckboxField
                                id={`fields.${question}`}
                                name={`fields.${question}`}
                                label={question}
                                checked={values.fields[question]}
                                onChange={handleChange}
                                error={touched.fields && touched.fields[question] ? formErrors[`fields-${question}`] : undefined}
                                onBlur={handleBlur}
                              />
                            </div>
                          )
                        default:
                          return <></>
                      }
                    })}
                  </div>
                )}
                <div className={styles.formRow}>
                  <InputField
                    id="partyName"
                    name="partyName"
                    label="Partei-Bezeichnung"
                    value={values.partyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={formErrors.partyName}
                  />
                </div>
                <div className={styles.formRow}>
                  <InputField
                    id="caseId"
                    name="caseId"
                    label="Aktenzeichen"
                    value={values.caseId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={formErrors.caseId}
                  />
                </div>
                <div className={styles.formRow}>
                  <CheckboxField
                    id="bccActive"
                    name="bccActive"
                    label="Kopie der Deckungsanfrage"
                    checked={values.bccActive}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  />
                  <InputField
                    id="bccMail"
                    name="bccMail"
                    value={values.bccMail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={formErrors.bccMail}
                    disabled={!values.bccActive}
                  />
                </div>
                <div className={styles.formRow}>
                  <TextAreaField
                    theme="white"
                    placeholder="Nachricht"
                    id="message"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Ergänzende Informationen können Sie in nachfolgendem Freifeld eintragen:"
                  />
                </div>

                <ButtonSecondary onClick={toogleFilesModal} iconLeft={<SVGAnhang width="11" height="22" />}>
                  Anhang hinzufügen
                </ButtonSecondary>
                <p className="attachments-label">{files.length === 1 ? <>1 Anhang</> : <>{files.length} Anhänge</>}</p>
                {!!filesErrors.files && <FormFeedback>{filesErrors.files}</FormFeedback>}

                <ButtonPrimary onClick={() => handleSubmit} type="submit">
                  Anfrage absenden
                </ButtonPrimary>

                <FilesModal
                  open={filesModalOpen}
                  onAddFiles={onAddFile}
                  onRemoveFile={onRemoveFile}
                  onCancelClick={onCloseFiles}
                  error={filesModalError}
                  files={files}
                  accept="image/*, .pdf"
                />

                <Persist name={coverageFormId} />
              </form>
            </>
          )}
        </Formik>
      </div>
    </>
  )
}

export { CoverageForm }
