export const LEGAL_HERO_FOL = 'Zeugnisprüfung Legal Hero'

export const FIELDS_OF_LAW: { [key: string]: [] } = {
  // 'Abfindung': [],
  // Aufhebungsvertrag: [],
  // Arbeitsrecht: [],
  'Arbeitsrecht: Abmahnung': [],
  'Arbeitsrecht: Arbeitsvertrag prüfen': [],
  'Arbeitsrecht: Aufhebungsvertrag': [],
  'Arbeitsrecht: Kündigung': [],
  'Arbeitsrecht: Zeugnisprüfung': [],
  'Arbeitsrecht: Sonstiges': [],
  // 'Arbeitszeugnis': [],
  Arzthaftung: [],
  Asylrecht: [],
  Bankrecht: [],
  Baurecht: [],
  Dieselskandal: [],
  'DSGVO / Datenschutz': [],
  // 'Ehevertrag': [],
  Erbrecht: [],
  Fahrradrecht: [],
  Familienrecht: [],
  Gesellschaftsrecht: [],
  Gründung: [],
  Insolvenzrecht: [],
  Immobilienrecht: [],
  // 'Kündigung': [],
  // 'Kündigung Arbeitsverhältnis': [],
  Markenrecht: [],
  Medizinrecht: [],
  Mietrecht: [],
  'Mineko - Mietnebenkostencheck': [],
  // Musterfeststellungsklage: [],
  Ordnungswidrigkeiten: [],
  'PKV-Beitragsrückerstattung': [],
  'Prüfung Kündigung': [],
  Reiserecht: [],
  Schadensersatz: [],
  'SB-Verzicht Kulanz': [],
  'Schadensersatz Verkehrsrecht': [],
  // 'Scheidung': [],
  // 'Sorgerecht und Umgangsrecht': [],
  Sozialrecht: [],
  Steuerrecht: [],
  Strafrecht: [],
  // 'Trennung': [],
  // 'Unterhalt': [],
  Urheberrecht: [],
  Verkehrsrecht: [],
  Verkehrsunfall: [],
  Versicherungsrecht: [],
  Vertragsrecht: [],
  Verwaltungsrecht: [],
  Wettbewerbsrecht: [],
  'Widerruf Darlehen/Kredit': [],
  Wohnungseigentumsrecht: [],
  [LEGAL_HERO_FOL]: [],
  'Sonstige Rechtsfragen': [],
}

type FOLEntries = Array<{ id: string; name: string }>

export const FIELDS_OF_LAW_ENTRIES: FOLEntries = Object.keys(FIELDS_OF_LAW).map(key => ({ id: key, name: key }))

// remove b2b FOL
const FIELDS_OF_LAW_B2B: Array<string> = ['Prüfung Kündigung']
export const FIELDS_OF_LAW_ENTRIES_B2C: FOLEntries = FIELDS_OF_LAW_ENTRIES.filter(entry => !FIELDS_OF_LAW_B2B.includes(entry.id))

/**
 * remove certain FOL when user it not DEVK or TRB
 * @todo remove this definition as soon as devk special offer expires
 */
const FIELDS_OF_LAW_TRB: Array<string> = ['SB-Verzicht Kulanz', LEGAL_HERO_FOL]
const FIELDS_OF_LAW_GLOBAL: Array<string> = ['SB-Verzicht Kulanz', LEGAL_HERO_FOL, 'Mineko - Mietnebenkostencheck']

export const FIELDS_OF_LAW_ENTRIES_FILTERED = (isDEVK: boolean, isTRB: boolean): FOLEntries => {
  if (isDEVK) return FIELDS_OF_LAW_ENTRIES_B2C
  else if (isTRB) return FIELDS_OF_LAW_ENTRIES_B2C.filter(entry => !FIELDS_OF_LAW_TRB.includes(entry.id))
  else return FIELDS_OF_LAW_ENTRIES_B2C.filter(entry => !FIELDS_OF_LAW_GLOBAL.includes(entry.id))
}
