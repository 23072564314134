import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEventHandler } from 'react'
import { NavLink } from 'react-router-dom'
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import SVGKlugoLogo from 'common/icons/klugo-logo-white.svg'
import { ButtonPrimary, ButtonSecondary } from 'common/ui'

import SVGExit from './images/exit.svg'
import SVGConfirmation from './images/icon_bestätigung.svg'

interface PasswordResetViewProps {
  email: string
  errors?: any
  onCancel: () => void
  onSendPassword: React.MouseEventHandler<HTMLButtonElement>
  onChange: ChangeEventHandler<HTMLInputElement>
  success: boolean
  onRedirect: () => void
}

export const PasswordResetView = (props: PasswordResetViewProps) => {
  const { errors, email, onCancel, onSendPassword, onChange, success, onRedirect } = props

  if (success) {
    return (
      <div className="panel signin">
        <div className="head-shape" />
        <div className="body-shape" />
        <div className="login-header">
          <SVGKlugoLogo className="login-logo" style={{ fill: '#0a1343' }} />
          <h4>Passwortwiederherstellung</h4>
        </div>
        <div className="login-body">
          <div className="text-center">
            <SVGConfirmation height="80" width="80" />
          </div>
          <div className="text-center">
            <span className="success-span">
              Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen des Passworts zugeschickt.
            </span>
          </div>
          <div className="text-center m-t-50">
            <span className="fail-span">Sie haben keine E-Mail erhalten?</span>
          </div>
          <div className="form-group m-t-30">
            <ButtonPrimary block onClick={onRedirect}>
              Erneut abschicken
            </ButtonPrimary>
          </div>
          <div className="form-group m-t-15">
            <NavLink to="/login" className="forgot">
              Zurück zum Login
            </NavLink>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="panel signin">
      <div className="head-shape" />
      <div className="body-shape" />
      <div className="login-header">
        <SVGKlugoLogo className="login-logo" style={{ fill: '#0a1343' }} />
        <h4>Passwortwiederherstellung</h4>
      </div>
      <div className="login-body">
        <form>
          <div className="body-title">Bitte geben Sie Ihre E-Mail-Adresse an, um Ihr Passwort zurückzusetzen.</div>
          <div className="form-group m-b-10">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon="envelope" />
                </InputGroupText>
              </InputGroupAddon>
              <Input name="email" valid={!errors.invalidEmail} placeholder="E-Mail-Adresse" value={email} onChange={onChange} />
              {!!errors && <FormFeedback>{errors.invalidEmail}</FormFeedback>}
            </InputGroup>
          </div>
          <div className="form-group m-t-50">
            <ButtonPrimary block onClick={onSendPassword}>
              Abschicken
            </ButtonPrimary>
          </div>
          <div className="form-group m-t-15">
            <ButtonSecondary block onClick={onCancel} iconLeft={<SVGExit height="14" width="14" />}>
              Abbrechen
            </ButtonSecondary>
          </div>
        </form>
      </div>
    </div>
  )
}
