import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import SVGKlugoLogo from 'common/icons/klugo-logo-original.svg'
import { ButtonPrimary } from 'common/ui'

interface LoginBoxViewProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>
  onFieldChange: React.FormEventHandler<HTMLInputElement>
  login: {
    email: string
    password: string
  }
  errors: {
    invalidLogin: boolean
  }
}

export const LoginBoxView = (props: LoginBoxViewProps) => {
  const { onFieldChange, onSubmit, login, errors } = props
  return (
    <div className="panel signin">
      <div className="login-header">
        <SVGKlugoLogo className="login-logo" />
        <h4>Antragsverwaltung</h4>
      </div>
      <div className="login-body">
        <div className="line-divider">Login</div>
        <form onSubmit={onSubmit}>
          <div className="form-group m-b-10">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon="user" />
                </InputGroupText>
              </InputGroupAddon>
              <Input name="email" valid={!errors.invalidLogin} value={login.email} onChange={onFieldChange} placeholder="User" />
            </InputGroup>
          </div>
          <div className="form-group m-t-15">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon="lock" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="password"
                valid={!errors.invalidLogin}
                value={login.password}
                type="password"
                onChange={onFieldChange}
                placeholder="Passwort"
              />
              {errors.invalidLogin && <FormFeedback>Ungültiger Login</FormFeedback>}
            </InputGroup>
          </div>
          <div>
            <NavLink to="/password-reset" className="forgot">
              Sie haben Ihr Passwort vergessen?
            </NavLink>
          </div>
          <div className="form-group m-t-20">
            <ButtonPrimary type="submit" block>
              Einloggen
            </ButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  )
}
