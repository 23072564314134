import { CircularProgress } from '@mui/material'
import React, { FC } from 'react'

interface SpinnerProps {
  condition?: boolean
  center?: boolean
  className?: string
}

export const Spinner: FC<SpinnerProps> = ({ condition, center, className, children }) =>
  condition ? (
    center ? (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress className={className} />
      </div>
    ) : (
      <CircularProgress className={className} />
    )
  ) : (
    <>{children}</>
  )
