import { includes } from 'lodash/fp'

import { Partner } from 'common/api/getPartners'

import { customPartnerIds } from '../constants'

export const getPartnerById = (id: string, partners: Array<Partner>): Partner | undefined => partners.find(p => p.id === id)

export const hasPartnerCustomForm = (partnerId: string): boolean => {
  if (!partnerId) {
    return false
  }
  return includes(partnerId, customPartnerIds)
}
