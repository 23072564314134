export const NUMBER_OPERATORS: { [key: string]: string } = {
  equals: '=',
  lt: '<',
  lte: '≤',
  gt: '>',
  gte: '≥',
  notEmpty: 'vorhanden',
  empty: 'nicht vorhanden',
}

export const NUMBER_OPERATOR_ENTRIES = Object.entries(NUMBER_OPERATORS).map(([k, v]) => ({ id: k, name: v }))
