import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AsyncFunction, request } from 'common/utils'
import { SendToEConsultMutation, SendToEConsultMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/sendToEConsult.graphql'

export const sendToEConsult: AsyncFunction<string, string> = async adviceRequestId => {
  const variables: SendToEConsultMutationVariables = { adviceRequestId }
  const response = await request<SendToEConsultMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.sendToEConsult
}
