import { Function, Predicate } from './functional'

const fill = (value: number) => (value.toString().length === 2 ? value : `0${value}`)

export const formatDate = (date: Date): string => `${fill(date.getDate())}.${fill(date.getMonth() + 1)}.${date.getFullYear()}`

export const formatDateTime = (date: Date): string =>
  `${fill(date.getDate())}.${fill(date.getMonth() + 1)}.${date.getFullYear()} ${fill(date.getHours())}:${fill(date.getMinutes())}`

export const formatTime = (date: Date): string => `${fill(date.getHours())}:${fill(date.getMinutes())}`

export const getWeekOfYear = (date: Date): number => {
  // Copy date so don't modify original
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))

  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))

  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))

  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)

  return weekNo
}

export const isInMinutes: Function<number, Predicate<string | Date | undefined>> = minutes => forwardTime => {
  if (!forwardTime) {
    return false
  }
  const date = new Date(forwardTime)
  return Date.now() + minutes * 1000 * 60 >= date.getTime()
}
