import React, { Fragment, FC } from 'react'
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { StaticRow } from 'common/components/StaticRow'
import { Unpacked } from 'common/interfaces'
import { ButtonCancel } from 'common/ui/Button'

import { CheckInsuranceNumberQuery } from '../../interfaces/schemaDefinition'

type Risks = CheckInsuranceNumberQuery['checkInsuranceNumber']['risks']
type Risk = NonNullable<Unpacked<Risks>>

const Labels: { [key: string]: string } = {
  arbjahr: 'ARB Jahr',
  tarifjahr: 'Tarif Jahr',
  wagnisart: 'Wagnisart',
  wagnisnummer: 'Wagnisnummer',
  wagniskennzeichen: 'Wagniskennzeichen',
  mvpvorname: 'MVP Vorname',
  mvpnachname: 'MVP Nachname',
}

interface OptionalRowProps {
  prop: keyof Risk
  risk: { [key in keyof Risk]: string | null | undefined }
}

const OptionalRow = ({ prop, risk }: OptionalRowProps): JSX.Element | null =>
  risk[prop] ? (
    <StaticRow>
      <Col md={6}>
        <Label>
          <strong>{Labels[prop]}</strong>
        </Label>
      </Col>
      <Col md={6}>{risk[prop]}</Col>
    </StaticRow>
  ) : null

const RiskLabels = ({ risk }: { risk: Risk }): JSX.Element => (
  <div>
    <OptionalRow prop="arbjahr" risk={risk} />
    <OptionalRow prop="tarifjahr" risk={risk} />
    <OptionalRow prop="wagniskennzeichen" risk={risk} />
    <OptionalRow prop="wagnisart" risk={risk} />
    <OptionalRow prop="wagnisnummer" risk={risk} />
    <OptionalRow prop="mvpnachname" risk={risk} />
    <OptionalRow prop="mvpnachname" risk={risk} />
  </div>
)

interface InsuranceModalProps {
  open: boolean
  onCompleteClick: () => void
  name: string
  risks: Risks
}

export const InsuranceModal: FC<InsuranceModalProps> = ({ open, risks, name, onCompleteClick }) => (
  <Modal toggle={onCompleteClick} isOpen={open} id="InsuranceModal">
    <ModalBody>
      <Form className="form">
        <FormGroup>
          <h3 className="h3 small">
            Versicherungsschutz
            <br />
            {name}
          </h3>
        </FormGroup>
        <FormGroup>
          {(risks || []).map((risk, index) => (
            <Fragment key={index}>
              <RiskLabels risk={risk} />
              {index < (risks || []).length - 1 && <hr />}
            </Fragment>
          ))}
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <ButtonCancel onClick={onCompleteClick}>Zurück</ButtonCancel>
    </ModalFooter>
  </Modal>
)
