import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/adviceRequest.graphql'

export const fetchAdviceDetail = async (adviceId: string) => {
  const variables = {
    adviceId,
  }
  try {
    const response = await request<any>(ADVICE_REQUESTS_ENDPOINT, query, variables)
    return response
  } catch (error) {
    throw error
  }
}
