import React, { FC } from 'react'

import { PARTNER_LOGOS } from '../../utils/getPartnerLogos'

interface PartnerCellProps {
  partner?: {
    id: string
    name: string
  }
}

export const PartnerCell: FC<PartnerCellProps> = ({ partner }) => {
  if (!partner) {
    return <td className="text-center">-</td>
  }

  const partnerName = partner.id === 'devk-direktsteuerung-2' ? 'DEVK Direktsteuerung' : partner.name

  const Logo = PARTNER_LOGOS[partner.id]

  return (
    <td className="text-center">
      {!!Logo ? (
        <span title={partnerName}>
          <Logo />
        </span>
      ) : (
        <>{partnerName}</>
      )}
    </td>
  )
}
