import React, { FC, ReactElement } from 'react'

import styles from './FormattedList.module.scss'

interface FormattedListProps {
  data: Array<string>
}

export const FormattedList: FC<FormattedListProps> = ({ data }): ReactElement => (
  <ul className={styles.formattedList}>
    {data.map((entry: string, i: number) => (
      <li key={i}>{entry}</li>
    ))}
  </ul>
)
