import { getOr } from 'lodash/fp'

import { ListState, SortDirection } from '../interfaces'

const toggleSortDirection = (sortDirection: SortDirection): SortDirection =>
  sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc

export const setSort: <C extends string>(state: ListState<C>, sortBy: C) => ListState<C> = (state, sortBy) => ({
  ...state,
  sort: {
    sortBy,
    sortDirection: toggleSortDirection(getOr('asc', 'sort.sortDirection', state)),
  },
})
