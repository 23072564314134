import makeStyles from '@mui/styles/makeStyles'
import {
  DataGrid,
  DataGridProps,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridFilterModel,
  GridFilterItem,
} from '@mui/x-data-grid'
import React, { FunctionComponent } from 'react'

import theme from 'theme/theme'

import { useGridData } from './hooks/useGridData'

type MuiDataGridProps = DataGridProps & {
  actions: ReturnType<typeof useGridData>['actions']
  tableState: ReturnType<typeof useGridData>['tableState']
  mapFilterNames?: (name: string) => string
  noBorder?: boolean
}

const mapFilterModel = (mapFields: MuiDataGridProps['mapFilterNames'], model: GridFilterModel): GridFilterModel => ({
  ...model,
  items: model.items.map(
    (item): GridFilterItem => ({
      ...item,
      columnField: mapFields?.(item.columnField || '') ?? item.columnField,
    })
  ),
})

const useStyles = makeStyles<typeof theme, { noBorder?: boolean }>({
  root: {
    border: props => (props.noBorder ? 'none' : undefined),
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
})

const Toolbar: FunctionComponent = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
  </GridToolbarContainer>
)
export const MuiDataGrid: FunctionComponent<MuiDataGridProps> = ({
  actions,
  tableState,
  mapFilterNames,
  noBorder,
  // We want to give the exact same "rest" props from MuiDataGrid to DataGrid
  // eslint-disable-next-line fp/no-rest-parameters
  ...props
}) => {
  const classes = useStyles({ noBorder })
  return (
    <DataGrid
      classes={{ root: classes.root }}
      autoHeight
      disableSelectionOnClick
      filterMode="server"
      sortingMode="server"
      paginationMode="server"
      page={tableState.page}
      pageSize={tableState.pageSize}
      sortingOrder={['asc', 'desc']}
      onFilterModelChange={params =>
        actions.setFilter(
          mapFilterModel(mapFilterNames, params),
          params.items.map(c => mapFilterNames?.(c.columnField) ?? c.columnField)
        )
      }
      onSortModelChange={params => actions.setSort(params)}
      onPageChange={params => actions.setPage(params)}
      onPageSizeChange={params => actions.setPageSize(params)}
      sortModel={[{ field: tableState.sort.sortBy, sort: tableState.sort.sortDirection }]}
      components={{ Toolbar }}
      {...props}
    />
  )
}
