import { formatEuro } from '@grapes-agency/universal'

const GERMAN_NUMBER_REGEX = /^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$|^-?\d+(?:,\d{2})$/

export function currencyFormatter(value: number | string | null | undefined): string {
  if ((!value && value !== 0) || value === '-') {
    return '-'
  }

  if (typeof value === 'string' && GERMAN_NUMBER_REGEX.test(value)) {
    return formatEuro(Number(value.replace('.', '').replace(',', '.')))
  }

  return formatEuro(Number(value))
}

export function isValidAmount(value: string): boolean {
  return /^\d*,?\d{0,2}$/.test(value)
}
