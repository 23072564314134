import { Roles, URLs } from 'common/constants'
import SVGRequests from 'common/icons/icon-anträge.svg'
import SVGControlling from 'common/icons/icon-controlling.svg'
import SVGOutlineDashboard from 'common/icons/outline-dashboard.svg'
import SVGQuestion from 'common/icons/question-circle-regular.svg'
import { Route } from 'common/interfaces'
import { ControllingNavFilter } from 'packages/lawyers/components/ControllingNavFilter'
import SVGEuro from 'packages/lawyers/components/images/euro.svg'

export const lawyerB2BRoutes: ReadonlyArray<Route> = [
  {
    url: URLs.chancellery.b2b.home,
    roles: [Roles.Lawyer],
    nav: {
      svg: SVGRequests,
      label: 'Anträge',
    },
    children: [
      {
        url: URLs.chancellery.b2b.open,
        label: 'Offen',
      },
      {
        url: URLs.chancellery.b2b.processing,
        label: 'In Bearbeitung',
      },
      {
        url: URLs.chancellery.b2b.complete,
        label: 'Abgeschlossen',
      },
      {
        url: URLs.chancellery.b2b.closed,
        label: 'Abgebrochene Beratungen',
      },
    ],
  },
]

export const lawyerRoutes: ReadonlyArray<Route> = [
  {
    url: URLs.chancellery.dashboard,
    roles: [Roles.Lawyer],
    nav: {
      svg: SVGOutlineDashboard,
      label: 'Dashboard',
    },
  },
  {
    url: URLs.chancellery.adviceRequests.home,
    roles: [Roles.Lawyer],
    nav: {
      svg: SVGRequests,
      label: 'Anträge',
    },
    children: [
      {
        url: URLs.chancellery.adviceRequests.openRequests,
        label: 'Offene Anfragen',
      },
      {
        url: URLs.chancellery.adviceRequests.potentialMandates,
        label: 'Wiedervorlagen',
      },
      {
        url: URLs.chancellery.adviceRequests.closedRequests,
        label: 'Geschlossene Anfragen',
      },
      {
        url: URLs.chancellery.adviceRequests.mandates,
        label: 'Akten',
      },
      {
        url: URLs.chancellery.adviceRequests.completeMandates,
        label: 'Abgelegte Akten',
      },
    ],
  },
  {
    url: URLs.chancellery.sales,
    roles: [Roles.Lawyer],
    nav: {
      svg: SVGEuro,
      label: 'Umsatz',
    },
  },
  {
    url: URLs.lawyerControlling.home,
    roles: [Roles.Lawyer],
    nav: {
      label: 'Controlling',
      svg: SVGControlling,
      filter: ControllingNavFilter,
    },
  },
  {
    url: URLs.help.home,
    roles: [Roles.Lawyer],
    nav: {
      svg: SVGQuestion,
      label: 'Hilfe',
    },
  },
]
