import React, { useContext, FC } from 'react'

import { UserContext, LoggedInUser } from 'common/user-context'
import { isLawyer } from 'common/utils'
import { fetchDataWith } from 'common/utils/fetchDataWith'

import { getChancelleries } from '../actions'
import { ChancelleriesQuery, Chancellery } from '../interfaces'

import { LawyerContext } from './LawyerContext'

const useChancelleries = fetchDataWith(getChancelleries)

const findPowerBILinks = (
  user: LoggedInUser,
  chancelleries: ChancelleriesQuery['chancelleries']['list']
): Chancellery['powerBI'] => {
  const userChancellery = chancelleries.find(c => c.id === user.organisation)

  if (userChancellery) {
    return userChancellery.powerBI
  }

  const chancellery = chancelleries.find(c => !!c.powerBI && c.powerBI[0].length && c.powerBI[1].length)

  return chancellery ? chancellery.powerBI : null
}

export const LawyerContextProvider: FC = ({ children }) => {
  const { user } = useContext(UserContext)
  const { data: chancelleries } = useChancelleries()

  const powerBI = user && isLawyer(user) ? findPowerBILinks(user, chancelleries) : null

  const lawyerContext = {
    powerBI,
  }

  return <LawyerContext.Provider value={lawyerContext}>{children} </LawyerContext.Provider>
}
