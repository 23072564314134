import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/createResubmission.graphql'

export const createResubmission = async (adviceRequestId: string) => {
  try {
    const response = await request<any>(ADVICE_REQUESTS_ENDPOINT, query, {
      adviceRequestId,
    })
    return response
  } catch (error) {
    throw error
  }
}
