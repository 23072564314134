import { Box, Chip, TextField as MuiTextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useField } from 'formik'
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'

import { AddButton } from '../../AddButton/AddButton'
import { useScrollToInvalidField } from '../useScrollToInvalidField'

const useGroupInputStyles = makeStyles(theme => ({
  textField: {
    marginRight: theme.spacing(2),
    width: '10em',
  },
  addButton: {
    marginTop: theme.spacing(0.5),
    marginBottom: 0,
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}))

type GroupInputProps = {
  name: string
  value: Array<string>
  setValue: (value: Array<string>) => void
  forwardedRef?: React.MutableRefObject<HTMLDivElement | null>
  label: string
  validationSchema?: Yup.BaseSchema
  validate?: (inputValue: string) => string | undefined
  classes?: Record<string, string>
  disabled?: boolean
  sort?: boolean | ((a: string, b: string) => 1 | -1)
  error?: string
}

/**
 * GroupInput combines a TextField with "Chips". It depends on a surrounding
 * Formik form which defines an Array<string> under the "name" given to
 * GroupInput.
 * You can supply a Yup validationSchema or a custom validate function to check
 * entered values before they get added to the Array.
 * You can also inject an error string in case of "external" errors.
 * There are MUI style "classes" defined to add styles to sub components if you
 * must:
 *
 * root: the container
 * textField: the MUI-TextField component
 * addButton: the button for adding elements
 * chip: applied to all rendered MUI Chips
 */
export const GroupInput: FunctionComponent<GroupInputProps> = ({
  name,
  label,
  value,
  setValue,
  forwardedRef,
  validate,
  validationSchema,
  disabled,
  sort,
  error: externalError,
  classes: styleClasses,
}) => {
  const rootClass = styleClasses?.root ?? ''
  const textFieldClass = styleClasses?.textField ?? ''
  const addButtonClass = styleClasses?.addButton ?? ''
  const chipClass = styleClasses?.chip ?? ''
  const classes = useGroupInputStyles()
  const [textValue, setTextValue] = useState('')
  const [inputError, setInputError] = useState<string>()
  const inputRef = useRef<HTMLInputElement>(null)

  const internalValidate = useCallback(
    async (inputValue: string): Promise<string | undefined> => {
      if (validate) {
        return Promise.resolve(validate(inputValue))
      }
      if (validationSchema) {
        return validationSchema
          .validate(inputValue)
          .then(() => undefined)
          .catch(error => error.errors[0])
      }
      return Promise.resolve(undefined)
    },
    [validate, validationSchema]
  )

  // Cleanup error message
  useEffect(() => {
    if (inputError) {
      if (textValue === '') {
        setInputError(undefined)
        return
      }
      internalValidate(textValue).then(error => setInputError(error))
    }
  }, [textValue, inputError, internalValidate])

  const handleChange: React.EventHandler<React.ChangeEvent<HTMLInputElement>> = e => {
    setTextValue(e.currentTarget.value)
  }

  const handleAdd = async (): Promise<void> => {
    if (inputRef.current?.value) {
      const error = await internalValidate(textValue)
      if (error) {
        setInputError(error)
        return
      }
      // Allow each string only once in value. It makes no sense otherwise and leads to
      // strange behavior.
      setValue([...new Set(value).add(textValue)])
      setTextValue('')
    }
  }

  const handleDelete = (entry: string) => () => {
    setValue(value.filter(current => current !== entry))
  }

  const errorMessage = inputError || externalError

  const maybeSortedValues =
    // eslint-disable-next-line fp/no-mutating-methods
    !sort ? value : [...value].sort(typeof sort === 'function' ? sort : undefined)

  return (
    <Box display="flex" flexWrap="wrap" mb={3} className={rootClass}>
      <Box display="flex" alignItems="flex-start" mr={2} mb={3}>
        <MuiTextField
          disabled={disabled}
          ref={forwardedRef}
          inputRef={inputRef}
          value={textValue}
          onChange={handleChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          variant="outlined"
          id={`group-input-${name}`}
          label={label}
          className={`${classes.textField} ${textFieldClass}`}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleAdd()
            }
          }}
        />
        <AddButton disabled={disabled} classes={{ root: `${classes.addButton} ${addButtonClass}` }} onClick={handleAdd} />
      </Box>
      <Box flex="0 1 auto" display="flex" alignItems="center" flexWrap="wrap" mb={3}>
        {maybeSortedValues.map(entry => (
          <Chip
            disabled={disabled}
            key={entry}
            label={entry}
            variant="outlined"
            color="primary"
            onDelete={handleDelete(entry)}
            className={`${classes.chip} ${chipClass}`}
          />
        ))}
      </Box>
    </Box>
  )
}

export const FormikGroupInput: FunctionComponent<Omit<GroupInputProps, 'value' | 'setValue' | 'ref'>> = props => {
  const { name } = props
  const [field, , helpers] = useField<Array<string>>(name)
  const fieldRef = useScrollToInvalidField<HTMLDivElement>(name)
  return <GroupInput {...props} value={field.value} setValue={helpers.setValue} forwardedRef={fieldRef} />
}
