import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AsyncFunction, request } from 'common/utils'
import { UnlockAdviceRequestMutation, UnlockAdviceRequestMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/unlockAdviceRequest.graphql'

export const unlockAdviceRequest: AsyncFunction<string, string> = async adviceRequestId => {
  const variables: UnlockAdviceRequestMutationVariables = { adviceRequestId }
  const response = await request<UnlockAdviceRequestMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.unlockAdviceRequest
}
