import classnames from 'classnames'
import React, { FC } from 'react'

import './FieldRow.scss'

interface FieldRowProps {
  className?: string
}

export const FieldRow: FC<FieldRowProps> = ({ className, children }) => (
  <div className={classnames('field-row', className)}>{children}</div>
)
