import React, { FC } from 'react'

import { InputField, InputFieldProps } from 'common/components/Form'

export const InsuranceNumberField: FC<InputFieldProps> = props => {
  const mask = 'ddd ddd ddd'
  const formatChars = {
    d: '[0-9]',
  }

  return (
    <InputField
      {...props}
      maskOptions={{
        mask,
        formatChars,
        maskChar: '_',
      }}
    />
  )
}
