import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import dayjs from 'dayjs'
import { useField } from 'formik'
import { remove } from 'ramda'
import React, { FunctionComponent } from 'react'

import { Autocomplete, Checkbox, DatePicker } from 'common/components-mui'
import { FIELDS_OF_LAW_ENTRIES } from 'common/constants/fieldsOfLaw'

import { ChancelleryLocation, Holiday } from '../interfaces/schemaDefinition'

type HolidaySettingsProps = {
  name: string
  index: number
  disabled?: boolean
}

const useHolidayStyles = makeStyles(theme => ({
  checkbox: {
    width: '100%',
  },
  times: {
    flex: '1 0 auto',
    marginRight: theme.spacing(2),
  },
  root: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(3),
      borderBottom: '1px solid rgba(0,0,0,.23)',
    },
  },
}))

/**
 * HolidaySettings
 * This Component assumes it is rendered inside a Formik Form which has a value of name `name` which is an Array.
 * The Array's elements must have the form:
 * {
 *   global: boolean
 *   begin: string
 *   end: string
 * }
 */
export const HolidaySettings: FunctionComponent<HolidaySettingsProps> = ({ name, index, disabled }) => {
  const classes = useHolidayStyles()
  const checkboxName = `${name}[${index}].global`
  const beginName = `${name}[${index}].start`
  const endName = `${name}[${index}].end`
  const folName = `${name}[${index}].fieldsOfLaw`
  const [, meta, helpers] = useField<ChancelleryLocation['holidays']>(name)
  const [beginField] = useField(beginName)
  const [folField] = useField<Holiday['fieldsOfLaw']>(folName)
  const [checkboxField] = useField<Holiday['global']>(checkboxName)
  const handleDelete = (): void => helpers.setValue(remove(index, 1, meta.value))
  return (
    <Box mb={3} display="flex" flexWrap="wrap" className={meta.value.length > 0 ? classes.root : undefined}>
      <Box flex="1 0 auto" display="flex" justifyContent="space-between" alignItems="flex-start">
        <DatePicker name={beginName} label="Von" className={classes.times} disabled={disabled} />
        <DatePicker
          name={endName}
          label="Bis"
          minDate={dayjs(beginField.value || '')}
          disabled={disabled}
          className={classes.times}
        />
        <Box mt={0.5}>
          <IconButton onClick={handleDelete} disabled={disabled} size="large">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Checkbox name={checkboxName} label="Gültig für alle Standorte" disabled={disabled} className={classes.checkbox} />
      <Autocomplete
        disabled={disabled || (checkboxField.value ?? undefined)}
        multiple
        options={FIELDS_OF_LAW_ENTRIES}
        getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
        name={folName}
        fullWidth
        limitTags={5}
        ChipProps={{ color: 'primary' }}
        label={
          (folField.value?.length || 0) > 0
            ? 'Gilt für ausgewählte Rechtsgebiete'
            : 'Gilt für alle Rechtsgebiete (oder auswählen)'
        }
        textfieldProps={{ variant: 'outlined' }}
      />
    </Box>
  )
}
