import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, TextField as MuiTextField, Autocomplete } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useField } from 'formik'
import { remove } from 'ramda'
import React, { FunctionComponent, memo } from 'react'

import { TextField } from 'common/components-mui'
import { FIELDS_OF_LAW_ENTRIES } from 'common/constants/fieldsOfLaw'

import { EmptyFieldOfLawPhone, LocalFieldOfLawPhone } from '../interfaces/formSchemas'

const useFolPhoneStyles = makeStyles(theme => ({
  field: {
    flex: '0 1 50%',
    marginRight: theme.spacing(2),
  },
}))

type FieldOfLawPhoneEntryProps = {
  name: string
  index: number
  disabled?: boolean
}

const MemoizedTextField = memo(MuiTextField)

export const FieldOfLawPhoneEntry: FunctionComponent<FieldOfLawPhoneEntryProps> = ({ name, index, disabled }) => {
  const classes = useFolPhoneStyles()
  const folName = `${name}[${index}].fieldOfLaw`
  const phoneName = `${name}[${index}].phone`
  const [, globalMeta, globalHelpers] = useField<Array<LocalFieldOfLawPhone | EmptyFieldOfLawPhone>>(name)
  const [, meta, helpers] = useField<{ id: string; name: string } | null>(folName)
  const handleDelete = (): void => globalHelpers.setValue(remove(index, 1, globalMeta.value))

  // Remove fieldsOfLaw that are already set from the options list.
  const filteredOptions = FIELDS_OF_LAW_ENTRIES.filter(
    entry => !globalMeta.value.map(folPhone => folPhone.fieldOfLaw?.id).includes(entry.id)
  )
  // Re-add the one that is chosen here in this instance. Otherwise MUI Autocomplete can't find the selected option in the list.
  const availableOptions = meta.value ? [meta.value].concat(filteredOptions) : filteredOptions
  return (
    <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-start">
      <Autocomplete
        id={folName}
        options={availableOptions}
        getOptionLabel={f => f.name}
        isOptionEqualToValue={
          (option, value) => option.id === value.id && option.name === value.name /* Default is strict equality */
        }
        onChange={(___, v) => helpers.setValue(v)}
        value={meta.value}
        fullWidth
        disabled={disabled}
        className={classes.field}
        renderInput={params => (
          <MemoizedTextField
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            label="Rechtsgebiet"
            variant="outlined"
            {...params}
          />
        )}
      />
      <TextField name={phoneName} label="Telefonnummer" disabled={disabled} className={classes.field} />
      <Box mt={0.5}>
        <IconButton onClick={handleDelete} disabled={disabled} size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
