import GetAppIcon from '@mui/icons-material/GetApp'
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material'
import React, { FunctionComponent, useEffect, useState } from 'react'

type ExportButtonProps = ButtonProps & {
  getExportUrl: () => Promise<string>
  hoverText?: string
  dialogTitle?: string
  dialogPendingText?: string
  dialogReadyText?: string
  dialogErrorText?: string
}

export const ExportButton: FunctionComponent<ExportButtonProps> = ({
  getExportUrl,
  dialogTitle,
  hoverText,
  dialogPendingText,
  dialogReadyText,
  dialogErrorText,
  // eslint-disable-next-line fp/no-rest-parameters
  ...props
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [exportPending, setExportPending] = useState(false)
  const [error, setError] = useState(null)
  const [downloadLink, setDownloadLink] = useState<string>()
  const handleClick = (): void => {
    setIsDialogOpen(true)
    setExportPending(true)
    getExportUrl()
      .then(res => {
        setDownloadLink(res)
      })
      .then(() => setExportPending(false))
      .catch(reason => {
        setError(reason)
        setExportPending(false)
      })
  }

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return (
    <>
      <Button {...props} onClick={handleClick}>
        <GetAppIcon titleAccess={hoverText ?? 'Herunterladen'} />
      </Button>
      <Dialog open={isDialogOpen}>
        <DialogTitle>{dialogTitle ?? 'Export'}</DialogTitle>
        <DialogContent dividers>
          {error ? (
            <Typography align="center" gutterBottom>
              {dialogErrorText ?? 'Es ist ein Fehler aufgetreten.'}
            </Typography>
          ) : exportPending ? (
            <>
              <Typography align="center">{dialogPendingText ?? 'Export wird vorbereitet …'}</Typography>
              <LinearProgress />
            </>
          ) : (
            <Typography align="center" gutterBottom>
              {dialogReadyText ?? 'Export bereit!'}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDialogOpen(false)
              setDownloadLink(undefined)
            }}
          >
            Dialog schließen
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!downloadLink}
            onClick={() => {
              setIsDialogOpen(false)
              setDownloadLink(undefined)
            }}
            target="_blank"
            href={downloadLink || ''}
          >
            Herunterladen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
