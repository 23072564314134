import { TextField, Autocomplete } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { FunctionComponent, useEffect, memo } from 'react'
import useSWR from 'swr'

import { FilterOperator, ListState, SortDirection } from 'common/interfaces'

import { fetchChancelleries } from '../actions'
import { FormUser } from '../interfaces/formSchema'

interface ChancellerySelectionProps {
  name: keyof FormUser
  typeName: keyof FormUser
  className?: string
}

const MemoizedTextField = memo(TextField)

const listState: ListState = {
  page: 1,
  pageSize: 100,
  sort: {
    sortBy: 'name',
    sortDirection: SortDirection.Asc,
  },
  filters: [
    {
      name: 'tags',
      value: '',
      operator: FilterOperator.Empty,
    },
  ],
}

export const ChancellerySelection: FunctionComponent<ChancellerySelectionProps> = ({ name, typeName, className }) => {
  const { data: chancelleries, error } = useSWR([listState], fetchChancelleries)
  const isLoading = !chancelleries && !error
  const {
    values: { [name]: selectedChancellery },
    setFieldValue,
  } = useFormikContext<FormUser>()

  if (typeof selectedChancellery !== 'string') {
    throw new Error('Name for ChancellerySelection input points to non-string value.')
  }

  const mapOptionObject = (list?: typeof chancelleries): Array<{ name: string; id: string }> =>
    list?.flatMap(chancellery => {
      if (chancellery.chancelleryLocations.length >= 1) {
        return [
          { id: chancellery.id, name: chancellery.name },
          ...chancellery.chancelleryLocations.map(location => ({
            ...location,
            name: `-- ${location.address.city}, ${location.address.street}`,
          })),
        ]
      }
      return [{ id: chancellery.id, name: chancellery.name }]
    }) ?? []

  const mapOptionsIds = (list: typeof chancelleries): Array<string> =>
    list?.flatMap(chancellery => {
      if (chancellery.chancelleryLocations.length >= 1) {
        return [chancellery.id, ...chancellery.chancelleryLocations.map(location => location.id)]
      }
      return [chancellery.id]
    }) ?? []

  const getOptionLabel = (option: string): string => {
    const foundOption = mapOptionObject(chancelleries).find(val => val.id === option)
    if (foundOption) {
      return foundOption.name
    } else return ''
  }

  useEffect(() => {
    if (chancelleries) {
      setFieldValue(typeName, chancelleries.find(g => g.id === selectedChancellery) ? 'chancelleryGroup' : null)
    } else {
      setFieldValue(typeName, null)
    }
  }, [typeName, chancelleries, setFieldValue, selectedChancellery])

  return chancelleries ? (
    <Autocomplete
      id="organisation"
      options={mapOptionsIds(chancelleries)}
      onChange={(_, v) => setFieldValue(name, v)}
      value={mapOptionsIds(chancelleries).includes(selectedChancellery) ? selectedChancellery : null}
      getOptionLabel={getOptionLabel}
      className={className}
      disabled={isLoading}
      renderInput={params => (
        <MemoizedTextField
          {...params}
          variant="outlined"
          label="Kanzlei"
          placeholder="Keine Auswahl"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  ) : null
}
