import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component, Fragment, ReactNode } from 'react'
import { Popover, PopoverBody } from 'reactstrap'

interface PopoverItemProps {
  content: ReactNode
}

interface PopoverItemState {
  open: boolean
}

function makeId() {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

  for (let i = 0; i < 20; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

export class PopoverItemView extends Component<PopoverItemProps, PopoverItemState> {
  state = {
    open: false,
    id: makeId(),
  }

  onToggle = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  onMouseOver = () => {
    this.setState({
      open: true,
    })
  }

  onMouseLeave = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    return (
      <Fragment>
        <span id={this.state.id} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
          {this.props.children}
          <FontAwesomeIcon icon="info-circle" className="m-l-10" size="lg" />
          <Popover placement="right" isOpen={this.state.open} target={this.state.id} toggle={this.onToggle}>
            <PopoverBody>{this.props.content}</PopoverBody>
          </Popover>
        </span>
      </Fragment>
    )
  }
}
