import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { LoggedInUser } from 'common/user-context'
import { request } from 'common/utils'
import { ChancelleryLocationsQuery } from 'packages/lawyers/interfaces/schemaDefinition'
import { getChancelleryFilter } from 'packages/lawyers/utils'

import query from '../graphql/adviceTotalCount.graphql'
import { AdviceTotalCountQuery, FilterOperator } from '../interfaces'

export const getPotentialRequestsCount = async (
  user: LoggedInUser,
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
): Promise<number> => {
  const response: AdviceTotalCountQuery = await request<AdviceTotalCountQuery>(ADVICE_REQUESTS_ENDPOINT, query, {
    filters: [
      getChancelleryFilter(user, chancelleryLocations),
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'potential',
      },
    ],
  })

  return response.adviceRequestsCount.total
}
