import React, { FC } from 'react'
import { Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { ButtonBack } from 'common/ui'

import SVGConfirmation from '../images/icon_bestätigung.svg'

interface CompleteModalProps {
  open: boolean
  onCompleteClick: () => void
}

export const B2BCompleteModal: FC<CompleteModalProps> = ({ open, onCompleteClick }) => (
  <Modal toggle={onCompleteClick} isOpen={open} className="success-modal" id="B2BCompleteSuccessModal">
    <ModalBody>
      <SVGConfirmation height="75" width="75" className="m-r-10" />
      <Label>
        Diese Beratung wurde erfolgreich abgeschlossen und ist ab sofort unter <span>“Abgeschlossen“</span> zu finden
      </Label>
    </ModalBody>
    <ModalFooter>
      <ButtonBack onClick={onCompleteClick}>Zurück zu Ihren Beratungen</ButtonBack>
    </ModalFooter>
  </Modal>
)
