import { difference } from 'ramda'

import { Group } from 'common/interfaces'

import { FormUser } from '../interfaces/formSchema'
import { Group as APIGroup, RolesQuery, UserInput } from '../interfaces/schemaDefinition'

import { fetchUserRoles } from './fetchUserRoles'
import { saveGroups } from './saveGroups'

const getExcludedRights = (
  rolesWithRights: RolesQuery['roles'],
  userRights: FormUser['rights'],
  userRoles: FormUser['roles']
): Array<string> => {
  const roleIds = userRoles.map(role => role.id)
  // filter roles with rights that are assigned to the user
  const assignedRolesWithRights = rolesWithRights.filter(role => roleIds.includes(role.id))
  // extract the rights of the assigned roles
  const rightsOfAssignedRoles = assignedRolesWithRights.flatMap(role => role.rights.map(el => el.id))
  // remove duplicates
  const uniqueRightsOfAssignedRoles = Array.from(new Set(rightsOfAssignedRoles))
  // difference between the rights of the assigned roles and the current user rights
  // Note: userRights are already filtered by excludedRights. So if excludedRights is not empty, userRights will be smaller than uniqueRightsOfAssignedRoles. And so the difference will again be the same as excludedRights. If additional rights were removed which belong to an assigned role, the difference will contain those removed rights additionally. If a right that was in excludedRights is added again, then it will not be in the difference anymore and so will be removed from excluded rights on save.
  return difference(uniqueRightsOfAssignedRoles, userRights)
}

const getUserGroups = async (groupsArray: Array<Group>): Promise<Array<APIGroup>> => {
  // find new/unsaved groups
  const groupNames = groupsArray.filter(group => group.id === group.name).map(group => group.name)
  // save new groups
  const newGroups = groupNames.length > 0 ? await saveGroups(groupNames) : []
  return [...groupsArray, ...newGroups]
}

// eslint-disable-next-line complexity
export const mapUserToInput: (user: FormUser) => Promise<UserInput> = async user => {
  const rolesWithRights = await fetchUserRoles()
  return {
    name: user.name,
    email: user.email,
    deleted: user.deleted,
    active: user.active,
    // Make null from empty strings. Better input for the API.
    id: user.id || null,
    roles: user.roles.length ? user.roles.map(role => role.id) : null,
    excludedRights: getExcludedRights(rolesWithRights, user.rights, user.roles),
    groups: (await getUserGroups(user.groups)).map(group => group.id),
    channel: user.channel || null,
    rights: user.rights.length ? user.rights : null,
    organisation: user.organisation || null,
    organisationType: user.organisationType || null,
    parentId: user.parentId || null,
    password: user.password || null,
    // We want to send 'false' so we use ?? instead of ||.
    sendRegisterEmail: user.sendRegisterEmail ?? null,
  }
}
