import React, { FC, ReactNode } from 'react'

import styles from './Toolbar.module.scss'

export interface ToolbarProps {
  title?: String
  buttons?: Array<ReactNode>
}

export const Toolbar: FC<ToolbarProps> = ({ title, buttons }) => (
  <div className={styles.toolbar}>
    {title && <h1 className={styles.title}>{title}</h1>}
    {buttons && <div className={styles.buttons}>{buttons.map(e => e)}</div>}
  </div>
)
