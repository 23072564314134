import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs'
import localeDE from 'dayjs/locale/de'
import React from 'react'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { UserProvider } from 'common/user-context'
import { Backend } from 'layouts/backend'
import { Login } from 'layouts/login'
import theme from 'theme/theme'

import { Apollo } from './apollo'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const App = hot(() => (
  <Apollo>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter} locale={localeDE}>
          <BrowserRouter>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/password-reset" component={Login} />
              <Route path="/password-set" component={Login} />
              <Route
                path="/"
                render={() => (
                  <UserProvider>
                    <Backend />
                  </UserProvider>
                )}
              />
            </Switch>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Apollo>
))
