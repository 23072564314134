import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from './b2bAdviceRequests.graphql'
import { B2bAdviceRequestsQuery } from './schemaDefinition'

export const b2bAdviceRequests = async (): Promise<B2bAdviceRequestsQuery> => {
  const response = await request<B2bAdviceRequestsQuery>(ADVICE_REQUESTS_ENDPOINT, query)
  return response
}
