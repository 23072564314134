export const legalProtectionInsurer = [
  '',
  'Advocard',
  'Allianz Deutschland AG',
  'Allrecht',
  'Alte Leipziger Versicherung AG',
  'Arag',
  'Auxilia Rechtsschutz',
  'BGV Badische Versicherungen',
  'Bruderhilfe',
  'Concordia',
  'Continentale',
  'DA Deutsche Allgemeine',
  'D.A.S. Deutscher Automobil Schutz',
  'Debeka',
  'Deurag',
  'DEVK',
  'DFV Deutsche Familienversicherung AG',
  'Verti Versicherung AG',
  'DMB',
  'GVO Gegenseitigkeit',
  'HDI-Gerling Firmen und Privat',
  'HDI Versicherung AG',
  'Huk24 AG',
  'Huk-Coburg',
  'Itzehoer Versicherung',
  'Jurpartner',
  'LVM - Versicherung',
  'Mecklenburgische',
  'Medien-Versicherung a.G.',
  'NRV - Neue Rechtsschutz',
  'Örag',
  'Roland',
  'R+V',
  'VGH – Versicherungen',
  'WGV-Versicherung AG',
  'Württembergische',
  'Sonstige',
]
