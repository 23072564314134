import classnames from 'classnames'
import { path } from 'ramda'
import React, { useState, useContext, useCallback, FC } from 'react'

import { Popover, PopoverBody, PopoverHeader } from 'common/components/Popover'
import EllipsisVSolid from 'common/icons/EllipsisVSolid.svg'
import { ButtonClear } from 'common/ui'
import { UserContext } from 'common/user-context'

import { assignTo } from '../../actions'
import { LawyerRequest } from '../../interfaces'
import { AssignModal } from '../AssignModal'

import './AdviceMenuButton.scss'

interface AdviceMenuButtonProps {
  adviceRequest: LawyerRequest
  onAssignTo: (adviceId: string, user: { id: string; name: string }) => void
  assignableUsers: Array<{ id: string; name: string }>
}

const hasId = (id: string, element: HTMLElement): boolean =>
  element.id === id || (!!element.parentElement && hasId(id, element.parentElement))

export const AdviceMenuButton: FC<AdviceMenuButtonProps> = ({ onAssignTo, adviceRequest, assignableUsers }) => {
  const adviceRequestId = adviceRequest.id
  const [isOpen, setIsOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [assignTarget, setAssignTarget] = useState('')
  const [modalLoading, setModalLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const buttonId = `menu-${adviceRequestId}`
  const iconId = `icon-${adviceRequestId}`
  const popoverId = `popover-${adviceRequestId}`

  const { user } = useContext(UserContext)

  const assignedTo = path(['assignedTo', 'id'], adviceRequest)
  const assignableToMe = !assignedTo
  const assignableToOthers = !assignedTo || assignedTo === path(['id'], user)

  const assignToMe = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      if (user && assignableToMe) {
        setIsOpen(false)
        await assignTo(adviceRequestId, user.id)
        onAssignTo(adviceRequestId, user)
      }
    },
    [onAssignTo, user, adviceRequestId, assignableToMe]
  )

  const toggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(prevIsOpen => !prevIsOpen)
    event.stopPropagation()
    return false
  }

  // this makes it so that the popover is closed whenever something is clicked without preventing the intended click
  // action, but if the button itself is clicked again it the click action has to be prevented, otherwise the popover
  // would stay open
  const close = (event: MouseEvent) => {
    if (hasId(popoverId, event.target as HTMLElement)) {
      return true
    }
    setIsOpen(false)
    if (hasId(buttonId, event.target as HTMLElement)) {
      event.stopPropagation()
      return false
    }
    return true
  }

  if (isOpen) {
    window.addEventListener('click', close, { capture: true, once: true })
  }

  const openModal = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (assignableToOthers) {
        setModalOpen(true)
      }
      return false
    },
    [assignableToOthers]
  )

  const onAssignTargetChange = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      setAssignTarget(event.currentTarget.value)
    },
    [setAssignTarget]
  )

  const onModalClose = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const onModalComplete = useCallback(() => {
    setModalOpen(false)
    onAssignTo(adviceRequestId, assignableUsers.find(u => u.id === assignTarget) as { id: string; name: string })
  }, [assignTarget, assignableUsers, adviceRequestId, setModalOpen, onAssignTo])

  const onAssign = useCallback(async () => {
    if (!assignTarget) {
      setErrors({ assignTarget: 'Bitte wählen' })
      return
    }
    setModalLoading(true)
    await assignTo(adviceRequestId, assignTarget)
    setModalLoading(false)
    setModalSuccess(true)
  }, [assignTarget, adviceRequestId])

  return (
    <>
      <ButtonClear
        className="advice-menu__button"
        type="button"
        id={buttonId}
        onClick={toggle}
        iconLeft={<EllipsisVSolid id={iconId} width="24" height="24" />}
      />
      <Popover id={popoverId} target={iconId} isOpen={isOpen} className="advice-menu__popover">
        <PopoverHeader className="advice-menu__header">Optionen</PopoverHeader>
        <PopoverBody className="advice-menu__body">
          <ul className="advice-menu__actions">
            <li
              onClick={assignToMe}
              className={classnames('advice-menu__action', { 'advice-menu__action--disabled': !assignableToMe })}
            >
              Antrag zuweisen an mich
            </li>
            <li
              onClick={openModal}
              className={classnames('advice-menu__action', { 'advice-menu__action--disabled': !assignableToOthers })}
            >
              Antrag zuweisen an ...
            </li>
          </ul>
        </PopoverBody>
      </Popover>
      <AssignModal
        users={assignableUsers}
        loading={modalLoading}
        open={modalOpen}
        success={modalSuccess}
        assignTarget={assignTarget}
        setAssignTarget={onAssignTargetChange}
        onCancelClick={onModalClose}
        onCompleteClick={onModalComplete}
        onAssignClick={onAssign}
        errors={errors}
      />
    </>
  )
}
