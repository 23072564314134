import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { AsyncFunction, request } from 'common/utils'

import query from '../graphql/getChancelleries.graphql'
import { GetChancelleriesQuery, GetChancelleriesQueryVariables } from '../interfaces/schemaDefinition'

export const fetchChancelleries: AsyncFunction<
  GetChancelleriesQueryVariables,
  GetChancelleriesQuery['chancelleries']['list']
> = async (variables: GetChancelleriesQueryVariables) => {
  const response = await request<GetChancelleriesQuery>(CHANCELLERIES_ENDPOINT, query, variables)
  // Make sure we get all of them
  if (variables.pageSize && response.chancelleries.total > variables.pageSize) {
    return (
      await request<GetChancelleriesQuery>(CHANCELLERIES_ENDPOINT, query, {
        ...variables,
        pageSize: response.chancelleries.total,
      })
    ).chancelleries.list.filter(chancellery => !!chancellery.chancelleryLocations.length)
  }
  return response.chancelleries.list.filter(chancellery => !!chancellery.chancelleryLocations.length)
}
