import React, { Fragment } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { Spinner } from 'common/components'
import { ContactType } from 'common/graphql/schemaDefinition'
import { ButtonConfirm, ButtonCancel, ButtonSecondary } from 'common/ui/Button'

import { AdviceRequest } from '../../interfaces'
import { getCallbackTimes } from '../../utils/getCallbackTimes'
import { SearchView } from '../SearchView'
import { StatusRow } from '../StatusRow'

import './FinishModal.scss'

interface FinishModalProps {
  adviceRequest: AdviceRequest
  open: boolean
  loading: boolean
  phone: string
  onChangeCallbackTime: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCompleteClick: () => void
  onCancelClick: () => void
  onCopyClick: () => void
  isCopied: boolean
  dateError?: string
  isTRB: boolean
  isAdmin: boolean
}

const getTexts = (contactType?: ContactType): { title: string; body: string } =>
  contactType === 'REDIRECT'
    ? {
        title: 'Weiterleitung durchführen',
        body:
          // tslint:disable-next-line:max-line-length
          'Leiten Sie nun den Kunden an den Anwalt weiter und bestätigen Sie sobald der Anwalt angenommen hat. Damit ist der Antrag abgeschlossen und kann nicht mehr verändert werden.',
      }
    : {
        title: 'Rückruf einrichten',
        body:
          // tslint:disable-next-line:max-line-length
          'Bestätigen Sie die Einrichtung des Rückrufs. Damit erhält der Anwalt eine E-Mail mit den Kontakt und Falldaten des Kunden und der Antrag ist abeschlossen und kann nicht mehr verändert werden.',
      }

export const FinishModal = ({
  adviceRequest,
  phone,
  open,
  isCopied,
  dateError,
  isTRB,
  isAdmin,
  loading,
  onChangeCallbackTime,
  onCopyClick,
  onCancelClick,
  onCompleteClick,
}: FinishModalProps): JSX.Element => {
  const texts = getTexts(adviceRequest.contactType)
  return (
    <Modal isOpen={open} className="finish-modal">
      {loading || !open ? (
        <ModalBody>
          <Spinner center />
        </ModalBody>
      ) : (
        <Fragment>
          <Form className="form">
            <ModalHeader>{texts.title}</ModalHeader>
            <ModalBody>
              <StatusRow adviceRequest={adviceRequest} isTRB={isTRB} isAdmin={isAdmin} />
              <SearchView adviceRequest={adviceRequest} showInsuranceNumber={isTRB} isTRB={isTRB} />
              <p>{texts.body}</p>
              {phone && (
                <Fragment>
                  <div className="finish-modal__static finish-modal__phone">
                    <span>Tel-Nr. der Kanzlei:</span>
                    <CopyToClipboard text={phone} onCopy={onCopyClick}>
                      <span className="finish-modal__phone__hover">{phone}</span>
                    </CopyToClipboard>
                    <CopyToClipboard text={phone} onCopy={onCopyClick}>
                      <ButtonSecondary className="pull-right">{isCopied ? 'Wurde kopiert' : 'Kopieren'}</ButtonSecondary>
                    </CopyToClipboard>
                  </div>
                </Fragment>
              )}
              {adviceRequest.contactType === 'CALLBACK' && (
                <FormGroup tag="fieldset">
                  <legend>Rückruf</legend>
                  <FormGroup style={{ marginTop: '10px' }}>
                    <Label>Rückrufzeit:&nbsp;</Label>
                    <Input
                      onChange={onChangeCallbackTime}
                      value={adviceRequest.preferredCallbackTime ? adviceRequest.preferredCallbackTime.toString() : ''}
                      type="select"
                      name="preferredCallbackTime"
                      id="callbackTime"
                    >
                      <option value="">---Bevorzugte Rückrufzeit---</option>
                      {getCallbackTimes(new Date()).map(callbackTime => (
                        <option key={callbackTime.value} value={callbackTime.value}>
                          {callbackTime.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonCancel onClick={onCancelClick}>Abbrechen</ButtonCancel>
              <ButtonConfirm onClick={onCompleteClick} disabled={!!dateError}>
                Bestätigen und Antrag abschließen
              </ButtonConfirm>
            </ModalFooter>
          </Form>
        </Fragment>
      )}
    </Modal>
  )
}
