import React, { useCallback, useState, Fragment, FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Col, Row, Table } from 'reactstrap'

import { Partner } from 'common/api/types'
import { FilterTags, Panel, Spinner, Toolbar } from 'common/components'
import { ConfirmButton } from 'common/components/Confirmables'
import SVGNew from 'common/components/Panel/images/icon-new.svg'
import { SearchContainer } from 'common/components/SearchField'
import { ADVICE_REQUESTS_ENDPOINT, Roles, ROLES, ROLE_IDS } from 'common/constants'
import { ListProps, withList } from 'common/container'
import { Unpacked, Role } from 'common/interfaces'
import { LoggedInUser } from 'common/user-context'
import { isTRB, isTRBManager } from 'common/utils/roles'
import { startTRB } from 'packages/chancellery-search'

import { ExportModal } from '../container/ExportModal'
import query from '../graphql/fetchAdviceRequests.graphql'
import { AdviceRequestsQuery, FilterOperator } from '../interfaces'

import { AdviceListBody } from './AdviceListBody'
import { AdviceListHeader } from './AdviceListHeader'

interface AdviceListProps extends ListProps<Unpacked<AdviceRequestsQuery['adviceRequests']['list']>> {
  user?: LoggedInUser
  partners: Array<Partner>
  onNew: () => void
  onExport: () => void
}

const mapRoleNameToRole: (user?: LoggedInUser) => Role | undefined = user => {
  let statusRole: Role | undefined
  if (user) {
    user.roles.forEach(role => {
      switch (role) {
        case Roles.Administrator:
          statusRole = ROLES[ROLE_IDS.Administrator]
          return
        case Roles.Employee:
          statusRole = ROLES[ROLE_IDS.Employee]
          return
        case Roles.Callcenter:
          statusRole = statusRole || ROLES[ROLE_IDS.Callcenter]
          break
        case Roles.Channel:
          statusRole = statusRole || ROLES[ROLE_IDS.Channel]
          break
        case Roles.TRB:
          statusRole = statusRole || ROLES[ROLE_IDS.TRB]
          break
        case Roles.TRBManager:
          statusRole = statusRole || ROLES[ROLE_IDS.TRBManager]
          break
        case Roles.Lawyer:
          break
        default:
          break
      }
    })
  }
  return statusRole
}

export const AdviceListView: FC<AdviceListProps & RouteComponentProps<{}>> = ({
  user,
  list,
  sort,
  page,
  pageSize,
  total,
  filters,
  onSort,
  onFilterRemove,
  onFilterChange,
  onNew,
  onNext,
  onPrev,
  partners,
  loading,
  history,
}) => {
  const statusRole: Role | undefined = mapRoleNameToRole(user)
  const trbOrManager = isTRB(user) || isTRBManager(user)
  const [exportOpen, setExportOpen] = useState(false)

  const toggleExportOpen = useCallback(() => {
    setExportOpen(!exportOpen)
  }, [exportOpen, setExportOpen])

  if (!statusRole) {
    return null
  }

  const buttons = [<SearchContainer key="search" />]
  return (
    <Fragment>
      <Panel
        title={trbOrManager ? 'Antragsverwaltung der Telefonischen Rechtsberatung' : 'Antragsverwaltung'}
        onNew={trbOrManager ? undefined : onNew}
        onExport={toggleExportOpen}
        buttons={
          trbOrManager
            ? [
                <ConfirmButton
                  className="icon-btn btn"
                  color="round"
                  key="new"
                  data-cy="new"
                  onComplete={startTRB(history)}
                  question="Telefonische Rechtsberatung starten?"
                  description="Bei Bestätigung erzeugen Sie eine Beratungs-ID und können die Beratung starten."
                  iconLeft={<SVGNew height="20" width="20" />}
                />,
              ]
            : buttons
        }
      >
        <Toolbar pagination={{ page, pageSize, total, label: 'Anfragen' }} onNext={onNext} onPrev={onPrev} />
        <Row>
          <Col sm="12" className="col-padding">
            <Spinner condition={loading} center>
              <FilterTags filters={filters} onTagRemove={onFilterRemove} partners={partners} />
              {list.length > 0 ? (
                <Table hover responsive>
                  <AdviceListHeader
                    onFilterChange={onFilterChange}
                    onSort={onSort}
                    sort={sort}
                    partners={partners}
                    hidePartners={isTRB(user) || isTRBManager(user)}
                    hideActions={false}
                    role={statusRole}
                  />
                  <AdviceListBody
                    list={list}
                    hidePartners={isTRB(user) || isTRBManager(user)}
                    fixedStatus={false}
                    hideActions={false}
                    role={statusRole}
                  />
                </Table>
              ) : (
                <h3 className="no-data">Es wurden keine Anträge eingetragen.</h3>
              )}
            </Spinner>
          </Col>
        </Row>
      </Panel>
      <ExportModal open={exportOpen} filters={filters} sort={sort} onCancelClick={toggleExportOpen} user={user} />
    </Fragment>
  )
}

export const AdviceList = withList<Unpacked<AdviceRequestsQuery['adviceRequests']['list']>, AdviceListProps>({
  query,
  endpoint: ADVICE_REQUESTS_ENDPOINT,
  responseKey: 'adviceRequests',
  queryMapper: (listState, props) =>
    isTRB(props.user)
      ? {
          ...listState,
          view: 'trb',
          filters: [
            ...listState.filters,
            {
              name: 'partnerId',
              operator: FilterOperator.Equals,
              value: 'devk-trb',
            },
          ],
        }
      : listState,
})(withRouter(AdviceListView))
