import React, { ChangeEventHandler, FC } from 'react'
import { Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import { StaticRow } from 'common/components/StaticRow'
import { ButtonBack, ButtonPrimary } from 'common/ui/Button'

import { AdviceRequest, ProductType } from '../../interfaces'
import SVGExit from '../images/exit.svg'
import SVGClosedRequests from '../images/icon_geschlossene_anfragen.svg'

interface CloseModalProps {
  adviceDetail: AdviceRequest
  open: boolean
  loading: boolean
  success: boolean
  onCancelClick: () => void
  onCloseClick: () => void
  onCompleteClick: () => void
  setNotes: ChangeEventHandler<HTMLInputElement>
  errors?: { [props: string]: string }
}

export const CloseModal: FC<CloseModalProps> = ({
  adviceDetail,
  open,
  loading,
  success,
  onCancelClick,
  onCloseClick,
  onCompleteClick,
  setNotes,
  errors = {},
}) => {
  const isBusinessProduct = adviceDetail.product.type === ProductType.Business
  const closeReasonsArray: Array<string> = isBusinessProduct
    ? [
        'Kunde hat ein falsches Paket gebucht',
        'Kunde hat versehentlich doppelt gebucht',
        'Kunde will stornieren / bricht Beratung ab',
      ]
    : [
        'Nutzer wollte nur eine Einschätzung',
        'Nutzer hat bereits einen Anwalt und wollte eine zweite Meinung',
        'Keine Erfolgsaussichten',
        'Nutzer will RA vor Ort',
        'Doppelte Anfrage',
        'Kunde nicht mehr erreicht / Kunde reagiert nicht mehr.',
        'Kunde hat vor Mandatierung zurückgezogen.',
        'Vorgang vor Kontakt bereits erledigt',
        'Kunde bei Erstkontakt wiederholt nicht erreicht',
      ]

  if (loading || !open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  if (success) {
    return (
      <Modal toggle={onCompleteClick} isOpen={open} className="success-modal" id="CloseSuccessModal">
        <ModalBody>
          <SVGClosedRequests height="160" width="200" />
          <Label>
            {isBusinessProduct ? (
              <>
                Diese Beratung wurde erfolgreich geschlossen und ist ab sofort unter
                <span>“Beratung abgebrochen“</span> zu finden.
              </>
            ) : (
              <>
                Dieser Antrag wurde erfolgreich geschlossen und ist ab sofort unter
                <span>“Geschlossene Anfragen“</span> zu finden.
              </>
            )}
          </Label>
        </ModalBody>
        <ModalFooter>
          <ButtonBack onClick={onCompleteClick}>Zurück zu Ihren Anträgen</ButtonBack>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} className="new-modal medium-size" id="CloseModal">
      <ModalBody>
        <div className="btn-cancel close-modal" onClick={onCancelClick}>
          <SVGExit height="14" width="14" />
        </div>
        <Form className="form">
          <FormGroup>
            <h3 className="h3 small">
              {isBusinessProduct
                ? `Bitte geben Sie den Grund an weshalb Sie die Beratung abbrechen möchten.`
                : `Bitte geben Sie einen Grund an weshalb der Antrag geschlossen wird.`}
            </h3>
          </FormGroup>
          <FormGroup>
            {closeReasonsArray.map(reason => (
              <StaticRow key={reason}>
                <Col md={11}>
                  <Label>{reason}</Label>
                </Col>
                <Col md={1}>
                  <Input
                    type="radio"
                    name="closeReason"
                    value={reason}
                    checked={adviceDetail.closeReason === reason}
                    onChange={setNotes}
                  />
                </Col>
              </StaticRow>
            ))}
            {!!errors.closeReason && <FormFeedback>{errors.closeReason}</FormFeedback>}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary onClick={onCloseClick} iconLeft={<SVGExit height="14" width="14" />}>
          {isBusinessProduct ? `Beratung abbrechen` : `Antrag schließen`}
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  )
}
