/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable fp/no-mutation, fp/no-this */
import { isHoliday } from 'feiertagejs'
import * as H from 'history'
import { get, set } from 'lodash/fp'
import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { fetchPartners, Partner } from 'common/api/getPartners'
import { ConfirmModal } from 'common/components/ConfirmModal'
import { B2B_INDIVIDUAL_PRODUCT, FIELDS_OF_LAW_ENTRIES, URLs } from 'common/constants'
import { UserContextProps, withUser } from 'common/user-context'
import {
  Consumer,
  Procedure,
  getQueryParam,
  isAdmin,
  isChannel,
  isEmpty,
  isLawyer,
  isTRB,
  isValidAmount,
  isDevk,
} from 'common/utils'
import {
  MutationCancelRequestArgs,
  ContactType,
  AdviceRequestQuery,
  ProductType,
} from 'packages/chancellery-search/interfaces/schemaDefinition'

import {
  getAdviceRequest,
  cancelRequest,
  createResubmission,
  findChancelleries,
  finishAdviceRequest,
  mapResponseToAdviceRequest,
  saveAdviceRequest,
  saveInternalNote,
  sendAGBs,
  startTRB as startTRBAction,
  setToClosed,
  rematch,
} from '../actions'
import { CancelModal } from '../components/CancelModal'
import { FinishModal } from '../components/FinishModal'
import { ChancellerySearchPage } from '../components/SearchPage'
import { ChancellerySearchResults } from '../components/SearchResults'
import { closingReasons, internalClosingReasons } from '../constants'
import { AdviceRequest, MatchedChancellery } from '../interfaces'
import { defaultCallbackTime } from '../utils/getCallbackTimes'
import { getPartnerById } from '../utils/partner'

type ChancellerySearchProps = RouteComponentProps<{}> & UserContextProps

interface ErrorMap {
  [key: string]: string
}

interface ChancellerySearchState {
  adviceRequest: AdviceRequest
  partners: Array<Partner>
  hidePartnerSelection: boolean
  lockFieldOfLaw: boolean
  matchedChancelleries: {
    chancelleries: Array<MatchedChancellery>
    ownChancelleries: Array<MatchedChancellery>
    centralChancelleries?: Array<MatchedChancellery> | null
    premiumChancelleries: Array<MatchedChancellery>
  }
  initialLoading: boolean
  loading: boolean
  matching: boolean
  completing: boolean
  searched: boolean
  rematched: boolean
  phone: string
  confirmCancel: boolean
  cancelOpen: boolean
  finishOpen: boolean
  errors: ErrorMap
  isMounted: boolean
  isCopied: boolean
  isTRB: boolean
  isLawyer: boolean
  useBillingCompany: boolean
  dateError?: string
  closeErrors: ErrorMap
  savingInternalNote: boolean
  serviceType: string
}

const emptyAdviceRequest: () => AdviceRequest = () => ({
  foa: '',
  createdAt: new Date(),
  firstname: '',
  lastname: '',
  street: '',
  streetNr: '',
  city: '',
  zip: '',
  email: '',
  phone: '',
  companyName: '',
  companyCompanyType: '',
  companyAddressStreet: '',
  companyAddressStreetNr: '',
  companyAddressZip: '',
  companyAddressCity: '',
  companyTaxId: '',
  billingCompanyName: '',
  billingCompanyCompanyType: '',
  billingCompanyAddressStreet: '',
  billingCompanyAddressStreetNr: '',
  billingCompanyAddressZip: '',
  billingCompanyAddressCity: '',
  billingCompanyTaxId: '',
  agbsAccepted: false,
  dataAccepted: false,
  damageNumber: '',
  damageNumberDisabled: false,
  insuranceNumber: '',
  deductible: false,
  deductibleAmount: '',
  protection: false,
  protectionAmount: '',
  disputeAmount: '',
  internalPartnerNotes: '',
  instance: '',
  description: '',
  internalNotes: '',
  externalNotes: '',
  closeReason: '',
  status: 'new',
  adviceId: '',
  insured: false,
  newsletter: false,
  resultNotes: '',
  lawyerNotes: '',
  product: {
    id: '',
    name: '',
    price: 0,
    type: ProductType.Consumer,
  },
  language: {
    code: 'de',
    language: 'Deutsch',
  },
})

const redirectToAdvice: (history: H.History) => (adviceId?: string) => void = history => adviceId => {
  if (adviceId) {
    if (typeof adviceId !== 'string') {
      throw new Error(`Expected adviceId of type string but got ${typeof adviceId}: ${JSON.stringify(adviceId)}`)
    }
    // eslint-disable-next-line fp/no-mutating-methods
    history.push(`${URLs.adviceRequests.home}/form?adviceId=${adviceId}`)
  } else {
    // eslint-disable-next-line fp/no-mutating-methods
    history.push(URLs.adviceRequests.home)
  }
}

/* Callback for ConfirmNavLink to prepare new TRB and link to this page */
export const startTRB: (history: H.History) => Procedure = history => async () => {
  const adviceRequest = await startTRBAction()
  redirectToAdvice(history)(adviceRequest.adviceId)
}

const ChancellerySearchContainer = withUser(
  class ChancellerySearchContainer extends Component<ChancellerySearchProps, ChancellerySearchState> {
    public state: ChancellerySearchState = {
      adviceRequest: emptyAdviceRequest(),
      partners: [],
      hidePartnerSelection: false,
      lockFieldOfLaw: false,
      matchedChancelleries: {
        chancelleries: [],
        ownChancelleries: [],
        centralChancelleries: undefined,
        premiumChancelleries: [],
      },
      initialLoading: false,
      loading: false,
      matching: false,
      completing: false,
      searched: false,
      rematched: false,
      phone: '',
      errors: {},
      closeErrors: {},
      confirmCancel: false,
      cancelOpen: false,
      finishOpen: false,
      isMounted: true,
      isCopied: false,
      isTRB: false,
      isLawyer: false,
      useBillingCompany: false,
      savingInternalNote: false,
      serviceType: 'Telefonische Rechtsberatung',
    }

    private redirectToAdvice: (adviceId?: string) => void = redirectToAdvice(this.props.history)

    public async componentDidMount(): Promise<void> {
      const { user, location } = this.props
      const partners = await this.fetchAndStorePartners()
      this.setDefaultPartner()
      const { adviceRequest } = this.state
      const partnerId = getQueryParam(location, 'partnerId')
      const adviceId = getQueryParam(location, 'adviceId')

      if (user) {
        if (isChannel(user)) {
          const channel = partners.find(partner => partner.id === user.channel)
          const setPartner = set('partner', channel)
          this.setState({
            adviceRequest: setPartner(adviceRequest),
            hidePartnerSelection: true,
            lockFieldOfLaw: true,
          })
          this.handlePartnerId(user.channel!)
        }

        if (isTRB(user)) {
          const channel = partners.find(partner => partner.id === 'devk-trb')
          const setPartner = set('partner', channel)

          this.setState({
            adviceRequest: setPartner(adviceRequest),
            hidePartnerSelection: true,
            isTRB: true,
          })
          this.handlePartnerId('devk-trb')
        }
        if (isLawyer(user)) {
          this.setState({ isLawyer: true })

          if (!isTRB(user)) {
            this.handlePartnerId('devk')
            this.setState({ hidePartnerSelection: true })
          }
        }
      }

      if (partnerId) {
        this.handlePartnerId(partnerId)
      }
      if (adviceId) {
        this.handleAdviceId(adviceId)
      }
    }

    public componentDidUpdate(prevProps: ChancellerySearchProps): void {
      const { location } = this.props
      if (location !== prevProps.location) {
        const adviceId = getQueryParam(location, 'adviceId')
        if (adviceId) {
          this.handleAdviceId(adviceId)
        }
      }
    }

    public componentWillUnmount(): void {
      this.setState({ isMounted: false })
    }

    private onServiceTypeChange: Consumer<React.FormEvent<HTMLInputElement>> = event => {
      const { name, value } = event.currentTarget
      if (!name) {
        throw new Error('missing name on event target')
      } else {
        this.setState({ serviceType: value })
      }
    }

    private onPersonChange = (
      firstname: string,
      lastname: string,
      street: string,
      streetNr: string,
      city: string,
      zip: string
    ): void => {
      this.setState(prevState => {
        const adviceRequest = {
          ...prevState.adviceRequest,
          firstname,
          lastname,
          street,
          streetNr,
          city,
          zip,
        }
        return { adviceRequest }
      })
    }

    // eslint-disable-next-line complexity
    private onFieldChange: Consumer<React.FormEvent<HTMLInputElement>> = event => {
      const { name, value } = event.currentTarget
      if (!name) {
        throw new Error('missing name on event target')
      } else {
        let adviceRequest: AdviceRequest
        if (name === 'fieldOfLaw') {
          adviceRequest = {
            ...this.state.adviceRequest,
            fieldOfLaw: FIELDS_OF_LAW_ENTRIES.find(fol => fol.id === value) || { id: '', name: '' },
          }
        } else if (name === 'language') {
          adviceRequest = {
            ...this.state.adviceRequest,
            language: {
              code: value,
              language: value,
            },
          }
        } else if (name === 'disputeAmount') {
          adviceRequest = {
            ...this.state.adviceRequest,
            disputeAmount: isValidAmount(value) ? value : this.state.adviceRequest.disputeAmount,
          }
        } else if (name === 'damageNumber') {
          const damageNumber = value.replace(/\s\.\s/g, '')
          adviceRequest = {
            ...this.state.adviceRequest,
            damageNumber,
          }
        } else if (name === 'partner') {
          const partner = getPartnerById(value, this.state.partners)
          adviceRequest = {
            ...this.getPartnerDefaults(value),
            agbsAccepted: partner ? partner.agbsPreAccepted : this.state.adviceRequest.agbsAccepted,
            dataAccepted: partner ? partner.agbsPreAccepted : this.state.adviceRequest.dataAccepted,
            partner: partner!,
          }
        } else {
          adviceRequest = {
            ...this.state.adviceRequest,
            [name]: value,
          }
        }
        this.setState({ adviceRequest })
      }
    }

    private onCheckboxChange = (event: React.FormEvent<HTMLInputElement>): void => {
      const { useBillingCompany, adviceRequest } = this.state
      if (!event.currentTarget.name) {
        throw new Error('missing name on event target')
      } else if (event.currentTarget.name === 'billingCompany') {
        this.setState({ useBillingCompany: !useBillingCompany })
      } else {
        const renamedAdviceRequest = set(event.currentTarget.name, !get(event.currentTarget.name, adviceRequest), adviceRequest)

        if (event.currentTarget.name === 'damageNumberDisabled') {
          renamedAdviceRequest.damageNumber = ''
        }

        if (event.currentTarget.name === 'insuranceNumberNotAvailabe') {
          renamedAdviceRequest.insuranceNumber = ''
        }

        this.setState({ adviceRequest: renamedAdviceRequest })
      }
    }

    private getAdviceRequest = async (adviceId: string, tries = 0): Promise<void> => {
      try {
        const response = await getAdviceRequest(adviceId)
        this.handleResponse(response)
        this.setState({ initialLoading: false })
      } catch (error) {
        if (tries < 5) {
          setTimeout(() => {
            this.getAdviceRequest(adviceId, tries + 1)
          }, 100)
        } else {
          throw error
        }
      }
    }

    private handleAdviceId = (adviceId: string): void => {
      if (adviceId) {
        this.setState({ initialLoading: true })
        this.getAdviceRequest(adviceId)
      }
    }

    private handlePartnerId = (id: string): void => {
      const { partners, adviceRequest, isMounted } = this.state
      const partner = getPartnerById(id, partners)
      if (!partner) {
        this.redirectToError()
      } else {
        const changedAdviceRequest = {
          ...this.getPartnerDefaults(id),
          partner,
          insured: adviceRequest.insured,
          agbsAccepted: partner.agbsPreAccepted,
          dataAccepted: partner.agbsPreAccepted,
        }
        if (isMounted) {
          this.setState({
            adviceRequest: changedAdviceRequest,
          })
        }
      }
    }

    private getPartnerDefaults = (id: string): AdviceRequest => {
      const { adviceRequest } = this.state
      if ((id === 'devk' || id === 'devk-steuerungstarif' || id === 'devk-diesel') && adviceRequest.status === 'new') {
        return {
          ...adviceRequest,
          protection: true,
          protectionAmount: '1. Instanz',
        }
      }
      return {
        ...adviceRequest,
      }
    }

    public setDefaultPartner = () => {
      const partner = getPartnerById('klugo', this.state.partners)
      if (partner) {
        const adviceRequest: AdviceRequest = {
          ...this.state.adviceRequest,
          partner,
        }
        this.setState({ adviceRequest })
      }
    }

    public setPartnerById = async (partnerId: string): Promise<void> => {
      const partner = getPartnerById(partnerId, this.state.partners)
      if (partner) {
        const adviceRequest: AdviceRequest = {
          ...this.state.adviceRequest,
          partner,
        }
        this.setState({ adviceRequest })
      }
    }

    public fetchAndStorePartners = async (): Promise<Array<Partner>> => {
      const partners = await fetchPartners()
      this.setState({ partners })
      return partners
    }

    public redirectToError: () => void = () => this.props.history.push('/404')

    public onRematch = async () => {
      this.setState({ rematched: true, matching: true })
      const matchedChancelleries = await findChancelleries(this.state.adviceRequest)
      this.setState({ matchedChancelleries, matching: false })
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      })
    }

    public onFindChancelleries = async () => {
      const { isMounted } = this.state
      const errors = this.validateInput(this.state.adviceRequest)
      if (Object.keys(errors).length) {
        if (isMounted) {
          this.setState({ errors })
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      if (isMounted) {
        this.setState({ searched: true, matching: true })
      }
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      })

      try {
        const { user } = this.props

        if (this.state.adviceRequest.partner?.id === 'devk-direktsteuerung' && this.state.adviceRequest.protection === false) {
          await this.setPartnerById('devk-direktsteuerung-2')
        }

        const matchedChancelleries = await findChancelleries(
          this.state.adviceRequest,
          isTRB(user) ? ContactType.Callback : undefined
        )
        if (isMounted) {
          this.setState({ matchedChancelleries, matching: false })
        }
        setTimeout(() => {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        })
      } catch (error) {
        if (error && error.message === 'address_not_found') {
          if (isMounted) {
            this.setState({
              searched: false,
              matching: false,
              errors: { address: 'Die angegebene Adresse konnte nicht gefunden werden.' },
            })
          }
        } else {
          throw error
        }
      }
    }

    public onRedirectClick: Consumer<MatchedChancellery> = chancellery => {
      const adviceRequest: AdviceRequest = {
        ...this.state.adviceRequest,
        chancellery,
        chancelleryId: chancellery.id,
        contactType: ContactType.Redirect,
      }
      this.setState({ adviceRequest, phone: chancellery.phone, finishOpen: true, isCopied: false })
    }

    public onCallbackClick = (chancellery: MatchedChancellery) => {
      const adviceRequest: AdviceRequest = {
        ...this.state.adviceRequest,
        chancellery,
        chancelleryId: chancellery.id,
        contactType: ContactType.Callback,
        preferredCallbackTime: defaultCallbackTime(new Date()).value,
      }
      this.setState({ adviceRequest, finishOpen: true })
    }

    public onCancelClick = () => {
      const adviceRequest: AdviceRequest = {
        ...this.state.adviceRequest,
        chancelleryId: undefined,
      }
      this.setState({ adviceRequest, phone: '', finishOpen: false })
    }

    public isWeekend = (date: Date) => date.getDay() === 0 || date.getDay() === 6

    public isClosedHour = (date: Date) => date.getHours() < 8 || date.getHours() >= 19

    public isOpen = (date?: string | Date | null) => {
      if (date) {
        const dateFormat = new Date(date)
        if (
          isHoliday(dateFormat, 'BUND') ||
          isHoliday(dateFormat, 'NW') ||
          this.isWeekend(dateFormat) ||
          this.isClosedHour(dateFormat) ||
          (dateFormat.getDate() === 24 && dateFormat.getMonth() === 11)
        ) {
          return 'Bitte einen Zeitpunkt Werktags zwischen 8 und 19 Uhr wählen.'
        }
      }
      return ''
    }

    public onCompleteClick = async () => {
      const dateError =
        this.validateCallbackTime(this.state.adviceRequest.preferredCallbackTime) ||
        this.isOpen(this.state.adviceRequest.preferredCallbackTime)

      if (this.state.adviceRequest.contactType === 'CALLBACK' && dateError) {
        this.setState({ dateError })
        return
      }
      this.setState({ completing: true })
      const { adviceRequest } = this.state

      if (!this.state.rematched) {
        finishAdviceRequest(adviceRequest)
          .then(result => {
            if (this.state.isMounted) {
              const adviceRequest: AdviceRequest = {
                ...this.state.adviceRequest,
                adviceId: result.finishMatching,
              }

              this.setState({
                adviceRequest,
                completing: false,
                finishOpen: false,
                searched: false,
              })

              this.redirectToAdvice(adviceRequest.adviceId)
            }
          })
          .catch(error => {
            if (this.state.isMounted) {
              this.setState({
                completing: false,
                finishOpen: false,
              })
              throw error
            }
          })
      } else if (adviceRequest.id && adviceRequest.chancelleryId) {
        await rematch({
          rematchInput: {
            adviceRequestId: adviceRequest.id,
            chancelleryId: adviceRequest.chancelleryId,
            fieldOfLawId: adviceRequest.fieldOfLaw?.id || '',
          },
        })

        this.setState({
          adviceRequest,
          completing: false,
          finishOpen: false,
          rematched: false,
        })

        this.redirectToAdvice()
      }
    }

    public pushError = (error: ErrorMap): void => {
      this.setState(prevState => ({
        errors: { ...prevState.errors, ...error },
      }))
    }

    public onSendAGBs: Consumer<AdviceRequest> = adviceRequest => {
      const { foa, firstname, lastname, email, adviceId } = adviceRequest
      if (isEmpty(email)) {
        this.pushError({ email: 'Bitte eine gültige Emailadresse angeben.' })
      } else {
        this.setState({ loading: true })
        sendAGBs({ foa, firstname, lastname, email, adviceId })
          .then(() =>
            this.setState(prevState => ({
              adviceRequest: { ...prevState.adviceRequest, agbsSent: new Date() },
              loading: false,
            }))
          )
          .catch(error => {
            // eslint-disable-next-line
            console.error(`Failed to send AGBs email: ${error}`)
          })
      }
    }

    public validateCallbackTime = (date?: string | Date | null) => {
      if (this.state.isTRB) {
        if (!date) {
          return 'Bitte einen Rückrufzeitpunkt wählen'
        }
      }
      return ''
    }

    public onChangeCallbackTime = (event: any) => {
      const getCallbackTime = (event: any) => {
        if (event instanceof Date) {
          return event
        }
        if (event) {
          return event.currentTarget.value
        }
        return undefined
      }

      const adviceRequest: AdviceRequest = {
        ...this.state.adviceRequest,
        preferredCallbackTime: getCallbackTime(event),
      }
      this.setState({ adviceRequest, dateError: this.validateCallbackTime(event) })
    }

    public onSaveInternalNote = async () => {
      this.setState({
        savingInternalNote: true,
      })
      const variables = {
        internalNote: {
          adviceId: this.state.adviceRequest.adviceId,
          internalNotes: this.state.adviceRequest.internalNotes,
        },
      }
      await saveInternalNote(variables).catch(error => {
        throw error
      })

      // this is for UX to get some feedback on the button (it is too fast otherwise)
      setTimeout(() => {
        this.setState({
          savingInternalNote: false,
        })
      }, 400)
    }

    // eslint-disable-next-line complexity
    public validateInput = (adviceRequest: AdviceRequest): { [key: string]: string } => {
      let errors: { [key: string]: string } = {}

      if (adviceRequest.product.type === ProductType.Business) {
        if (!adviceRequest.companyName) {
          errors = {
            ...errors,
            companyName: 'Bitte einen Unternehmensnamen angeben.',
          }
        }

        if (adviceRequest.product.id !== B2B_INDIVIDUAL_PRODUCT) {
          if (!adviceRequest.companyAddressStreet) {
            errors = {
              ...errors,
              companyAddressStreet: 'Bitte eine Straße angeben.',
            }
          }
          if (!adviceRequest.companyAddressStreetNr) {
            errors = {
              ...errors,
              companyAddressStreetNr: 'Bitte eine Hausnummer angeben.',
            }
          }
        }

        if (!adviceRequest.companyAddressZip || !/\d{5}/.test(adviceRequest.companyAddressZip)) {
          errors = {
            ...errors,
            companyAddressZip: 'Bitte eine gültige deutsche Postleitzahl angeben.',
          }
        }
        if (!adviceRequest.companyAddressCity) {
          errors = {
            ...errors,
            companyAddressCity: 'Bitte eine Stadt angeben.',
          }
        }

        if (this.state.useBillingCompany) {
          if (!adviceRequest.billingCompanyName) {
            errors = {
              ...errors,
              billingCompanyName: 'Bitte einen Unternehmensnamen angeben.',
            }
          }
          if (!adviceRequest.billingCompanyAddressStreet) {
            errors = {
              ...errors,
              billingCompanyAddressStreet: 'Bitte eine Straße angeben.',
            }
          }
          if (!adviceRequest.billingCompanyAddressStreetNr) {
            errors = {
              ...errors,
              billingCompanyAddressStreetNr: 'Bitte eine Hausnummer angeben.',
            }
          }
          if (!adviceRequest.billingCompanyAddressZip) {
            errors = {
              ...errors,
              billingCompanyAddressZip: 'Bitte eine Postleitzahl angeben.',
            }
          }
          if (!adviceRequest.billingCompanyAddressCity) {
            errors = {
              ...errors,
              billingCompanyAddressCity: 'Bitte eine Stadt angeben.',
            }
          }
        }
      }

      if (!adviceRequest.firstname) {
        errors = {
          ...errors,
          firstname: 'Bitte einen Vornamen angeben.',
        }
      }

      if (!adviceRequest.lastname) {
        errors = {
          ...errors,
          lastname: 'Bitte einen Nachnamen angeben.',
        }
      }

      const insuranceNumber = (adviceRequest.insuranceNumber || '').replace(/\s|_/g, '')
      if (
        (this.state.isTRB || ['devk-trb', 'dahag-trb'].includes(adviceRequest.partner ? adviceRequest.partner.id : '')) &&
        !adviceRequest.insuranceNumberNotAvailabe &&
        (!insuranceNumber || insuranceNumber.length !== 9)
      ) {
        errors = {
          ...errors,
          insuranceNumber: 'Bitte 9-stellige Versicherungsscheinnummer angeben.',
        }
      }

      if (adviceRequest.product.type !== ProductType.Business && (!adviceRequest.zip || !/\d{5}/.test(adviceRequest.zip))) {
        errors = {
          ...errors,
          zip: 'Bitte eine gültige deutsche Postleitzahl angeben.',
        }
      }
      if (!adviceRequest.phone) {
        errors = {
          ...errors,
          phone: 'Bitte eine Telefonnummer angeben.',
        }
      }
      if (!adviceRequest.fieldOfLaw || !adviceRequest.fieldOfLaw.id) {
        errors = {
          ...errors,
          fieldOfLaw: 'Bitte ein Rechtsgebiet auswählen.',
        }
      }

      if (!adviceRequest.email) {
        errors = {
          ...errors,
          email: 'Bitte eine E-Mail Adresse angeben.',
        }
      }
      if (!adviceRequest.agbsAccepted) {
        errors = {
          ...errors,
          agbsAccepted: 'Bitte die AGBs akzeptieren.',
        }
      }

      const { partner } = adviceRequest
      if ((partner && isDevk(partner.id)) || isChannel(this.props.user)) {
        if (adviceRequest.protection && !adviceRequest.protectionAmount) {
          errors = {
            ...errors,
            protectionAmount: 'Bitte den Umfang des Kostenschutzes wählen',
          }
        }
        if (adviceRequest.deductible && !adviceRequest.deductibleAmount) {
          errors = {
            ...errors,
            deductibleAmount: 'Bitte die Höhe der Selbstbeteiligung wählen',
          }
        }
      }

      if (partner && isDevk(partner.id)) {
        if (!adviceRequest.damageNumberDisabled && (!adviceRequest.damageNumber || !/\d{14}/.test(adviceRequest.damageNumber))) {
          errors = {
            ...errors,
            damageNumber: 'Bitte 14-stellige Schadensnummer angeben oder bestätigen, dass keine Schadensnummer vorhanden ist',
          }
        }
      }

      if (this.state.isTRB && !adviceRequest.dataAccepted) {
        errors = {
          ...errors,
          dataAccepted: 'Bitte die Einwilligungserklärung akzeptieren.',
        }
      }

      return errors
    }

    onCancelRequest = () => {
      this.setState({ confirmCancel: true })
    }

    completeCancel = () => {
      this.setState({ loading: true })

      const variables: MutationCancelRequestArgs = {
        cancelRequestInput: {
          adviceId: this.state.adviceRequest.adviceId,
        },
      }
      cancelRequest(variables)
        .then(result => {
          if (result.cancelRequest === this.state.adviceRequest.id) {
            const adviceRequest: AdviceRequest = {
              ...this.state.adviceRequest,
              status: 'cancelled',
            }
            this.setState({ adviceRequest, loading: false, confirmCancel: false })
          } else {
            throw 'unexpected result'
          }
        })
        .catch(error => {
          throw error
        })
    }

    cancelCancel = () => {
      this.setState({ confirmCancel: false })
    }

    onResubmissionClick = async () => {
      const response = await createResubmission(this.state.adviceRequest.adviceId)

      if (response.createResubmission === this.state.adviceRequest.id) {
        await saveAdviceRequest({
          ...this.state.adviceRequest,
          status: 'resubmission',
        })
        this.setState({
          adviceRequest: {
            ...this.state.adviceRequest,
            status: 'resubmission',
          },
        })

        this.redirectToAdvice()
      }
    }

    onAcceptAGBsClick: Procedure = () => {
      this.setState(prevState => ({
        adviceRequest: {
          ...prevState.adviceRequest,
          agbsAccepted: !prevState.adviceRequest.agbsAccepted,
        },
      }))
    }

    onAcceptDataClick: Procedure = () => {
      this.setState(prevState => ({
        adviceRequest: {
          ...prevState.adviceRequest,
          dataAccepted: !prevState.adviceRequest.dataAccepted,
        },
      }))
    }

    onCopyClick = () => {
      this.setState({ isCopied: true })
    }

    onSaveAndClose = async () => {
      let errors: { [key: string]: string } = {}
      const { serviceType, adviceRequest } = this.state

      if (!adviceRequest.id) {
        throw new Error('cannot close adviceRequest without id')
      }

      if (serviceType !== 'Service Call') {
        if (!adviceRequest.fieldOfLaw || !adviceRequest.fieldOfLaw.id) {
          errors = {
            ...errors,
            fieldOfLaw: 'Bitte ein Rechtsgebiet auswählen.',
          }
        }

        if (
          adviceRequest.status !== 'awaiting_callback' &&
          !adviceRequest.insuranceNumberNotAvailabe &&
          !adviceRequest.insuranceNumber
        ) {
          errors = {
            ...errors,
            insuranceNumber: 'Bitte Versicherungsscheinnummer angeben.',
          }
        }

        if (Object.keys(errors).length) {
          this.setState({ errors })
          return
        }
        this.setState({ cancelOpen: true })
      } else {
        await saveAdviceRequest(adviceRequest)
        await setToClosed({
          id: adviceRequest.id,
          lawyerNotes: adviceRequest.lawyerNotes,
          resultNotes: adviceRequest.resultNotes,
          closeReason: 'Service Call',
        })

        this.setState({
          cancelOpen: false,
          loading: false,
        })

        this.redirectToAdvice()
      }
    }

    onSaveAdviceRequest = async () => {
      await saveAdviceRequest({
        ...this.state.adviceRequest,
        status: 'editing',
      })
      this.redirectToAdvice()
    }

    completeSaveAndClose = async () => {
      if (!this.state.loading) {
        const { adviceRequest } = this.state

        if (!adviceRequest.id) {
          throw new Error('cannot close adviceRequest without id')
        }

        if (!adviceRequest.closeReason) {
          this.setState({
            closeErrors: {
              closeReason: 'Bitte wählen Sie einen Grund aus.',
            },
          })
          return
        }
        this.setState({ loading: true })
        try {
          await saveAdviceRequest(adviceRequest)
          await setToClosed({
            id: adviceRequest.id,
            lawyerNotes: adviceRequest.lawyerNotes,
            resultNotes: adviceRequest.resultNotes,
            closeReason: adviceRequest.closeReason,
          })

          this.setState({
            cancelOpen: false,
            loading: false,
          })

          this.redirectToAdvice()
        } catch (e) {
          this.setState({
            loading: false,
          })
          throw e
        }
      }
    }

    cancelSaveAndClose = () => {
      this.setState({ cancelOpen: false })
    }

    handleResponse(response: AdviceRequestQuery) {
      if (response) {
        const { adviceRequest } = response
        if (adviceRequest) {
          if (this.state.isMounted) {
            this.setState({
              adviceRequest: {
                ...emptyAdviceRequest(),
                ...mapResponseToAdviceRequest(adviceRequest),
              },
              useBillingCompany: !!adviceRequest.billingCompany && adviceRequest.product?.type === ProductType.Business,
            })
            if (this.state.adviceRequest.insuranceNumber === '') {
              const adviceRequest = {
                ...this.state.adviceRequest,
                insuranceNumberNotAvailabe: false,
              }
              this.setState({ adviceRequest })
            }
          }
        }
      }
    }

    render() {
      const isPaid = !!this.state.adviceRequest.invoice
      const closeReasons = isAdmin(this.props.user) ? internalClosingReasons : closingReasons

      return (
        <Fragment>
          <ChancellerySearchPage
            adviceRequest={this.state.adviceRequest}
            loading={this.state.initialLoading}
            partners={this.state.partners}
            hidePartnerSelection={this.state.hidePartnerSelection}
            lockFieldOfLaw={this.state.lockFieldOfLaw}
            useBillingCompany={this.state.useBillingCompany}
            lockInsured={this.state.isTRB}
            errors={this.state.errors}
            onFieldChange={this.onFieldChange}
            onPersonChange={this.onPersonChange}
            onFindChancelleries={this.onFindChancelleries}
            onRematch={this.onRematch}
            onCheckboxChange={this.onCheckboxChange}
            onCancelRequestClick={this.onCancelRequest}
            onResubmissionClick={this.onResubmissionClick}
            onSendAGBs={this.onSendAGBs}
            onAcceptAGBsClick={this.onAcceptAGBsClick}
            onAcceptDataClick={this.onAcceptDataClick}
            onSaveInternalNote={this.onSaveInternalNote}
            savingInternalNote={this.state.savingInternalNote}
            user={this.props.user}
            onSaveAndClose={this.onSaveAndClose}
            onSaveAdviceRequest={this.onSaveAdviceRequest}
            serviceType={this.state.serviceType}
            onServiceTypeChange={this.onServiceTypeChange}
          />
          {(this.state.searched || this.state.rematched) && (
            <ChancellerySearchResults
              loading={this.state.matching}
              premiumChancelleries={this.state.matchedChancelleries.premiumChancelleries}
              chancelleries={this.state.matchedChancelleries.chancelleries}
              ownChancelleries={this.state.matchedChancelleries.ownChancelleries}
              centralChancelleries={this.state.matchedChancelleries.centralChancelleries}
              onRedirectClick={this.onRedirectClick}
              onCallbackClick={this.onCallbackClick}
              isLawyer={isLawyer(this.props.user) && !isTRB(this.props.user)}
              adviceRequest={this.state.adviceRequest}
            />
          )}
          <FinishModal
            loading={this.state.completing}
            open={this.state.finishOpen}
            adviceRequest={this.state.adviceRequest}
            phone={this.state.phone}
            onChangeCallbackTime={this.onChangeCallbackTime}
            onCancelClick={this.onCancelClick}
            onCompleteClick={this.onCompleteClick}
            onCopyClick={this.onCopyClick}
            isCopied={this.state.isCopied}
            dateError={this.state.dateError}
            isTRB={isChannel(this.props.user) || isTRB(this.props.user)}
            isAdmin={isAdmin(this.props.user)}
          />
          <ConfirmModal
            open={this.state.confirmCancel}
            question={`Antrag ${this.state.adviceRequest.adviceId} wirklich stornieren?`}
            description={
              isPaid ? (
                <span>
                  Sie sind dabei, den Antrag zu stornieren und eine <strong>Gutschrift der Zahlung zu veranlassen.</strong>
                  <br />
                  <br /> Es wird eine Stornorechnung erstellt und der Kunde erhält diese per E-Mail. <br />
                  <br />
                  <strong>Dies kann nicht rückgängig gemacht werden.</strong>
                  <br /> Soll der Antrag wirklich storniert werden?
                </span>
              ) : (
                'Sie sind dabei, den Antrag zu stornieren. Dies kann nicht rückgängig gemacht werden. Soll der Antrag wirklich storniert werden?'
              )
            }
            onComplete={this.completeCancel}
            onCancel={this.cancelCancel}
            loading={this.state.loading}
          />
          <CancelModal
            open={this.state.cancelOpen}
            reason={this.state.adviceRequest.closeReason || ''}
            onCompleteClick={this.completeSaveAndClose}
            onCancelClick={this.cancelSaveAndClose}
            onReasonChange={this.onFieldChange}
            errors={this.state.closeErrors}
            title={
              this.state.adviceRequest.status === 'awaiting_callback'
                ? 'Bitte geben Sie an warum der Fall geschlossen wird.'
                : undefined
            }
            closeReasons={this.state.adviceRequest.status === 'awaiting_callback' ? closeReasons : undefined}
          />
        </Fragment>
      )
    }
  }
)

export { ChancellerySearchContainer }
