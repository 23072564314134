import { AdviceRequest } from '../interfaces'

type Address = {
  street?: string
  streetNr?: string
  zip: string
  city?: string
}

export function getFullName(adviceRequest: AdviceRequest) {
  return `${adviceRequest.foa} ${adviceRequest.firstname} ${adviceRequest.lastname}`.trim()
}

export function getAddress(adviceRequest: AdviceRequest) {
  return adviceRequest.street || adviceRequest.streetNr
    ? `${adviceRequest.street} ${adviceRequest.streetNr}, ${adviceRequest.zip} ${adviceRequest.city}`.trim()
    : `${adviceRequest.zip} ${adviceRequest.city}`.trim()
}

export const formatName = (foa: string, firstname: string, lastname: string): string => `${foa} ${firstname} ${lastname}`.trim()

export const formatAddress = (address: Address): string =>
  address.street || address.streetNr
    ? `${address.street} ${address.streetNr}, ${address.zip} ${address.city}`.trim()
    : `${address.zip} ${address.city}`.trim()
