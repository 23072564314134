import React, { useState, useEffect } from 'react'

import { Select } from 'common/components-mui'
import { ROLES, ROLE_IDS } from 'common/constants'
import { BiFunction } from 'common/utils'

import { fetchChancelleries as actionsFetchChancelleryGroups, fetchUserListFiltered, MaybeUser } from '../actions'
import { DEFAULT_LIST_STATE } from '../constants'
import { GetChancelleriesQuery, Filter, FilterOperator } from '../interfaces/schemaDefinition'
import { findById } from '../utils'

const FILTER_TRB_MANAGER: Filter = {
  name: 'trbManagerFilter',
  operator: FilterOperator.HasRole,
  value: ROLES[ROLE_IDS.TRBManager].id,
}

type FromPromise<T> = T extends Promise<infer U> ? U : never
type User = Exclude<MaybeUser, null>

const usersChancelleryName: BiFunction<User, FromPromise<ReturnType<typeof actionsFetchChancelleryGroups>>, string> = (
  user,
  chancelleryGroups
) => {
  const chancellery = findById(chancelleryGroups, user.organisation || '')
  return chancellery?.name ?? 'keiner Organisation zugeordnet'
}

type TRBManagerSelectionProps = {
  name: string
  className?: string
}

const fetchTRBManagers = async (setTRBManager: Function): Promise<void> => {
  const userFilter = [FILTER_TRB_MANAGER]
  const trbManagers: Array<User> = (await fetchUserListFiltered(userFilter)).filter((x): x is User => Boolean(x))
  setTRBManager(trbManagers)
}

export const TRBManagerSelection: React.FC<TRBManagerSelectionProps> = ({ name, className }) => {
  const [chancelleries, setChancelleries] = useState<GetChancelleriesQuery['chancelleries']['list']>([])
  const [trbManagers, setTRBManagers] = useState<Array<User>>([])

  useEffect(() => {
    actionsFetchChancelleryGroups(DEFAULT_LIST_STATE).then(setChancelleries)
    fetchTRBManagers(setTRBManagers)
  }, [])

  return (
    <Select disabled={trbManagers.length === 0} name={name} label="TRB-Manager" fullWidth className={className}>
      {trbManagers.map(manager => (
        <option key={manager.id} value={manager.id}>
          {manager.name}, {usersChancelleryName(manager, chancelleries)}
        </option>
      ))}
    </Select>
  )
}
