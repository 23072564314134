import { formatCurrency } from '@grapes-agency/universal'
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import React, { FC } from 'react'

import {
  Badge,
  CenterBox,
  createGridColumnsFrom,
  FilterOperator,
  MuiDataGrid,
  PageInfoline,
  PageLayout,
  useGridData,
} from 'common/components-mui'
import { PRODUCTS_NEW_ENDPOINT } from 'common/constants'

import getProductsNewQuery from '../graphql/getProductsNew.graphql'
import { GetProductsNewQuery } from '../interfaces/schemaDefinition'

const dataObjectName = 'products'

const getStatus = (params: GridCellParams): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const active = params.row.active as Boolean
  return active ? (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  )
}

const columns: Array<GridColDef> = createGridColumnsFrom([
  { field: 'name', headerName: 'Produktname', flex: 0.25 },
  {
    field: 'price',
    headerName: 'Preis',
    flex: 0.1,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    valueFormatter: (params: GridValueFormatterParams) => formatCurrency({ unit: 'EUR' })(params.value as number),
  },
  { field: 'active', headerName: 'Status', renderCell: getStatus },
])

export const ProductsListPageNew: FC = () => {
  const { data, error, actions, tableState } = useGridData<typeof dataObjectName, GetProductsNewQuery>(
    PRODUCTS_NEW_ENDPOINT,
    getProductsNewQuery,
    dataObjectName,
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      filters: [
        {
          name: 'tags',
          operator: FilterOperator.Empty,
          value: '',
        },
      ],
    }
  )

  return (
    <PageLayout error={error} heading="Produktverwaltung Neu" spacing="table">
      <PageInfoline>
        Insgesamt {data?.total ?? 0} {data && data.total > 1 ? 'Einträge' : 'Eintrag'}
      </PageInfoline>
      <MuiDataGrid noBorder actions={actions} tableState={tableState} columns={columns} rows={data?.list ?? []} loading={!data} />
    </PageLayout>
  )
}
