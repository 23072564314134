import React, { FC, ReactNode } from 'react'

import { formatDate, formatTime } from 'common/utils/formatter'

import SVGCallback from '../../images/callback.svg'

import './ConsultationPanelStep.scss'
import styles from './ConsultationPanelStep.module.scss'

export interface ConsultationPanelStepProps {
  callBackTime?: string
  title: string
  content?: React.ReactNode
  text: string
  completed?: Date
  buttons?: Array<ReactNode>
}

export const ConsultationPanelStep: FC<ConsultationPanelStepProps> = ({
  callBackTime,
  title,
  text,
  completed,
  buttons,
  content,
}) => (
  <div className={styles.content}>
    <p className={styles.title}>{title}</p>
    <p style={{ margin: 0 }}>{text}</p>
    {content}

    {callBackTime && (
      <div className={styles.time}>
        <div>
          <SVGCallback width="20" height="20" />
          {`Die gewünschte Rückrufzeit ${callBackTime} Uhr`}
        </div>
      </div>
    )}

    {!!completed && (
      <div className={styles.completed}>{`Kontaktaufnahme erfolgte am ${formatDate(completed)} um ${formatTime(completed)}`}</div>
    )}

    <div className="buttonContainer">{buttons?.map(e => e)}</div>
  </div>
)
