import React, { FC } from 'react'
import { render } from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'

import { init } from 'common/sentry'

import 'common/styles/main.scss'
import { App } from './app.component'
import './fa.init'

smoothscroll.polyfill()

const { env = {} } = (window as any).process || {}

init({
  name: env.SENTRY_PROJECT,
  version: env.APP_VERSION,
  dsn: env.SENTRY_DSN,
  env: env.APP_ENV,
})

const renderApp = (Component: FC) => {
  render(<Component />, document.getElementById('app'))
}

renderApp(App)
