/* eslint-disable fp/no-rest-parameters */
import { TextField, TextFieldProps } from '@mui/material'
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete'
import { useField } from 'formik'
import React, { memo } from 'react'

const MemoizedTextField = memo(TextField)

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
    name: string
    label?: string
    textfieldProps: TextFieldProps
  }
): JSX.Element {
  const { name, textfieldProps, label, ...other } = props
  const [field, meta, helpers] = useField({ name, type: 'text' })

  return (
    <MuiAutocomplete
      {...other}
      {...field}
      onChange={(__, value) => {
        helpers.setValue(value)
      }}
      renderInput={params => (
        <MemoizedTextField
          {...params}
          {...textfieldProps}
          {...(label && { label })}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error && meta.error}
        />
      )}
    />
  )
}
