import React from 'react'

import { EventSourceComponent } from '../interfaces'

import { EffectTriggerComponent, EffectTriggerProps } from './useEffectTrigger'

/**
 * Utility hook that takes an EffectTriggerComponent and props to return an EventSourceComponent that will apply
 * supplied props. Useful if you want to turn an EffectTriggerComponent into a more general EventSourceComponent that
 * satisfy another interfaces (for example to display EffectTriggerComponents in usePopoverMenu which accepts
 * EventSourceComponents).k
 *
 * @param Trigger The EffectTriggerComponent
 * @param props EffectTriggerProps that should be applied to the EffectTriggerComponent except renderTrigger
 * @returns EventSourceComponent An EventSourceComponent
 */
export const useConnectEffectTrigger: <I, R>(
  Trigger: EffectTriggerComponent<I, R>,
  props: Omit<EffectTriggerProps<I, R>, 'renderTrigger'>
) => EventSourceComponent =
  (Trigger, props) =>
  ({ renderTrigger, disabled, ...other }) =>
    <Trigger {...other} {...props} disabled={props.disabled || disabled} renderTrigger={renderTrigger} />
