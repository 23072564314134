import React from 'react'

import LogoACV from 'common/icons/acv_partner.png'
import LogoAlbatros from 'common/icons/albatros_partner.png'
import LogoDEVK from 'common/icons/devk_partner.svg'
import LogoKlugo from 'common/icons/klugo-logo-original.svg'
import LogoMineko from 'common/icons/mineko_partner.png'
import LogoSparda from 'common/icons/sparda_partner.png'

import styles from './PartnerLogos.module.scss'

export const PARTNER_LOGOS: { [id: string]: React.ComponentType<{}> } = {
  klugo: () => <LogoKlugo className={styles.logoKlugo} />,
  'klugo-info': LogoKlugo,
  devk: LogoDEVK,
  'devk-allgemein': LogoDEVK,
  'devk-arbeitgeberportal': LogoDEVK,
  'devk-ausland': LogoDEVK,
  'devk-diesel': LogoDEVK,
  'devk-direktsteuerung': LogoDEVK,
  'devk-direktsteuerung-2': LogoDEVK,
  'devk-dsb': LogoDEVK,
  'devk-dsgvo': LogoDEVK,
  'devk-gewerbe': LogoDEVK,
  'devk-gutschein': LogoDEVK,
  'devk-hotline': LogoDEVK,
  'devk-kfz': LogoDEVK,
  'devk-new': LogoDEVK,
  'devk-r-app': LogoDEVK,
  'devk-rechtskunden': LogoDEVK,
  'devk-steuerungstarif': LogoDEVK,
  'devk-trb': LogoDEVK,
  'devk-trb-sb': LogoDEVK,
  'devk-versicherung': LogoDEVK,
  'devk-vtp': LogoDEVK,
  'devk-werkstaetten': LogoDEVK,
  acv: () => <img src={LogoACV} alt="ACV" />,
  'acv-mandat': () => <img src={LogoACV} alt="ACV Mandat" />,
  albatros: () => <img src={LogoAlbatros} alt="Albatros" />,
  mineko: () => <img src={LogoMineko} alt="Mineko" />,
  sparda: () => <img src={LogoSparda} alt="Sparda" />,
}
