import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/rematch.graphql'
import { RematchMutation, RematchMutationVariables } from '../interfaces/schemaDefinition'

export const rematch = async ({ rematchInput }: RematchMutationVariables): Promise<void> => {
  const variables = { rematchInput }

  await request<RematchMutation, RematchMutationVariables>(ADVICE_REQUESTS_ENDPOINT, query, variables)
}
