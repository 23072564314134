import classnames from 'classnames'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { InputField } from 'common/components/Form'
import SVGNew from 'common/icons/New.svg'
import SVGAnhang from 'common/icons/anhang.svg'
import SVGEdit from 'common/icons/edit-regular.svg'
import SVGCheck from 'common/icons/icn_check.svg'
import { ButtonPrimary } from 'common/ui'
import { isNumberInput, isSomewhatValidCurrencyNumber, Procedure, uploadFiles } from 'common/utils'

import { File, FileInput } from '../../interfaces/schemaDefinition'
import SVGEuro from '../images/euro.svg'
import SVGExit from '../images/exit.svg'

import './SalesModal.scss'

interface SalesModalProps {
  open: boolean
  edit: boolean
  revenue: number | string
  invoiceNumber: string
  onCloseClick: Procedure
  onConfirmClick: (revenue: number, invoiceNumber: string, attachments: Array<FileInput>) => void
}

export const SalesModal: FC<SalesModalProps> = ({
  open,
  edit,
  revenue: originalRevenue,
  invoiceNumber: originalInvoiceNumber,
  onCloseClick,
  onConfirmClick,
}) => {
  const [revenue, setRevenue] = useState<string>(originalRevenue.toString().replace('.', ','))
  const [invoiceNumber, setInvoiceNumber] = useState<string>(originalInvoiceNumber)
  const [attachments, setAttachments] = useState<Array<File>>([])
  const [error, setError] = useState<string>('')

  useEffect(() => {
    setRevenue(originalRevenue.toString().replace('.', ','))
    setInvoiceNumber(originalInvoiceNumber)
  }, [originalInvoiceNumber, originalRevenue])

  const handleRevenueChange = (event: ChangeEvent<HTMLInputElement>) => {
    error && setError('')

    if (event.target.value !== '') {
      if (isNumberInput(event.target.value)) {
        setRevenue(event.target.value)
      }
    } else {
      setRevenue('')
    }
  }

  const handleInvoiceNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInvoiceNumber(event.target.value)
  }

  const handleAddFilesChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files !== null) {
      const files = await uploadFiles([...event.currentTarget.files])
      setAttachments([...attachments, ...files])
    }
  }

  const handleOnConfirmClick = () => {
    const convertedRevenue = revenue ? Math.round(parseFloat(revenue.toString().replace(',', '.')) * 100) / 100 : 0

    if (!revenue) {
      setError('Bitte tragen Sie einen Umsatz ein.')
      return
    }

    if (!isSomewhatValidCurrencyNumber(revenue)) {
      setError('Bitte einen gültigen Wert als Umsatz eingeben.')
      return
    }

    onConfirmClick(convertedRevenue, invoiceNumber, attachments)
  }

  const reset = () => {
    setRevenue('')
    setInvoiceNumber('')
    setAttachments([])
    setError('')
  }

  return (
    <Modal isOpen={open} toggle={onCloseClick} onClosed={reset} className="new-modal medium-size">
      <ModalBody>
        <div className="sales-modal__header">
          {edit ? <SVGEdit height="20" width="20" /> : <SVGNew height="20" width="20" />}
          <h3 className={classnames('h3 small', 'sales-modal__header__title')}>
            {edit ? 'Umsatz bearbeiten' : 'Umsatz hinzufügen'}
          </h3>
        </div>
        <div className="btn-cancel close-modal" onClick={onCloseClick}>
          <SVGExit height="14" width="14" />
        </div>
        <FormGroup className="sales-modal__body__revenue-input">
          <Label>
            Betrag (netto)
            <SVGEuro width="18" height="18" className="align-middle m-r-10 m-l-5" />
            <Input
              value={revenue}
              type="text"
              name="sales-details-revenue"
              id="sales-details-revenue"
              onChange={handleRevenueChange}
              className="d-inline"
              placeholder="0,00"
              autoComplete="off"
            />
          </Label>
          {error && <FormFeedback>{error}</FormFeedback>}
        </FormGroup>
        <InputField
          label="Rechnungsnummer (optional)"
          id="sales-details-add-invoice-number"
          name="sales-invoice-number"
          value={invoiceNumber}
          onChange={handleInvoiceNumberChange}
          autoComplete="off"
          inputClassName="sales-modal__body__reason-input__input"
          formClassName="sales-modal__body__reason-input"
        />
        <p>{attachments.length === 1 ? '1 Anhang (optional)' : <>{attachments.length} Anhänge (optional)</>}</p>
        <Label
          for="modalFileInput"
          className={classnames('btn btn-primary m-b-10 input-file', 'sales-modal__body__file-input', 'sales-modal__btn')}
          onClick={e => e.stopPropagation()}
        >
          <Input
            type="file"
            name="modalFiles"
            id="modalFileInput"
            data-multiple-caption="{count} files selected"
            multiple
            onChange={handleAddFilesChange}
          />
          <SVGAnhang width="20" height="20" />
          Anhang hinzufügen
        </Label>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary onClick={handleOnConfirmClick} iconLeft={<SVGCheck height="20" width="20" />} className="sales-modal__btn">
          Umsatz bestätigen
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  )
}
