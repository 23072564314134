import { Box, Button } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { TextField } from 'common/components-mui/formik'
import { Procedure } from 'common/utils'
import { generatePassword } from 'common/utils/passwordGen'

import { FormUser } from '../interfaces/formSchema'

type PasswordSelectionProps = {
  name: keyof FormUser
  repeatName: keyof FormUser
  className?: string
}

export const PasswordSelection: FunctionComponent<PasswordSelectionProps> = ({ name, repeatName, className }) => {
  const [hidePassword, setHidePassword] = useState(true)
  const {
    values: { [name]: password },
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<FormUser>()
  const onGeneratePassword: Procedure = () => {
    const generatedPassword = generatePassword(10, 1)
    setFieldValue(name, generatedPassword)
    setFieldTouched(repeatName, false)
    setFieldValue(repeatName, generatedPassword)
    setHidePassword(false)
  }

  useEffect(() => {
    if (password === '') {
      setHidePassword(true)
    }
  }, [password])

  return (
    <div className={className}>
      <Box display="flex" justifyContent="space-between">
        <Box maxWidth="70%" width="100%">
          <TextField
            name={name}
            label="Passwort"
            type={hidePassword ? 'password' : 'text'}
            placeholder="Passwort"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" maxWidth="30%" width="100%" maxHeight="55px">
          <Button onClick={onGeneratePassword} variant="contained" color="primary">
            Generieren
          </Button>
        </Box>
      </Box>

      <Box display="flex" mt="20px">
        <Box maxWidth="70%" width="100%">
          <TextField
            name={repeatName}
            label="Wiederholung"
            type={hidePassword ? 'password' : 'text'}
            fullWidth
            placeholder="Passwort wiederholung"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>
    </div>
  )
}
