export enum Status {
  AWAITING_CALL = 'awaiting_call',
  AWAITING_CALLBACK = 'awaiting_callback',
  RESUBMISSION = 'resubmission',
  EDITING = 'editing',
  CANCELED = 'cancelled',
  AT_CHANCELLERY = 'at_chancellery',
  POTENTIAL = 'potential',
  MANDATE = 'mandate',
  CLOSED = 'closed',
  COMPLETE = 'complete',
  NEW = 'new',
}
