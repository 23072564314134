import React, { ChangeEventHandler, FC } from 'react'
import { Col, Row } from 'reactstrap'

import { TextAreaField } from 'common/components/Form'
import { TextAreaStaticField } from 'common/components/StaticField'
import { StaticRow } from 'common/components/StaticRow'

interface TextAreaRowProps {
  title: string
  value?: string
  label: string
  image: any
  name?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  id: string
}

export const TextAreaRow: FC<TextAreaRowProps> = ({ id, title, label, value, image, onChange, name = '', disabled = false }) => (
  <StaticRow>
    <Col md={12}>
      {image}
      <h3 className="h3 small">{title}</h3>
    </Col>
    <Row className="row-margin">
      <Col md={12}>
        {name !== undefined && value !== undefined && onChange !== undefined ? (
          <TextAreaField id={id} label="" value={value} name={name} onChange={onChange} disabled={disabled} />
        ) : (
          <TextAreaStaticField label={label} value={value || '-'} />
        )}
      </Col>
    </Row>
  </StaticRow>
)
