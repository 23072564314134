export const addOptionalPropIf =
  <Added extends { [key in keyof Data]?: Data[key] }, Data>(condition: boolean) =>
  (toAdd: Added) =>
  (data: Data): typeof condition extends true ? Data & Added : Data =>
    condition
      ? {
          ...data,
          ...toAdd,
        }
      : data
