import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { FC } from 'react'
import { FormGroup, InputGroup, InputGroupAddon, Input } from 'reactstrap'

import './SearchField.scss'

interface SearchFieldProps {
  value: string
  onChange: (value: string) => void
  onSearchClick: React.MouseEventHandler<HTMLElement>
  className?: string
}

export const SearchField: FC<SearchFieldProps> = ({ value, onSearchClick, onChange, className }) => (
  <FormGroup className={classnames('search-field', className)}>
    <InputGroup>
      <Input
        className="search-field__input"
        value={value}
        placeholder="Suchbegriff"
        type="text"
        name="searchTerm"
        id="searchTerm"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
      />
      <InputGroupAddon addonType="append" className="pointer" onClick={onSearchClick}>
        <FontAwesomeIcon icon="search" size="lg" />
      </InputGroupAddon>
    </InputGroup>
  </FormGroup>
)
