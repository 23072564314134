import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { ChangeEventHandler, FC, Fragment } from 'react'
import { Col, Row } from 'reactstrap'

import { BooleanStaticField, DateStaticField, NumberStaticField, StaticField } from 'common/components/StaticField'
import { StaticRow } from 'common/components/StaticRow'

import { InputTextField } from '../InputTextField/InputTextField'

import './StaticFieldRow.scss'

interface GenericStaticFieldProps {
  label: string
  value: any
}

interface FieldConfig {
  label: string
  value: any
  data?: Array<{ name: string; value: string }>
  component?: React.ComponentType<GenericStaticFieldProps>
  icon?: React.ReactNode
}

interface StaticFieldRowProps {
  image: React.ReactNode
  title: string
  fields: Array<FieldConfig>
  editable?: boolean
  changeRowData?: ChangeEventHandler<HTMLInputElement>
  onCancelClick?: () => void
  onSaveClick?: () => void
  editMode?: boolean
  onEditMode?: () => void
}

const StaticFieldTypeMap: { [key: string]: React.ComponentType<GenericStaticFieldProps> } = {
  string: StaticField,
  number: NumberStaticField,
  boolean: BooleanStaticField,
}

const getStaticFieldComponent = (value: any) => {
  if (value instanceof Date) {
    return DateStaticField
  }

  return StaticFieldTypeMap[typeof value] || StaticField
}

export const StaticFieldRow: FC<StaticFieldRowProps> = ({
  title,
  image,
  fields,
  editable,
  editMode,
  onEditMode,
  onSaveClick,
  onCancelClick,
  changeRowData,
}) => (
  <StaticRow>
    <Col md={12}>
      {image}
      <h3 className="h3 small">{title}</h3>
      {editable && (
        <Fragment>
          <span onClick={onEditMode}>
            <FontAwesomeIcon
              icon="pencil-alt"
              size="lg"
              className={classnames('m-l-20 row-hover', { 'edit-mode-icon': editMode })}
            />
          </span>
          {editMode && (
            <Fragment>
              <div className="m-l-15 vertical-line" />
              <span onClick={onSaveClick}>
                <FontAwesomeIcon icon={['far', 'check-circle']} size="lg" className="m-l-20 row-hover" />
              </span>
              <span onClick={onCancelClick}>
                <FontAwesomeIcon icon={['far', 'times-circle']} size="lg" className="m-l-20 row-hover" />
              </span>
            </Fragment>
          )}
        </Fragment>
      )}
    </Col>
    <Row className="row-margin">
      {fields.map(key => {
        const GenericStaticField = key.component || getStaticFieldComponent(key.value)
        return (
          <Fragment key={key.label}>
            {key.icon && <div className="icon-static-field">{key.icon}</div>}
            {editMode && key.data ? (
              <InputTextField data={key.data} onChange={changeRowData} />
            ) : (
              <Col md={3} key={key.label}>
                <GenericStaticField label={key.label} value={key.value || '-'} />
              </Col>
            )}
          </Fragment>
        )
      })}
    </Row>
  </StaticRow>
)
