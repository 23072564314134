import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { FunctionComponent } from 'react'

type ConfirmDialogProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  content?: string | JSX.Element
  confirmAction?: () => Promise<void> | void | string
  confirmText?: string
  abortText?: string
}

/*
 * ConfirmDialog can be used with a function to execute on confirm or with a string
 * which is a link target to go to on confirm.
 */
export const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
  isOpen,
  title,
  onClose,
  content,
  confirmAction,
  confirmText,
  abortText,
}) => (
  <Dialog open={isOpen}>
    <DialogTitle>{title}</DialogTitle>
    {content && <DialogContent dividers>{content}</DialogContent>}
    <DialogActions>
      <Button onClick={onClose}>{abortText || 'Abbrechen'}</Button>
      {typeof confirmAction === 'function' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onClose()
            confirmAction()
          }}
        >
          {confirmText || 'Bestätigen'}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          href={confirmAction || '#'}
          onClick={() => {
            onClose()
          }}
        >
          {confirmText || 'Bestätigen'}
        </Button>
      )}
    </DialogActions>
  </Dialog>
)
