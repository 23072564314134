import React, { ComponentType } from 'react'

import { ButtonProps } from 'common/ui/Button'

import { EventTriggerRenderer } from '../interfaces'

/**
 * This a utility function that take a Button Component and returns an EventTriggerRenderer that can be used for the
 * renderTrigger prop of any EventSourceComponent.
 * @param Button any Component that implements ButtonProps
 * @param props optional Button Props that should be applied to Button. Useful for `{type: 'submit'}` for example
 * @returns EventTriggerRenderer Function that can applied as is to renderTrigger of any EventSourceComponent
 */
export const createTriggerButton: (Button: ComponentType<ButtonProps>, props?: Partial<ButtonProps>) => EventTriggerRenderer =
  (Button, props = {}) =>
  ({ trigger, disabled, loading, id, style }) => {
    const onClick = (event: React.SyntheticEvent) => {
      if (props.type === 'submit') {
        event.preventDefault()
      }
      return trigger(event)
    }

    return <Button {...props} style={style} onClick={onClick} disabled={disabled} id={id} loading={loading} />
  }
