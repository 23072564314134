import React from 'react'

import { Partner } from 'common/api/types'
import { Tag, Tags } from 'common/components'
import { ACTIVE, CONTACT_TYPES, DATE_OPERATORS, STATUS, NUMBER_OPERATORS } from 'common/constants'
import { ContactType, FilterOperator, Filters, Filter, FilterValue } from 'common/interfaces'
import { dateToLabel, formatDate } from 'common/utils'

interface FilterTagsProps {
  filters: Filters
  partners?: Array<Partner>
  onTagRemove: (filter: Filter) => void
}

const getPartnerNameById = (partnerId: FilterValue, partners: Array<Partner>) => {
  const partner = partners.find(p => p.id === partnerId)
  return partner ? partner.name : ''
}

const mapCreatedAt = (filter: Filter) => {
  switch (filter.operator) {
    case 'at':
      return `${DATE_OPERATORS.at}: ${dateToLabel(new Date(filter.value as Date), formatDate)}`
    case 'after':
      return `${DATE_OPERATORS.from}: ${dateToLabel(new Date(filter.value as Date))}`
    case 'before':
      return `${DATE_OPERATORS.to}: ${dateToLabel(new Date(filter.value as Date))}`
    case 'equals':
      return `${DATE_OPERATORS.at}: ${dateToLabel(new Date(filter.value as Date), formatDate)}`
    case 'gte':
      return `${DATE_OPERATORS.from}: ${dateToLabel(new Date(filter.value as Date))}`
    case 'lte':
      return `${DATE_OPERATORS.to}: ${dateToLabel(new Date(filter.value as Date))}`
    default:
      return ''
  }
}

const mapNumberOperator = (operator: FilterOperator) => {
  switch (operator) {
    case 'equals':
      return NUMBER_OPERATORS.equals
    case 'lt':
      return NUMBER_OPERATORS.lt
    case 'lte':
      return NUMBER_OPERATORS.lte
    case 'gt':
      return NUMBER_OPERATORS.gt
    case 'gte':
      return NUMBER_OPERATORS.gte
    case 'notEmpty':
      return NUMBER_OPERATORS.notEmpty
    case 'empty':
      return NUMBER_OPERATORS.empty
    default:
      return operator
  }
}

const filterLabels: any = {
  chancelleryName: (filter: Filter) => `Kanzlei: ${filter.value}`,
  chancelleryAddress: (filter: Filter) => `Adresse: ${filter.value}`,
  globalZipAreas: (filter: Filter) => `Zentraler Postleitzahlbereich: ${filter.value}`,
  zipAreas: (filter: Filter) => `Postleitzahlbereich: ${filter.value}`,
  globalFieldOfLaw: (filter: Filter) => `Zentrales Rechtsgebiet: ${filter.value}`,
  fieldOfLaw: (filter: Filter) => `Rechtsgebiet: ${filter.value}`,
  status: (filter: Filter) =>
    filter.value !== 'active' && filter.value !== 'inactive'
      ? `Status: ${STATUS[filter.value as string]}`
      : `Status: ${ACTIVE[filter.value as string]}`,
  personName: (filter: Filter) => `Name: ${filter.value}`,
  personAddress: (filter: Filter) => `Addresse: ${filter.value}`,
  email: (filter: Filter) => `E-Mail: ${filter.value}`,
  partnerId: (filter: Filter, partners: Array<Partner>) => `Partner: ${getPartnerNameById(filter.value, partners)}`,
  phone: (filter: Filter) => `Telefon: ${filter.value}`,
  contactType: (filter: Filter) => `Typ: ${CONTACT_TYPES[filter.value as ContactType]}`,
  createdAt: (filter: Filter) => mapCreatedAt(filter),
  preferredCallbackTime: (filter: Filter) => mapCreatedAt(filter),
  reminderDate: (filter: Filter) => mapCreatedAt(filter),
  adviceId: (filter: Filter) => `ID: ${filter.value}`,
  mandateId: (filter: Filter) => `Akten Zeichen: ${filter.value}`,
  mandateName: (filter: Filter) => `Akten Name: ${filter.value}`,
  revenue: (filter: Filter) => `Gesamtumsatz ${mapNumberOperator(filter.operator)} ${filter.value}`,
}

const createOnTagRemove = (func: (filter: Filter) => void, filter: Filter) => () => func(filter)

export const FilterTags = ({ filters, partners, onTagRemove }: FilterTagsProps) => (
  <Tags>
    {filters.map((filter, index) => (
      <Tag key={index} onRemove={createOnTagRemove(onTagRemove, filter)}>
        {filterLabels[filter.name](filter, partners)}
      </Tag>
    ))}
  </Tags>
)
