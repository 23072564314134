import React, { Fragment, FC } from 'react'
import { Col, Form, FormGroup, Row } from 'reactstrap'

import { StaticField } from 'common/components'
import { StaticRow } from 'common/components/StaticRow'
import { LoggedInUser } from 'common/user-context'
import { Chancellery } from 'packages-mui/chancelleries/interfaces/schemaDefinition'

import SVGChancellery from '../images/kanzlei.svg'
import SVGPersonalData from '../images/personendaten.svg'

interface UserProfileProps {
  user: LoggedInUser
  chancellery: Chancellery
}

export const UserProfileView: FC<UserProfileProps> = ({ user, chancellery }) => (
  <Fragment>
    <Row className="row-margin">
      <Col md={12} className="text-center">
        <h3 className="h3 small">Ihr Account</h3>
      </Col>
    </Row>
    <Form className="Nutzer Konto form">
      <FormGroup>
        <StaticRow>
          <Col md={12}>
            <SVGPersonalData width="18" height="20" className="icons-label m-r-10" />
            <h3 className="h3 small">Hinterlegte Personendaten</h3>
          </Col>
          <Row className="row-margin">
            <Col md={3}>
              <StaticField label="Name" value={user.name || '-'} />
            </Col>
            <Col md={3}>
              <StaticField label="E-Mail" value={user.email || '-'} />
            </Col>
          </Row>
        </StaticRow>
      </FormGroup>
      <FormGroup>
        <StaticRow>
          <Col md={12}>
            <SVGChancellery width="18" height="20" className="icons-label m-r-10" />
            <h3 className="h3 small">Hinterlegte Kanzleidaten</h3>
          </Col>
          <Row className="row-margin">
            <Col md={3}>
              <StaticField label="Name" value={chancellery.name || '-'} />
            </Col>
            <Col md={3}>
              <StaticField label="Telefon" value={chancellery.phone || '-'} />
            </Col>
          </Row>
        </StaticRow>
      </FormGroup>
    </Form>
  </Fragment>
)
