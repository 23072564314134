import { ensureEndsWith } from './string'

interface GraphQLError {
  message: string
  locations: Array<{ line: number; column: number }>
  path: Array<string>
}

interface GraphQLResponse<T> {
  data?: T
  errors?: Array<GraphQLError>
  status: number
}

async function getResult<T>(response: Response): Promise<GraphQLResponse<T> | string> {
  const contentType = response.headers.get('Content-Type')
  if (contentType && contentType.startsWith('application/json')) {
    return response.json()
  }
  return response.text()
}

const API_BASE = process.env.API_BASE || '/api'

export async function request<T, V extends { [name: string]: any } = {}>(endpoint: string, query: string, variables?: V) {
  const token = localStorage.getItem('token')

  const url = `${ensureEndsWith('/', API_BASE)}${endpoint}`

  const body = JSON.stringify({
    query,
    variables: variables || undefined,
  })

  const response = await fetch(url, {
    body,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(token ? { 'x-access-token': token } : {}),
    },
  })

  const result = await getResult<T>(response)

  if (response.ok && typeof result !== 'string' && !result.errors && result.data) {
    return result.data
  }
  const errorResult = typeof result === 'string' ? { errors: [{ message: result }] } : result

  if (errorResult.errors![0].message) {
    throw new Error(errorResult.errors![0].message)
  } else {
    throw new Error(`GraphQL Error: ${response.status}`)
  }
}
