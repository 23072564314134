import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { CreateSalesDetailsExportQuery, QueryCreateSalesDetailsExportArgs } from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/createSalesDetailsExport.graphql'

export const createSalesDetailsExport = async (variables: QueryCreateSalesDetailsExportArgs) => {
  const response = await request<CreateSalesDetailsExportQuery>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.createSalesDetailsExport
}
