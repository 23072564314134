import React, { useState, FC, ChangeEvent } from 'react'
import { Form, FormFeedback, FormGroup, Input } from 'reactstrap'

import { ButtonPrimary } from 'common/ui'
import { isCurrencyNumber } from 'common/utils'

import { proposalAccepted } from '../../../actions'
import { AccountDisplay } from '../../../components/AccountDisplay'
import SVGSave from '../../../components/images/save.svg'
import { ProposalAcceptedMutation } from '../../../interfaces/schemaDefinition'

type ProposalAccount = ProposalAcceptedMutation['proposalAccepted']

interface ProposalAmountFormProps {
  adviceRequestId: string
  onSuccess: (account: ProposalAccount) => void
}

export const ProposalAmountForm: FC<ProposalAmountFormProps> = ({ onSuccess, adviceRequestId }) => {
  const [error, setError] = useState<string>('')
  const [amount, setAmount] = useState('')
  const [account, setAccount] = useState<ProposalAccount>()
  const [loading, setLoading] = useState(false)

  const onChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setAmount(event.currentTarget.value)
  }

  const onCompleteClick = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (!isCurrencyNumber(amount)) {
      setError('Bitte einen gültigen positiven Euro Betrag angeben')
      return
    }

    setLoading(true)
    const account = await proposalAccepted(adviceRequestId, parseFloat(amount.replace('.', '')), window.location.origin)
    setAccount(account)
  }

  const onSuccessClick = () => {
    if (account) {
      onSuccess(account)
    }
  }

  if (account) {
    return (
      <>
        <FormGroup>
          <h3 className="h3 small">
            Angebot erfolgreich gespeichert.
            {account.iban && <>Bitte verwenden folgenden Kontodaten beim Erstellen der Rechnung.</>}
          </h3>
        </FormGroup>
        <AccountDisplay account={account} />
        <ButtonPrimary onClick={onSuccessClick}>Zurück</ButtonPrimary>
      </>
    )
  }

  return (
    <>
      <Form className="form">
        <FormGroup>
          <h3 className="h3 small">Bitte geben Sie den Gesamtbetrag des versendeten Angebots an.</h3>
        </FormGroup>
        <FormGroup>
          <Input value={amount} name="amount" onChange={onChangeAmount} className="m-b-10" />
          {!!error && <FormFeedback>{error}</FormFeedback>}
        </FormGroup>
        <ButtonPrimary onClick={onCompleteClick} loading={loading} iconLeft={<SVGSave width="20" height="15" />}>
          Angebot speichern
        </ButtonPrimary>
      </Form>
    </>
  )
}
