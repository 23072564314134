import AddIcon from '@mui/icons-material/AddCircleOutline'
import { IconButton, IconButtonProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FunctionComponent } from 'react'

const useLocationsStyles = makeStyles(theme => ({
  addButton: {
    marginBottom: theme.spacing(2),
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    '&:hover, &:focus': {
      backgroundColor: 'white',
      boxShadow: theme.shadows[3],
      outline: 'none',
    },
  },
}))

export const AddButton: FunctionComponent<IconButtonProps> = props => {
  const { addButton } = useLocationsStyles()
  return (
    <IconButton aria-label="Standort hinzufügen" color="primary" {...props} className={addButton} size="large">
      <AddIcon />
    </IconButton>
  )
}
