export function isInteger(value: string) {
  return /^\d+$/.test(value)
}

export const isSomewhatValidCurrencyNumber: (value: string | number | undefined) => boolean = value => {
  if (value === undefined) {
    return false
  }
  return /^[+-]?(?:0|[1-9]\d*)(?:(?:,|\.)\d{1,2})?$/.test(value.toString())
}

export const isCurrencyNumber: (value: string | number | undefined) => boolean = value => {
  if (value === undefined) {
    return false
  }

  return /^\d{1,3}(?:\.?\d{3})*(?:,\d{2})?$/.test(value.toString())
}

export const isNumberInput: (value: string) => boolean = value => /^[+-]?[\d.,]*$/.test(value)
