import React, { useContext } from 'react'
import { NavLink, useHistory, NavLinkProps } from 'react-router-dom'

import { ConfirmNavLink } from 'common/components/Confirmables'
import { Roles } from 'common/constants'
import { UserContext } from 'common/user-context'
import { containsSome } from 'common/utils'
import { startTRB } from 'packages/chancellery-search'

const isTRB = containsSome([Roles.TRB])
const isTRBManager = containsSome([Roles.TRBManager])

export const CreateAdviceNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(function CreateAdviceNavLinkInternal(
  // Necessary for usage with MaterialUI
  // eslint-disable-next-line fp/no-rest-parameters
  { to, children, ...rest },
  ref
) {
  const { user } = useContext(UserContext)
  const history = useHistory()
  if (!user) {
    return null
  }

  const isTRBOrTRBManager = isTRB(user.roles) || isTRBManager(user.roles)
  return (
    <>
      {isTRBOrTRBManager ? (
        <ConfirmNavLink
          ref={ref}
          onComplete={startTRB(history)}
          question="Telefonische Rechtsberatung starten?"
          description="Bei Bestätigung erzeugen Sie eine Beratungs-ID und können die Beratung starten."
          to="/404" // unused
          {...rest}
        >
          {children}
        </ConfirmNavLink>
      ) : (
        <NavLink to={to} {...rest}>
          {children}
        </NavLink>
      )}
    </>
  )
})
