import React, { Component, ComponentType } from 'react'

import { Popover, PopoverBody } from '..'
import { Filter, FilterInputProps, Sort, Column, FilterOperator } from '../../interfaces'

import { Th } from './Th'

interface SortFilterThProps extends FilterInputProps {
  id: Column
  sort: Sort
  onSort: () => void
  FilterInput: ComponentType<FilterInputProps>
  operator?: FilterOperator
}

interface SortFilterThState {
  popoverOpen: boolean
}

export class SortFilterTh extends Component<SortFilterThProps, SortFilterThState> {
  state = {
    popoverOpen: false,
  }

  onPopoverToggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    })
  }

  onFilterSubmit = (filter: Filter) => {
    this.setState({
      popoverOpen: false,
    })

    this.props.onFilterSubmit(filter)
  }

  render() {
    const { id, onSort, children, name, suggestions, placeholder, FilterInput, sort, operator } = this.props
    const { popoverOpen } = this.state
    return (
      <>
        <Th id={id} onSort={onSort} popoverOpen={popoverOpen} onToggleFilterPopover={this.onPopoverToggle} sort={sort}>
          {children}
        </Th>
        <Popover isOpen={popoverOpen} placement="bottom" target={id} toggle={this.onPopoverToggle}>
          <PopoverBody className="filter-popover">
            <FilterInput
              operator={operator}
              onFilterSubmit={this.onFilterSubmit}
              suggestions={suggestions}
              name={name}
              placeholder={placeholder}
            />
          </PopoverBody>
        </Popover>
      </>
    )
  }
}
