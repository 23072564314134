import { FormUser } from '../interfaces/formSchema'
import { UserQuery } from '../interfaces/schemaDefinition'

// eslint-disable-next-line complexity
export const mapResponseToUser: (response: UserQuery['user']) => FormUser = response => {
  const excludedRightsIds = response.excludedRights?.map(right => right.id)
  const rights = response.rights.map(right => right.id)

  return {
    name: response.name,
    email: response.email,
    deleted: response.deleted,
    active: response.active,
    id: response.id,
    roles: response.roles,
    groups: response.groups,
    channel: response.channel || '',
    rights: rights.filter(rightId => !excludedRightsIds?.includes(rightId)),
    organisation: response.organisation || '',
    organisationType: response.organisationType || '',
    parentId: response.parentId || '',
    registeredAt: response.registeredAt,

    // internal values
    password: '',
    passwordRepeat: '',
    sendRegisterEmail: undefined,
  }
}
