import classnames from 'classnames'
import React, { useState, useEffect, FC } from 'react'

interface NotificationCountProps {
  getCount: () => Promise<number>
  className?: string
}

export const NotificationCount: FC<NotificationCountProps> = ({ getCount, className }) => {
  const [count, setCount] = useState<undefined | number>(undefined)

  useEffect(() => {
    const doGetCount = async () => {
      const count = await getCount()
      setCount(count)
    }

    doGetCount()
  }, [getCount])

  if (count === undefined) {
    return null
  }

  return (
    <div
      className={classnames(
        'tab-notification',
        { 'tab-notification-low': count < 100 },
        { 'tab-notification-high': count >= 100 },
        className
      )}
    >
      {count}
    </div>
  )
}
