import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/redirect.graphql'

export const redirect = async (adviceRequestId: string, chancelleryId: string) => {
  const response = await request<string>(ADVICE_REQUESTS_ENDPOINT, query, {
    adviceRequestId,
    chancelleryId,
  })
  return response
}
