import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'

import { PaginationProps } from '../../interfaces'

import './Toolbar.scss'

interface ToolbarProps {
  pagination?: PaginationProps
  title?: string
  onNew?: () => void
  onExport?: () => void
  onEdit?: () => void
  onDelete?: () => void
  onNext?: () => void
  onPrev?: () => void
}

function lowerLimit(pagination: PaginationProps) {
  return (pagination.page - 1) * pagination.pageSize + 1
}

function upperLimit(pagination: PaginationProps) {
  return Math.min(pagination.page * pagination.pageSize, pagination.total)
}

function hasNextPage(pagination: PaginationProps) {
  return pagination.page <= Math.floor(pagination.total / pagination.pageSize)
}

function hasPrevPage(pagination: PaginationProps) {
  return pagination.page > 1
}

export const Toolbar = ({ pagination, title, onNext, onPrev }: ToolbarProps) => (
  <div className="toolbar">
    <div className="people-options clearfix">
      {pagination && (
        <Fragment>
          <div className="btn-group pull-left title">{title}</div>
          <div className="btn-group pull-right people-pager">
            {!!onPrev && (
              <button disabled={!hasPrevPage(pagination)} type="button" className="btn prev" onClick={onPrev}>
                <FontAwesomeIcon icon="chevron-left" />
              </button>
            )}
            {!!onNext && (
              <button disabled={!hasNextPage(pagination)} type="button" className="btn next" onClick={onNext}>
                <FontAwesomeIcon icon="chevron-right" />
              </button>
            )}
          </div>
          <span className="people-count pull-right">
            Zeige{' '}
            <strong>
              {lowerLimit(pagination)}-{upperLimit(pagination)}
            </strong>{' '}
            von <strong>{pagination.total}</strong> {pagination.label}
          </span>
        </Fragment>
      )}
    </div>
  </div>
)
