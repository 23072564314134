import classnames from 'classnames'
import React, { FC } from 'react'

import { ButtonRounded } from 'common/ui'

import SVGOutlineFilter from './image/outline-filter_list.svg'

import './AssignFilter.scss'

interface AssignFilterProps {
  active?: boolean
  onClick: React.MouseEventHandler
}

export const AssignFilter: FC<AssignFilterProps> = ({ active, onClick }) => (
  <ButtonRounded
    title="Nur meine Anträge anzeigen"
    className={classnames('filter-button', 'm-r-10', { 'filter-button--active': active })}
    onClick={onClick}
    iconLeft={<SVGOutlineFilter width={20} height={20} />}
  />
)
