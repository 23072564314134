import { Effect } from '@grapes-agency/universal'

import { saveAdviceRequest as saveAction } from '../../actions'
import { AdviceRequest } from '../../interfaces/AdviceRequest'
import { convertToSaveAdviceRequestInput } from '../../utils'

interface SaveInput {
  adviceRequest: AdviceRequest
}

export const saveAdviceRequest: Effect<SaveInput, void> = async ({ adviceRequest }) => {
  await saveAction(convertToSaveAdviceRequestInput(adviceRequest))
}
