import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography, Alert } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Formik, Form, FormikHelpers } from 'formik'
import { pipe } from 'ramda'
import React, { useEffect, FunctionComponent, useState } from 'react'
import uriTemplate from 'uri-templates'

import {
  AddButton,
  PageLayout,
  TextField,
  Checkbox,
  LoadingMessage,
  AbortButton,
  SaveButton,
  DeleteWithConfirmation,
  FormActionBox,
} from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants/endpoints'
import { useParams, useRedirect } from 'common/hooks'
import { request, addOptionalPropIf } from 'common/utils'

import {
  mapChancelleryAPIDataToForm,
  GetChancelleryAPIData,
  ChancelleryLocationsTable,
  GlobalHolidays,
  OrderVolumeSetting,
  FallbackIssueWarnings,
  SimpleUserTable,
} from '../components'
import saveChancelleryMutation from '../graphql/saveChancellery.graphql'
import {
  chancelleryInitialValues,
  chancelleryValidationSchema,
  ChancelleryFormValues,
  LocalOrderVolume,
} from '../interfaces/formSchemas'
import { ChancelleryInput, SaveChancelleryMutation } from '../interfaces/schemaDefinition'
import { getNewOrderVolumeEntry } from '../utils'

const TO_OVERVIEW = '/chancelleries'
const TO_EDIT_VIEW = uriTemplate('/chancelleries/edit/{id}')

const handleSubmit =
  (triggerRedirect: (to?: string) => void) =>
  (values: ChancelleryFormValues, helpers: FormikHelpers<ChancelleryFormValues>): void => {
    const {
      active,
      deleted,
      name,
      contactPersonSalutation,
      contactPersonGivenName,
      contactPersonFamilyName,
      phone,
      email,
      orderVolumes,
      chancelleryId,
      ustId,
      iban,
      eConsultId,
      secupay,
      powerBI1,
      powerBI2,
      callbacks,
    } = values
    const input: ChancelleryInput = {
      name,
      active,
      deleted,
      contactPerson: {
        foa: contactPersonSalutation,
        firstname: contactPersonGivenName,
        lastname: contactPersonFamilyName,
      },
      phone,
      email,
      orderVolumes: orderVolumes
        .filter((entry): entry is LocalOrderVolume => Boolean(entry.fieldOfLaw?.id))
        .map(entry => ({
          weeklyMin: entry.weeklyMin !== '' ? parseInt(entry.weeklyMin, 10) : undefined,
          weeklyMax: entry.weeklyMax !== '' ? parseInt(entry.weeklyMax, 10) : undefined,
          fieldOfLawId: entry.fieldOfLaw.id,
        })),
      eConsult: {
        active: eConsultId.length > 0,
        customerId: eConsultId,
      },
      secupay: {
        active: secupay.length > 0,
        contractId: secupay,
      },
      callbacks,
    }
    const withPowerBI = addOptionalPropIf<{ powerBI: Array<string> }, ChancelleryInput>(
      powerBI1.length > 0 && powerBI2.length > 0
    )({
      powerBI: [powerBI1, powerBI2],
    })
    const redirectToEdit = !chancelleryId
    const withId = addOptionalPropIf<{ id: string }, ChancelleryInput>(chancelleryId.length > 0)({ id: chancelleryId })
    const withTaxId = addOptionalPropIf<{ taxId: string }, ChancelleryInput>(ustId.length > 0)({ taxId: ustId })
    const withIban = addOptionalPropIf<{ iban: string }, ChancelleryInput>(iban.length > 0)({ iban })

    const withOptionalValues = pipe(withId, withPowerBI, withTaxId, withIban)
    const wholeInput: ChancelleryInput = withOptionalValues(input)

    request<SaveChancelleryMutation>(CHANCELLERIES_ENDPOINT, saveChancelleryMutation, { chancellery: wholeInput })
      .then(({ saveChancellery }) => {
        if (saveChancellery) {
          helpers.setValues(mapChancelleryAPIDataToForm(saveChancellery))
        }
        return { saveChancellery }
      })
      .then(({ saveChancellery }) =>
        triggerRedirect(redirectToEdit && saveChancellery ? TO_EDIT_VIEW.fillFromObject({ id: saveChancellery.id }) : undefined)
      )
      .catch(() => undefined) // TODO: Do we need to do anything real here?
    helpers.setSubmitting(false)
  }

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(3),
  },
  formButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
      marginLeft: theme.spacing(2),
    },
    marginBottom: theme.spacing(3),
  },
  infoIcon: {
    marginRight: theme.spacing(2),
  },
}))

type ChancelleryFormPageParams = {
  id?: string
}

const FormReset = ({ id, resetForm }: { id?: string; resetForm: () => void }): JSX.Element | null => {
  useEffect(() => {
    if (!id) {
      resetForm()
    }
  }, [id, resetForm])

  return null
}

export const ChancelleryFormPage: FunctionComponent = () => {
  const { id } = useParams<ChancelleryFormPageParams>()
  const [isLoading, setIsLoading] = useState(false)
  const redirect = useRedirect()
  const [closePending, setClosePending] = useState(false)
  const classes = useStyles()
  return (
    <Formik
      validationSchema={chancelleryValidationSchema}
      initialValues={chancelleryInitialValues}
      onSubmit={handleSubmit((to?: string) => (closePending ? redirect(TO_OVERVIEW) : to ? redirect(to) : undefined))}
    >
      {/* eslint-disable-next-line complexity */}
      {({ isSubmitting, values, setFieldValue, submitForm, resetForm }) => {
        const disabled = isLoading || values.deleted
        return (
          <PageLayout heading={values.deleted ? 'Kanzleidetails' : id ? 'Kanzlei bearbeiten' : 'Kanzlei anlegen'}>
            <FormReset id={id} resetForm={resetForm} />
            <FallbackIssueWarnings />
            <Form>
              <GetChancelleryAPIData id={id} setLoading={setIsLoading} />
              <LoadingMessage isLoading={isLoading} />
              {values.deleted && (
                <Box mb={3}>
                  <Alert severity="warning">
                    Diese Kanzlei wurde als „ausgetreten“ markiert. Änderungen sind nicht mehr möglich.
                  </Alert>
                </Box>
              )}
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                <Box width={{ xs: '100%', sm: '45%', lg: '33%' }}>
                  <Checkbox name="active" label="Aktiv" disabled={disabled} className={classes.field} />

                  <TextField name="name" label="Name Kanzlei" disabled={disabled} fullWidth className={classes.field} />
                  <TextField
                    name="contactPersonSalutation"
                    label="Ansprechpartner Anrede"
                    placeholder='z.B. "Frau"'
                    disabled={disabled}
                    className={classes.field}
                    fullWidth
                  />
                  <TextField
                    name="contactPersonFamilyName"
                    label="Ansprechpartner Name"
                    disabled={disabled}
                    className={classes.field}
                    fullWidth
                  />
                  <TextField
                    name="contactPersonGivenName"
                    label="Ansprechpartner Vorname(n)"
                    disabled={disabled}
                    className={classes.field}
                    fullWidth
                  />
                  <TextField name="phone" label="Telefon" disabled={disabled} className={classes.field} fullWidth />
                  <TextField name="email" label="Email-Adresse" disabled={disabled} className={classes.field} fullWidth />
                  {values.chancelleryId && (
                    <TextField name="chancelleryId" label="Kanzlei-ID" disabled className={classes.field} fullWidth />
                  )}
                  <TextField name="ustId" label="Umsatzsteuer-ID" disabled={disabled} className={classes.field} fullWidth />
                  <TextField name="iban" label="IBAN" disabled={disabled} className={classes.field} fullWidth />
                  <TextField name="eConsultId" label="E-Consult ID" disabled={disabled} className={classes.field} fullWidth />
                  <TextField name="secupay" label="Secupay-Nummer" disabled={disabled} className={classes.field} fullWidth />
                  <Checkbox
                    name="callbacks"
                    label="Rückrufe aktiviert / Macht Rückrufe selbst"
                    disabled={disabled}
                    className={classes.field}
                  />
                </Box>
                <Box width={{ xs: '100%', sm: '45%' }}>
                  <fieldset>
                    <legend>Auftragsvolumen</legend>
                    <Box mt={3}>
                      {values.orderVolumes.map((_, index) => (
                        <OrderVolumeSetting key={index} index={index} name="orderVolumes" />
                      ))}
                      <Box mb={3}>
                        <AddButton
                          aria-label="Auftragsvolumen hinzufügen"
                          disabled={disabled}
                          onClick={() => setFieldValue('orderVolumes', values.orderVolumes.concat(getNewOrderVolumeEntry()))}
                        />
                      </Box>
                    </Box>
                  </fieldset>
                </Box>
              </Box>
              {values.chancelleryId ? (
                <>
                  <ChancelleryLocationsTable chancelleryId={values.chancelleryId} disabled={disabled} />
                  <Box display="flex" flexWrap="wrap" width={{ xs: '100%', sm: '45%', lg: '33%' }}>
                    <Box width="100%">
                      <GlobalHolidays title="Für alle Standorte geltende Urlaubszeiten" chancelleryId={values.chancelleryId} />
                    </Box>
                  </Box>
                </>
              ) : (
                <Box display="flex" alignItems="center" mb={3}>
                  <InfoIcon color="primary" className={classes.infoIcon} />
                  <Typography>Um Standorte hinzuzufügen, müsssen Sie die Kanzlei erst mit „Speichern“ anlegen.</Typography>
                </Box>
              )}
              <SimpleUserTable title="Verknüpfte Anwälte" users={values.users} />

              <fieldset>
                <legend>Power BI</legend>
                <Box display="flex" flexWrap="wrap" width={{ xs: '100%', sm: '50%', lg: '33%' }}>
                  <TextField
                    name="powerBI1"
                    label="Link für Bearbeitungsstand"
                    disabled={disabled}
                    className={classes.field}
                    fullWidth
                  />
                  <TextField
                    name="powerBI2"
                    label="Link für Umwandlungsquote"
                    disabled={disabled}
                    className={classes.field}
                    fullWidth
                  />
                </Box>
              </fieldset>
              <FormActionBox>
                {id && !values.deleted && (
                  <DeleteWithConfirmation
                    actionButtonText="Kooperation beenden"
                    onConfirm={async () => {
                      setFieldValue('deleted', true)
                      setClosePending(true)
                      await submitForm()
                    }}
                  >
                    Diese Aktion ist nicht umkehrbar. Kooperation mit Kanzlei wird permanent beendet.
                  </DeleteWithConfirmation>
                )}
                <AbortButton
                  onClick={() => {
                    redirect(TO_OVERVIEW)
                  }}
                  disabled={isSubmitting}
                >
                  {values.deleted ? 'Schließen' : null}
                </AbortButton>
                {values.deleted || (
                  <>
                    <SaveButton disabled={isSubmitting} />
                    <SaveButton onClick={() => setClosePending(true)} disabled={isSubmitting}>
                      Speichern und Schließen
                    </SaveButton>
                  </>
                )}
              </FormActionBox>
            </Form>
          </PageLayout>
        )
      }}
    </Formik>
  )
}
