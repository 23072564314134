// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JPOdEA3gWFd4soBSQYGv{padding:.5rem 0}.tRrwiAXWUi0ygpVpMgdr{padding:1rem 0}", "",{"version":3,"sources":["webpack://./src/packages/lawyers/coverage-requests/components/ContactForms/CoverageForm.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,cAAA","sourcesContent":[".formRow {\n  padding: 0.5rem 0;\n}\n\n.fields {\n  padding: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRow": "JPOdEA3gWFd4soBSQYGv",
	"fields": "tRrwiAXWUi0ygpVpMgdr"
};
export default ___CSS_LOADER_EXPORT___;
