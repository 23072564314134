import { ADVICE_REQUESTS_ENDPOINT, STATUS_AT_CHANCELLERY } from 'common/constants'
import { Filter } from 'common/interfaces/Filter'
import { LoggedInUser } from 'common/user-context'
import { request } from 'common/utils'
import { ChancelleryLocationsQuery } from 'packages/lawyers/interfaces/schemaDefinition'
import { getChancelleryFilter } from 'packages/lawyers/utils'

import query from '../graphql/adviceTotalCount.graphql'
import { AdviceTotalCountQuery, FilterOperator } from '../interfaces'

export const getOpenRequestsCount =
  (
    user: LoggedInUser,
    chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list'],
    filters: Array<Filter> = []
  ) =>
  async (): Promise<number> => {
    const response: AdviceTotalCountQuery = await request<AdviceTotalCountQuery>(ADVICE_REQUESTS_ENDPOINT, query, {
      filters: [
        getChancelleryFilter(user, chancelleryLocations),
        ...filters,
        {
          name: 'status',
          operator: FilterOperator.Equals,
          value: STATUS_AT_CHANCELLERY,
        },
      ],
    })

    return response.adviceRequestsCount.total
  }
