import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils'

import query from '../graphql/getUserRights.graphql'
import { RightsQuery } from '../interfaces/schemaDefinition'

export const fetchUserRights: () => Promise<RightsQuery['rights']> = async () => {
  const response = await request<RightsQuery>(USER_AUTH_ENDPOINT, query)
  return response.rights
}
