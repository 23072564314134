import React, { Component, Fragment } from 'react'
import { Input } from 'reactstrap'

import { DateTimePicker } from 'common/components/DateTimePicker'
import SVGCheck from 'common/icons/Check.svg'
import { ButtonClear } from 'common/ui/Button'

import { DATE_OPERATOR_ENTRIES } from '../../constants'
import { FilterInputProps, DateOperator, FilterOperator } from '../../interfaces'

import './DatePickerInput.scss'

type DatePickerInputProps = FilterInputProps

interface DatePickerInputState {
  date: Date
  operator: DateOperator
}

export class DatePickerInput extends Component<DatePickerInputProps, DatePickerInputState> {
  state = {
    date: new Date(),
    operator: 'at' as DateOperator,
  }

  onDateChange = (date: Date) => this.setState({ date })

  onOperatorChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.name) {
      throw new Error(`event.target.name not set on: ${event.currentTarget}`)
    } else {
      this.setState({
        operator: event.currentTarget.value as DateOperator,
      })
    }
  }

  onDateSubmit = () => {
    const { name, onFilterSubmit } = this.props
    const { date } = this.state
    const filter = {
      name,
      operator: this.mapOperator() as FilterOperator,
      value: date.toISOString(),
    }
    onFilterSubmit(filter)
  }

  mapOperator = () => {
    switch (this.state.operator) {
      case 'at':
        return 'at'
      case 'from':
        return 'after'
      case 'to':
        return 'before'
      default:
        return ''
    }
  }

  render() {
    return (
      <Fragment>
        <span className="date-picker-input__select-wrapper">
          <Input onChange={this.onOperatorChange} value={this.state.operator} type="select" name="dateFilter" id="dateFilter">
            {DATE_OPERATOR_ENTRIES.map(item => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Input>
        </span>
        <DateTimePicker onChange={this.onDateChange} value={this.state.date} />
        <ButtonClear iconLeft={<SVGCheck width="16" height="16" />} onClick={this.onDateSubmit} />
      </Fragment>
    )
  }
}
