import classnames from 'classnames'
import React, { FC } from 'react'
import { CustomInput, FormGroup } from 'reactstrap'

import './RadioField.scss'

interface RadioOption {
  label: string
  value: string
}

interface RadioFieldProps {
  id: string
  name: string
  options: Array<RadioOption>
  onChange: React.ChangeEventHandler<HTMLInputElement>
  value: string
  disabled?: boolean
  vertical?: boolean
}

const createOnChange =
  (onChange: React.ChangeEventHandler<HTMLInputElement>, value: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...event,
      target: {
        ...event.target,
        name: event.target.name,
        value,
      },
      currentTarget: {
        ...event.currentTarget,
        name: event.currentTarget.name,
        value,
      },
    })

export const RadioField: FC<RadioFieldProps> = ({ id, name, value, onChange, disabled = false, vertical = false, options }) => (
  <FormGroup>
    <div className={classnames('radio-field', { 'radio-field--vertical': vertical })}>
      {options.map((option, index) => (
        <CustomInput
          className={classnames({ 'radio-field--checked': value === option.value })}
          key={option.value}
          onChange={createOnChange(onChange, option.value)}
          name={name}
          type="radio"
          id={`${id}-${index}`}
          label={option.label}
          checked={value === option.value}
          disabled={disabled}
        />
      ))}
    </div>
  </FormGroup>
)
