import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEventHandler } from 'react'
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import SVGKlugoLogo from 'common/icons/klugo-logo-white.svg'
import { ButtonPrimary } from 'common/ui'

import SVGConfirmation from './images/icon_bestätigung.svg'

interface PasswordSetViewProps {
  onChange: ChangeEventHandler<HTMLInputElement>
  password: string
  confirmPassword: string
  errors?: any
  onSetPassword: React.MouseEventHandler<HTMLButtonElement>
  success: boolean
  onRedirectSuccess: () => void
}

export const PasswordSetView = (props: PasswordSetViewProps) => {
  const { onChange, password, confirmPassword, errors, onSetPassword, success, onRedirectSuccess } = props

  if (success) {
    return (
      <div className="panel signin">
        <div className="head-shape" />
        <div className="body-shape" />
        <div className="login-header">
          <SVGKlugoLogo className="login-logo" style={{ fill: '#0a1343' }} />
          <h4>Passwortwiederherstellung</h4>
        </div>
        <div className="login-body">
          <div className="text-center">
            <SVGConfirmation height="80" width="80" />
          </div>
          <div className="text-center">
            <span className="success-span">Ihr Passwort wurde erfolgreich abgeändert.</span>
          </div>
          <div className="form-group m-t-30">
            <ButtonPrimary block onClick={onRedirectSuccess}>
              Zum Login
            </ButtonPrimary>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="panel signin">
      <div className="head-shape" />
      <div className="body-shape" />
      <div className="login-header">
        <SVGKlugoLogo className="login-logo" />
        <h4>Passwortwiederherstellung</h4>
      </div>
      <div className="login-body">
        <div className="body-title">Bitte geben Sie nachfolgend Ihr neues Passwort ein.</div>
        <form>
          <div className="form-group m-t-15">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon="lock" />
                </InputGroupText>
              </InputGroupAddon>
              <Input name="password" value={password} type="password" onChange={onChange} placeholder="Neues Passwort" />
            </InputGroup>
          </div>
          <div className="form-group m-t-15">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon="lock" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="confirmPassword"
                value={confirmPassword}
                type="password"
                onChange={onChange}
                placeholder="Passwort bestätigen"
              />
            </InputGroup>
            {!!errors && <FormFeedback>{errors.invalidPassword}</FormFeedback>}
          </div>
          <div className="form-group m-t-50">
            <ButtonPrimary block onClick={onSetPassword}>
              Bestätigen
            </ButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  )
}
