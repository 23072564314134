import { History } from 'history'
import { compose, defaults, get } from 'lodash/fp'
import React, { Component, ComponentType } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Subtract } from 'utility-types'

import { addFilter, nextPage, prevPage, removeFilter, setSort } from 'common/actions'
import { Column, SortDirection, Filter, ListState } from 'common/interfaces'
import { pickListState, pushSearch, request } from 'common/utils'
import { mapListStateToSearch } from 'packages/lawyers/actions/mapListStateToSearch'
import { mapSearchToListState } from 'packages/lawyers/actions/mapSearchToListState'

export const DEFAULT_PAGE_SIZE = 50
export const DEFAULT_PAGE = 1
export const DEFAULT_SORT_DIRECTION = 'desc'
export const DEFAULT_SORT_BY = 'createdAt'

export const DEFAULT_LIST_STATE = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  sort: {
    sortBy: DEFAULT_SORT_BY as Column,
    sortDirection: DEFAULT_SORT_DIRECTION as SortDirection,
  },
  filters: [],
}

export interface ListProps<T> extends ListState {
  list: Array<T>
  total: number
  loading: boolean
  onReload: () => void
  onSort: (sortBy: Column) => void
  onPrev: () => void
  onNext: () => void
  onFilterChange: (filter: Filter<Column>) => void
  onFilterRemove: (filter: Filter<Column>) => void
}

type ListInternalProps = {
  assignFilter?: string
} & RouteComponentProps<{}>

type ListContainerState<T> = ListState & {
  list: Array<T>
  loading: boolean
  total: number
}

interface ListConfig<P extends {}> {
  endpoint: string
  query: string
  responseKey: string
  searchPrefix?: string
  queryMapper?: (listState: ListState, props: P) => ListState
  pageSize?: number
}

export function withList<T, P extends ListProps<T>>({
  endpoint,
  query,
  responseKey,
  searchPrefix = '',
  queryMapper = listState => listState,
  pageSize = DEFAULT_PAGE_SIZE,
}: ListConfig<Readonly<Subtract<P, ListProps<T>> & ListInternalProps> & Readonly<{ children?: React.ReactNode }>>): (
  Wrapped: ComponentType<P>
) => ComponentType<Subtract<P, ListProps<T>>> {
  const getSearch: (props: Partial<ListInternalProps>) => string = get('location.search')
  const withDefaultListState = defaults({ ...DEFAULT_LIST_STATE, pageSize })
  const updateStateFromSearch = compose(withDefaultListState, mapSearchToListState(searchPrefix), getSearch)

  const initialState = () => ({
    ...DEFAULT_LIST_STATE,
    pageSize: pageSize || DEFAULT_PAGE_SIZE,
    total: 0,
    loading: false,
    list: [],
  })

  const updateSearch = (history: History) => (listState: ListState, searchPrefix: string) =>
    pushSearch(history)(
      mapListStateToSearch(
        {
          sort: listState.sort,
          filters: listState.filters,
          page: listState.page,
          pageSize: listState.pageSize,
        },
        searchPrefix
      )
    )
  type OuterProps = Subtract<P, ListProps<T>>
  // since typescript 3.5.1 this does not compile without any
  // any input/research on how to get rid of these any is very welcome
  const result: any = (Wrapped: ComponentType<any>) => {
    const container = class ListContainer extends Component<OuterProps & ListInternalProps, ListContainerState<T>> {
      state = initialState()

      componentDidMount() {
        this.fetchList(this.props)
      }

      componentDidUpdate(prevProps: ListInternalProps) {
        this.fetchList(this.props, prevProps)
      }

      fetchList = (props: ListInternalProps, prevProps: Partial<ListInternalProps> = {}) => {
        const searchChanged = getSearch(props) !== getSearch(prevProps)
        if (searchChanged || props.assignFilter !== prevProps.assignFilter) {
          this.setState({ loading: true })
          this.setState(updateStateFromSearch(props), () => {
            this.fetchAndStoreList(pickListState(this.state))
          })
        }
      }

      fetchAndStoreList = async (queryVariables: ListState) => {
        try {
          const response = await request<any>(endpoint, query, queryMapper(queryVariables, this.props))
          const listState = response[responseKey]
          this.setState({
            ...listState,
            loading: false,
          })
        } catch (err) {
          // eslint-disable-next-line
          console.error(err)
          this.setState(initialState())
        }
      }

      onReload = () => {
        this.setState({ loading: true })
        this.fetchAndStoreList(this.state)
      }

      onSort = (sortBy: Column) => {
        if (sortBy) {
          this.setState(
            prevState => setSort(prevState, sortBy),
            () => {
              updateSearch(this.props.history)(this.state, searchPrefix)
            }
          )
        }
      }

      onFilterChange = (filter: Filter<Column>) => {
        this.setState(
          prevState => addFilter(filter, prevState),
          () => {
            updateSearch(this.props.history)(this.state, searchPrefix)
          }
        )
      }

      onNext = () => {
        this.setState(
          prevState => nextPage(prevState),
          () => {
            updateSearch(this.props.history)(this.state, searchPrefix)
          }
        )
      }

      onPrev = () => {
        if (this.state.page > 1) {
          this.setState(
            prevState => prevPage(prevState),
            () => {
              updateSearch(this.props.history)(this.state, searchPrefix)
            }
          )
        }
      }

      onFilterRemove = (filter: Filter<Column>) => {
        this.setState(
          prevState => removeFilter(filter, prevState.filters),
          () => {
            updateSearch(this.props.history)(this.state, searchPrefix)
          }
        )
      }

      render() {
        const { list, loading, sort, page, pageSize, total, filters } = this.state
        return (
          <Wrapped
            {...this.props}
            list={list}
            sort={sort}
            page={page}
            pageSize={pageSize}
            total={total}
            filters={filters}
            loading={loading}
            onReload={this.onReload}
            onSort={this.onSort}
            onPrev={this.onPrev}
            onNext={this.onNext}
            onFilterChange={this.onFilterChange}
            onFilterRemove={this.onFilterRemove}
          />
        )
      }
    }
    return withRouter(container)
  }
  return result
}
