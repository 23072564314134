import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/assignableUsers.graphql'
import { AssignableUsersQuery } from '../interfaces/schemaDefinition'

export const fetchAssignableUsers = async () => {
  const response = await request<AssignableUsersQuery>(ADVICE_REQUESTS_ENDPOINT, query)
  return response.assignableUsers
}
