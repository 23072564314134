import React, { ComponentType, FC } from 'react'
import { Subtract } from 'utility-types'

import { UserConsumer } from './UserContext'
import { UserContextProps } from './interfaces'

export function withUser<P extends UserContextProps>(component: ComponentType<P>): ComponentType<Subtract<P, UserContextProps>> {
  // since typescript 3.5.1 this does not compile without any
  // any input/research on how to get rid of these any is very welcome
  const Component: any = component
  const WithUserComponent: FC<Subtract<P, UserContextProps>> = props => (
    <UserConsumer>{(value: UserContextProps) => <Component {...value} {...props} />}</UserConsumer>
  )
  return WithUserComponent
}
