import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/adviceRequest.graphql'
import { AdviceRequestQuery, AdviceRequestQueryVariables } from '../interfaces/schemaDefinition'

export const getAdviceRequest = async (adviceId: string): Promise<AdviceRequestQuery> => {
  const variables = {
    adviceId,
  }
  return request<AdviceRequestQuery, AdviceRequestQueryVariables>(ADVICE_REQUESTS_ENDPOINT, query, variables)
}
