import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { CloseInput, SetToClosedMutation, SetToClosedMutationVariables } from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/setToClosed.graphql'

export const setToClosed = async (closeInput: CloseInput): Promise<string> => {
  const variables = {
    closeInput: {
      id: closeInput.id,
      resultNotes: closeInput.resultNotes,
      lawyerNotes: closeInput.lawyerNotes,
      closeReason: closeInput.closeReason,
    },
  }
  const response = await request<SetToClosedMutation, SetToClosedMutationVariables>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.setToClosed
}
