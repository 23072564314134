import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import {
  SaveAdviceRequestInput,
  SaveAdviceRequestMutation,
  SaveAdviceRequestMutationVariables,
} from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/saveAdviceRequest.graphql'

const partnerFields = [
  'damageNumber',
  'deductible',
  'deductibleAmount',
  'protection',
  'protectionAmount',
  'disputeAmount',
  'internalPartnerNotes',
]

export const saveAdviceRequest = async (adviceRequest: SaveAdviceRequestInput) => {
  const variables: SaveAdviceRequestMutationVariables = {
    adviceRequest: {
      id: adviceRequest.id || '',
      fieldOfLawId: adviceRequest.fieldOfLawId,
      language: adviceRequest.language,
      person: adviceRequest.person,
      company: adviceRequest.company
        ? { ...adviceRequest.company, name: adviceRequest.company?.name ? adviceRequest.company?.name : '' }
        : null,
      billingCompany: adviceRequest.billingCompany
        ? {
            ...adviceRequest.billingCompany,
            name: adviceRequest.billingCompany?.name ? adviceRequest.billingCompany?.name : '',
          }
        : null,
      description: adviceRequest.description,
      internalNotes: adviceRequest.internalNotes,
      externalNotes: adviceRequest.externalNotes,
      lawyerNotes: adviceRequest.lawyerNotes,
      resultNotes: adviceRequest.resultNotes,
      insuranceNumber: adviceRequest.insuranceNumber,
      legalProtectionInsurer: adviceRequest.legalProtectionInsurer,
      caseData: [...partnerFields]
        .filter(field => (adviceRequest as any)[field] !== undefined)
        .map(field => ({
          name: field,
          value: (adviceRequest as any)[field],
        })),
      mandateName: adviceRequest.mandateName,
    },
  }

  const response = await request<SaveAdviceRequestMutation, SaveAdviceRequestMutationVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )
  return response
}
