import React, { Component, RefObject } from 'react'
import { RouteComponentProps } from 'react-router'

import { fetchPartners, Partner } from 'common/api/getPartners'
import { Spinner } from 'common/components'
import { URLs } from 'common/constants'
import { UserContextProps, withUser } from 'common/user-context'
import { Procedure } from 'common/utils'
import { LawyerListView } from 'packages/lawyers/components/LawyerListView'

import { getChancelleryLocations, subscribeAdviceRequestAdded, fetchAssignableUsers } from '../actions'
import { ChancelleryLocationsQuery, AdviceRequestEntry } from '../interfaces'

type LawyerListProps = RouteComponentProps<{}> & UserContextProps

interface LawyerListState {
  isMounted: boolean
  activeTab: string
  partners: Array<Partner>
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  assignableUsers: Array<{ id: string; name: string }>
  loading: boolean
  addedAdviceRequests: Array<AdviceRequestEntry>
  hasPremium: boolean
}

export const LawyerContainer = withUser(
  class LawyerContainer extends Component<LawyerListProps, LawyerListState> {
    node: RefObject<HTMLDivElement>

    closeSubscription: Procedure

    state = {
      isMounted: true,
      activeTab: '1',
      partners: [],
      chancelleryLocations: [],
      assignableUsers: [],
      loading: true,
      addedAdviceRequests: [],
      hasPremium: false,
    }

    async componentDidMount() {
      const partners = await this.fetchAndStorePartners()
      this.setActiveTab()
      // tslint:disable-next-line:no-object-mutation
      this.node = React.createRef()
      const [assignableUsers, chancelleryLocations] = await Promise.all([fetchAssignableUsers(), getChancelleryLocations()])
      const { user } = this.props
      if (user) {
        this.setState({
          chancelleryLocations,
          assignableUsers,
          partners,
          loading: false,
        })
      }

      if (this.props.location.state) {
        this.scrollToCallbackRequests()
      }

      this.closeSubscription = await subscribeAdviceRequestAdded({ filters: [] }, ({ adviceRequestAdded }) => {
        this.setState(prevState => ({
          ...prevState,
          addedAdviceRequests: [
            ...prevState.addedAdviceRequests,
            {
              ...adviceRequestAdded,
              added: true,
            },
          ],
        }))
      })
    }

    componentDidUpdate(prevProps: LawyerListProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        this.setActiveTab()
      }
    }

    componentWillUnmount() {
      this.setState({ isMounted: false })
    }

    fetchAndStorePartners = async (): Promise<Array<Partner>> => {
      const partners = await fetchPartners()
      this.setState({ partners })
      return partners
    }

    setActiveTab() {
      switch (this.props.location.pathname) {
        case '/chancellery/advice-requests':
          this.setState({ activeTab: '1' })
          break
        case URLs.chancellery.adviceRequests.openRequests:
          this.setState({ activeTab: '1' })
          break
        case URLs.chancellery.adviceRequests.potentialMandates:
          this.setState({ activeTab: '2' })
          break
        case URLs.chancellery.adviceRequests.closedRequests:
          this.setState({ activeTab: '3' })
          break
        case URLs.chancellery.adviceRequests.mandates:
          this.setState({ activeTab: '4' })
          break
        case URLs.chancellery.adviceRequests.completeMandates:
          this.setState({ activeTab: '5' })
          break
        default:
          // eslint-disable-next-line
          console.error(`unkown pathname: ${this.props.location.pathname}`)
          break
      }
    }

    onToggle = (activeTab: string) => () => {
      if (this.state.activeTab !== activeTab) {
        this.setState({ activeTab })
        switch (activeTab) {
          case '1':
            this.props.history.push(URLs.chancellery.adviceRequests.openRequests)
            break
          case '2':
            this.props.history.push(URLs.chancellery.adviceRequests.potentialMandates)
            break
          case '3':
            this.props.history.push(URLs.chancellery.adviceRequests.closedRequests)
            break
          case '4':
            this.props.history.push(URLs.chancellery.adviceRequests.mandates)
            break
          case '5':
            this.props.history.push(URLs.chancellery.adviceRequests.completeMandates)
            break
          default:
            this.props.history.push(URLs.chancellery.adviceRequests.openRequests)
            break
        }
      }
    }

    onRowClick = (rowId: string) => () => {
      if (rowId) {
        this.props.history.push(`/advice-request/detail?adviceId=${rowId}`)
      }
    }

    scrollToCallbackRequests = () => {
      if (this.node.current) {
        window.scrollTo(0, this.node.current.offsetTop)
      }
    }

    onAddedClick = () => {
      this.setState({
        addedAdviceRequests: [],
      })
    }

    render() {
      const { loading, activeTab, partners, assignableUsers, chancelleryLocations, addedAdviceRequests } = this.state

      return (
        <Spinner condition={loading} center>
          <LawyerListView
            activeTab={activeTab}
            onToggle={this.onToggle}
            onRowClick={this.onRowClick}
            partners={partners}
            chancelleryLocations={chancelleryLocations}
            assignableUsers={assignableUsers}
            node={this.node}
            addedAdviceRequests={addedAdviceRequests}
            onAddedClick={this.onAddedClick}
            hasPremium={this.state.hasPremium}
          />
        </Spinner>
      )
    }
  }
)
