import { Box, Button, TextField, Alert, Autocomplete } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { FunctionComponent, useState } from 'react'
import { Redirect, useParams } from 'react-router'
import useSWR from 'swr'
import * as Yup from 'yup'

import {
  PageLayout,
  FormikGroupInput as GroupInput,
  Checkbox,
  Select,
  LoadingMessage,
  AbortButton,
  SaveButton,
  DeleteWithConfirmation,
  FormActionBox,
} from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT, FIELDS_OF_LAW_ENTRIES, PARTNERS_ENDPOINT, PRODUCTS_ENDPOINT } from 'common/constants'
import { request, fetcher, addOptionalPropIf } from 'common/utils'

import { FallbackIssueWarnings, GetConfigurationAPIData, mapMatchingConfigAPIDataToForm } from '../components'
import partnersQuery from '../graphql/getPartners.graphql'
import productsQuery from '../graphql/getProducts.graphql'
import saveMatchingConfigMutation from '../graphql/saveMatchingConfig.graphql'
import { ConfigurationFormValues, configurationInitialValues, configurationValidationSchema } from '../interfaces/formSchemas'
import {
  ConfigWeight,
  GetPartnersQuery,
  GetProductsQuery,
  MatchingConfigInput,
  SaveMatchingConfigMutation,
  SortDirection,
} from '../interfaces/schemaDefinition'
import { calculatePriority, sortProducts, weightMap } from '../utils'

type ConfigurationFormPageParams = {
  id?: string
  lid?: string
}

type PartnersApiResponse = {
  data: GetPartnersQuery
}

type ProductsResponse = {
  data: GetProductsQuery
}

const toApiInputFormat = (values: ConfigurationFormValues): MatchingConfigInput => {
  const { locationId, zipCodes, fieldsOfLaw, partners, products, weight, priority, configurationId, active, deleted } = values

  const baseInput = {
    zipAreas: zipCodes,
    active,
    deleted,
    fieldOfLawIds: {
      included: fieldsOfLaw,
    },
    productIds: {
      included: products.map(p => p.id),
    },
    partnerIds: {
      included: partners.map(p => p.id),
    },
    weight,
    priority,
    chancelleryLocationId: locationId,
    fallback: false,
  }

  const withId = addOptionalPropIf<{ id: string }, MatchingConfigInput>(configurationId.length > 0)({ id: configurationId })

  return withId(baseInput)
}

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(3),
  },
}))

export const ConfigurationFormPage: FunctionComponent = () => {
  const { id, lid: chancelleryLocationId } = useParams<ConfigurationFormPageParams>()
  const classes = useStyles()
  const [closePending, setClosePending] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [configurationDataLoading, setConfigurationDataLoading] = useState(false)
  const { data: productsResponse, error: productsError } = useSWR([PRODUCTS_ENDPOINT, productsQuery], (e: string, q: string) =>
    fetcher<ProductsResponse>(e, q, { page: 1, pageSize: 100 })
  )
  const { data: partnersResponse, error: partnersError } = useSWR([PARTNERS_ENDPOINT, partnersQuery], (e: string, q: string) =>
    fetcher<PartnersApiResponse>(e, q, { page: 1, pageSize: 100, sort: { sortBy: 'name', sortDirection: SortDirection.Asc } })
  )

  const isLoading = (!partnersResponse && !partnersError) || (!productsResponse && !productsError) || configurationDataLoading

  const handleSubmit =
    (triggerRedirect: () => void) =>
    (values: ConfigurationFormValues, helpers: FormikHelpers<ConfigurationFormValues>): void => {
      const prioBaselineConfig = {
        folMax: FIELDS_OF_LAW_ENTRIES.length,
        productsMax: productsResponse?.data.products.list.length ?? 0,
        partnersMax: partnersResponse?.data.partners.list.length ?? 0,
      }
      const input: MatchingConfigInput = toApiInputFormat({ ...values, priority: calculatePriority(prioBaselineConfig, values) })
      request<SaveMatchingConfigMutation>(CHANCELLERIES_ENDPOINT, saveMatchingConfigMutation, {
        matchingConfig: input,
      })
        .then(({ saveMatchingConfig }) => {
          if (saveMatchingConfig) {
            helpers.setValues(mapMatchingConfigAPIDataToForm(saveMatchingConfig))
          }
        })
        .then(() => triggerRedirect())
        .catch(() => undefined)
        .then(() => {
          helpers.setSubmitting(false)
        })
    }

  const initialValues = {
    ...configurationInitialValues,
    locationId: chancelleryLocationId || '',
  }

  return (
    <PageLayout heading={id ? 'Konfiguration bearbeiten' : 'Konfiguration anlegen'}>
      <FallbackIssueWarnings />
      <Formik
        validationSchema={configurationValidationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit(() => (closePending ? setShouldRedirect(true) : undefined))}
      >
        {/* eslint-disable-next-line complexity */}
        {({ isSubmitting, setFieldValue, values, touched, errors, submitForm }) =>
          shouldRedirect ? (
            <Redirect to={`/chancelleries/locations/edit/${chancelleryLocationId ?? values.locationId}`} />
          ) : partnersError ? (
            <Alert severity="error">Partner konnten nicht geladen werden.</Alert>
          ) : productsError ? (
            <Alert severity="error">Produkte konnten nicht geladen werden.</Alert>
          ) : (
            <>
              <GetConfigurationAPIData id={id} setLoading={setConfigurationDataLoading} />
              <LoadingMessage isLoading={isLoading} />
              <Form>
                <Box display="flex" flexWrap="wrap" width={{ xs: '100%', sm: '45%' }}>
                  <Checkbox name="active" label="Aktiv" disabled={isLoading} className={classes.field} />
                  <Box width="100%">
                    <GroupInput
                      sort
                      name="zipCodes"
                      label="PLZ"
                      disabled={isLoading}
                      validationSchema={Yup.string().matches(/^\d{1,5}$/, 'Bitte PLZ(-Bereiche) von 0 - 99999 eingeben')}
                    />
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Box flex="1 0 auto" maxWidth="75%" mr={2}>
                      <Autocomplete
                        multiple
                        id="fieldsOfLaw"
                        options={FIELDS_OF_LAW_ENTRIES.map(f => f.name)}
                        // Please.
                        // eslint-disable-next-line fp/no-mutating-methods
                        onChange={(_, v) => setFieldValue('fieldsOfLaw', v.sort())}
                        value={values.fieldsOfLaw}
                        fullWidth
                        limitTags={5}
                        ChipProps={{ color: 'primary' }}
                        className={classes.field}
                        disabled={isLoading}
                        renderInput={params => (
                          <TextField
                            error={touched.fieldsOfLaw && !!errors.fieldsOfLaw}
                            helperText={touched.fieldsOfLaw && errors.fieldsOfLaw}
                            label="Rechtsgebiete"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Button
                        onClick={() =>
                          values.fieldsOfLaw.length === FIELDS_OF_LAW_ENTRIES.length
                            ? setFieldValue('fieldsOfLaw', [])
                            : // eslint-disable-next-line fp/no-mutating-methods
                              setFieldValue('fieldsOfLaw', FIELDS_OF_LAW_ENTRIES.map(f => f.name).sort())
                        }
                        variant="outlined"
                        color="primary"
                        className={classes.field}
                      >
                        {values.fieldsOfLaw.length === FIELDS_OF_LAW_ENTRIES.length ? 'Alle entfernen' : 'Alle auswählen'}
                      </Button>
                    </Box>
                  </Box>
                  {productsResponse && (
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      <Box flex="1 0 auto" maxWidth="75%" mr={2}>
                        <Autocomplete
                          multiple
                          id="products"
                          options={sortProducts(productsResponse.data.products.list, true)}
                          getOptionLabel={p => p.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
                          groupBy={option => option.type}
                          // Please.
                          // eslint-disable-next-line fp/no-mutating-methods
                          onChange={(_, v) => setFieldValue('products', v.sort())}
                          value={values.products}
                          fullWidth
                          limitTags={5}
                          ChipProps={{ color: 'primary' }}
                          className={classes.field}
                          disabled={isLoading}
                          renderInput={params => (
                            <TextField
                              error={touched.products && !!errors.products}
                              helperText={touched.products && errors.products}
                              label="Produkte"
                              variant="outlined"
                              {...params}
                            />
                          )}
                        />
                      </Box>
                      <Box>
                        <Button
                          onClick={() =>
                            values.products.length === productsResponse.data.products.list.length
                              ? setFieldValue('products', [])
                              : // eslint-disable-next-line fp/no-mutating-methods
                                setFieldValue('products', sortProducts(productsResponse.data.products.list))
                          }
                          variant="outlined"
                          color="primary"
                          className={classes.field}
                        >
                          {values.products.length === productsResponse.data.products.list.length
                            ? 'Alle entfernen'
                            : 'Alle auswählen'}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {partnersResponse && (
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      <Box flex="1 0 auto" maxWidth="75%" mr={2}>
                        <Autocomplete
                          multiple
                          id="partners"
                          options={partnersResponse.data.partners.list}
                          getOptionLabel={p => p.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
                          // Please.
                          // eslint-disable-next-line fp/no-mutating-methods
                          onChange={(_, v) => setFieldValue('partners', v.sort())}
                          value={values.partners}
                          fullWidth
                          limitTags={5}
                          ChipProps={{ color: 'primary' }}
                          className={classes.field}
                          disabled={isLoading}
                          renderInput={params => (
                            <TextField
                              error={touched.partners && !!errors.partners}
                              helperText={touched.partners && errors.partners}
                              label="Partner"
                              variant="outlined"
                              {...params}
                            />
                          )}
                        />
                      </Box>
                      <Box>
                        <Button
                          onClick={() =>
                            values.partners.length === partnersResponse.data.partners.list.length
                              ? setFieldValue('partners', [])
                              : setFieldValue('partners', partnersResponse.data.partners.list)
                          }
                          variant="outlined"
                          color="primary"
                          className={classes.field}
                        >
                          {values.partners.length === partnersResponse.data.partners.list.length
                            ? 'Alle entfernen'
                            : 'Alle auswählen'}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  <Select name="weight" label="Gewichtung" disabled={isLoading} fullWidth>
                    {Object.entries(ConfigWeight).map(w => (
                      <option key={w[1]} value={w[1]}>
                        {weightMap[w[1]] ?? w[1]}
                      </option>
                    ))}
                  </Select>
                </Box>
                <FormActionBox>
                  {values.configurationId && (
                    <DeleteWithConfirmation
                      actionButtonText="Konfiguration löschen"
                      onConfirm={async () => {
                        setFieldValue('deleted', true)
                        setClosePending(true)
                        await submitForm()
                      }}
                    >
                      Diese Aktion ist nicht umkehrbar. Konfiguration wird permanent gelöscht.
                    </DeleteWithConfirmation>
                  )}
                  <AbortButton
                    onClick={() => {
                      setShouldRedirect(true)
                    }}
                    disabled={isSubmitting}
                  />
                  <SaveButton disabled={isSubmitting} />
                  <SaveButton onClick={() => setClosePending(true)} disabled={isSubmitting}>
                    Speichern und Schließen
                  </SaveButton>
                </FormActionBox>
              </Form>
            </>
          )
        }
      </Formik>
    </PageLayout>
  )
}
