import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/resetPasswordRequest.graphql'

export const resetPasswordMutation = async (email: string, urlPrefix: string) => {
  const variables = {
    email,
    urlPrefix,
  }
  try {
    const response = await request<boolean>(USER_AUTH_ENDPOINT, query, variables)
    return response
  } catch (error) {
    throw error
  }
}
