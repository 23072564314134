import React, { FC } from 'react'
import { Table } from 'reactstrap'

import { ADVICE_REQUEST_FORM_DETAIL } from 'common/constants/routes'
import { useNavigate } from 'common/hooks'
import { Role } from 'common/interfaces'
import { formatDate } from 'common/utils'
import { personName, partnerName, address, email, phone, chancelleryName, fieldOfLawName } from 'common/utils/label'
import { AdviceListStatusLabel } from 'packages-mui/advice/components/AdviceListRoleStatusLabel'
import { AdviceRequestStatus } from 'packages/chancellery-search/interfaces/AdviceRequest'

import { SearchAdviceRequest } from '../interfaces'

interface Props {
  adviceRequests: Array<SearchAdviceRequest>
  role: Role
  fixedStatus?: boolean
}

export const AllRequests: FC<Props> = ({ adviceRequests, role, fixedStatus }) => {
  const toAdviceDetail = useNavigate(ADVICE_REQUEST_FORM_DETAIL)
  if (adviceRequests.length === 0) {
    return null
  }
  return (
    <>
      <Table hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Kanzlei</th>
            <th>Rechtsgebiet</th>
            <th>Name</th>
            <th>Anschrift</th>
            <th>Tel-Nr.</th>
            <th>E-Mail</th>
            <th>Partner</th>
            <th>Erstellt</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {adviceRequests.map((adviceRequest, index) => {
            const status: AdviceRequestStatus =
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              (fixedStatus && adviceRequest.status !== 'editing' && 'closed') || (adviceRequest.status as AdviceRequestStatus)
            return (
              <tr key={index} className="row-hover" onClick={() => toAdviceDetail({ adviceId: adviceRequest.adviceId })}>
                <th scope="row" className="text-center">
                  {adviceRequest.adviceId}
                </th>
                <td className="text-center">{chancelleryName(adviceRequest)}</td>
                <td className="text-center">{fieldOfLawName(adviceRequest)}</td>
                <td className="text-center">{personName(adviceRequest)}</td>
                <td className="text-center">{address(adviceRequest)}</td>
                <td className="text-center">{phone(adviceRequest)}</td>
                <td className="text-center">{email(adviceRequest)}</td>
                <td className="text-center">{partnerName(adviceRequest)}</td>
                <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
                <td className="text-center">
                  <AdviceListStatusLabel status={status} role={role} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}
