import { Filter, ListState, Column, FilterOperator, FilterValue, Filters } from '../interfaces'

export const equalsFilter = (filterOne: Filter, filterTwo: Filter) =>
  filterOne.name === filterTwo.name && filterOne.operator === filterTwo.operator && filterOne.value === filterTwo.value

export const addFilter = <C extends string>(filter: Filter<C>, state: ListState<C>) => ({
  ...state,
  filters: [...state.filters.concat(filter)],
})

export const removeFilter = <C extends string>(filter: Filter<C>, filters: Filters<C>) => ({
  filters: filters.filter(item => !equalsFilter(item, filter)),
})

export const createFilter = (name: Column, operator: FilterOperator, value: FilterValue) => ({
  name,
  operator,
  value,
})

export const hasFilterWithName = (filters: Filters, filterName: Column) => filters.some(item => item.name === filterName)

export const hasFilter = (filters: Filters, filter: Filter) => filters.some(item => equalsFilter(item, filter))

export const updateFilter = (filter: Filter, filters: Filters) =>
  filters.map(item => {
    if (item.name !== filter.name) {
      return item
    }
    return filter
  })

export const removeFilterByName = (filter: Filter, filters: Filters) => ({
  filters: filters.filter(item => item.name !== filter.name),
})

export const getFilterValueByName = (filterName: Column, filters: Filters) => {
  const filter = filters.find(item => item.name === filterName)
  return filter ? filter.value : ''
}

export const getFiltersByName = (filterName: Column, filters: Filters) => filters.filter(filter => filter.name === filterName)

export const transformByName = (filterName: Column, filters: Filters) => {
  const filtersByName = getFiltersByName(filterName, filters)

  if (filtersByName) {
    return filtersByName.map(filter => ({
      id: filter.name,
      name: filter.name,
    }))
  }
  return []
}
