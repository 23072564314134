import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { sort } from 'ramda'
import React, { FunctionComponent, useMemo } from 'react'
import useSWR from 'swr'

import { TransferList, TransferListOption } from 'common/components-mui'
import { USER_AUTH_ENDPOINT } from 'common/constants'

import { fetchUserRights } from '../actions'

const sortOptionArray = (a: TransferListOption, b: TransferListOption): number => {
  if (a.label > b.label) {
    return 1
  } else {
    return -1
  }
}

const useStyles = makeStyles({
  list: {
    maxHeight: '235px',
  },
})

type RightsTransferListProps = {
  name: string
  className?: string
}

export const RightsTransferList: FunctionComponent<RightsTransferListProps> = ({ className, name }) => {
  const { data: availableRights } = useSWR([USER_AUTH_ENDPOINT, 'rights'], fetchUserRights)
  const options = useMemo(
    () => sort(sortOptionArray, availableRights?.map(right => ({ id: right.id, label: right.name })) ?? []),
    [availableRights]
  )
  const { list } = useStyles()
  return <TransferList name={name} label="Nutzerrechte" options={options} className={classNames(list, className)} />
}
