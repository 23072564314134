import {
  Typography,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Radio,
  Box,
} from '@mui/material'
import { Formik, Form } from 'formik'
import React, { FunctionComponent, useState } from 'react'

import { RadioGroup } from 'common/components-mui'
import { useUser } from 'common/user-context'
import { isAdmin, isEmployee } from 'common/utils/roles'

import { ExportType, Filter, Sort } from '../interfaces'
import { createExport } from '../utils'

interface ExportModalProps {
  open: boolean
  filters: Array<Filter>
  sort: Sort
  onCancelClick: () => void
}

export const ExportModal: FunctionComponent<ExportModalProps> = ({ open, filters, sort, onCancelClick }) => {
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState('')
  const [error, setError] = useState(null)

  const { user } = useUser()

  const fetchAndStoreExportLink = async (values: { exportType: ExportType }): Promise<void> => {
    setLoading(true)

    try {
      const exportLink = await createExport({
        sort,
        filters,
        type: values.exportType,
      })

      setLink(`${process.env.FILE_BASE || ''}/${exportLink.createExport}`)
    } catch (e) {
      setError(e)
    }

    setLoading(false)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Export</DialogTitle>
      <DialogContent dividers>
        <Box minWidth="360px">
          {error ? (
            <Typography align="center" gutterBottom>
              Es ist ein Fehler aufgetreten.
            </Typography>
          ) : loading ? (
            <>
              <Typography align="center">Export wird vorbereitet …</Typography>
              <LinearProgress />
            </>
          ) : !link ? (
            <Formik initialValues={{ exportType: ExportType.Controlling }} onSubmit={fetchAndStoreExportLink}>
              {() => (
                <Form>
                  <Box mb={3}>
                    <RadioGroup label="Export-Typ" name="exportType">
                      <FormControlLabel label="Controlling" control={<Radio />} value={ExportType.Controlling} />
                      {(isAdmin(user) || isEmployee(user)) && (
                        <>
                          <FormControlLabel label="Ekomi" control={<Radio />} value={ExportType.Ekomi} />
                          <FormControlLabel label="ASCII" control={<Radio />} value={ExportType.Ascii} />
                        </>
                      )}
                    </RadioGroup>
                  </Box>
                  <Button type="submit" variant="contained">
                    Anfordern
                  </Button>
                </Form>
              )}
            </Formik>
          ) : (
            <Typography align="center" gutterBottom>
              Export bereit!
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!link}
          onClick={() => {
            onCancelClick()
            setLink('')
          }}
          target="_blank"
          href={link || ''}
        >
          Herunterladen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
