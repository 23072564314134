import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { groupBy } from 'ramda'

import { ConfigurationFormValues, EmptyFieldOfLawPhone, EmptyOrderVolume } from './interfaces/formSchemas'
import { ConfigWeight, GetProductsQuery, HolidayInput, Product, ProductType } from './interfaces/schemaDefinition'

// Use UTC plugin
dayjs.extend(utc)

export const isValidNumber = (lower: number, upper: number) => (n: number | undefined) => {
  if (typeof n === 'undefined' || Number.isNaN(n) || n < lower || n > upper) {
    return false
  }
  return true
}

const isValidHour = isValidNumber(0, 23)
const isValidMinute = isValidNumber(0, 59)

export const toUTCTime = (timeString: string): number => {
  if (timeString === '') {
    return 0
  }

  const [hours, minutes] = timeString.split(':').map(i => parseInt(i, 10))
  if (!isValidHour(hours) || !isValidMinute(minutes)) {
    throw new Error(`Wrong time string format. Expected: HH:mm, seen ${timeString}`)
  }
  // Create dayjs object in local time, set value from form, convert to UTC.
  return dayjs(0).hour(hours).minute(minutes).utc().valueOf()
}

export const getNewHolidayEntry = (): HolidayInput => ({
  global: false,
  start: dayjs().startOf('day').toDate(),
  end: dayjs().endOf('day').toDate(),
})

export const getNewPhoneEntry = (): EmptyFieldOfLawPhone => ({
  fieldOfLaw: null,
  phone: '',
})

export const getNewOrderVolumeEntry = (): EmptyOrderVolume => ({
  fieldOfLaw: null,
  weeklyMin: '',
  weeklyMax: '',
})

type BaselineConfig = {
  folMax: number
  partnersMax: number
  productsMax: number
}

export const calculatePriority = (baselineConfig: BaselineConfig, values: ConfigurationFormValues): number => {
  const basePrio = 0
  return (
    basePrio +
    (baselineConfig.folMax > values.fieldsOfLaw.length ? 1 : 0) +
    (baselineConfig.partnersMax > values.partners.length ? 1 : 0) +
    (baselineConfig.productsMax > values.products.length ? 1 : 0)
  )
}

export const weightMap: Record<string, string> = {
  [ConfigWeight.Much]: 'sehr hoch',
  [ConfigWeight.More]: 'hoch',
  [ConfigWeight.Default]: 'normal',
  [ConfigWeight.Less]: 'gering',
  [ConfigWeight.Few]: 'sehr gering',
}

export type ExtractRowType<T, N extends string> = T extends { [name in N]: { list: Array<infer U> } } ? U : never

export const listFormatter = <T extends { name: string }>(value: Array<T>): string =>
  value.length > 1 ? 'konfiguriert' : value[0]?.name || ''

type Extract<T> = T extends Array<infer U> ? U : never

type ProductList = Exclude<GetProductsQuery, undefined>['products']['list']

type IndexProduct = {
  [index: string]: Array<
    {
      __typename?: 'Product' | undefined
    } & Pick<Product, 'id' | 'name' | 'type'>
  >
}

export const sortProducts = (list: ProductList, consumerFirst = false): Array<{ name: string; id: string; type: string }> => {
  const grouped: IndexProduct = groupBy((product: Extract<ProductList>) => product.type, list)
  // We are mutating the list of keys that is created in this line and using it only once.
  // eslint-disable-next-line fp/no-mutating-methods
  return Object.keys(grouped)
    .sort((a, b) => (consumerFirst ? -1 : 1) * a.localeCompare(b))
    .flatMap(key =>
      // The lists in grouped are also created in this function and only used in this flow.
      // eslint-disable-next-line fp/no-mutating-methods
      grouped[key]
        .sort((a: Extract<ProductList>, b: Extract<ProductList>) => a.name.localeCompare(b.name))
        .map((p: Extract<ProductList>) => ({ ...p, type: p.type === ProductType.Business ? 'B2B' : 'B2C' }))
    )
}
