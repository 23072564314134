import { isHoliday } from 'feiertagejs'

export interface CallbackTime {
  value: string
  label: string
}

const weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
const DAY = 1000 * 60 * 60 * 24
const HOUR = 1000 * 60 * 60

const addDay = (date: Date): Date => new Date(date.getTime() + DAY)

const isWorkday = (date: Date): boolean =>
  date.getDay() !== 0 &&
  date.getDay() !== 6 &&
  !isHoliday(date, 'BUND') &&
  !isHoliday(date, 'NW') &&
  !(date.getDate() === 24 && date.getMonth() === 11)

export const getNextWorkday = (date: Date): Date => {
  const tomorrow = addDay(date)
  return isWorkday(tomorrow) ? tomorrow : getNextWorkday(tomorrow)
}

const getTimeRangeTuples = (startHour = 8, endHour = 17, hourDivisor = 2): Array<[number, number]> => {
  if (startHour >= endHour) {
    return []
  }
  // eslint-disable-next-line prefer-spread
  const result = Array.apply(null, { length: (endHour - startHour) * hourDivisor }).map((_: never, i: number) => [
    startHour * HOUR + (i - hourDivisor) * (HOUR / hourDivisor),
    startHour * HOUR + (i - hourDivisor + 1) * (HOUR / hourDivisor),
  ])
  return result
}

const toHoursAndMinutes = (increment: number): [number, number] => [
  new Date(increment).getHours(),
  new Date(increment).getMinutes(),
]

const setToHours = (date: Date, hours: number, minutes: number): Date => {
  const newDate = new Date(date)
  newDate.setHours(hours)
  newDate.setMinutes(minutes)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)
  return newDate
}

const padLeft = (i: number): string => (i < 10 ? `0${i}` : `${i}`)

const toRangeLabel =
  (date: Date, day = 'Heute') =>
  (timeRangeTuple: [number, number]): { value: string; label: string } => {
    const [hours, minutes] = toHoursAndMinutes(timeRangeTuple[0])
    const [nextHours, nextMinutes] = toHoursAndMinutes(timeRangeTuple[1])
    return {
      value: setToHours(date, hours, minutes).toString(),
      label: `${day} ${padLeft(hours)}:${padLeft(minutes)} - ${padLeft(nextHours)}:${padLeft(nextMinutes)}`,
    }
  }

const isMonday = (date: Date): boolean => date.getDay() === 1

export const getCallbackTimes = (currentDate: Date): Array<CallbackTime> => {
  const nextWorkday = getNextWorkday(currentDate)
  return [
    ...(isWorkday(currentDate)
      ? getTimeRangeTuples(Math.max(isMonday(currentDate) ? 12 : 8, currentDate.getHours() + 3)).map(toRangeLabel(currentDate))
      : []),
    ...getTimeRangeTuples(isMonday(nextWorkday) ? 12 : 8).map(toRangeLabel(nextWorkday, weekdays[nextWorkday.getDay()])),
  ]
}

export const defaultCallbackTime = (currentDate: Date): CallbackTime => {
  const limit = new Date(Date.now() + 1000 * 60 * 60 * 24).getTime()
  const nowHours = new Date().getHours()
  return getCallbackTimes(currentDate).reduce(
    (callbackTime, nextCallbackTime) =>
      new Date(callbackTime.value).getTime() < limit || new Date(callbackTime.value).getHours() <= nowHours
        ? nextCallbackTime
        : callbackTime,
    { value: new Date().toString(), label: '' }
  )
}
