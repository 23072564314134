/* eslint-disable complexity */
import { gql, useMutation } from '@apollo/client'
import { Effect } from '@grapes-agency/universal'

import { InputError } from 'common/errors'

import CONTACT_INSURER from '../../graphql/contactInsurer.graphql'
import { AdviceRequest, MessageType } from '../../interfaces'
import { ContactInsurerMutation } from '../../interfaces/schemaDefinition'
import { FormDataEntry } from '../components/ContactForms/coverageForms'

export interface ContactInsurerInput {
  adviceRequest: AdviceRequest
  iban?: string
  topic?: string
  formData?: Array<FormDataEntry>
  coverageDecription: string
  caseId?: string
  partyName?: string
  attachments: Array<File>
  type?: MessageType
  blindCarbonCopy?: Array<string>
}

const partnerFields = [
  'damageNumber',
  'deductible',
  'deductibleAmount',
  'protection',
  'protectionAmount',
  'disputeAmount',
  'internalPartnerNotes',
]

export const useSendCoverageRequest: () => Effect<ContactInsurerInput, string> = () => {
  const [contactInsurer] = useMutation<ContactInsurerMutation>(gql(CONTACT_INSURER))
  const sendCoverageRequest: Effect<ContactInsurerInput, string> = async ({
    adviceRequest,
    iban,
    topic = undefined,
    formData = [],
    coverageDecription,
    caseId = '',
    partyName = '',
    attachments,
    blindCarbonCopy = [],
    type = MessageType.CoverageRequest,
  }) => {
    if (type !== MessageType.Contact && !formData.length && !attachments.length) {
      throw new InputError({ files: 'Für diese Anfrage ist mindestens ein Dateianhang erforderlich' })
    }

    if (!adviceRequest.chancellery) {
      throw new Error('cannot send coverage request on unassigend advice request')
    }

    const result = await contactInsurer({
      variables: {
        contactInput: {
          adviceId: adviceRequest.adviceId,
          description: adviceRequest.description,
          person: {
            firstname: adviceRequest.firstname,
            lastname: adviceRequest.lastname,
            phone: adviceRequest.phone,
            email: adviceRequest.email,
            address: {
              zip: adviceRequest.zip,
            },
          },
          caseData: [...partnerFields]
            .filter(field => (adviceRequest as any)[field] !== undefined)
            .map(field => ({
              name: field,
              value: (adviceRequest as any)[field],
            })),
          fieldOfLaw: adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.name : '',
          message: coverageDecription,
          messageType: type,
          attachments,
          insuranceNumber: adviceRequest.insuranceNumber || '',
          chancellery: {
            name: adviceRequest.chancellery?.name,
            address: adviceRequest.chancellery?.address.street,
            zip: adviceRequest.chancellery?.address.zip,
            city: adviceRequest.chancellery?.address.city,
            phone: adviceRequest.chancellery?.phone,
          },
          blindCarbonCopy,
          caseId,
          partyName,
          iban,
          topic,
          formData: formData.map(data => ({
            label: data.question,
            value: data.answer,
          })),
        },
      },
    })
    return result.data?.contactInsurer || ''
  }

  return sendCoverageRequest
}
