import { KeyStringValueMap, Option } from '../interfaces'

export const MapToOptions = (map: KeyStringValueMap): Array<Option> =>
  Object.keys(map).map(key => ({ label: map[key], value: key }))

export const MapEntry = (entry: string, map: KeyStringValueMap): string => (!!map[entry] ? map[entry] : entry)

export const MapEntries = (entries: Array<string>, map: KeyStringValueMap): Array<string> =>
  entries.map((entry: string) => MapEntry(entry, map))

export const isValidPrice = (price: number | string): boolean => /^(\d*[.])?\d+$/.test(price.toString())

export const isKebabCase = (value: string): boolean => !!value && /^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/.test(value)

// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms: number): Promise<unknown> => new Promise(resolve => setTimeout(resolve, ms))
