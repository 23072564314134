import React, { FC } from 'react'
import { Col, Row } from 'reactstrap'

import { StaticField } from 'common/components/StaticField'
import { StaticRow } from 'common/components/StaticRow'
import SVGSecureGreen from 'common/icons/secure-green.svg'
import SVGSecure from 'common/icons/secure.svg'

import { AdviceRequest } from '../../interfaces/AdviceRequest'

import './InsuranceFieldSet.scss'

interface InsuranceFieldSetProps {
  adviceRequest: AdviceRequest
}

export const InsuranceFieldSet: FC<InsuranceFieldSetProps> = ({ adviceRequest }) => {
  const hasDamageNumber = !!adviceRequest.damageNumber

  const insured = adviceRequest.insured || adviceRequest.legalProtectionInsurer || hasDamageNumber
  const insurer = adviceRequest.legalProtectionInsurer || '-'

  const insuredValue = !insured ? (
    'Nein'
  ) : (
    <span className="insured-yes">
      Ja <SVGSecureGreen />
    </span>
  )
  return (
    <StaticRow>
      <Col md={12}>
        <SVGSecure className="icons-label m-r-10" />
        <h3 className="h3 small">Rechtsschutzversicherung</h3>
        <Row className="m-l-5 row-margin">
          <Col md={3}>
            <StaticField label="Rechtsschutzversichert" value={insuredValue} />
          </Col>
          <Col md={3}>
            <StaticField label="Rechtsschutzversicherung" value={insurer || '-'} />
          </Col>
          <Col md={3}>
            <StaticField label="Versicherungsscheinnummer" value={adviceRequest.insuranceNumber || '-'} />
          </Col>
        </Row>
      </Col>
    </StaticRow>
  )
}
