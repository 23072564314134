import React, { ChangeEventHandler, FC } from 'react'
import { Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import { StaticRow } from 'common/components/StaticRow'
import { ButtonPrimary } from 'common/ui'

import { AdviceRequest } from '../../interfaces'
import File from '../images/akte.svg'
import Back from '../images/back.svg'
import SVGExit from '../images/exit.svg'
import Folder from '../images/icon_akten.svg'
import Sending from '../images/sending.svg'

import './MandatesModal.scss'

interface MandatesModalProps {
  adviceDetail: AdviceRequest
  open: boolean
  loading: boolean
  success: boolean
  setUseEConsult: ChangeEventHandler<HTMLInputElement>
  transferloading: boolean
  onCancelClick: () => void
  onCompleteClick: () => void
  onMandateClick: () => void
  setNotes: ChangeEventHandler<HTMLInputElement>
  errors?: { [prop: string]: string }
  useEConsult: boolean | undefined
  hasEConsult: boolean
}

export const MandatesModal: FC<MandatesModalProps> = ({
  adviceDetail,
  loading,
  success,
  open,
  useEConsult,
  hasEConsult,
  setUseEConsult,
  transferloading,
  onCancelClick,
  onCompleteClick,
  onMandateClick,
  setNotes,
  errors = {},
}) => {
  if (loading || !open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  if (success) {
    return (
      <Modal toggle={onCompleteClick} isOpen={open} className="success-modal">
        <ModalBody>
          <Folder height="160" width="200" />
          <Label>
            Dieser Antrag wurde erfolgreich umgewandelt und unter <span>&quot;Akten&quot;</span> gespeichert.
          </Label>
        </ModalBody>
        <ModalFooter>
          <ButtonPrimary onClick={onCompleteClick} iconLeft={<Back width="16" height="16" />}>
            Zurück zu Ihren Anträgen
          </ButtonPrimary>
        </ModalFooter>
      </Modal>
    )
  }

  if (transferloading) {
    return (
      <Modal isOpen={open} className="success-modal">
        <ModalBody>
          <Spinner center />
          <Sending className="m-b-20 m-t-20" />
          <Label className="m-b-20 m-t-20">Wir bitten um einen Moment Geduld.</Label>
          <Label className="m-b-20"> Ihre Akte wird an die hinterlegte Kanzlei-Software übermittelt.</Label>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} className="new-modal small-size" id="MandatesModal">
      <ModalBody>
        <div className="btn-cancel close-modal" onClick={onCancelClick}>
          <SVGExit height="14" width="14" />
        </div>
        <Form className="form">
          <FormGroup>
            <h3 className="h3 small">Vergeben Sie einen Aktennamen:</h3>
            <Input
              value={adviceDetail.mandateName}
              placeholder="Aktennamen vergeben"
              type="text"
              name="mandateName"
              id="mandateName"
              onChange={setNotes}
            />
            {!!errors.mandateName && <FormFeedback>{errors.mandateName}</FormFeedback>}
          </FormGroup>
          {hasEConsult && (
            <FormGroup>
              <StaticRow>
                <Col md={10}>
                  <Label for="useEConsult0">Akte im KLUGO System erstellen.</Label>
                </Col>
                <Col md={2}>
                  <Input
                    id="useEConsult0"
                    type="radio"
                    name="useEConsult"
                    value={0}
                    checked={useEConsult === false}
                    onChange={setUseEConsult}
                  />
                </Col>
              </StaticRow>
              <StaticRow>
                <Col md={10}>
                  <Label for="useEConsult1" className="left-align">
                    Akte im KLUGO System erstellen und Daten an Kanzlei-Software übertragen.
                  </Label>
                </Col>
                <Col md={2}>
                  <Input
                    id="useEConsult1"
                    type="radio"
                    name="useEConsult"
                    value={1}
                    checked={useEConsult === true}
                    onChange={setUseEConsult}
                  />
                </Col>
              </StaticRow>
              {!!errors.useEConsult && <FormFeedback>{errors.useEConsult}</FormFeedback>}
            </FormGroup>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary onClick={onMandateClick} iconLeft={<File width="20" height="16" />}>
          Weiter
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  )
}
