import {
  Event,
  Severity,
  captureEvent as sCaptureEvent,
  captureException as sCaptureException,
  captureMessage as sCaptureMessage,
  init as sInit,
} from '@sentry/browser'

let ENV = 'development'

const isLive = (env: string) => ['qa', 'staging', 'production'].includes(env)

export const init = ({ name, version, dsn, env = ENV }: { name: string; version: string; dsn: string; env: string }) => {
  if (isLive(env)) {
    ENV = env
    if (!dsn) {
      // eslint-disable-next-line
      console.error('Detected live environment but no Sentry DSN is configured')
      return
    }
    sInit({
      dsn,
      release: `${name}@${version}`,
      environment: ENV,
    })
  }
}

export const captureException = (err: any) => {
  if (isLive(ENV)) {
    return sCaptureException(err)
  }
  // eslint-disable-next-line
  console.error(err)
  return undefined
}
export const captureMessage = (message: string, level?: Severity) => {
  if (isLive(ENV)) {
    return sCaptureMessage(message, level)
  }
  // eslint-disable-next-line
  console.log(message)
  return undefined
}
export const captureEvent = (event: Event) => {
  if (isLive(ENV)) {
    return sCaptureEvent(event)
  }
  // eslint-disable-next-line
  console.log(event)
  return undefined
}
