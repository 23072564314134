import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/finishMatching.graphql'
import { FinishMatchingMutation, AdviceRequestFlattened } from '../interfaces'

import { mapAdviceRequestToFinishRequest } from './mappings'

export const finishMatching: (adviceRequest: AdviceRequestFlattened, saveBillingCompany?: boolean) => Promise<string> = async (
  adviceRequest,
  saveBillingCompany = false
) => {
  const response = await request<FinishMatchingMutation>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    mapAdviceRequestToFinishRequest(adviceRequest, saveBillingCompany)
  )
  return response.finishMatching
}
