import { Box, Alert } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Checkbox,
  TextField,
  PageLayout,
  FormActionBox,
  AbortButton,
  SaveButton,
  DeleteWithConfirmation,
  LoadingMessage,
} from 'common/components-mui'
import { useParams } from 'common/hooks'

import { mapResponseToUser, mapUserToInput, saveUser } from '../actions'
import {
  GroupsSelect,
  ChancellerySelection,
  ChannelSelection,
  PasswordSelection,
  RolesSelect,
  TRBManagerSelection,
  RightsTransferList,
} from '../components'
import { GetApiUser } from '../components/GetApiUser'
import { FormUser, initialValues, userValidationSchema } from '../interfaces/formSchema'
import { hasRoleTRB, hasRoleLawyer, hasRoleChannel } from '../utils'

type UserFormPageParams = {
  id?: string
}

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(3),
  },
}))

const handleSubmit =
  (setError: (isError: boolean) => void, triggerRedirect: () => void) =>
  async (values: FormUser, helpers: FormikHelpers<FormUser>): Promise<void> => {
    saveUser(await mapUserToInput(values))
      .then(({ saveUser: saveUserResult }) => {
        if (saveUserResult) {
          helpers.setValues(mapResponseToUser(saveUserResult))
        }
      })
      .then(() => helpers.setSubmitting(false))
      .then(() => triggerRedirect())
      .catch(error => {
        setError(true)
        throw error
      })
  }

export const UserFormPage: React.FunctionComponent = () => {
  const { id: userId } = useParams<UserFormPageParams>()

  const [serverError, setServerError] = useState(false)
  const [isUserLoading, setUserLoading] = useState(false)
  const [closePending, setClosePending] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const classes = useStyles()

  return shouldRedirect ? (
    <Redirect to="/users" />
  ) : (
    <PageLayout heading="Nutzerverwaltung">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit(setServerError, () => (closePending ? setShouldRedirect(true) : undefined))}
        validationSchema={userValidationSchema}
      >
        {/* eslint-disable-next-line complexity */}
        {({ values: { roles, deleted }, isSubmitting, setFieldValue, submitForm }) => {
          const disabled = isUserLoading || deleted
          const openChannels = hasRoleChannel(roles)

          return (
            <Form>
              <GetApiUser id={userId} setLoading={setUserLoading} />
              <LoadingMessage isLoading={isUserLoading} />
              {deleted && (
                <Box mb={3}>
                  <Alert severity="warning">
                    Dieser Benutzer wurde als „gelöscht“ markiert. Änderungen sind nicht mehr möglich.
                  </Alert>
                </Box>
              )}
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                <Box width={{ xs: '100%', sm: '45%' }}>
                  <Checkbox name="active" label="Aktiv" disabled={disabled} className={classes.field} />
                  <TextField
                    name="name"
                    label="Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Erika Mustermann"
                    disabled={disabled}
                    fullWidth
                    className={classes.field}
                  />
                  <TextField
                    type="email"
                    name="email"
                    variant="outlined"
                    label="E-Mail"
                    InputLabelProps={{ shrink: true }}
                    placeholder="email@organistation.de"
                    disabled={disabled}
                    fullWidth
                    className={classes.field}
                  />
                  {serverError && <div className="error">Die Email Adresse ist schon vorhanden.</div>}
                  <RolesSelect name="roles" className={classes.field} />
                  <RightsTransferList name="rights" className={classes.field} />
                </Box>
                <Box width={{ xs: '100%', sm: '45%' }} pt={8}>
                  <GroupsSelect name="groups" className={classes.field} />
                  {hasRoleLawyer(roles) && (
                    <ChancellerySelection name="organisation" typeName="organisationType" className={classes.field} />
                  )}
                  {hasRoleTRB(roles) && <TRBManagerSelection name="parentId" className={classes.field} />}
                  {openChannels && <ChannelSelection className={classes.field} />}
                  <PasswordSelection name="password" repeatName="passwordRepeat" className={classes.field} />
                </Box>
              </Box>
              <FormActionBox>
                {userId && !deleted && (
                  <DeleteWithConfirmation
                    actionButtonText="Benutzer löschen"
                    onConfirm={async () => {
                      setFieldValue('deleted', true)
                      setClosePending(true)
                      await submitForm()
                    }}
                  >
                    Diese Aktion ist nicht umkehrbar. Benutzer wird permanent gelöscht.
                  </DeleteWithConfirmation>
                )}
                <AbortButton
                  onClick={() => {
                    setShouldRedirect(true)
                  }}
                  disabled={isSubmitting}
                >
                  {deleted ? 'Schließen' : null}
                </AbortButton>
                {deleted || (
                  <>
                    <SaveButton disabled={isSubmitting || isUserLoading} />
                    <SaveButton onClick={() => setClosePending(true)} disabled={isSubmitting || isUserLoading}>
                      Speichern und Schließen
                    </SaveButton>
                  </>
                )}
              </FormActionBox>
            </Form>
          )
        }}
      </Formik>
    </PageLayout>
  )
}
