import { Role } from '../interfaces'

export const Roles = {
  Administrator: 'Administrator',
  Callcenter: 'Callcenter',
  Employee: 'Mitarbeiter',
  Lawyer: 'Anwalt',
  Channel: 'Rechtsberater Modethema',
  TRB: 'Telefonische Rechtsberatung',
  TRBManager: 'TRB Manager',
}

export const enum ROLE_IDS {
  Administrator = 0,
  Callcenter,
  Employee,
  Lawyer,
  Channel,
  TRB,
  TRBManager,
  Client,
}

const ROLES: Array<Role> = []
/* eslint-disable fp/no-mutation */
ROLES[ROLE_IDS.Administrator] = { id: 'administrator', name: 'Administrator' }
ROLES[ROLE_IDS.Callcenter] = { id: 'callcenter', name: 'Callcenter' }
ROLES[ROLE_IDS.Employee] = { id: 'mitarbeiter', name: 'Mitarbeiter' }
ROLES[ROLE_IDS.Lawyer] = { id: 'anwalt', name: 'Anwalt' }
ROLES[ROLE_IDS.Channel] = { id: 'channel', name: 'Rechtsberater Modethema' }
ROLES[ROLE_IDS.TRB] = { id: 'trb', name: 'Telefonische Rechtsberatung' }
ROLES[ROLE_IDS.TRBManager] = { id: 'trb-manager', name: 'TRB Manager' }
ROLES[ROLE_IDS.Client] = { id: 'client', name: 'Kunde' }
/* eslint-enable fp/no-mutation */

export { ROLES }
