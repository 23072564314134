import { Box, BoxProps, Typography } from '@mui/material'
import React, { FC } from 'react'

interface StaticFieldProps extends BoxProps {
  label: string
  value: React.ReactNode
}

// eslint-disable-next-line fp/no-rest-parameters
export const StaticField: FC<StaticFieldProps> = ({ label, value, ...props }) => (
  <Box {...props}>
    <Typography>{value}</Typography>
    <Typography variant="caption">{label}</Typography>
  </Box>
)
