import React, { Fragment, FunctionComponent } from 'react'
import { Col } from 'reactstrap'

import { DateStaticField, StaticField } from 'common/components'
import { StaticRow } from 'common/components/StaticRow'
import { ROLES, ROLE_IDS } from 'common/constants'
import { AdviceListStatusLabel } from 'packages-mui/advice/components/AdviceListRoleStatusLabel'

import { CONTACT_TYPE_LABELS } from '../../constants'
import { AdviceRequest } from '../../interfaces'

interface StatusRowProps {
  adviceRequest: AdviceRequest
  isTRB?: boolean
  isAdmin?: boolean
}

export const StatusRow: FunctionComponent<StatusRowProps> = ({ adviceRequest, isTRB, isAdmin }: StatusRowProps) => {
  const role = isTRB ? ROLES[ROLE_IDS.TRB] : isAdmin ? ROLES[ROLE_IDS.Administrator] : ROLES[ROLE_IDS.Callcenter]

  return (
    <Fragment>
      <StaticRow>
        <Col md={3}>
          <StaticField label="Beratungs-ID" value={adviceRequest.adviceId || '-'} />
        </Col>
        <Col md={3}>
          <StaticField label="Status" value={<AdviceListStatusLabel status={adviceRequest.status} role={role} />} />
        </Col>
        <Col md={3}>
          <StaticField
            label="Kontaktart"
            value={adviceRequest.contactType ? CONTACT_TYPE_LABELS[adviceRequest.contactType] : ''}
          />
        </Col>
        {!!adviceRequest.createdAt && (
          <Col md={3}>
            <DateStaticField label="Erstellt" value={adviceRequest.createdAt} />
          </Col>
        )}
      </StaticRow>
      <StaticRow>
        <Col md={3}>
          <StaticField label="Rechtsgebiet" value={adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.name : '-'} />
        </Col>
        <Col md={3}>
          <StaticField label="Sprache" value={adviceRequest.language ? adviceRequest.language.language : 'Deutsch'} />
        </Col>
        {adviceRequest.partner && (
          <Col md={6}>
            <StaticField label="Partner" value={`${adviceRequest.partner.name}`} />
          </Col>
        )}
      </StaticRow>
    </Fragment>
  )
}
