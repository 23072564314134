import classnames from 'classnames'
import React, { FC } from 'react'
import { FormFeedback, Input, Label, FormGroup } from 'reactstrap'

import './SelectField.scss'

interface Option {
  label: string
  value: string
}

interface SelectFieldProps {
  id: string
  name: string
  label: string
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  options: Array<Option>
  error?: string
  highlight?: boolean
  required?: boolean
  disabled?: boolean
  width?: string
}

export const SelectField: FC<SelectFieldProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  onBlur,
  options,
  width,
  error,
  highlight = false,
  required = false,
  disabled = false,
}) => (
  <FormGroup
    className={classnames('select-field', {
      'select-field--highlight': highlight,
      'select-field--disabled': disabled,
      'select-field--width': !!width,
      'select-field--invalid': error,
    })}
    style={{ flexBasis: width }}
  >
    <Label htmlFor={id}>{required ? `${label} *` : label}</Label>
    <Input type="select" id={id} name={name} value={value} disabled={disabled} onChange={onChange} onBlur={onBlur}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
    {error && <FormFeedback>{error}</FormFeedback>}
  </FormGroup>
)
