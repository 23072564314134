import { useFormikContext } from 'formik'
import { useRef, useEffect, MutableRefObject } from 'react'

export const useScrollToInvalidField = <T extends HTMLElement>(name: string): MutableRefObject<T | null> => {
  const formikBag = useFormikContext()
  const fieldRef = useRef<T>(null)

  useEffect(() => {
    const firstError = Object.keys(formikBag.errors)[0]
    if (formikBag.isSubmitting && firstError === name) {
      fieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [formikBag.isSubmitting, name, formikBag.errors])
  return fieldRef
}
