import React from 'react'
import { Col, Row } from 'reactstrap'

import { BooleanStaticField, NumberStaticField, StaticField, TextAreaStaticField } from 'common/components/StaticField'
import { StaticRow } from 'common/components/StaticRow'
import { currencyFormatter } from 'common/utils'

import { AdviceRequest } from '../../../interfaces'
import PartnerData from '../images/antragsdaten.svg'
import Remark from '../images/interne_bemerkung.svg'

import './DevkView.scss'

interface DEVKViewProps {
  adviceRequest: AdviceRequest
}

export const DevkView = ({ adviceRequest }: DEVKViewProps): JSX.Element => (
  <StaticRow>
    <Col md={12} className="devk-view  m-b-20">
      <PartnerData width="18" height="20" className="m-r-10" />
      <h3 className="h3 small">DEVK-Falldaten</h3>
      <Row className="m-l-5 m-r-10">
        <Col md={12}>
          <StaticField label="Schadensnummer" value={adviceRequest.damageNumber || '-'} />
        </Col>
        <Col md={3}>
          <NumberStaticField label="Streitwert" value={adviceRequest.disputeAmount || '-'} formatter={currencyFormatter} />
        </Col>
        <Col md={3}>
          {adviceRequest.deductible ? (
            <NumberStaticField
              label="Höhe der Selbstbeteiligung"
              value={adviceRequest.deductibleAmount}
              formatter={currencyFormatter}
            />
          ) : (
            <BooleanStaticField label="Höhe der Selbstbeteiligung" value={adviceRequest.deductible} />
          )}
        </Col>
        <Col md={3}>
          {adviceRequest.protection ? (
            <StaticField label="Umfang Kostenschutz" value={adviceRequest.protectionAmount || '-'} />
          ) : (
            <BooleanStaticField label="Umfang Kostenschutz" value={adviceRequest.protection} />
          )}
        </Col>
      </Row>
    </Col>
    <Col md={12} className="devk-view">
      <Remark width="18" height="20" className="m-r-10" />
      <h3 className="h3 small">DEVK Interne Bemerkung</h3>
      <Row className="m-l-5 m-r-10">
        <Col md={12}>
          <TextAreaStaticField label="" value={adviceRequest.internalPartnerNotes || '-'} />
        </Col>
      </Row>
    </Col>
  </StaticRow>
)
