import InfoIcon from '@mui/icons-material/Info'
import { Box, Snackbar, TextField as MuiTextField, Typography, Alert, Autocomplete } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { FunctionComponent, memo, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import {
  AddButton,
  TextField,
  PageLayout,
  LoadingMessage,
  SaveButton,
  AbortButton,
  DeleteWithConfirmation,
  FormActionBox,
} from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants/endpoints'
import { FIELDS_OF_LAW_ENTRIES } from 'common/constants/fieldsOfLaw'
import { addOptionalPropIf, request } from 'common/utils'

import {
  FieldOfLawPhoneEntry,
  HolidaySettings,
  OpeningHoursSettings,
  GetChancelleryLocationAPIData,
  mapChancelleryLocationAPIDataToForm,
  FallbackIssueWarnings,
  SimpleUserTable,
} from '../components'
import { GlobalHolidays } from '../components/GlobalHolidays'
import { MatchingConfigurationsTable } from '../components/MatchingConfigurationsTable'
import saveChancelleryLocationMutation from '../graphql/saveChancelleryLocation.graphql'
import { locationInitialValues, LocationFormValues, chancelleryLocationValidationSchema } from '../interfaces/formSchemas'
import { ChancelleryLocationInput, FieldOfLawPhone, SaveChancelleryLocationMutation } from '../interfaces/schemaDefinition'
import { getNewHolidayEntry, getNewPhoneEntry, toUTCTime } from '../utils'

const MemoizedTextField = memo(MuiTextField)

const toApiInputFormat = (values: LocationFormValues): ChancelleryLocationInput => {
  const {
    locationId,
    chancelleryId,
    fallbackFor,
    streetAddress,
    zip,
    city,
    phone,
    openingHours,
    fieldOfLawPhones,
    holidays,
    deleted,
  } = values

  const baseInput = {
    phone,
    address: {
      zip,
      city,
      street: streetAddress,
    },
    openingHours: openingHours.map(day => ({
      enabled: day.enabled,
      start: toUTCTime(day.begin),
      end: toUTCTime(day.end),
    })),
    fallbackFor,
    fieldOfLawPhones: fieldOfLawPhones
      .filter((folPhone): folPhone is FieldOfLawPhone => Boolean(folPhone.fieldOfLaw?.id))
      .map(folPhone => ({
        fieldOfLawId: folPhone.fieldOfLaw.id,
        phone: folPhone.phone,
      })),
    // eslint-disable-next-line fp/no-rest-parameters
    holidays: holidays.map(({ fieldsOfLaw, ...holidayEntry }) => ({
      ...holidayEntry,
      fieldOfLawIds: fieldsOfLaw?.map(field => field.id),
    })),
    deleted,
    active: true,
    chancelleryId,
  }

  const withId = addOptionalPropIf<{ id: string }, ChancelleryLocationInput>(locationId.length > 0)({ id: locationId })

  return withId(baseInput)
}

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(3),
  },
  formButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
      marginLeft: theme.spacing(2),
    },
    marginBottom: theme.spacing(3),
  },
  folFieldSet: {
    display: 'flex',
    height: '100%',
    alignItems: 'end',
  },
  infoIcon: {
    marginRight: theme.spacing(2),
  },
}))

type ChancelleryLocationsFormPageParams = {
  id?: string
  cid?: string
}

export const ChancelleryLocationsFormPage: FunctionComponent = () => {
  const { id, cid: chancelleryId } = useParams<ChancelleryLocationsFormPageParams>()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [closePending, setClosePending] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit =
    (triggerRedirect: () => void) =>
    (values: LocationFormValues, helpers: FormikHelpers<LocationFormValues>): void => {
      const input: ChancelleryLocationInput = toApiInputFormat(values)
      request<SaveChancelleryLocationMutation>(CHANCELLERIES_ENDPOINT, saveChancelleryLocationMutation, {
        chancelleryLocation: input,
      })
        .then(({ saveChancelleryLocation }) => {
          if (saveChancelleryLocation) {
            helpers.setValues(mapChancelleryLocationAPIDataToForm(saveChancelleryLocation))
          }
        })
        .catch((e: Error) => {
          setErrorMessage(e.message === 'address_not_found' ? 'Adresse wurde nicht gefunden.' : 'Ein Fehler ist aufgetreten.')
          setError(true)
        })
        .then(() => triggerRedirect())
        .catch(() => undefined)
        .then(() => {
          helpers.setSubmitting(false)
        })
    }

  const initialValues = {
    ...locationInitialValues,
    chancelleryId: chancelleryId || '',
  }

  return (
    <PageLayout heading={id ? 'Standort bearbeiten' : 'Standort anlegen'}>
      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <FallbackIssueWarnings />
      <Formik
        initialValues={initialValues}
        validationSchema={chancelleryLocationValidationSchema}
        onSubmit={handleSubmit(() => (closePending ? setShouldRedirect(true) : undefined))}
      >
        {({ isSubmitting, submitForm, values, errors, touched, setFieldValue }) =>
          shouldRedirect ? (
            <Redirect to={`/chancelleries/edit/${chancelleryId ?? values.chancelleryId}`} />
          ) : (
            <Form>
              <GetChancelleryLocationAPIData id={id} setLoading={setIsLoading} />
              <LoadingMessage isLoading={isLoading} />
              <Box mb={3} display="flex" flexWrap="wrap" justifyContent="space-between">
                <Box display="flex" flexWrap="wrap" justifyContent="space-between" width={{ xs: '100%', md: '45%', xl: '33%' }}>
                  <TextField name="phone" label="Telefon" disabled={isLoading} fullWidth className={classes.field} />
                  <Box width={{ xs: '100%' }}>
                    <TextField name="streetAddress" label="Adresse" disabled={isLoading} fullWidth className={classes.field} />
                  </Box>
                  <Box width={{ xs: '100%', sm: '30%' }}>
                    <TextField name="zip" label="Postleitzahl" disabled={isLoading} fullWidth className={classes.field} />
                  </Box>
                  <Box width={{ xs: '100%', sm: '60%' }}>
                    <TextField name="city" label="Stadt" fullWidth disabled={isLoading} className={classes.field} />
                  </Box>
                </Box>
                <Box display="flex" flexWrap="wrap" justifyContent="space-between" width={{ xs: '100%', md: '45%', xl: '60%' }}>
                  <Box flex="1 1 auto">
                    <fieldset className={classes.folFieldSet}>
                      <legend>Fallback für</legend>
                      <Autocomplete
                        multiple
                        id="fallbackFor"
                        options={FIELDS_OF_LAW_ENTRIES.map(f => f.name)}
                        onChange={(_, v) => setFieldValue('fallbackFor', v)}
                        value={values.fallbackFor}
                        fullWidth
                        limitTags={5}
                        ChipProps={{ color: 'primary' }}
                        disabled={isLoading}
                        className={classes.field}
                        renderInput={params => (
                          <MemoizedTextField
                            error={touched.fallbackFor && !!errors.fallbackFor}
                            helperText={touched.fallbackFor && errors.fallbackFor}
                            label="Rechtsgebiete"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </fieldset>
                  </Box>
                </Box>
              </Box>
              {values.locationId ? (
                <MatchingConfigurationsTable chancelleryLocationId={values.locationId} />
              ) : (
                <Box display="flex" alignItems="center" mb={3}>
                  <InfoIcon color="primary" className={classes.infoIcon} />
                  <Typography>Um Konfigurationen hinzuzufügen, müsssen Sie den Standort erst mit „Speichern“ anlegen.</Typography>
                </Box>
              )}
              <SimpleUserTable title="Verknüpfte Anwälte" users={values.users} />
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                <Box width={{ xs: '100%', md: '45%', xl: '40%' }} mb={3}>
                  <fieldset>
                    <legend>Öffnungszeiten</legend>
                    {['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'].map((day, index) => (
                      <OpeningHoursSettings key={day} name={`openingHours[${index}]`} label={day} disabled={isLoading} />
                    ))}
                  </fieldset>
                </Box>
                <Box width={{ xs: '100%', md: '45%', xl: '40%' }}>
                  <fieldset>
                    <legend>Urlaubszeiten</legend>
                    <Box mb={3}>
                      <AddButton
                        aria-label="Urlaubszeitraum hinzufügen"
                        disabled={isLoading}
                        onClick={() => setFieldValue('holidays', values.holidays.concat(getNewHolidayEntry()))}
                      />
                    </Box>
                    {values.holidays.map((_, index) => (
                      <HolidaySettings key={index} index={index} name="holidays" disabled={isLoading} />
                    ))}
                    <GlobalHolidays
                      title="Weitere Urlaubszeiten für alle Standorte"
                      chancelleryId={chancelleryId || values.chancelleryId}
                      skipLocation={values.locationId}
                    />
                  </fieldset>
                </Box>
                <Box width={{ xs: '100%', md: '45%', xl: '40%' }} mb={3}>
                  <fieldset>
                    <legend>Telefon-Nr. pro Rechtsgebiet</legend>
                    <Box mb={3}>
                      <AddButton
                        aria-label="Telefonnummer hinzufügen"
                        disabled={isLoading}
                        onClick={() => setFieldValue('fieldOfLawPhones', values.fieldOfLawPhones.concat(getNewPhoneEntry()))}
                      />
                    </Box>
                    {values.fieldOfLawPhones.map((_, index) => (
                      <FieldOfLawPhoneEntry key={index} index={index} name="fieldOfLawPhones" disabled={isLoading} />
                    ))}
                  </fieldset>
                </Box>
              </Box>
              <FormActionBox>
                {id && (
                  <DeleteWithConfirmation
                    actionButtonText="Standort löschen"
                    onConfirm={async () => {
                      setFieldValue('deleted', true)
                      setClosePending(true)
                      await submitForm()
                    }}
                  >
                    Diese Aktion ist nicht umkehrbar. Standort wird permanent gelöscht.
                  </DeleteWithConfirmation>
                )}
                <AbortButton
                  onClick={() => {
                    setShouldRedirect(true)
                  }}
                  disabled={isSubmitting}
                />
                <SaveButton disabled={isSubmitting} />
                <SaveButton onClick={() => setClosePending(true)} disabled={isSubmitting}>
                  Speichern und Schließen
                </SaveButton>
              </FormActionBox>
            </Form>
          )
        }
      </Formik>
    </PageLayout>
  )
}
