import * as Yup from 'yup'

export const partnerInitialValues = {
  active: true,
  id: '',
  name: '',
  locked: false,
  phone: '',
  insurer: '',
  platform: {
    consumer: false,
    business: false,
  },
  discount: false,
  agbsPreAccepted: false,
  customerIdValidation: {
    pattern: '',
    description: '',
  },
}

export type PartnerFormValues = typeof partnerInitialValues

export const partnerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name des Partners ist erforderlich'),
  phone: Yup.string().matches(/^\+?[0-9 ]*$/, 'Nur Ziffern und Leerzeichen erlaubt.'),
})
