import React, { FC } from 'react'

import SVGProduct from 'common/icons/menu_icn_products.svg'
import { Panel } from 'common/ui'
import { PanelSegment } from 'common/ui/Panel/PanelSegment'

import styles from './ProductPanel.module.scss'

interface ProductPanelProps {
  title: string
  features?: Array<string>
}

export const ProductPanel: FC<ProductPanelProps> = ({ title, features }) => (
  <Panel>
    <PanelSegment title={title} svg={SVGProduct}>
      {features && (
        <div className={styles.flex}>
          <ul>
            {features.map((feature: string, i: number) => (
              <li key={i}>{feature}</li>
            ))}
          </ul>
        </div>
      )}
    </PanelSegment>
  </Panel>
)
