import { RadioGroup as MuiRadioGroup, FormControl, FormControlProps, FormLabel, FormHelperText } from '@mui/material'
import { useField } from 'formik'
import React, { FunctionComponent } from 'react'

import { useScrollToInvalidField } from '../useScrollToInvalidField'

type RadioGroupProps = Omit<FormControlProps, 'onChange'> & {
  name: string
  label: string
}

/* RadioGroup expects MaterialUI Radio or better FormControlLabel children
 * See: https://material-ui.com/components/radio-buttons/#radiogroup
 */
// eslint-disable-next-line fp/no-rest-parameters
export const RadioGroup: FunctionComponent<RadioGroupProps> = ({ children, name, label, ...rest }) => {
  const [field, meta] = useField({ name, type: 'radio' })
  const fieldRef = useScrollToInvalidField<HTMLDivElement>(name)
  return (
    <FormControl error={!!meta.error} ref={fieldRef} {...rest}>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup {...field} row>
        {children}
      </MuiRadioGroup>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}
