import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AsyncBiFunction, request } from 'common/utils'
import { AssignToMutation, AssignToMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/assignTo.graphql'

export const assignTo: AsyncBiFunction<string, string, string> = async (adviceRequestId, userId) => {
  const variables: AssignToMutationVariables = { adviceRequestId, userId }
  const response = await request<AssignToMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.assignTo
}
