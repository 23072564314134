import React from 'react'

import CheckIcon from 'common/icons/icn_check.svg'
import './SalesStatusLabel.scss'

export const SalesStatusLabel = ({ status, revenue }: { status: string; revenue?: number | null }) => {
  switch (true) {
    case status === 'mandate':
      return <span className="status-orange">Offen</span>
    case status === 'complete' && revenue === 0:
      return <span className="status-red">Kein Mandat</span>
    case status === 'complete' && !revenue:
      return <span className="status-orange">Akte abgelegt</span>
    case status === 'complete':
      return (
        <div className="status-green">
          <span>Abgeschlossen</span>
          <CheckIcon />
        </div>
      )
    default:
      return <span>{status}</span>
  }
}
