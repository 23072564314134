/* eslint-disable import/export, no-nested-ternary, no-redeclare */
/* Utility class to collect functional helpers */

import * as R from 'ramda'

export type Optional<T> = T | null | undefined

/* Functional types */
export type Adjuster<T> = (x: T) => T
export type OptionalAdjuster<T> = (x?: T) => Optional<T>
export type AsyncAdjuster<T> = (x: T) => Promise<T>
export type OptionalAsyncAdjuster<T> = (x?: T) => Promise<Optional<T>>
export type Function<T, R> = (x: T) => R
export type OptionalFunction<T, R> = (x?: T) => Optional<R>
export type AsyncFunction<T, R> = Function<T, Promise<R>>
export type OptionalAsyncFunction<T, R> = (x?: T) => Promise<Optional<R>>
export type BiFunction<T, U, R> = (x: T, y: U) => R
export type AsyncBiFunction<T, U, R> = BiFunction<T, U, Promise<R>>

export type Supplier<R> = () => R
export type AsyncSupplier<R> = Supplier<Promise<R>>
export type Consumer<T> = (x: T) => void
export type OptionalConsumer<T> = (x?: T) => void
export type AsyncConsumer<T> = (x: T) => Promise<void>
export type OptionalAsyncConsumer<T> = (x?: T) => Promise<void>
export type BiConsumer<T, U> = (x: T, y: U) => void
export type AsyncBiConsumer<T, U> = (x: T, y: U) => Promise<void>

/* For completion */

export type Procedure = () => void
export type AsyncProcedure = () => Promise<void>
export type InfiniteLoop = () => never

export type Predicate<T> = Function<T, boolean>
export type AsyncPredicate<T> = Function<T, Promise<boolean>>
export type BiPredicate<T, U> = BiFunction<T, U, boolean>
export type AsyncBiPredicate<T, U> = BiFunction<T, U, Promise<boolean>>

export type SetPredicate<T> = Predicate<Array<T>>
export type AsyncSetPredicate<T> = AsyncPredicate<Array<T>>
export type SetAdjuster<T> = Adjuster<Array<T>>
export type AsyncSetAdjuster<T> = AsyncAdjuster<Array<T>>
export type SetFunction<T, R> = Function<Array<T>, Array<R>>
export type AsyncSetFunction<T, R> = SetFunction<T, Promise<R>>

/**
 * Builds the inverse Predicate of f
 */
export const not =
  <T>(f: Predicate<T>) =>
  (x: T) =>
    !f(x)
/**
 * Logical OR combiner
 */
export const either =
  <T>(...a: Array<Predicate<T>>): Predicate<T> =>
  x =>
    a.some(p => p(x))
/**
 * Logical AND combiner
 */
export const all =
  <T>(...a: Array<Predicate<T>>): Predicate<T> =>
  x =>
    a.every(p => p(x))

/**
 * Checks if argument is null or undefined value
 */
export const isNotDefined = R.isNil
/**
 * Checks if argument is set to any non-null value
 */
export const isDefined = not(isNotDefined)

const _isEmpty = <T>(x: T): boolean => R.equals(x, R.empty(x))
/**
 * Checks if arguments is falsy or empty.
 */
export const isEmpty = either(isNotDefined, _isEmpty)
/**
 * Checks if arguments is not falsy or empty.
 */
export const isNotEmpty = not(isEmpty)

export const equals = <T>(a: T, b: T): boolean => R.equals(a, b)
export const notEquals = <T>(a: T, b: T): boolean => !R.equals(a, b)

/* Set appliers */
export const every =
  <T>(f: Predicate<T>): SetPredicate<T> =>
  a =>
    a.every(f)
export const some =
  <T>(f: Predicate<T>): SetPredicate<T> =>
  a =>
    a.some(f)
export const none =
  <T>(f: Predicate<T>): SetPredicate<T> =>
  a =>
    a.every(not(f))
export const includes =
  <T>(a: Array<T>): Predicate<T> =>
  be =>
    a.some(ae => R.equals(ae, be))

/**
 * Returns a new array containing only those elements that match the predicate f
 */
export const filter =
  <T>(f: Predicate<T>): SetAdjuster<T> =>
  a =>
    a.filter(f)

/**
 * Finds the first element in a that matches the predicate f or undefined
 */
export const find =
  <T>(f: Predicate<T>): Function<Array<T>, Optional<T>> =>
  a =>
    a.find(f)

/**
 * Returns the input value.
 */
export const identity = <T>(x: T) => x
/**
 * Removes all null/undefined values from a
 */
export const onlyDefined = filter(isDefined)

/**
 * Removes all unset values from a
 */
export const nonEmpty = filter(isNotEmpty)

/**
 * Maps all defined values of a by applying f. Undefined values are silently discarded.
 */
export const map =
  <T, R>(f: Function<T, R>): SetFunction<Optional<T>, R> =>
  (a): Array<R> =>
    onlyDefined(a).map(f)

/**
 * Reduce function with optional accessor for internal mapping.
 */
export function reduce<T, R>(reducer: BiFunction<R, T, R>, initial: R): Function<Array<T>, R>
export function reduce<T, U, R>(reducer: BiFunction<R, U, R>, initial: R, accessor: Function<T, U>): Function<Array<T>, R>
export function reduce<T, U, R>(reducer: BiFunction<R, T, R>, initial: R, accessor: Function<T, U>): Function<Array<T>, R>
export function reduce<T, U, R>(reducer: BiFunction<R, T | U, R>, initial: R, accessor?: Function<T, U>): Function<Array<T>, R> {
  return accessor
    ? a => a.reduce<R>((prev, curr) => reducer(prev, accessor(curr)), initial)
    : a => a.reduce<R>((prev, curr) => reducer(prev, curr), initial)
}

export function sum(a: Array<number>): number
export function sum<T>(a: Array<T>, accessor: Function<T, number>): number
export function sum<T>(a: Array<T | number>, accessor?: Function<T, number>): number {
  const nums: Array<number> = accessor ? a.map<number>(accessor) : (a as Array<number>)
  return nums.reduce((s, e) => s + e, 0)
}

export function max<T>(initial: T): Function<Array<T>, T>
export function max<T, U>(initial: T, accessor: Function<T, U>): Function<Array<T>, T>
export function max<T, U>(initial: T, accessor?: Function<T, U>): Function<Array<T>, T> {
  return accessor
    ? reduce<T, U, T>((p: T, e: T) => (equals(p, initial) ? e : accessor(e) > accessor(p) ? e : p), initial, accessor)
    : reduce<T, T>((p, e) => (equals(p, initial) ? e : e > p ? e : p), initial)
}

export function min<T>(initial: T): Function<Array<T>, T>
export function min<T, U>(initial: T, accessor: Function<T, U>): Function<Array<T>, T>
export function min<T, U>(initial: T, accessor?: Function<T, U>): Function<Array<T>, T> {
  return accessor
    ? reduce<T, U, T>((p: T, e: T) => (equals(p, initial) ? e : accessor(e) < accessor(p) ? e : p), initial, accessor)
    : reduce<T, T>((p: T, e: T) => (equals(p, initial) ? e : e < p ? e : p), initial)
}

export function avg<T>(initial: T): Function<Array<T>, T>
export function avg<T, U>(initial: T, accessor: Function<T, U>): Function<Array<T>, T>
export function avg<T, U>(initial: T, accessor?: Function<T, U>): Function<Array<T>, T> {
  return accessor
    ? reduce<T, U, T>((p: T, e: T) => (equals(p, initial) ? e : accessor(e) < accessor(p) ? e : p), initial, accessor)
    : reduce<T, T>((p: T, e: T) => (equals(p, initial) ? e : e < p ? e : p), initial)
}

export const allEmpty = every(isEmpty)
export const someEmpty = some(isEmpty)
export const noneEmpty = none(isEmpty)

/**
 * Does literally nothing
 */
export const noOp: Procedure = () => {}

/**
 * Checks if a contains at least some elements of b
 */
export const containsSome =
  <T>(b?: Array<T>) =>
  (a?: Array<T>): boolean =>
    !b || !b.length || (a && a.length && b.some(includes(a))) || false

/**
 * Checks if a contains all elements of b
 */
export const containsAll =
  <T>(b?: Array<T>) =>
  (a?: Array<T>): boolean =>
    !b || !b.length || (a && a.length && b.every(includes(a))) || false
