import { ListState, Sort, Filters } from '../interfaces'

export const buildFilterQueryString = (filters: Filters): string => {
  const filtersJson = JSON.stringify(filters)
  return `filters=${filtersJson}`
}

export const buildPaginationQueryStrings = (page: number, pageSize: number): string => `page=${page}&pageSize=${pageSize}`

export const buildSortQueryStrings = (sort: Sort): string => `sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}`

export const buildQueryStrings = ({ page, pageSize, sort, filters }: ListState): string => {
  const paginationQueryStrings = page && pageSize ? buildPaginationQueryStrings(page, pageSize) : ''
  const sortQueryStrings = sort ? buildSortQueryStrings(sort) : ''
  const filterQueryString = filters ? buildFilterQueryString(filters) : ''
  return `${paginationQueryStrings}&${sortQueryStrings}&${filterQueryString}`
}

export const mapListStateToSearch = (listState: ListState): string => buildQueryStrings(listState)
