import * as Yup from 'yup'

import { FIELDS_OF_LAW } from 'common/constants/fieldsOfLaw'

import { SimpleUser } from '../components/SimpleUserTable'

import { ConfigWeight, FieldOfLaw, GetPartnersQuery, GetProductsQuery, Partner, Product } from './schemaDefinition'

export type EmptyOrderVolume = {
  fieldOfLaw: null
  weeklyMin: string
  weeklyMax: string
}

export type LocalOrderVolume = {
  fieldOfLaw: {
    name: string
    id: string
  }
  weeklyMax: string
  weeklyMin: string
}

export const chancelleryInitialValues = {
  active: true,
  deleted: false,
  name: '',
  contactPersonFamilyName: '',
  contactPersonGivenName: '',
  contactPersonSalutation: '',
  email: '',
  phone: '',
  chancelleryId: '', // On edit value from API
  ustId: '',
  iban: '',
  eConsultId: '',
  secupay: '',
  powerBI1: '',
  powerBI2: '',
  callbacks: false,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  orderVolumes: [] as Array<LocalOrderVolume | EmptyOrderVolume>,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  users: [] as Array<SimpleUser>,
}

export type ChancelleryFormValues = typeof chancelleryInitialValues

export const chancelleryValidationSchema = Yup.object().shape({
  active: Yup.boolean(),
  name: Yup.string().required('Name der Kanzlei ist erforderlich'),
  contactPersonSalutation: Yup.string(),
  contactPersonFamilyName: Yup.string().required('Name des Ansprechpartners ist erforderlich.'),
  contactPersonGivenName: Yup.string().required('Vorname des Ansprechpartners ist erforderlich.'),
  email: Yup.string().email('Bitte eine gültige Email-Adresse angeben.').required('Email-Adresse ist erforderlich.'),
  phone: Yup.string()
    .matches(/^\+?[0-9 ]*$/, 'Nur Ziffern und Leerzeichen erlaubt.')
    .required('Telefon ist erforderlich.'),
  orderVolumes: Yup.array().of(
    Yup.object().shape({
      weeklyMin: Yup.string().matches(/^[0-9]*$/, 'Bitte nur Ziffern eintragen.'),
      weeklyMax: Yup.string().matches(/^[0-9]*$/, 'Bitte nur Ziffern eintragen.'),
    })
  ),
  chancelleryId: Yup.string(),
  ustId: Yup.string().matches(/^[A-Z]{2}[0-9A-Z]{0,12}$/, 'Bitte nur gültige Umsatzsteuer-IDs eingeben.'),
  iban: Yup.string().matches(
    /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/,
    'Bitte nur gültige IBAN eingeben.'
  ),
  eConsultId: Yup.string(),
  secupay: Yup.string(),
  powerBI1: Yup.string(),
  powerBI2: Yup.string(),
})

export type EmptyFieldOfLawPhone = { fieldOfLaw: null; phone: string }

type LocalHoliday = {
  start: unknown
  end: unknown
  global?: boolean | null
  fieldsOfLaw?: Array<{ id: string; name: string }> | null
}

export type LocalFieldOfLawPhone = {
  fieldOfLaw: { id: string; name: string }
  phone: string
}

export const locationInitialValues = {
  locationId: '',
  chancelleryId: '',
  zip: '',
  city: '',
  phone: '',
  streetAddress: '',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  fallbackFor: [] as Array<string>,
  openingHours: [
    {
      enabled: false,
      begin: '',
      end: '',
    },
    {
      enabled: false,
      begin: '',
      end: '',
    },
    {
      enabled: false,
      begin: '',
      end: '',
    },
    {
      enabled: false,
      begin: '',
      end: '',
    },
    {
      enabled: false,
      begin: '',
      end: '',
    },
    {
      enabled: false,
      begin: '',
      end: '',
    },
    {
      enabled: false,
      begin: '',
      end: '',
    },
  ],
  holidays: [] as Array<LocalHoliday>, // eslint-disable-line @typescript-eslint/consistent-type-assertions
  fieldOfLawPhones: [] as Array<LocalFieldOfLawPhone | EmptyFieldOfLawPhone>, // eslint-disable-line @typescript-eslint/consistent-type-assertions
  users: [] as Array<SimpleUser>, // eslint-disable-line @typescript-eslint/consistent-type-assertions
  deleted: false,
}

export type LocationFormValues = typeof locationInitialValues

/**
 * This function takes two strings "which" and "value" and expects them
 * to match HH:mm. It then calculates for each the number of minutes since
 * midnight and checks if "value" is after (= larger than) "which".
 * Note that it does not check if the numbers are really numbers and if they
 * are valid times. We do that before in simpler validations.
 */
const after = (which: string) => (value: string) => {
  if (!which || which.split(':').length < 2 || !value) {
    return true
  }
  const whichArray = which.split(':')
  const valueArray = value.split(':')
  if (valueArray.length < 2) {
    return false
  }
  const timeReducer = (acc: number, curr: string, idx: number): number =>
    idx === 0 ? acc + parseInt(curr, 10) * 60 : acc + parseInt(curr, 10)
  const whichTime = whichArray.reduce(timeReducer, 0)
  const valueTime = valueArray.reduce(timeReducer, 0)
  return valueTime > whichTime
}

export const chancelleryLocationValidationSchema = Yup.object().shape({
  streetAddress: Yup.string().required('Anschrift ist erforderlich'),
  zip: Yup.string()
    .matches(/^\d{5}$/, 'Bitte gültige Postleitzahl angeben')
    .required('Postleitzahl ist erforderlich'),
  city: Yup.string().required('Stadt ist erforderlich'),
  fallbackFor: Yup.array().of(Yup.string().oneOf(Object.keys(FIELDS_OF_LAW))),
  openingHours: Yup.array().of(
    Yup.object().shape({
      enabled: Yup.boolean(),
      begin: Yup.string()
        .matches(/^((0\d)|(1\d)|(2[0-3])):[0-5]\d$/, 'Uhrzeiten bitte im Format HH:mm angeben')
        .when('enabled', {
          is: true,
          then: Yup.string().required('Für aktive Tage bitte Uhrzeiten eintragen'),
        }),
      end: Yup.string()
        .matches(/^((0\d)|(1\d)|(2[0-3])):[0-5]\d$/, 'Uhrzeiten bitte im Format HH:mm angeben')
        .when('enabled', {
          is: true,
          then: Yup.string()

            .when('begin', (begin: string, current: ReturnType<typeof Yup.string>) =>
              current.test('time-test', 'Die Schlusszeit muss nach der Öffnungszeit liegen', after(begin))
            )
            .required('Für aktive Tage bitte Uhrzeiten eintragen'),
        }),
    })
  ),
  holidays: Yup.array().of(
    Yup.object().shape({
      global: Yup.boolean(),
      start: Yup.date().required('Urlaubszeiten müssen ein Beginndatum haben'),
      end: Yup.date().required('Urlaubszeiten müssen ein Enddatum haben'),
    })
  ),
  fieldOfLawPhones: Yup.array().of(
    Yup.object().shape({
      phone: Yup.string()
        .matches(/^\+?[0-9 ]*$/, 'Nur Ziffern und Leerzeichen erlaubt.')
        .required('Telefonnummer ist erforderlich.'),
      fieldOfLaw: Yup.object().shape({
        id: Yup.string().oneOf(Object.keys(FIELDS_OF_LAW)),
        name: Yup.string().oneOf(Object.keys(FIELDS_OF_LAW)),
      }),
    })
  ),
})

export type ConfigurationFormValues = typeof configurationInitialValues

export const configurationInitialValues = {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  configurationId: '',
  locationId: '',
  active: true,
  zipCodes: [] as Array<string>,
  fieldsOfLaw: [] as Array<string>,
  products: [] as GetProductsQuery['products']['list'],
  partners: [] as GetPartnersQuery['partners']['list'],
  priority: 0,
  weight: ConfigWeight.Default,
  deleted: false,
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

export const configurationValidationSchema = Yup.object().shape({
  fieldsOfLaw: Yup.array().min(1, 'Konfiguration muss mindestens ein Rechtsgebiet enthalten'),
})

const fiveMinutesInMs = 1000 * 60 * 5

export const configTestPanelInitialValues = {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  zipCode: '',
  fieldOfLaw: null as FieldOfLaw | null,
  partner: null as Pick<Partner, 'id' | 'name'> | null,
  product: null as Pick<Product, 'id' | 'name'> | null,
  dateTime: new Date(Math.ceil(new Date().getTime() / fiveMinutesInMs) * fiveMinutesInMs),
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

export type ConfigTestPanelFormValues = typeof configTestPanelInitialValues

export const configTestValidationSchema = Yup.object().shape({
  zipCode: Yup.string().required('Die Postleitzahl ist erforderlich').length(5, 'Bitte eine 5-stellige Postleitzahl angeben'),
  fieldOfLaw: Yup.mixed().required('Rechtsgebiet ist erforderlich'),
})
