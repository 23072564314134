import { Function } from '@grapes-agency/universal'
import React, { useMemo, useState, FC } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import { EventSourceComponent } from 'common/hooks/interfaces'
import SVGExit from 'common/icons/exit.svg'

/**
 * A function that can return any JSX which will be used as a Modals content. Use provided close callback to close the
 * Modal from within the content
 * @param close function that closes modal when called
 * @return JSX.Element any JSX.Element that should be displayed as modal content
 */
export interface ModalContentRenderer {
  (close: () => void): JSX.Element
}

/**
 * Toggles Modal open state.
 * @returns openState the new state of the modal
 */
export interface ModalStateTrigger {
  (): boolean
}

const wrapValidator = (trigger: ModalStateTrigger, validator?: () => boolean) => () => {
  if (!validator || validator()) {
    return trigger()
  }
  return false
}

const modalTrigger: Function<ModalContentRenderer, EventSourceComponent> =
  content =>
  ({ renderTrigger, disabled, validator }) => {
    const [open, setOpen] = useState(false)

    const trigger: ModalStateTrigger = () => {
      setOpen(!open)
      return !open
    }

    return (
      <>
        {renderTrigger({ trigger: wrapValidator(trigger, validator), disabled })}
        <Modal isOpen={open} toggle={trigger} className="new-modal">
          <div className="btn-cancel close-modal" onClick={trigger} style={{ top: '-25px', right: '-25px' }}>
            <SVGExit height="14" width="14" />
          </div>
          <ModalBody>{content(trigger)}</ModalBody>
        </Modal>
      </>
    )
  }

/**
 * Takes a content render function that returns a JSX.Element and returns an EventSourceComponent that
 * will open the rendered content in a modal when the event is triggered.
 * @param renderContent render function that returns a JSX.Element which will be used as modal content
 * @returns EventSourceComponent EventSourceComponent that triggers open state of modal
 */
export const useModalTrigger = (renderContent: ModalContentRenderer) =>
  useMemo(() => modalTrigger(renderContent), [renderContent])

/**
 * Takes a content render function that returns a JSX.Element and returns a ModalComponent and an EventSourceComponent that
 * will open the rendered content in a modal when the Event is triggered. This is used to separate the modal from the
 * EventSource, so the EventSource can be unmounted while the modal is open (for example when the modal is opened
 * through a PopoverMenu
 * @param renderContent render function that returns a JSX.Element which will be used as modal content
 * @returns Array first element is the ModalComponent. Second
 * element is EventSourceComponent to trigger open state of modal
 */
export const useExternalModalTrigger: Function<ModalContentRenderer, [FC, EventSourceComponent]> = content => {
  const [open, setOpen] = useState(false)
  const trigger = () => {
    setOpen(!open)
    return !open
  }

  const ModalComponent: FC = () => (
    <Modal isOpen={open} toggle={trigger} className="new-modal">
      <div className="btn-cancel close-modal" onClick={trigger} style={{ top: '-25px', right: '-25px' }}>
        <SVGExit height="14" width="14" />
      </div>
      <ModalBody>{content(trigger)}</ModalBody>
    </Modal>
  )

  const TriggerComponent: EventSourceComponent = ({ renderTrigger, disabled, validator }) =>
    renderTrigger({ trigger: wrapValidator(trigger, validator), disabled })

  return [ModalComponent, TriggerComponent]
}
