import React, { useState, FC } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import { Input } from 'reactstrap'

import SVGCheck from 'common/icons/Check.svg'
import { ButtonClear } from 'common/ui/Button'

import { NUMBER_OPERATOR_ENTRIES } from '../../constants'
import { FilterInputProps, FilterOperator } from '../../interfaces'
import { noOp } from '../../utils'

export const NumberFilterInput: FC<FilterInputProps> = ({ name, onFilterSubmit, suggestions, placeholder, operator }) => {
  const [value, setValue] = useState('')
  const [usedOperator, setOperator] = useState<FilterOperator>(operator || FilterOperator.Equals)

  const onOperatorChange = (event: React.FormEvent<HTMLInputElement>) => {
    setOperator(event.currentTarget.value as FilterOperator)
  }

  const onValueChange = (value: string) => {
    setValue(value)
  }

  const handleSubmit = (item: any) => {
    const filter = {
      name,
      operator: usedOperator,
      value: !item ? '' : Number(item.replace(',', '.')) || '-',
    }

    if (item || usedOperator === 'notEmpty' || usedOperator === 'empty') {
      onFilterSubmit(filter)
    }
  }

  const onValueSubmit = () => {
    handleSubmit(value)
  }

  const onTagAdded = (tag: any) => {
    handleSubmit(tag)
  }

  return (
    <span>
      <span style={{ marginRight: 10, display: 'inline-block' }}>
        <Input onChange={onOperatorChange} value={usedOperator} type="select" name="numberFilter" id="numberFilter">
          {NUMBER_OPERATOR_ENTRIES.map(item => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Input>
      </span>
      <ReactTags
        tags={[]}
        suggestions={suggestions}
        handleAddition={onTagAdded}
        handleInputChange={onValueChange}
        handleDelete={noOp}
        minQueryLength={0}
        placeholder={placeholder}
        readOnly={usedOperator === 'notEmpty' || usedOperator === 'empty'}
      />
      <ButtonClear iconLeft={<SVGCheck width="16" height="16" />} onClick={onValueSubmit} />
    </span>
  )
}
