import React, { useState, FC, ChangeEvent } from 'react'
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap'

import { useEffectTrigger, createTriggerButton } from 'common/hooks/Effects'
import { ButtonPrimary } from 'common/ui/Button'

import SVGRedirectRequest from '../../../components/images/antrag_weiterleiten.svg'
import SVGBack from '../../../components/images/back.svg'
import SVGRedirect from '../../../components/images/weiterleiten.svg'
import { assign } from '../../effects'

interface AssignFormProps {
  adviceRequestId: string
  users: Array<{ name: string; id: string }>
  onSuccess: () => void
}

const SubmitButton = createTriggerButton(ButtonPrimary, {
  children: 'Antrag zuweisen',
  type: 'submit',
  iconLeft: <SVGRedirect width="18" height="15" />,
})

export const AssignForm: FC<AssignFormProps> = ({ adviceRequestId, users, onSuccess }) => {
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<{ [prop: string]: string }>({})
  const [assignTarget, setAssignTarget] = useState('')

  const onChangeAssign = (event: ChangeEvent<HTMLInputElement>) => {
    setAssignTarget(event.currentTarget.value)
  }

  const Submit = useEffectTrigger(assign)
  const input = {
    adviceRequestId,
    assignTarget,
  }

  const setSuccessTrue = () => setSuccess(true)

  if (success) {
    const user = users.find(c => c.id === assignTarget)
    return (
      <>
        <SVGRedirectRequest height="160" width="200" />
        <Label>
          Dieser Antrag wurde erfolgreich <span>&quot; {user!.name} &quot;</span> zugewiesen.
        </Label>
        <ButtonPrimary onClick={onSuccess} iconLeft={<SVGBack width="16" height="16" />}>
          Zurück zu Ihren Anträgen
        </ButtonPrimary>
      </>
    )
  }

  return (
    <>
      <Form className="form">
        <FormGroup>
          <h3 className="h3 small">Wem soll der Antrag zugewiesen werden?</h3>
          <Input type="select" name="redirectTarget" id="redirectTarget" onChange={onChangeAssign} value={assignTarget}>
            <option value="">---Bitte wählen---</option>
            {users.map(users => (
              <option key={users.id} value={users.id}>
                {users.name}
              </option>
            ))}
          </Input>
          {!!errors.assignTarget && <FormFeedback>{errors.assignTarget}</FormFeedback>}
        </FormGroup>
        <Submit input={input} onSuccess={setSuccessTrue} onError={setErrors} renderTrigger={SubmitButton} />
      </Form>
    </>
  )
}
