import { ADVICE_REQUESTS_ENDPOINT, STATUS_AT_CHANCELLERY } from 'common/constants'
import { ContactType } from 'common/interfaces'
import { LoggedInUser } from 'common/user-context'
import { request } from 'common/utils'
import { getChancelleryFilter } from 'packages/lawyers/utils'

import query from '../graphql/adviceTotalCount.graphql'
import { AdviceTotalCountQuery, FilterOperator } from '../interfaces'

export const getCallbacksCount = async (user: LoggedInUser, chancelleryLocations: Array<{ id: string }>): Promise<number> => {
  const response: AdviceTotalCountQuery = await request<AdviceTotalCountQuery>(ADVICE_REQUESTS_ENDPOINT, query, {
    filters: [
      getChancelleryFilter(user, chancelleryLocations),
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: STATUS_AT_CHANCELLERY,
      },
      {
        name: '',
        operator: FilterOperator.Or,
        value: [
          {
            name: 'contactType',
            operator: FilterOperator.Equals,
            value: ContactType.Callback,
          },
          {
            name: 'contactType',
            operator: FilterOperator.Equals,
            value: ContactType.Email,
          },
        ],
      },
    ],
  })

  return response.adviceRequestsCount.total
}
