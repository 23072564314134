import { FormControl, FormControlProps, FormGroup, FormHelperText, FormLabel } from '@mui/material'
import { useField } from 'formik'
import React, { FunctionComponent } from 'react'

import { useScrollToInvalidField } from '../useScrollToInvalidField'

type CheckboxGroupProps = Omit<FormControlProps, 'onChange'> & {
  name: string
  label: string
}

// eslint-disable-next-line fp/no-rest-parameters
export const CheckboxGroup: FunctionComponent<CheckboxGroupProps> = ({ children, name, label, ...rest }) => {
  const [, meta] = useField({ name })
  const fieldRef = useScrollToInvalidField<HTMLDivElement>(name)
  return (
    <FormControl error={!!meta.error} ref={fieldRef} {...rest}>
      <FormLabel>{label}</FormLabel>
      <FormGroup row>{children}</FormGroup>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}
