import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/resetPassword.graphql'

export const setPasswordMutation = async (resetToken: string, newPassword: string) => {
  const variables = {
    resetToken,
    newPassword,
  }
  try {
    const response = await request<boolean>(USER_AUTH_ENDPOINT, query, variables)
    return response
  } catch (error) {
    throw error
  }
}
