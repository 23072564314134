import classNames from 'classnames'
import React, { EventHandler, MouseEvent } from 'react'

import { ElementWithOverlay } from 'common/components'
import { SearchContainer } from 'common/components/SearchField'
import { ButtonPrimary } from 'common/ui/Button'
import { NamedDiv, ReactDivFC } from 'common/utils'

import SVGCalender from '../images/dashboard-calender.svg'
import SVGOpenRequests from '../images/dashboard-offene-anfragen.svg'
import SVGCallbacks from '../images/dashboard-rueckmeldung.svg'
import SVGSupport from '../images/dashboard-support.svg'

import './DashboardView.scss'

interface DashboardProps {
  userName: string
  redirectClick: EventHandler<MouseEvent<HTMLButtonElement>>
  actualDay: string
  actualMonth: string
  openCount: string | number
  callbackCount: string | number
}

const DashboardPage: ReactDivFC = NamedDiv('dashboard-page')
const DashboardPanel: ReactDivFC = NamedDiv('dashboard')
const Card: ReactDivFC = NamedDiv('dashboard-card')
const CardElement: ReactDivFC = NamedDiv('dashboard-card-element')

interface OverlayTextProps {
  count: string | number
}

const OverlayText: React.FC<OverlayTextProps & React.HTMLAttributes<HTMLDivElement>> = ({ count, className }) => {
  let sizeCN = ''
  if (count < 100) {
    sizeCN = 'low'
  } else if (count < 1000) {
    sizeCN = 'med'
  } else if (count > 1000) {
    sizeCN = 'high'
  }
  return <div className={classNames('overlay-text', `overlay-text-${sizeCN}`, className)}>{count}</div>
}

interface OverlayDateProps {
  day: string
  month: string
}

const OverlayDate: React.FC<OverlayDateProps> = ({ day, month }) => (
  <div className="overlay-date">
    {day}
    <br />
    {month}
  </div>
)

export const Dashboard: React.FC<DashboardProps> = ({
  userName,
  redirectClick,
  actualDay,
  actualMonth,
  openCount,
  callbackCount,
}) => (
  <DashboardPage>
    <h2 className="text-center h2 small">Willkommen, {userName}!</h2>
    <legend className="text-center legend regular">Ihre Zusammenfassung für heute:</legend>
    <ElementWithOverlay className="overlay-calender" overlayElement={<OverlayDate day={actualDay} month={actualMonth} />}>
      <SVGCalender width={62} height={62} />
    </ElementWithOverlay>
    <DashboardPanel>
      <Card>
        <CardElement>
          <ElementWithOverlay overlayElement={<OverlayText count={callbackCount} className="overlay-callback" />}>
            <SVGCallbacks width={90} height={90} />
          </ElementWithOverlay>
        </CardElement>
        <CardElement>
          <h1>Anstehende Rückmeldungen</h1>
        </CardElement>
        <CardElement>
          <ButtonPrimary onClick={redirectClick} name="callback">
            Rückmeldungen anzeigen
          </ButtonPrimary>
        </CardElement>
      </Card>
      <Card>
        <CardElement>
          <ElementWithOverlay overlayElement={<OverlayText count={openCount} />}>
            <SVGOpenRequests width={90} height={90} />
          </ElementWithOverlay>
        </CardElement>
        <CardElement>
          <h1>Offene Anfragen</h1>
        </CardElement>
        <CardElement>
          <ButtonPrimary onClick={redirectClick} name="call">
            Anfragen anzeigen
          </ButtonPrimary>
        </CardElement>
      </Card>
    </DashboardPanel>
    <Card className="card-support">
      <CardElement>
        <SVGSupport width={80} height={80} />
      </CardElement>
      <CardElement>
        <ButtonPrimary onClick={redirectClick} name="support">
          Support kontaktieren
        </ButtonPrimary>
      </CardElement>
    </Card>
    <p className="legend medium m-b-10 m-t-10">Direkte Antragssuche:</p>
    <SearchContainer />
  </DashboardPage>
)
