import React, { ChangeEvent, useState, FC } from 'react'
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap'

import { useEffectTrigger, createTriggerButton } from 'common/hooks/Effects'
import { ButtonPrimary } from 'common/ui/Button'

import SVGRedirectRequest from '../../../components/images/antrag_weiterleiten.svg'
import SVGBack from '../../../components/images/back.svg'
import SVGRedirect from '../../../components/images/weiterleiten.svg'
import { redirect } from '../../effects'

interface Address {
  city: string
  street: string
  zip: string
}

interface RedirectFormProps {
  onSuccess: () => void
  adviceRequestId: string
  chancelleries: Array<{ name: string; id: string; address: Address }>
}

const SubmitButton = createTriggerButton(ButtonPrimary, {
  children: 'Antrag weiterleiten',
  type: 'submit',
  iconLeft: <SVGRedirect width="18" height="15" />,
})

const getAddress = (chancellery: { address: Address }): string =>
  `${chancellery.address.street}, ${chancellery.address.zip} ${chancellery.address.city}`.trim()

export const RedirectForm: FC<RedirectFormProps> = ({ adviceRequestId, chancelleries, onSuccess }) => {
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<{ [prop: string]: string }>({})
  const [redirectTarget, setRedirectTarget] = useState('')

  const onChangeRedirect = (event: ChangeEvent<HTMLInputElement>) => {
    setRedirectTarget(event.currentTarget.value)
  }

  const Submit = useEffectTrigger(redirect)
  const input = {
    adviceRequestId,
    chancelleryId: redirectTarget,
  }

  const setSuccessTrue = () => setSuccess(true)

  if (success) {
    const chancellery = chancelleries.find(c => c.id === redirectTarget)
    return (
      <>
        <SVGRedirectRequest height="160" width="200" />
        <Label>
          Dieser Antrag wurde erfolgreich an <span>&quot; {chancellery!.name} &quot;</span> weitergeleitet.
        </Label>
        <ButtonPrimary onClick={onSuccess} iconLeft={<SVGBack width="16" height="16" className="m-r-10" />}>
          Zurück zu Ihren Anträgen
        </ButtonPrimary>
      </>
    )
  }

  return (
    <>
      <Form className="form">
        <FormGroup>
          <h3 className="h3 small">An wen soll der Antrag weitergeleitet werden ?</h3>
          <Input type="select" name="redirectTarget" id="redirectTarget" onChange={onChangeRedirect} value={redirectTarget}>
            <option value="">---Bitte wählen---</option>
            {chancelleries.map(chancellery => (
              <option key={chancellery.id} value={chancellery.id}>
                {`${chancellery.name}, ${getAddress(chancellery)}`}
              </option>
            ))}
          </Input>
          {!!errors.redirectTarget && <FormFeedback>{errors.redirectTarget}</FormFeedback>}
        </FormGroup>
        <Submit input={input} onSuccess={setSuccessTrue} onError={setErrors} renderTrigger={SubmitButton} />
      </Form>
    </>
  )
}
