import React from 'react'

export function Page404() {
  return (
    <div className="panel">
      <div className="panel-body">
        <h1>Seite nicht gefunden</h1>
      </div>
    </div>
  )
}
