import { Effect } from '@grapes-agency/universal'

import { redirect as redirectAction } from '../../actions'

interface RedirectInput {
  adviceRequestId: string
  chancelleryId: string
}

export const redirect: Effect<RedirectInput, void> = async ({ adviceRequestId, chancelleryId }) => {
  await redirectAction(adviceRequestId, chancelleryId)
}
