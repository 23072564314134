import * as R from 'ramda'

export const closingReasons = [
  'Kunde konnte nicht erreicht werden',
  'Telefonnummer des Kunden nicht vergeben / fehlerhaft',
  'Kunde wollte keine Beratung mehr',
  'Anliegen/Problem hat sich vor Vermittlung erledigt',
  'Kunde unzufrieden mit Beratung (Beschwerde)',
  'Doppelte Anfrage',
  'Testanfrage',
]

export const internalClosingReasons = R.append('Keine Kanzlei verfügbar', closingReasons)
