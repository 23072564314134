import { Flex } from '@grapes-agency/ui'
import classnames from 'classnames'
import React, { useCallback, useState, FC, RefObject } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { Partner } from 'common/api/types'
import { ElementWithOverlay } from 'common/components'
import { SearchContainer } from 'common/components/SearchField'
import { withUser, UserContextProps } from 'common/user-context'
import { ChancelleryLocationsQuery, FilterOperator } from 'packages/lawyers/interfaces/schemaDefinition'

import { getOpenRequestsCount, getPotentialRequestsCount } from '../actions'
import { AdviceRequestEntry } from '../interfaces'

import { AssignFilter } from './AssignFilter'
import { ClosedRequests } from './ClosedRequestsView'
import { CompleteMandates } from './CompleteMandatesView'
import { LiveReloadButton } from './LiveReloadButton'
import { Mandates } from './MandatesView'
import { NewButton } from './NewButton'
import { NotificationCount } from './NotificationCount'
import { OpenRequests } from './OpenRequestsView'
import { PotentialMandates } from './PotentialMandatesView'
import SVGFiles from './images/akten.svg'
import SVGClosedFiles from './images/geschlossene_akten.svg'
import SVGClosedRequests from './images/geschlossene_anfrage.svg'
import SVGMandate from './images/mandate.svg'
import SVGOpenRequests from './images/offene_anfrage.svg'

import './LawyerListView.scss'

interface LawyerProps extends UserContextProps {
  activeTab: string
  onToggle: (tabId: string) => () => void
  onRowClick: (rowId: string) => React.EventHandler<React.FormEvent<HTMLTableRowElement>>
  partners: Array<Partner>
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  assignableUsers: Array<{ id: string; name: string }>
  node: RefObject<HTMLDivElement>
  addedAdviceRequests: Array<AdviceRequestEntry>
  onAddedClick: React.MouseEventHandler<HTMLButtonElement>
  hasPremium?: boolean
}

const LawyerListView: FC<LawyerProps> = ({
  activeTab,
  onToggle,
  onRowClick,
  partners,
  chancelleryLocations,
  assignableUsers,
  node,
  user,
  onAddedClick,
  addedAdviceRequests,
  hasPremium,
}) => {
  const [assignFilter, setAssignFilter] = useState('')
  const [addedAdviceRequestIds, setAddedAdviceRequestIds] = useState<Array<string>>([])

  const onAssignFilterClick = useCallback(() => {
    if (user) {
      setAssignFilter(prevAssignFilter => (prevAssignFilter === user.id ? '' : user.id))
    }
  }, [user])

  const notificationFilter = assignFilter
    ? [
        {
          name: 'assignedTo',
          operator: FilterOperator.Equals,
          value: assignFilter,
        },
      ]
    : []

  const onReloadClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAddedAdviceRequestIds(addedAdviceRequests.map(a => a.id))
    onAddedClick(event)
  }

  return (
    <>
      <div className="lawyer-topbar">
        <Nav tabs>
          <NavItem onClick={onToggle('1')}>
            <NavLink className={classnames({ active: activeTab === '1' })}>
              <ElementWithOverlay
                overlayElement={
                  <NotificationCount getCount={getOpenRequestsCount(user!, chancelleryLocations, notificationFilter)} />
                }
                positioning={['top', 'right']}
              >
                <SVGOpenRequests height="66" width="86" />
              </ElementWithOverlay>
              <span className="nav-link__text">Offene Anfragen</span>
            </NavLink>
          </NavItem>
          <NavItem onClick={onToggle('2')}>
            <NavLink className={classnames({ active: activeTab === '2' })}>
              <ElementWithOverlay
                overlayElement={
                  <NotificationCount getCount={getPotentialRequestsCount(user!, chancelleryLocations, notificationFilter)} />
                }
                positioning={['top', 'right']}
              >
                <SVGMandate height="66" width="90" />
              </ElementWithOverlay>
              <span className="nav-link__text">Wiedervorlagen</span>
            </NavLink>
          </NavItem>
          <NavItem onClick={onToggle('3')}>
            <NavLink className={classnames({ active: activeTab === '3' })}>
              <SVGClosedRequests height="66" width="80" />
              <span className="nav-link__text">Geschlossene Anfragen</span>
            </NavLink>
          </NavItem>
          <NavItem onClick={onToggle('4')}>
            <NavLink className={classnames({ active: activeTab === '4' })}>
              <SVGFiles height="66" width="75" />
              <span className="nav-link__text">Offene Akten</span>
            </NavLink>
          </NavItem>
          <NavItem onClick={onToggle('5')}>
            <NavLink className={classnames({ active: activeTab === '5' })}>
              <SVGClosedFiles height="66" width="75" />
              <span className="nav-link__text">Geschlossene Akten</span>
            </NavLink>
          </NavItem>
        </Nav>
        <div className="lawyer-topbar__tools">
          <Flex>
            <NewButton />
            <AssignFilter active={!!assignFilter} onClick={onAssignFilterClick} />
            <SearchContainer className="lawyer-topbar__search" />
          </Flex>
        </div>
      </div>
      <LiveReloadButton addedLength={addedAdviceRequests.length} onClick={onReloadClick} className="m-b-20" />
      <TabContent activeTab={activeTab} className="tab">
        <TabPane tabId="1">
          {activeTab === '1' && (
            <OpenRequests
              hasPremium={!!hasPremium}
              addedAdviceRequestIds={addedAdviceRequestIds}
              onRowClick={onRowClick}
              user={user}
              partners={partners}
              chancelleryLocations={chancelleryLocations}
              assignableUsers={assignableUsers}
              assignFilter={assignFilter}
              node={node}
            />
          )}
        </TabPane>
        <TabPane tabId="2">
          {activeTab === '2' && (
            <PotentialMandates
              hasPremium={!!hasPremium}
              user={user}
              chancelleryLocations={chancelleryLocations}
              onRowClick={onRowClick}
              partners={partners}
              assignableUsers={assignableUsers}
              assignFilter={assignFilter}
            />
          )}
        </TabPane>
        <TabPane tabId="3">
          {activeTab === '3' && (
            <ClosedRequests
              user={user}
              chancelleryLocations={chancelleryLocations}
              onRowClick={onRowClick}
              partners={partners}
              assignableUsers={assignableUsers}
              assignFilter={assignFilter}
            />
          )}
        </TabPane>
        <TabPane tabId="4">
          {activeTab === '4' && (
            <Mandates
              user={user}
              chancelleryLocations={chancelleryLocations}
              onRowClick={onRowClick}
              partners={partners}
              assignableUsers={assignableUsers}
              assignFilter={assignFilter}
            />
          )}
        </TabPane>
        <TabPane tabId="5">
          {activeTab === '5' && (
            <CompleteMandates
              user={user}
              chancelleryLocations={chancelleryLocations}
              onRowClick={onRowClick}
              partners={partners}
              assignableUsers={assignableUsers}
              assignFilter={assignFilter}
            />
          )}
        </TabPane>
      </TabContent>
    </>
  )
}

const LawyerListViewWithUser = withUser(LawyerListView)

export { LawyerListViewWithUser as LawyerListView }
