import React, { FC } from 'react'
import { Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { Spinner } from 'common/components'
import { ButtonCancel, ButtonConfirm, ButtonBack } from 'common/ui/Button'

import SVGRedirectRequest from '../images/antrag_weiterleiten.svg'

interface AssignModalProps {
  users: Array<{ name: string; id: string }>
  open: boolean
  loading: boolean
  success: boolean
  assignTarget: string
  onCancelClick: () => void
  onCompleteClick: () => void
  onAssignClick: () => void
  setAssignTarget: (event: React.FormEvent<HTMLInputElement>) => void
  errors?: { [props: string]: string }
}

export const AssignModal: FC<AssignModalProps> = ({
  users,
  open,
  loading,
  success,
  assignTarget,
  onCancelClick,
  onCompleteClick,
  onAssignClick,
  setAssignTarget,
  errors = {},
}) => {
  if (loading || !open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  if (success) {
    const user = users.find(c => c.id === assignTarget)
    return (
      <Modal toggle={onCompleteClick} isOpen={open} className="success-modal" id="RedirectSuccessModal">
        <ModalBody>
          <SVGRedirectRequest height="160" width="200" />
          <Label>
            Dieser Antrag wurde erfolgreich <span>&quot; {user!.name} &quot;</span> zugewiesen.
          </Label>
        </ModalBody>
        <ModalFooter>
          <ButtonBack onClick={onCompleteClick}>Zurück zu Ihren Anträgen</ButtonBack>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} id="RedirectModal">
      <ModalHeader>Wem soll der Antrag zugewiesen werden?</ModalHeader>
      <ModalBody>
        <Form className="form">
          <FormGroup>
            <Input type="select" name="redirectTarget" id="redirectTarget" onChange={setAssignTarget} value={assignTarget}>
              <option value="">---Bitte wählen---</option>
              {users.map(users => (
                <option key={users.id} value={users.id}>
                  {users.name}
                </option>
              ))}
            </Input>
            {!!errors.assignTarget && <FormFeedback>{errors.assignTarget}</FormFeedback>}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={onCancelClick}>Zurück</ButtonCancel>
        <ButtonConfirm onClick={onAssignClick}>Antrag zuweisen</ButtonConfirm>
      </ModalFooter>
    </Modal>
  )
}
