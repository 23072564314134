import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

import {
  createGridColumnsFrom,
  HeaderButtonGroup,
  MuiDataGrid,
  PageInfoline,
  PageLayout,
  useGridData,
  CenterBox,
  Badge,
} from 'common/components-mui'
import { PARTNERS_ENDPOINT } from 'common/constants'

import partnersQuery from '../graphql/getPartners.graphql'
import { GetPartnersQuery, Platform } from '../interfaces/schemaDefinition'

const getStatus = (params: GridCellParams): React.ReactElement =>
  params.row.active ? (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  )

const columns: Array<GridColDef> = createGridColumnsFrom([
  { field: 'name', headerName: 'Partner', flex: 0.25 },
  {
    field: 'platform',
    headerName: 'Plattformen',
    sortable: false,
    filterable: false,
    flex: 0.25,
    valueFormatter: params => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const platform = params.value as Platform
      return Object.entries(platform).reduce((result, [key, value]) => {
        const capitalize = (s: string): string => {
          const firstLetter = s.slice(0, 1)
          return firstLetter.toUpperCase().concat(s.slice(1))
        }
        if (!value) {
          return result
        }
        if (result.length > 0) {
          return `${result}, ${capitalize(key)}`
        }
        return capitalize(key)
      }, '')
    },
  },
  { field: 'active', sortable: false, filterable: false, headerName: 'Status', width: 200, renderCell: getStatus },
])

export const PartnersListPage: FunctionComponent = () => {
  const { data, error, actions, tableState } = useGridData<'partners', GetPartnersQuery>(
    PARTNERS_ENDPOINT,
    partnersQuery,
    'partners',
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
    }
  )
  const history = useHistory()

  const totalCount = data?.total ?? 0

  return (
    <PageLayout error={error} heading="Partnerverwaltung" spacing="table">
      <HeaderButtonGroup>
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        <Button onClick={() => history.push('/partners/new')}>
          <AddCircleOutlineIcon titleAccess="Kanzlei hinzufügen" />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>Insgesamt {totalCount} Einträge</PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        columns={columns}
        rows={data?.list ?? []}
        loading={!data}
        rowCount={totalCount}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={r => (r.row.id ? history.push(`/partners/edit/${r.row.id}`) : undefined)}
      />
    </PageLayout>
  )
}
