import React, { FC } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

import { ButtonPrimary } from 'common/ui/Button'
import { Procedure } from 'common/utils'

import SVGFile from '../images/akte.svg'
import SVGCloseRequest from '../images/antrag_close.svg'
import SVGExit from '../images/exit.svg'
import SVGPostpone from '../images/zurueckstellen.svg'

interface FinishAdviceModalProps {
  open: boolean
  onOpenCloseModal: Procedure
  onOpenMandateModal: Procedure
  onOpenAppointmentModal: Procedure
  onCloseClick: Procedure
}

export const FinishAdviceModal: FC<FinishAdviceModalProps> = ({
  open,
  onOpenAppointmentModal,
  onOpenCloseModal,
  onOpenMandateModal,
  onCloseClick,
}) => (
  <Modal isOpen={open} className="new-modal" toggle={onCloseClick}>
    <ModalBody>
      <div className="btn-cancel close-modal" onClick={onCloseClick}>
        <SVGExit height="14" width="14" />
      </div>
      <h3>Sie möchten den Fall abschließen, was soll mit dem Antrag geschehen?</h3>
    </ModalBody>
    <ModalFooter>
      <ButtonPrimary block iconLeft={<SVGPostpone width="20" height="20" />} onClick={onOpenAppointmentModal} className="m-b-20">
        Wiedervorlage erstellen
      </ButtonPrimary>
      <ButtonPrimary block iconLeft={<SVGCloseRequest width="20" height="20" />} onClick={onOpenCloseModal} className="m-b-20">
        Antrag schließen
      </ButtonPrimary>
      <ButtonPrimary block iconLeft={<SVGFile width="20" height="20" />} onClick={onOpenMandateModal}>
        Akte erstellen
      </ButtonPrimary>
    </ModalFooter>
  </Modal>
)
