import { Effect } from '@grapes-agency/universal'

import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils/requests'

import query from '../../graphql/proposalRejected.graphql'
import { ProposalRejectedMutation, ProposalInvoiceSentMutationVariables } from '../../interfaces/schemaDefinition'

export const proposalRejected: Effect<ProposalInvoiceSentMutationVariables, string> = async (
  variables
): Promise<ProposalRejectedMutation['proposalRejected']> => {
  const response = await request<ProposalRejectedMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.proposalRejected
}
