import classnames from 'classnames'
import React, { FC } from 'react'
import { Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import { ButtonPrimary } from 'common/ui'
import { Procedure } from 'common/utils'

import SVGExit from '../images/exit.svg'

import './CompleteModal.scss'

interface CompleteModalProps {
  open: boolean
  loading: boolean
  onCancelClick: Procedure
  onViewSalesClick: Procedure
}

export const CompleteModal: FC<CompleteModalProps> = ({ loading, open, onCancelClick, onViewSalesClick }) => {
  if (loading || !open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} id="CompleteModal">
      <ModalBody className="complete-modal__body">
        <div className="complete-modal__body__header">
          <h3 className={classnames('h3 small', 'complete-modal__body__header__title')}>Akte ablegen</h3>
        </div>
        <div className="btn-cancel close-modal" onClick={onCancelClick}>
          <SVGExit height="14" width="14" />
        </div>
        <Label className="complete-modal__body__label">
          Die Akte kann ausschließlich in der Umsatzverwaltung abgelegt werden
        </Label>
      </ModalBody>
      <ModalFooter className="complete-modal__footer">
        <ButtonPrimary onClick={onViewSalesClick} type="submit">
          Umsatzverwaltung anzeigen
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  )
}
