/* eslint-disable complexity */
import { Alert, Box, Snackbar, Checkbox as MuiCheckBox, FormControlLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Form, Formik, FormikHelpers } from 'formik'
import { pipe } from 'ramda'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router'

import {
  PageLayout,
  TextField,
  Checkbox,
  FormActionBox,
  AbortButton,
  SaveButton,
  Select,
  CheckboxGroup,
  DeleteWithConfirmation,
} from 'common/components-mui'
import { legalProtectionInsurer, PARTNERS_ENDPOINT, VALIDATION_PATTERN } from 'common/constants'
import { useSequence } from 'common/hooks/Sequence'
import { addOptionalPropIf, request } from 'common/utils'

import { GetPartnerAPIData, mapPartnerAPIDataToForm } from '../components'
import createPartnerMutation from '../graphql/createPartner.graphql'
import deletePartnerMutation from '../graphql/deletePartner.graphql'
import updatePartnerMutation from '../graphql/updatePartner.graphql'
import { partnerInitialValues, PartnerFormValues, partnerValidationSchema } from '../interfaces/formSchemas'
import { PartnerInput, CreatePartnerMutation, UpdatePartnerMutation, DeletePartnerMutation } from '../interfaces/schemaDefinition'

const toApiInputFormat = (values: PartnerFormValues): PartnerInput => {
  const { id, active, name, locked, phone, insurer, platform, discount, agbsPreAccepted, customerIdValidation } = values
  const baseInput = {
    id,
    active,
    name,
    locked,
    phone,
    agbsPreAccepted,
    platform,
    discount,
    partnerValidation: {
      enabled: Boolean(customerIdValidation.pattern),
      regex: customerIdValidation.pattern,
      intro: customerIdValidation.description,
    },
  }

  const withInsurer = addOptionalPropIf<{ insurer: string }, PartnerInput>(insurer.length > 0)({ insurer })
  const withId = addOptionalPropIf<{ id: string }, PartnerInput>(id.length > 0)({ id })

  const withOptionalValues = pipe(withInsurer, withId)
  return withOptionalValues(baseInput)
}

const deletePartner = (id: string): Promise<DeletePartnerMutation | undefined> =>
  request<DeletePartnerMutation>(PARTNERS_ENDPOINT, deletePartnerMutation, { id }).catch(() => undefined)

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(3),
  },
  fullWidth: {
    width: '100%',
  },
}))

type PartnerFormPageParams = {
  id?: string
}

export const PartnerFormPage: FunctionComponent = () => {
  const { id } = useParams<PartnerFormPageParams>()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [closePending, setClosePending] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [isCheatSnackBarOpen, setIsCheatSnackbarOpen] = useState(false)
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = useState(false)
  const weHaveACheater = useSequence(VALIDATION_PATTERN)
  const isNewProduct = window.location.pathname === '/partners/new'

  const handleSubmit =
    (triggerRedirect: () => void) =>
    (values: PartnerFormValues, helpers: FormikHelpers<PartnerFormValues>): void => {
      const input = toApiInputFormat(values)
      if (isNewProduct) {
        request<CreatePartnerMutation>(PARTNERS_ENDPOINT, createPartnerMutation, {
          partner: input,
        })
          .then(({ createPartner: partner }) => {
            if (partner) {
              helpers.setValues(mapPartnerAPIDataToForm(partner))
            }
          })
          .then(() => triggerRedirect())
          .catch(() => setIsErrorSnackBarOpen(true))
          .then(() => helpers.setSubmitting(false))
      } else {
        request<UpdatePartnerMutation>(PARTNERS_ENDPOINT, updatePartnerMutation, {
          partner: input,
        })
          .then(({ updatePartner: partner }) => {
            if (partner) {
              helpers.setValues(mapPartnerAPIDataToForm(partner))
            }
          })
          .then(() => triggerRedirect())
          .then(() => helpers.setSubmitting(false))
      }
    }

  useEffect(() => {
    if (weHaveACheater) {
      setIsCheatSnackbarOpen(true)
    }
  }, [weHaveACheater])

  const disabled = isLoading

  const initialValues = {
    ...partnerInitialValues,
    id: id || '',
  }

  return shouldRedirect ? (
    <Redirect to="/partners" />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit(() => (closePending ? setShouldRedirect(true) : undefined))}
      validationSchema={partnerValidationSchema}
    >
      {({ isSubmitting, values }) => (
        <PageLayout heading={id ? 'Partner bearbeiten' : 'Partner anlegen'}>
          <Form>
            <GetPartnerAPIData id={id} setLoading={setIsLoading} />
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <Box width={{ xs: '100%', sm: '45%', lg: '33%' }}>
                <Checkbox name="active" label="Aktiv" disabled={disabled} className={classes.field} />
                <TextField name="name" label="Name Partner" disabled={disabled} fullWidth className={classes.field} />
                <TextField name="id" label="Partner-ID" disabled={disabled || !!id} className={classes.field} fullWidth />
                <Checkbox
                  name="agbsPreAccepted"
                  label="AGBs akzeptiert voreingestellt"
                  disabled={disabled}
                  className={classes.field}
                />
                {weHaveACheater ? (
                  <TextField
                    name="customerIdValidation.pattern"
                    label="Validierungsmuster Kundennummer"
                    disabled={disabled || !weHaveACheater}
                    fullWidth
                    className={classes.field}
                  />
                ) : (
                  <FormControlLabel
                    className={classNames(classes.fullWidth, classes.field)}
                    label="Kundennummervalidierung eingerichtet"
                    disabled={true}
                    control={<MuiCheckBox checked={!!values.customerIdValidation.pattern} />}
                  />
                )}
                {((values.customerIdValidation.description && values.customerIdValidation.pattern) || weHaveACheater) && (
                  <TextField
                    multiline
                    name="customerIdValidation.description"
                    label="Beschreibung Validierungsmuster"
                    disabled={disabled || !weHaveACheater}
                    fullWidth
                    className={classes.field}
                  />
                )}
                <Checkbox name="locked" label="System-Partner" disabled={disabled || !weHaveACheater} className={classes.field} />
              </Box>
              <Box width={{ xs: '100%', sm: '45%', lg: '33%' }}>
                <CheckboxGroup name="platform" label="Plattform" className={classes.field}>
                  <Checkbox name="platform.consumer" label="Consumer" />
                  <Checkbox name="platform.business" label="Business" />
                </CheckboxGroup>
                <Select name="insurer" label="Versicherer" disabled={disabled} fullWidth className={classes.field}>
                  {legalProtectionInsurer.map(insurer => (
                    <option key={insurer}>{insurer}</option>
                  ))}
                </Select>
                <Checkbox name="discount" label="Rabattierung" disabled={disabled} className={classes.field} />
                <TextField name="phone" label="Telefonnummer Beratung" disabled={disabled} fullWidth className={classes.field} />
              </Box>
            </Box>
            <FormActionBox>
              {!isNewProduct && (
                <DeleteWithConfirmation
                  actionButtonText="Partner löschen"
                  disabled={values.locked && !weHaveACheater}
                  onConfirm={async () => {
                    await deletePartner(values.id)
                    setShouldRedirect(true)
                  }}
                >
                  Diese Aktion ist nicht umkehrbar. Partner wird permanent gelöscht.
                </DeleteWithConfirmation>
              )}
              <AbortButton
                onClick={() => {
                  setShouldRedirect(true)
                }}
                disabled={isSubmitting}
              />
              <SaveButton disabled={isSubmitting} />
              <SaveButton onClick={() => setClosePending(true)} disabled={isSubmitting}>
                Speichern und Schließen
              </SaveButton>
            </FormActionBox>
          </Form>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={1500}
            open={isCheatSnackBarOpen}
            onClose={() => setIsCheatSnackbarOpen(false)}
          >
            <Alert severity="success">Du kannst jetzt das Validierungsmuster bearbeiten</Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isErrorSnackBarOpen}
            autoHideDuration={3000}
            onClose={() => setIsErrorSnackBarOpen(false)}
          >
            <Alert severity="error">Partner-ID &quot;{values.id}&quot; existiert bereits</Alert>
          </Snackbar>
        </PageLayout>
      )}
    </Formik>
  )
}
