import { History } from 'history'

// see: https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
const MAX_ALLOWED_URL_LENGTH = 2083

export const pushSearch = (history: History) => (search: string) => {
  const searchEncoded = encodeURI(search)

  if (searchEncoded.length > MAX_ALLOWED_URL_LENGTH) {
    // eslint-disable-next-line
    console.error(
      `Maximum URL length of ${MAX_ALLOWED_URL_LENGTH} exceeded by ${searchEncoded.length}. Processing query string skipped.`
    )
    return
  }

  history.push({
    pathname: history.location.pathname,
    search: searchEncoded,
  })
}
