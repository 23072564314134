import { useFormikContext } from 'formik'
import { FunctionComponent, useState, useEffect } from 'react'

import { fetchUser, mapResponseToUser } from '../actions'
import { FormUser, initialValues } from '../interfaces/formSchema'

type GetApiUserProps = {
  id?: string
  setLoading?: ReturnType<typeof useState>[1]
}

export const GetApiUser: FunctionComponent<GetApiUserProps> = ({ id, setLoading }) => {
  const { setValues } = useFormikContext<FormUser>()

  useEffect(() => {
    if (!id) {
      setValues(initialValues)
      setLoading?.(false)
      return
    }

    setLoading?.(true)

    fetchUser(id)
      .then(user => setValues(mapResponseToUser(user)))
      .catch(() => undefined)
      .then(() => setLoading?.(false))
  }, [id, setLoading, setValues])
  return null
}
