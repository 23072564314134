import classnames from 'classnames'
import React from 'react'

interface TextAreaStaticFieldProps {
  label: string
  value: string
  formatter?: (value: string) => string
}

export const TextAreaStaticField = (props: TextAreaStaticFieldProps) => {
  const staticFieldClass = classnames('static-field', 'static-field--text-area')
  return (
    <div className={staticFieldClass}>
      <div className="static-field__label">{props.label}</div>
      <div className="static-field__value">{props.value}</div>
    </div>
  )
}
