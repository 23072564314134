import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useField, useFormikContext } from 'formik'
import { difference } from 'lodash'
import React, { FC, memo } from 'react'
import useSWR from 'swr'

import { USER_AUTH_ENDPOINT, ROLES } from 'common/constants'
import { Role } from 'common/interfaces'

import { fetchUserRoles } from '../actions'
import { FormUser } from '../interfaces/formSchema'

interface RolesSelectProps {
  name: string
  className?: string
}

const MemoizedTextField = memo(TextField)

export const RolesSelect: FC<RolesSelectProps> = ({ name, className }) => {
  const { data: rolesWithRights, error } = useSWR([USER_AUTH_ENDPOINT, 'roles'], fetchUserRoles)
  const isLoading = !rolesWithRights && !error
  const [field, , helpers] = useField({ name, type: 'text' })
  const {
    values: { rights },
    setFieldValue,
  } = useFormikContext<FormUser>()

  const getRightsFromRoles = (roleIds: Array<string>): Array<string> => {
    const matchingRolesWithRights = rolesWithRights?.filter(role => roleIds.includes(role.id))
    const rightsOfRoles = matchingRolesWithRights?.flatMap(r => r.rights.map(right => right.id))
    return rightsOfRoles ?? []
  }

  return (
    <Autocomplete
      className={className}
      multiple
      filterSelectedOptions
      id={name}
      options={ROLES}
      value={field.value}
      onChange={(_event, newValue) => {
        const removedRoles = difference(field.value, newValue)
        const addedRoles = difference(newValue, field.value)

        helpers.setValue(newValue)

        if (addedRoles.length > 0) {
          const roleIds = addedRoles.map(el => el.id)
          const addedRightsOfRoles = getRightsFromRoles(roleIds)
          setFieldValue('rights', [...rights, ...addedRightsOfRoles])
        }

        if (removedRoles.length > 0) {
          const roleIds = removedRoles.map(el => el.id)
          const removedRightsOfRoles = getRightsFromRoles(roleIds)
          setFieldValue('rights', difference(rights, removedRightsOfRoles))
        }
      }}
      getOptionLabel={(option: Role) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <MemoizedTextField
          {...params}
          onFocus={field.onBlur}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          label="Benutzerrollen"
          placeholder="z.B. Administrator"
        />
      )}
      disabled={isLoading}
    />
  )
}
