import React, { useContext, FC } from 'react'

import { LawyerContext } from '../context'
import './LawyerControllingPage.scss'

export const LawyerControllingPage: FC = () => {
  const { powerBI } = useContext(LawyerContext)

  if (!powerBI) {
    return null
  }

  return (
    <>
      <div className="lawyer-controlling-wrapper">
        <iframe title="Bearbeitungsstand" src={powerBI[0]} className="lawyer-controlling-frame" />
      </div>
      <div className="lawyer-controlling-wrapper">
        <iframe title="Umwandlungsquote" src={powerBI[1]} className="lawyer-controlling-frame" />
      </div>
    </>
  )
}
