import { GridColDef } from '@mui/x-data-grid'
import React, { FunctionComponent } from 'react'

import { createGridColumnsFrom, MuiDataGrid, PageInfoline, PageLayout, useGridData } from 'common/components-mui'
import { FIELDS_OF_LAW_ENDPOINT } from 'common/constants'

import getFieldsOfLawQuery from '../graphql/getFieldsOfLaw.graphql'
import { GetFieldsOfLawQuery } from '../interfaces/schemaDefinition'

export const FieldsOfLawListPage: FunctionComponent = () => {
  const { data, error, actions, tableState } = useGridData<'fieldsOfLaw', GetFieldsOfLawQuery>(
    FIELDS_OF_LAW_ENDPOINT,
    getFieldsOfLawQuery,
    'fieldsOfLaw',
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
    }
  )

  const columns: Array<GridColDef> = createGridColumnsFrom([
    { field: 'name', headerName: 'Rechtsgebiet', flex: 0.33 },
    { field: 'weeklyMin', headerName: 'Wöchentlich mind.', sortable: false, filterable: false, flex: 0.33 },
    { field: 'weeklyMax', headerName: 'Wöchentlich max.', sortable: false, filterable: false, flex: 0.33 },
  ])

  return (
    <PageLayout error={error} heading="Rechtsgebieteverwaltung" spacing="table">
      <PageInfoline>Insgesamt {data?.total ?? '0'} Einträge</PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        columns={columns}
        rows={data?.list ?? []}
        loading={!data}
        rowCount={data?.total}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={() => null}
      />
    </PageLayout>
  )
}
