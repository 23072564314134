import { Icon } from '@grapes-agency/ui'
import { FieldArray, FieldArrayConfig } from 'formik'
import React from 'react'

import { InputField, TextAreaField } from 'common/components/Form'
import { ButtonSecondary, ButtonClear } from 'common/ui'

import SvgRemove from '../../icons/remove-circle.svg'

import styles from './CustomFieldArray.module.scss'

interface CustomFieldArrayProps extends FieldArrayConfig {
  values: Array<string>
  label: string
  name: string
  fallback: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  inputType?: 'input' | 'textarea'
}

export const CustomFieldArray = ({ values, name, label, fallback, onChange, inputType = 'input' }: CustomFieldArrayProps) => (
  <FieldArray
    name={name}
    render={arrayHelpers => (
      <div className={styles.fieldArray}>
        <label>{label}</label>
        {values && values.length > 0 ? (
          <>
            <div className={styles.list}>
              {values.map((value, index) => (
                <div key={index} className={styles.entry}>
                  {inputType === 'input' ? (
                    <InputField id={`${name}.${index}`} name={`${name}.${index}`} value={value} onChange={onChange} />
                  ) : (
                    <TextAreaField id={`${name}.${index}`} name={`${name}.${index}`} value={value} onChange={onChange} />
                  )}
                  <ButtonClear type="button" onClick={() => arrayHelpers.remove(index)} className={styles.buttonRemove}>
                    <Icon svg={SvgRemove} className={styles.icon} />
                  </ButtonClear>
                </div>
              ))}
            </div>
            <ButtonSecondary type="button" onClick={() => arrayHelpers.insert(values.length, '')} className={styles.buttonAdd}>
              {fallback}
            </ButtonSecondary>
          </>
        ) : (
          <ButtonSecondary type="button" onClick={() => arrayHelpers.push('')} className={styles.buttonAddText}>
            {fallback}
          </ButtonSecondary>
        )}
      </div>
    )}
  />
)
