import React from 'react'

import { Function } from './functional'

export type ReactDivFC = React.FC<React.HTMLAttributes<HTMLDivElement>>

export const NamedDiv: Function<string, ReactDivFC> =
  cn =>
  ({ children, className }) =>
    <div className={`${cn} ${className || ''}`}>{children}</div>
