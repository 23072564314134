import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field } from 'formik'
import React, { FunctionComponent } from 'react'

type CheckBoxProps = Omit<FormControlLabelProps, 'control'> & {
  name: string
}

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: theme.spacing(1),
  },
}))

// For reasons hidden in the dark MaterialUI sets the padding of their Checkbox wrongly to 9px which messes everything up. Here's correctly styled based on theme spacing.
const StyledCheckbox: FunctionComponent<MuiCheckboxProps> = props => {
  const classes = useStyles()
  return <MuiCheckbox classes={{ root: classes.checkbox }} {...props} />
}
// eslint-disable-next-line fp/no-rest-parameters
export const Checkbox: FunctionComponent<CheckBoxProps> = ({ name, ...rest }) => (
  <FormControlLabel control={<Field name={name} as={StyledCheckbox} type="checkbox" color="primary" />} {...rest} />
)
