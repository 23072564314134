import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Spinner } from 'reactstrap'

import { UserContextProps, withUser } from 'common/user-context'
import { uploadFiles } from 'common/utils'
import { CompleteSalesModal } from 'packages/lawyers/components/CompleteSalesModal'
import { ConfirmAdviceModal } from 'packages/lawyers/components/ConfirmAdviceModal'
import { SalesModal } from 'packages/lawyers/components/SalesModal'
import { noRevenueReasons } from 'packages/lawyers/constants'
import { AdviceRequest, FileInput, Revenue } from 'packages/lawyers/interfaces/schemaDefinition'

import {
  createSales,
  deleteSales,
  deleteSalesAttachment,
  fetchAdviceDetail,
  saveSalesAttachments,
  setToComplete,
  updateSales,
} from '../actions'
import { SalesDetailView } from '../components/SalesDetailView'

type LawyerProps = UserContextProps
type Confirmation = 'sales' | 'file'

const SalesDetailContainer: FC<LawyerProps> = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [adviceRequest, setAdviceRequest] = useState<AdviceRequest>()
  const [openSalesModal, setOpenSalesModal] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [openCompleteModal, setOpenCompleteModal] = useState<boolean>(false)
  const [editSales, setEditSales] = useState<boolean>(false)
  const [selectedRevenue, setSelectedRevenue] = useState<Revenue>()
  const [noRevenueReason, setNoRevenueReason] = useState<string>(noRevenueReasons[0])
  const [completeSuccess, setCompleteSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [fileId, setFileId] = useState<string>('')
  const [confirmation, setConfirmation] = useState<{ name: Confirmation; title: string }>({ name: 'sales', title: '' })

  const getAdviceRequest = useCallback(async () => {
    fetchAdviceDetail(id).then(response => {
      const { adviceRequest, error } = response
      if (error && error.graphQLErrors[0].message === '404-adviceRequest-not-found') {
        history.push('/404')
      }
      setAdviceRequest(adviceRequest)
      return adviceRequest
    })
  }, [id, history])

  useEffect(() => {
    getAdviceRequest()
  }, [getAdviceRequest])

  if (!adviceRequest) {
    return <Spinner />
  }

  const handleDeleteSales = async () => {
    setLoading(true)
    await deleteSales(adviceRequest.id, selectedRevenue!.id)
    if (adviceRequest.sales) {
      const index = adviceRequest.sales.entries.findIndex(sales => sales.id === selectedRevenue!.id)
      setAdviceRequest({
        ...adviceRequest,
        revenue: adviceRequest.revenue! - selectedRevenue!.revenue,
        sales: {
          ...adviceRequest.sales,
          entries: [...adviceRequest.sales.entries.slice(0, index), ...adviceRequest.sales.entries.slice(index + 1)],
        },
      })
    }
    setLoading(false)
    setOpenConfirmModal(false)
  }

  const handleUpdateSales = async (revenue: number, invoiceNumber: string, attachments: Array<FileInput>) => {
    if (attachments.length > 0) {
      await saveSalesAttachments(
        adviceRequest.id,
        adviceRequest.sales ? [...adviceRequest.sales.files, ...attachments] : attachments
      )
    }

    if (editSales) {
      await updateSales(adviceRequest.id, selectedRevenue!.id, {
        revenue,
        invoiceNumber,
      })
      const index = adviceRequest.sales!.entries.findIndex(sales => sales.id === selectedRevenue!.id)
      setAdviceRequest({
        ...adviceRequest,
        revenue: adviceRequest.revenue! - selectedRevenue!.revenue + revenue,
        sales: {
          entries: [
            ...adviceRequest.sales!.entries.slice(0, index),
            {
              id: selectedRevenue!.id,
              createdAt: selectedRevenue!.createdAt,
              revenue,
              invoiceNumber,
            },
            ...adviceRequest.sales!.entries.slice(index + 1),
          ],
          files: [...adviceRequest.sales!.files, ...attachments],
        },
      })
      setEditSales(false)
    } else {
      await createSales(adviceRequest.id, { revenue, invoiceNumber })
      getAdviceRequest()
    }
    setOpenSalesModal(false)
  }

  const handleCompleteAdviceRequest = async () => {
    setLoading(true)
    try {
      if (adviceRequest.revenue === 0 || !adviceRequest.revenue) {
        await setToComplete({ id: adviceRequest.id, revenue: 0, noRevenueReason })
        setAdviceRequest({ ...adviceRequest, revenue: 0, status: 'complete' })
      } else {
        await setToComplete({ id: adviceRequest.id, revenue: adviceRequest.revenue, noRevenueReason: undefined })
        setAdviceRequest({ ...adviceRequest, status: 'complete' })
      }
      setLoading(false)
      setCompleteSuccess(true)
    } catch (err) {
      setLoading(false)
      setCompleteSuccess(false)
      throw err
    }
  }

  const handleAddFilesChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files !== null) {
      const files = await uploadFiles([...event.currentTarget.files])
      await saveSalesAttachments(adviceRequest.id, adviceRequest.sales ? [...adviceRequest.sales.files, ...files] : files)
      if (adviceRequest.sales) {
        setAdviceRequest({
          ...adviceRequest,
          sales: { ...adviceRequest.sales, files: [...adviceRequest.sales.files, ...files] },
        })
      } else {
        setAdviceRequest({
          ...adviceRequest,
          sales: { entries: [], files },
        })
      }
    }
  }

  const handleDeleteFileClick = async () => {
    setLoading(true)
    await deleteSalesAttachment(adviceRequest.id, fileId)
    const index = adviceRequest.sales!.files.findIndex(file => file.id === fileId)
    if (adviceRequest.sales) {
      setAdviceRequest({
        ...adviceRequest,
        sales: {
          ...adviceRequest.sales,
          files: [...adviceRequest.sales!.files.slice(0, index), ...adviceRequest.sales!.files.slice(index + 1)],
        },
      })
    }
    setLoading(false)
    setOpenConfirmModal(false)
  }

  return (
    <>
      <SalesDetailView
        adviceRequest={adviceRequest}
        onAddSalesClick={() => setOpenSalesModal(true)}
        onEditSalesClick={sales => {
          setEditSales(true)
          setSelectedRevenue(sales)
          setOpenSalesModal(true)
        }}
        onDeleteSalesClick={sales => {
          setSelectedRevenue(sales)
          setOpenConfirmModal(true)
          setConfirmation({ name: 'sales', title: 'Wollen Sie den Umsatz löschen?' })
        }}
        onCompleteClick={() => setOpenCompleteModal(true)}
        onAddFilesChange={handleAddFilesChange}
        onDeleteFileClick={fileId => {
          setFileId(fileId)
          setConfirmation({ name: 'file', title: 'Wollen Sie den Anhang löschen?' })
          setOpenConfirmModal(true)
        }}
      />
      <ConfirmAdviceModal
        open={openConfirmModal}
        onCloseClick={() => setOpenConfirmModal(false)}
        onConfirmClick={() => (confirmation.name === 'sales' ? handleDeleteSales() : handleDeleteFileClick())}
        loading={loading}
        title={confirmation.title}
        confirmBtnTitle="Löschen"
      />
      <SalesModal
        open={openSalesModal}
        edit={editSales}
        revenue={editSales ? selectedRevenue!.revenue : ''}
        invoiceNumber={editSales ? selectedRevenue!.invoiceNumber! : ''}
        onCloseClick={() => {
          setEditSales(false)
          setOpenSalesModal(false)
        }}
        onConfirmClick={handleUpdateSales}
      />
      <CompleteSalesModal
        open={openCompleteModal}
        success={completeSuccess}
        loading={loading}
        status={adviceRequest.status}
        noRevenue={!adviceRequest.revenue}
        noRevenueReason={noRevenueReason}
        onCloseClick={() => setOpenCompleteModal(false)}
        onSuccessCloseClick={() => {
          setOpenCompleteModal(false)
          history.push('/chancellery/sales')
        }}
        onAddSalesClick={() => {
          setOpenCompleteModal(false)
          setOpenSalesModal(true)
        }}
        onCompleteClick={handleCompleteAdviceRequest}
        onNoRevenueReasonChange={event => setNoRevenueReason(event.target.value)}
      />
    </>
  )
}

const SalesDetailContainerWithUser = withUser(SalesDetailContainer)

export { SalesDetailContainerWithUser as SalesDetailContainer }
