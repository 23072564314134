import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/createExport.graphql'
import { CreateExportQuery, CreateExportQueryVariables } from '../interfaces'

export const createExport = async (variables: CreateExportQueryVariables) => {
  const response = await request<CreateExportQuery>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.createExport
}
