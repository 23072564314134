import { Box, CircularProgress, Typography, Alert } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FunctionComponent } from 'react'

import { PageLayout } from 'common/components-mui'

import { ErrorListCard } from '../components'
import { useFallbackCoverage } from '../components/useFallbackCoverage'

const useStyles = makeStyles(theme => ({
  sectionHeading: {
    marginBottom: theme.spacing(3),
  },
  fallbackInfoCard: {
    width: '400px',
    marginRight: theme.spacing(10),
  },
}))

const SectionHeading: FunctionComponent = ({ children }) => {
  const { sectionHeading } = useStyles()
  return (
    <Typography variant="h6" component="h2" className={sectionHeading}>
      {children}
    </Typography>
  )
}

const SectionContent: FunctionComponent = ({ children }) => <Box mb={4}>{children}</Box>

// eslint-disable-next-line complexity
export const IssuesDashboardPage: FunctionComponent = () => {
  const fallbackIssues = useFallbackCoverage()
  const fallbacksLoading = !fallbackIssues.data && !fallbackIssues.error
  const classes = useStyles()

  const fieldOfLawNamesMissing =
    fallbackIssues.data?.missingProblems
      .map(problem => problem.fieldOfLaw?.name)
      .filter((element): element is string => Boolean(element)) ?? []
  const hasMissingFieldsOfLaw = fieldOfLawNamesMissing.length > 0

  const fieldOfLawNamesTemporaryMissing =
    fallbackIssues.data?.temporaryMissingProblems
      .map(problem => problem.fieldOfLaw?.name)
      .filter((element): element is string => Boolean(element)) ?? []
  const hasTemporaryMissingFieldsOfLaw = fieldOfLawNamesTemporaryMissing.length > 0

  return (
    <PageLayout heading="Aktueller Handlungsbedarf">
      <SectionHeading>Fallback-Konfigurationen</SectionHeading>
      <SectionContent>
        {fallbacksLoading ? (
          <CircularProgress />
        ) : fallbackIssues.error || !fallbackIssues.data ? (
          <Alert severity="error">Es ist ein Fehler beim Laden der Fallback-Daten aufgetreten</Alert>
        ) : !hasMissingFieldsOfLaw && !hasTemporaryMissingFieldsOfLaw ? (
          <Alert severity="info">Es sind keine Probleme mit Fallback-Konfigurationen bekannt.</Alert>
        ) : (
          <Box display="flex">
            <ErrorListCard
              title="Fehlende Rechtsgebiete"
              list={fieldOfLawNamesMissing}
              severity={hasMissingFieldsOfLaw ? 'error' : 'success'}
              className={classes.fallbackInfoCard}
            >
              {hasMissingFieldsOfLaw
                ? 'Für folgende Rechtsgebiete wurden keine Fallback-Konfigurationen gefunden:'
                : 'Keine Probleme gefunden.'}
            </ErrorListCard>
            <ErrorListCard
              title="Urlaubsbedingte Abdeckungsprobleme"
              list={fallbackIssues.data.temporaryMissingProblems
                .map(problem => problem.fieldOfLaw?.name)
                .filter((element): element is string => Boolean(element))}
              severity={hasTemporaryMissingFieldsOfLaw ? 'warning' : 'success'}
              className={classes.fallbackInfoCard}
            >
              {hasTemporaryMissingFieldsOfLaw
                ? 'Für folgende Rechtsgebiete gibt es wegen Urlaubszeiten keine genügende Abdeckung:'
                : 'Keine Probleme gefunden.'}
            </ErrorListCard>
          </Box>
        )}
      </SectionContent>
    </PageLayout>
  )
}
