import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { Fragment, FC } from 'react'
import { Table } from 'reactstrap'

import { Partner } from 'common/api/types'
import { FilterTags, SortFilterTh, Spinner } from 'common/components'
import { DatePickerInput, TextFilterInput } from 'common/components/FilterInput'
import { PRODUCT_NAMES, ADVICE_REQUESTS_ENDPOINT, FIELDS_OF_LAW_ENTRIES } from 'common/constants'
import { ListProps, withList } from 'common/container'
import SVGRedirected from 'common/icons/Icon_Forward.svg'
import { LoggedInUser } from 'common/user-context'
import { convertToTagValues, formatDate } from 'common/utils'

import { getNextWorkday } from '../../chancellery-search/utils/getCallbackTimes'
import query from '../graphql/adviceRequests.graphql'
import { LawyerRequest } from '../interfaces'
import { ChancelleryLocationsQuery, FilterOperator, SortDirection } from '../interfaces/schemaDefinition'
import { wasRedirected, getChancelleryFilter } from '../utils'

import { AdviceMenuButton } from './AdviceMenu'
import { PartnerCell } from './PartnerCell'
import { ToolbarTabs } from './ToolbarTabs'
import { ToolbarImage } from './ToolbarTabs/ToolbarTabs'
import Mail from './images/mail.svg'

interface EmailRequestsProps extends ListProps<LawyerRequest> {
  partners: Array<Partner>
  user?: LoggedInUser
  assignableUsers: Array<{ id: string; name: string }>
  assignFilter?: string
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  onRowClick: (rowId: string) => React.EventHandler<React.FormEvent<HTMLTableRowElement>>
  hasPremium: boolean
}

const createOnSort = (func: (id: string) => void, id: string) => () => func(id)

const padLeft = (text: string | number, filler = '0', length = 2) => {
  let result = text.toString()
  while (result.length < length) {
    result = `${filler}${result}`
  }
  return result
}

const getForwardTime = (input?: string | Date) => {
  if (input) {
    const date = new Date(input)
    const limit = getNextWorkday(date)
    limit.setHours(date.getHours())
    return limit
  }
  return undefined
}

const formatForwardTime = (limit?: Date) => {
  if (limit) {
    return `Spätestens ${padLeft(limit.getDate())}.${padLeft(limit.getMonth() + 1)}.${limit.getFullYear()} ${Math.min(
      Math.max(12, limit.getHours()),
      22
    )}:00 Uhr`
  }
  return false
}

const oneHour = 1000 * 60 * 60

const isInMinutes = (forwardTime: string | Date | undefined, minutes: number) => {
  if (!forwardTime) {
    return false
  }
  const date = new Date(forwardTime)
  return (date.getTime() + oneHour - Date.now()) / 1000 / 60 <= minutes
}

export const EmailRequestsView: FC<EmailRequestsProps> = ({
  partners,
  assignableUsers,
  sort,
  onSort,
  onFilterChange,
  list,
  onRowClick,
  filters,
  onFilterRemove,
  page,
  pageSize,
  total,
  onNext,
  onPrev,
  onReload,
  loading,
  hasPremium,
}) => (
  <Fragment>
    <ToolbarTabs
      pagination={{ page, pageSize, total, label: 'E-Mail Anfragen' }}
      onNext={onNext}
      onPrev={onPrev}
      title="Ausstehende E-Mails"
      imgSource={ToolbarImage.imgMail}
    />
    <Spinner condition={loading} center>
      <FilterTags filters={filters} onTagRemove={onFilterRemove} partners={partners} />
      {list.length > 0 ? (
        <div className="vertical-div">
          <Mail height="20" width="20" className="vertical-icon-center" />
          <Table hover responsive>
            <thead>
              <tr>
                <SortFilterTh
                  id="emailRequestsForwardedAt"
                  name="answerDate"
                  sort={sort}
                  onSort={createOnSort(onSort, 'ForwardedAt={true}')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={DatePickerInput}
                  placeholder="Beantworten bis..."
                >
                  Beantworten bis
                </SortFilterTh>
                {hasPremium && <th>Produkt</th>}
                <SortFilterTh
                  id="emailRequestsPersonName"
                  name="personName"
                  sort={sort}
                  onSort={createOnSort(onSort, 'personName')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={TextFilterInput}
                  placeholder="Name..."
                >
                  Name
                </SortFilterTh>
                <SortFilterTh
                  id="emailRequestsAdviceId"
                  name="adviceId"
                  sort={sort}
                  onSort={createOnSort(onSort, 'adviceId')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={TextFilterInput}
                  placeholder="Beratungs ID..."
                >
                  ID
                </SortFilterTh>
                <SortFilterTh
                  id="emailRequestsFieldOfLaw"
                  name="fieldOfLaw"
                  sort={sort}
                  onSort={createOnSort(onSort, 'fieldOfLaw')}
                  onFilterSubmit={onFilterChange}
                  suggestions={convertToTagValues(FIELDS_OF_LAW_ENTRIES)}
                  FilterInput={TextFilterInput}
                  placeholder="Rechtsgebiet..."
                >
                  Rechtsgebiet
                </SortFilterTh>
                <SortFilterTh
                  id="emailRequestsPartnerId"
                  name="partnerId"
                  sort={sort}
                  onSort={createOnSort(onSort, 'partnerId')}
                  onFilterSubmit={onFilterChange}
                  suggestions={convertToTagValues(partners)}
                  FilterInput={TextFilterInput}
                  placeholder="Partner ID..."
                >
                  Partner
                </SortFilterTh>
                <SortFilterTh
                  id="emailChancellery"
                  name="chancellery"
                  sort={sort}
                  onSort={createOnSort(onSort, 'chancellery')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={TextFilterInput}
                  placeholder="Kanzlei..."
                >
                  Kanzlei
                </SortFilterTh>
                <SortFilterTh
                  id="emailRequestsCreatedAt"
                  name="createdAt"
                  sort={sort}
                  onSort={createOnSort(onSort, 'createdAt')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={DatePickerInput}
                  placeholder="Erstellt..."
                >
                  Erstellt
                </SortFilterTh>
                <th>In Bearbeitung durch</th>
                <th className="text-center action-header">Aktion</th>
              </tr>
            </thead>
            <tbody>
              {list.map((adviceRequest, index) => {
                const forwardTime = getForwardTime(adviceRequest.forwardedAt)
                return (
                  <tr
                    key={index}
                    className={classnames(
                      'row-hover',
                      'row-relative',
                      { orange: !isInMinutes(forwardTime, 15) && isInMinutes(forwardTime, 60) },
                      { red: isInMinutes(forwardTime, 15) },
                      { bold: !adviceRequest.read }
                    )}
                    onClick={onRowClick(adviceRequest.adviceId)}
                  >
                    <td className={classnames('text-center', { 'padding-left-25': wasRedirected(adviceRequest) })}>
                      {wasRedirected(adviceRequest) && (
                        <div className="icon-redirected" title="Dieser Antrag wurde an Ihre Kanzlei weitergeleitet">
                          <SVGRedirected width="24px" height="24px" />
                        </div>
                      )}
                      {adviceRequest.forwardedAt ? formatForwardTime(forwardTime) : '-'}
                    </td>
                    {hasPremium && <td>{adviceRequest.product ? PRODUCT_NAMES[adviceRequest.product.id] : 'Basic'}</td>}
                    <td className="text-center">
                      {adviceRequest.person.firstname} {adviceRequest.person.lastname}
                    </td>
                    <td scope="row" className="text-center">
                      {adviceRequest.adviceId}
                    </td>
                    <td className="text-center">{adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.name : '-'}</td>
                    <PartnerCell partner={adviceRequest.partner} />
                    <td className="text-center">{adviceRequest.chancellery ? adviceRequest.chancellery.name : '-'}</td>
                    <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
                    <td className="text-center">
                      {adviceRequest.assignedTo && adviceRequest.assignedTo.name ? (
                        adviceRequest.assignedTo.name
                      ) : (
                        <span>
                          <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="inline-icon" /> Nicht zugewiesen
                        </span>
                      )}
                    </td>
                    <td className="text-center action-cell">
                      <AdviceMenuButton adviceRequest={adviceRequest} onAssignTo={onReload} assignableUsers={assignableUsers} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div>
          <br />
          <h3 className="no-data">Keine neuen E-Mails</h3>
        </div>
      )}
    </Spinner>
  </Fragment>
)

export const EmailRequests = withList<LawyerRequest, EmailRequestsProps>({
  query,
  endpoint: ADVICE_REQUESTS_ENDPOINT,
  responseKey: 'adviceRequests',
  searchPrefix: 'email',
  pageSize: 5,
  queryMapper: (listState, props) => ({
    ...listState,
    sort: {
      sortBy: 'createdAt',
      sortDirection: SortDirection.Asc,
    },
    filters: [
      ...listState.filters,
      ...(props.assignFilter
        ? [
            {
              name: 'assignedTo',
              operator: FilterOperator.Equals,
              value: props.assignFilter,
            },
          ]
        : []),
      getChancelleryFilter(props.user, props.chancelleryLocations),
      {
        name: 'product.type',
        operator: FilterOperator.Equals,
        value: 'CONSUMER',
      },
      {
        name: 'chancelleryId',
        operator: FilterOperator.NotEmpty,
        value: true,
      },
      {
        name: 'contactType',
        operator: FilterOperator.Equals,
        value: 'EMAIL',
      },
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'at_chancellery',
      },
    ],
  }),
})(EmailRequestsView)
