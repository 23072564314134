import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils'
import { LoginUserQuery } from 'packages/userauth/interfaces/schemaDefinition'

import query from '../graphql/loginUser.graphql'

export const loginUser = async (email: string, password: string) => {
  const variables = {
    email,
    password,
  }
  try {
    const response = await request<LoginUserQuery>(USER_AUTH_ENDPOINT, query, variables)
    return response.login
  } catch (error) {
    throw error
  }
}
