import classnames from 'classnames'
import React, { FC } from 'react'
import InputMask from 'react-input-mask'
import { FormGroup, FormFeedback, Label, Input } from 'reactstrap'
import { InputType } from 'reactstrap/lib/Input'

import './InputField.scss'

export interface InputFieldProps {
  label?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  value: string
  name: string
  id: string
  maskOptions?: {
    mask: string
    formatChars?: { [key: string]: string }
    maskChar?: string
  }
  error?: string
  required?: boolean
  autoComplete?: string
  highlight?: boolean
  disabled?: boolean
  width?: string
  type?: InputType
  inputClassName?: string
  formClassName?: string
}

export const InputField: FC<InputFieldProps> = ({
  label,
  onChange,
  onBlur,
  value,
  name,
  id,
  disabled = false,
  highlight = false,
  required = false,
  autoComplete = 'on',
  width,
  error,
  maskOptions,
  type = 'text',
  inputClassName,
  formClassName,
}) => (
  <FormGroup
    className={classnames(
      'input-field',
      {
        'input-field--width': !!width,
        'input-field--highlight': highlight,
        'input-field--invalid': error,
      },
      formClassName
    )}
    style={{ flexBasis: width }}
  >
    {!!label && (
      <Label for={id}>
        {label}
        {required ? ' *' : ''}
      </Label>
    )}
    {!!maskOptions ? (
      <InputMask
        disabled={disabled}
        mask={maskOptions.mask}
        formatChars={maskOptions.formatChars}
        maskChar={maskOptions.maskChar}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        id={id}
        type="text"
      >
        {(inputProps: any) => <Input disabled={disabled} {...inputProps} />}
      </InputMask>
    ) : (
      <Input
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        type={type}
        name={name}
        id={id}
        disabled={disabled}
        autoComplete={autoComplete}
        className={inputClassName}
      />
    )}
    {error && <FormFeedback>{error}</FormFeedback>}
  </FormGroup>
)
