import { PARTNERS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/getPartners.graphql'
import { PartnersQuery } from '../graphql/schemaDefinition'

export type Partner = PartnersQuery['partners']['list'] extends Array<infer U> ? U : never

export const fetchPartners = async (): Promise<PartnersQuery['partners']['list']> => {
  const response = await request<PartnersQuery>(PARTNERS_ENDPOINT, query, {
    page: 1,
    pageSize: 200,
  })
  const { total, list } = response.partners

  const mapToResult = (partner: Partner): Partner => ({
    ...partner,
    insurer: partner.insurer || undefined,
  })

  if (total > list.length) {
    return request<PartnersQuery>(PARTNERS_ENDPOINT, query, {
      page: 1,
      pageSize: total,
    }).then(result => result.partners.list.map(mapToResult))
  }

  return list.map(mapToResult)
}
