import { USERS_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/saveUser.graphql'
import { SaveUserMutation, UserInput } from '../interfaces/schemaDefinition'

export const saveUser = async (user: UserInput): Promise<SaveUserMutation> => {
  const variables = {
    user,
  }
  const response = await request<SaveUserMutation>(USERS_ENDPOINT, query, variables)
  return response
}
