import TestCalculationIcon from '@mui/icons-material/InsertChartOutlined'
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  IconButtonProps,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FunctionComponent, useState } from 'react'
import useSWR from 'swr'

import { Autocomplete, DateTimePicker, TextField, SaveButton, FormActionBox } from 'common/components-mui'
import { ADVICE_REQUESTS_ENDPOINT, FIELDS_OF_LAW_ENTRIES, PARTNERS_ENDPOINT, PRODUCTS_ENDPOINT, Roles } from 'common/constants'
import { withAuthorization } from 'common/user-context'
import { fetcher, request } from 'common/utils'

import partnersQuery from '../graphql/getPartners.graphql'
import productsQuery from '../graphql/getProducts.graphql'
import testCalculationQuery from '../graphql/testCalculation.graphql'
import { ConfigTestPanelFormValues, configTestPanelInitialValues, configTestValidationSchema } from '../interfaces/formSchemas'
import {
  ConfigWeight,
  GetPartnersQuery,
  GetProductsQuery,
  SortDirection,
  TestCalculationQuery,
} from '../interfaces/schemaDefinition'
import { sortProducts } from '../utils'

type PartnersApiResponse = {
  data: GetPartnersQuery
}

type ProductsResponse = {
  data: GetProductsQuery
}

const weightMap: { [key in ConfigWeight]: string } = {
  [ConfigWeight.Default]: 'Normal',
  [ConfigWeight.Less]: 'Gering',
  [ConfigWeight.Few]: 'Sehr gering',
  [ConfigWeight.More]: 'Hoch',
  [ConfigWeight.Much]: 'Sehr hoch',
}

const useLinkStyles = makeStyles(theme => ({
  a: {
    color: theme.palette.primary.main,
  },
}))

const TableLink = ({ href, children }: { children: string; href: string }): JSX.Element => {
  const styles = useLinkStyles()
  return (
    <a className={styles.a} target="_blank" rel="noreferrer" href={href}>
      {children}
    </a>
  )
}

const CandidatesTable = ({
  candidates,
  style,
}: {
  candidates: TestCalculationQuery['matchChancelleries']['candidates']
  style?: React.CSSProperties
}): JSX.Element => (
  <Table style={style}>
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Adresse</TableCell>
        <TableCell>Rechtsgebiete</TableCell>
        <TableCell>Fallback</TableCell>
        <TableCell>Min</TableCell>
        <TableCell>Max</TableCell>
        <TableCell>Aktuell</TableCell>
        <TableCell>Gewichtung</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {candidates &&
        candidates.map(matchedLocation => (
          <TableRow key={matchedLocation.id}>
            <TableCell>
              <TableLink href={`/chancelleries/edit/${matchedLocation.chancelleryId}`}>{matchedLocation.name}</TableLink>
            </TableCell>
            <TableCell>
              <TableLink href={`/chancelleries/locations/edit/${matchedLocation.id}`}>
                {`${matchedLocation.address.street}, ${matchedLocation.address.zip} ${matchedLocation.address.city}`}
              </TableLink>
            </TableCell>
            <TableCell>
              {matchedLocation.fallback ? (
                matchedLocation.fieldsOfLaw.map(fol => fol.name).join(', ')
              ) : (
                <TableLink href={`/chancelleries/configurations/edit/${matchedLocation.configId}`}>
                  {matchedLocation.fieldsOfLaw.map(fol => fol.name).join(', ')}
                </TableLink>
              )}
            </TableCell>
            <TableCell>{matchedLocation.fallback ? 'Ja' : 'Nein'}</TableCell>
            <TableCell>{matchedLocation.min || '-'}</TableCell>
            <TableCell>{matchedLocation.max || '-'}</TableCell>
            <TableCell>{matchedLocation.current || 0}</TableCell>
            <TableCell>{matchedLocation.weight ? weightMap[matchedLocation.weight] : '-'}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>
)

// eslint-disable-next-line complexity
export const ConfigurationTestPanel: FunctionComponent = () => {
  const [isWaitingForResults, setIsWaitingForResults] = useState(false)
  const [resultList, setResultList] = useState<TestCalculationQuery['matchChancelleries']['chancelleries']>()
  const [candidates, setCandidates] = useState<TestCalculationQuery['matchChancelleries']['candidates']>()
  const [error, setError] = useState()
  const { data: productsResponse, error: productsError } = useSWR([PRODUCTS_ENDPOINT, productsQuery], (e: string, q: string) =>
    fetcher<ProductsResponse>(e, q, { page: 1, pageSize: 100 })
  )
  const { data: partnersResponse, error: partnersError } = useSWR([PARTNERS_ENDPOINT, partnersQuery], (e: string, q: string) =>
    fetcher<PartnersApiResponse>(e, q, { page: 1, pageSize: 100, sort: { sortBy: 'name', sortDirection: SortDirection.Asc } })
  )

  const isDataLoading = (!partnersResponse && !partnersError) || (!productsResponse && !productsError)

  const handleSubmit = async (
    values: ConfigTestPanelFormValues,
    helpers: FormikHelpers<ConfigTestPanelFormValues>
  ): Promise<void> => {
    setError(undefined)
    setIsWaitingForResults(true)
    // console.log(values)
    try {
      const result = await request<TestCalculationQuery>(ADVICE_REQUESTS_ENDPOINT, testCalculationQuery, {
        matchInput: {
          address: {
            zip: values.zipCode,
          },
          premium: true,
          language: 'deutsch',
          fieldOfLaw: values.fieldOfLaw?.id,
          productId: values.product?.id,
          partnerId: values.partner?.id,
          time: values.dateTime,
        },
      })
      setResultList(result.matchChancelleries.chancelleries)
      setCandidates(
        result.matchChancelleries.candidates.filter(
          candidate => !result.matchChancelleries.chancelleries.find(match => match.configId === candidate.configId)
        )
      )
    } catch (e) {
      setError(e)
    }
    setIsWaitingForResults(false)
    helpers.setSubmitting(false)
  }

  return (
    <Box m={3}>
      <Formik initialValues={configTestPanelInitialValues} onSubmit={handleSubmit} validationSchema={configTestValidationSchema}>
        <Form>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mb={3}>
            <TextField disabled={isDataLoading} name="zipCode" label="PLZ" />
            <Autocomplete
              disabled={isDataLoading}
              name="fieldOfLaw"
              label="Rechtsgebiet"
              options={FIELDS_OF_LAW_ENTRIES.concat({ id: '', name: '' })}
              getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
              isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
              textfieldProps={{ variant: 'outlined' }}
            />
            {partnersResponse && (
              <Autocomplete
                disabled={isDataLoading}
                name="partner"
                label="Partner"
                options={partnersResponse.data.partners.list}
                getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
                isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
                textfieldProps={{ variant: 'outlined' }}
              />
            )}
            {productsResponse && (
              <Autocomplete
                disabled={isDataLoading}
                name="product"
                label="Produkt"
                options={sortProducts(productsResponse.data.products.list, true)}
                getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
                isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
                groupBy={option => option.type}
                textfieldProps={{ variant: 'outlined' }}
              />
            )}
            <DateTimePicker name="dateTime" label="Zeitpunkt" minutesStep={5} />
          </Box>
          <FormActionBox>
            <SaveButton disabled={isDataLoading}>Testmatching starten</SaveButton>
          </FormActionBox>
          {isWaitingForResults && <LinearProgress />}
          {error && <Alert severity="error">Es ist ein Fehler aufgetreten</Alert>}
          {resultList && !error && (
            <>
              <Typography variant="h6">Ergebnisse</Typography>
              <CandidatesTable candidates={resultList} style={{ marginBottom: '2rem' }} />
              <Typography variant="h6">Weitere Kandidaten</Typography>
              <CandidatesTable candidates={candidates || []} />
            </>
          )}
        </Form>
      </Formik>
    </Box>
  )
}

const useStyles = makeStyles({
  button: {
    [`&:focus`]: {
      outline: 'none',
    },
  },
})

export const ConfigurationTestDialogButton: FunctionComponent<IconButtonProps> = withAuthorization([
  Roles.Administrator,
  Roles.Employee,
])((props: IconButtonProps) => {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)
  return (
    <>
      <IconButton color="primary" className={classes.button} onClick={() => setDialogOpen(true)} {...props} size="large">
        <TestCalculationIcon titleAccess="Testmatching" />
      </IconButton>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="xl">
        <DialogTitle>Testmatching</DialogTitle>
        <ConfigurationTestPanel />
      </Dialog>
    </>
  )
})
