import { ensureEndsWith } from './string'

const API_BASE = process.env.API_BASE || '/api'

export const fetcher = <T>(endpoint: string, query: string, variables: { [key: string]: unknown }): Promise<T> => {
  const token = localStorage.getItem('token')

  const url = `${ensureEndsWith('/', API_BASE)}${endpoint}`

  const body = JSON.stringify({
    query,
    variables: variables || undefined,
  })

  return fetch(url, {
    body,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(token ? { 'x-access-token': token } : {}),
    },
  }).then(res => res.json())
}

export const fetchWithBody = <T>(endpoint: string, body: string): Promise<T> => {
  const token = localStorage.getItem('token')

  const url = `${ensureEndsWith('/', API_BASE)}${endpoint}`

  return fetch(url, {
    body,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(token ? { 'x-access-token': token } : {}),
    },
  }).then(res => res.json())
}
