import React, { FC, useState } from 'react'

import Transfer from 'common/icons/Transfer.svg'
import TransferCheck from 'common/icons/TransferCheck.svg'
import { ButtonClear } from 'common/ui/Button'

import { sendToEConsult } from '../../actions'
import { LawyerRequest } from '../../interfaces'
import { didSendToEConsult } from '../../utils'
import { EConsultModal } from '../EConsultModal'

import './TransferButton.scss'

interface TransferButtonProps {
  adviceRequest: LawyerRequest
}

export const TransferButton: FC<TransferButtonProps> = ({ adviceRequest }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [hasSentNow, setHasSentNow] = useState(false)
  const didSend = didSendToEConsult(adviceRequest) || hasSentNow

  const onCloseModal = () => {
    setModalOpen(false)
  }

  const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (!didSend) {
      setModalOpen(true)
    }
    return false
  }

  const onTransferClick = async () => {
    setModalLoading(true)
    try {
      await sendToEConsult(adviceRequest.id)
      setHasSentNow(true)
    } catch (error) {
      setModalLoading(false)
      // eslint-disable-next-line no-console
      console.error(error)
      return
    }
    setModalSuccess(true)
  }

  return (
    <>
      <ButtonClear
        title={didSend ? 'Akte wurde bereits an Ihre Kanzlei-Software übertragen' : 'Akte an Kanzlei-Software übertragen'}
        disabled={didSend}
        iconLeft={didSend ? TransferCheck : Transfer}
        onClick={openModal}
      />
      <EConsultModal
        open={modalOpen}
        success={modalSuccess}
        transferloading={modalLoading}
        onCancelClick={onCloseModal}
        onCompleteClick={onCloseModal}
        onTransferClick={onTransferClick}
      />
    </>
  )
}
