import { useEffect, useRef, useState } from 'react'

type fetcherType<T> = (body?: any) => Promise<T>
type hookFunction<T> = (body?: any) => {
  data: T
  isLoading: boolean
  error: any
  refresh: () => void
}

interface ConfigObject<T> {
  fetcher: fetcherType<T>
  initialData?: T
  skipMountEffect?: boolean
}

export function fetchDataWith<T>(
  fetcherOrConfig: fetcherType<T> | ConfigObject<T>,
  initialData: T = [] as unknown as T
): hookFunction<T> {
  if (typeof fetcherOrConfig !== 'function' && typeof fetcherOrConfig.fetcher !== 'function') {
    throw new Error(
      'You must provide a data fetching function to fetchDataWith: Either as first argument or as "fetcher" property of an object that is the first argument.'
    )
  }

  return (body = {}) => {
    const internalFetcher = typeof fetcherOrConfig === 'function' ? fetcherOrConfig : fetcherOrConfig.fetcher

    const internalitData = typeof fetcherOrConfig === 'function' ? initialData : fetcherOrConfig.initialData || initialData

    const skipEffect = useRef(typeof fetcherOrConfig === 'function' ? false : fetcherOrConfig.skipMountEffect || false)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(internalitData)
    const [error, setError] = useState(null)
    const cancel = useRef(false)

    const refresh = () => {
      setIsLoading(true)
      cancel.current = false
      internalFetcher(body)
        .then(result => {
          if (!cancel.current) {
            setData(result)
          }
        })
        .catch(error => {
          if (!cancel.current) {
            setError(error)
          }
        })
        .then(() => {
          if (!cancel.current) {
            setIsLoading(false)
          }
        })
    }
    useEffect(() => {
      if (!skipEffect.current) {
        refresh()
      }
      skipEffect.current = false
      return () => {
        // Make sure not to call setters if Component is unmounted.
        cancel.current = true
      }
    }, [...Object.values(body)])
    return { data, isLoading, error, refresh }
  }
}
