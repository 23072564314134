import { getOr, pipe } from 'lodash/fp'

export const labelOrDash = getOr('-')
export const labelOrEmpty = getOr('')

export const concat: (joiner: string) => <T>(...funcs: Array<(obj: T) => string>) => (obj: T) => string =
  joiner =>
  (...funcs) =>
  obj =>
    funcs
      .map(func => func(obj))
      .filter(s => !!s)
      .join(joiner)

export const concatSpace = concat(' ')
export const concatComma = concat(', ')

const trim: (s: string) => string = s => (s ? s.trim() : s)

const orDefault: (d: string) => (s: string) => string = d => s => s || d
const orDash = orDefault('-')
const pipeTrimOrDash = <T extends (...args: Array<any>) => string>(func: T) => pipe(func, trim, orDash)

type Maybe<T> = T | undefined | null

interface MayHavePerson {
  person?: Maybe<{ firstname?: Maybe<string>; lastname?: Maybe<string> }>
}

export const personName: <T extends MayHavePerson>(obj: Maybe<T>) => string = pipeTrimOrDash(
  concatSpace(labelOrEmpty('person.firstname'), labelOrEmpty('person.lastname'))
)

interface MayHavePartner {
  partner?: Maybe<{ name: Maybe<string> }>
}

export const partnerName: <T extends MayHavePartner>(obj: Maybe<T>) => string = pipeTrimOrDash(labelOrDash('partner.name'))

interface MayHaveAddress {
  person?: Maybe<{
    address?: Maybe<{ street?: Maybe<string>; streetNr?: Maybe<string>; zip?: Maybe<string>; city?: Maybe<string> }>
  }>
}

const street: <T extends MayHaveAddress>(obj: Maybe<T>) => string = pipe(
  concatSpace(labelOrEmpty('person.address.street'), labelOrEmpty('person.address.streetNr')),
  trim
)

const city: <T extends MayHaveAddress>(obj: Maybe<T>) => string = pipe(
  concatSpace(labelOrEmpty('person.address.zip'), labelOrEmpty('person.address.city')),
  trim
)

export const address: <T extends MayHaveAddress>(obj: Maybe<T>) => string = pipeTrimOrDash(concatComma(street, city))

interface MayHavePhone {
  person?: Maybe<{ phone: Maybe<string> }>
}

export const phone: <T extends MayHavePhone>(obj: Maybe<T>) => string = pipeTrimOrDash(labelOrDash('person.phone'))

interface MayHaveEmail {
  person?: Maybe<{ email?: Maybe<string> }>
}

export const email: <T extends MayHaveEmail>(obj: Maybe<T>) => string = pipeTrimOrDash(labelOrDash('person.email'))

interface MayHaveChancellery {
  chancellery?: Maybe<{ name: Maybe<string> }>
}

export const chancelleryName: <T extends MayHaveChancellery>(obj: Maybe<T>) => string = pipeTrimOrDash(
  labelOrDash('chancellery.name')
)

interface MayHaveFieldOfLaw {
  fieldOfLaw?: Maybe<{ name: Maybe<string> }>
}

export const fieldOfLawName: <T extends MayHaveFieldOfLaw>(obj: Maybe<T>) => string = pipeTrimOrDash(
  labelOrDash('fieldOfLaw.name')
)

interface MayHaveMandate {
  mandateName?: Maybe<string>
}

export const mandateName: <T extends MayHaveMandate>(obj: Maybe<T>) => string = pipeTrimOrDash(labelOrDash('mandateName'))
