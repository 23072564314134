import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { useField } from 'formik'
import { remove } from 'ramda'
import React, { memo, FunctionComponent, useState, useCallback, useMemo } from 'react'

export type Option = {
  id: string
  label: string
}

type SubListProps = {
  elements: Array<Option>
  checked: Array<string>
  onToggle: (id: string) => void
  onFocus: (focused: boolean) => void
}

const useSublistStyles = makeStyles({
  paper: {
    overflow: 'auto',
    height: '100%',
  },
})

const SubList: FunctionComponent<SubListProps> = props => {
  const { elements, checked, onToggle, onFocus } = props
  const classes = useSublistStyles()
  return (
    <Paper className={classes.paper} tabIndex={-1}>
      <List>
        {elements.map(option => {
          const labelId = `list-label-${option.id}`
          return (
            <ListItem
              key={option.id}
              button
              onClick={() => onToggle(option.id)}
              onFocus={() => onFocus(true)}
              onBlur={() => onFocus(false)}
            >
              <ListItemIcon>
                <Checkbox
                  tabIndex={-1}
                  checked={checked.includes(option.id)}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} secondary={option.label} />
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )
}

const MemoizedSubList = memo(SubList)

type TransferListProps = {
  name: string
  label: string
  options: Array<Option>
  onChange?: (newOptions: Array<string>) => void
  className?: string
}

const useStyles = makeStyles(theme => ({
  fieldset: {
    width: '100%',
  },
  container: {
    maxHeight: '100%',
    flexWrap: 'nowrap',
  },
  buttonContainer: {
    height: '100%',
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(1, 0),
  },
}))

export const TransferList: FunctionComponent<TransferListProps> = ({ name, options, onChange, className, label }) => {
  const classes = useStyles()
  const [, meta, helpers] = useField<Array<string>>(name)
  const [focused, setFocused] = useState(false)
  const [checkedOptionIds, setCheckedOptionIds] = useState<Array<string>>([])

  // Make sure not to change options for SubList on every render.
  const selectedOptions = useMemo(() => options.filter(option => meta.value.includes(option.id)), [options, meta.value])
  const notSelectedOptions = useMemo(
    () => options.filter(option => !selectedOptions.map(o => o.id).includes(option.id)),
    [options, selectedOptions]
  )

  // Used for (un)checking the checkboxes
  const onToggle = useCallback(
    (id: string): void => {
      setCheckedOptionIds(oldList =>
        checkedOptionIds.includes(id) ? remove(oldList.indexOf(id), 1, oldList) : oldList.concat(id)
      )
    },
    [checkedOptionIds]
  )

  // Changes overall input value in formik: Adding options
  const moveCheckedToRight = (): void => {
    const leftCheckedIds = notSelectedOptions.filter(option => checkedOptionIds.includes(option.id)).map(o => o.id)
    const newValue = selectedOptions.map(o => o.id).concat(leftCheckedIds)
    helpers.setValue(newValue)
    onChange?.(newValue)
    setCheckedOptionIds(oldList => oldList.filter(id => !leftCheckedIds.includes(id)))
  }

  // Changes overall input value in formik: Removing options
  const moveCheckedToLeft = (): void => {
    const rightCheckedIds = selectedOptions.filter(option => checkedOptionIds.includes(option.id)).map(o => o.id)
    const newValue = selectedOptions.filter(option => !checkedOptionIds.includes(option.id)).map(o => o.id)
    helpers.setValue(newValue)
    onChange?.(newValue)
    setCheckedOptionIds(oldList => oldList.filter(id => !rightCheckedIds.includes(id)))
  }

  return (
    <FormControl component="fieldset" error={Boolean(meta.error)} focused={focused} className={classes.fieldset}>
      <FormLabel component="legend">{label}</FormLabel>
      <Grid container spacing={2} className={classNames(classes.container, className)}>
        <Grid item xs={5}>
          <MemoizedSubList elements={notSelectedOptions} checked={checkedOptionIds} onToggle={onToggle} onFocus={setFocused} />
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center" className={classes.buttonContainer}>
            <Grid item>
              <Button variant="outlined" onClick={moveCheckedToRight}>
                &gt;
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={moveCheckedToLeft}
                className={classes.button}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <MemoizedSubList elements={selectedOptions} checked={checkedOptionIds} onToggle={onToggle} onFocus={setFocused} />
        </Grid>
      </Grid>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}
