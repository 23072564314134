import React from 'react'

import './AccountDisplay.scss'
import { ProposalAcceptedMutation } from '../../interfaces/schemaDefinition'

type ProposalAccount = ProposalAcceptedMutation['proposalAccepted']

export const AccountDisplay = ({ account }: { account?: ProposalAccount }): JSX.Element | null =>
  account && account.iban ? (
    <table className="account-display">
      <tbody>
        <tr>
          <td>IBAN:</td>
          <td>{account.iban}</td>
        </tr>
        <tr>
          <td>BIC:</td>
          <td>{account.bic}</td>
        </tr>
        <tr>
          <td>Bank:</td>
          <td>{account.bankname}</td>
        </tr>
        <tr>
          <td>Verwendungszweck:</td>
          <td>{account.purpose}</td>
        </tr>
      </tbody>
    </table>
  ) : null
