import { set } from 'lodash/fp'
import React, { PureComponent } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Roles } from 'common/constants'
import { LoggedInUser, UserContextProps, withUser } from 'common/user-context'
import { containsSome } from 'common/utils'
import { loginUser } from 'packages/userauth/actions/loginUser'

import { LoginBoxView } from '../../components/LoginBox'

interface LoginBoxState {
  login: {
    email: string
    password: string
  }
  errors: {
    invalidLogin: boolean
  }
}

type LoginBoxProps = RouteComponentProps<{}> & UserContextProps

const isAdminOrEmployee = containsSome([Roles.Administrator, Roles.Employee])
const isChannelOrTRB = containsSome([Roles.Channel, Roles.TRB, Roles.TRBManager])
const isLawyer = containsSome([Roles.Lawyer])
const isLoginURL = (url: string): boolean => /.*?login.*/.test(url)

const getRedirectUrl = (props: LoginBoxProps, user: LoggedInUser): string => {
  if (props.lastUrl && !isLoginURL(props.lastUrl.pathname)) {
    const { pathname, search, hash } = props.lastUrl
    return `${pathname}${search}${hash}`
  }
  if (isAdminOrEmployee(user.roles)) {
    return '/advice-requests'
  }

  if (isChannelOrTRB(user.roles)) {
    return '/advice-requests'
  }

  if (isLawyer(user.roles)) {
    return '/chancellery/advice-requests/open-requests'
  }

  return '/advice-requests'
}

export const LoginBox = withUser(
  class LoginBox extends PureComponent<LoginBoxProps, LoginBoxState> {
    state: LoginBoxState = {
      login: {
        email: '',
        password: '',
      },
      errors: {
        invalidLogin: false,
      },
    }

    componentDidMount() {
      if (this.props.user && this.props.user.id) {
        const redirectURL = getRedirectUrl(this.props, this.props.user)
        this.props.history.push(redirectURL)
      }
    }

    onFieldChange = (event: React.FormEvent<HTMLInputElement>) => {
      if (!event.currentTarget.name) {
        throw 'missing name on event target'
      } else {
        const login = set(event.currentTarget.name, event.currentTarget.value, this.state.login)
        this.setState({ login })
      }
    }

    handleLogin = async (email: string, password: string) => {
      const userResponse = await loginUser(email, password)
      if (!userResponse) {
        this.setState({
          errors: {
            invalidLogin: true,
          },
        })
        return
      }
      const user = {
        id: userResponse.user.id,
        name: userResponse.user.name,
        email: userResponse.user.email,
        organisation: userResponse.user.organization || '',
        organisationType: userResponse.user.organizationType || '',
        channel: userResponse.user.channel || '',
        roles: userResponse.user.roles.map((r: any) => r.name),
        accessBitSet: userResponse.user.accessBitSet,
      }
      this.props.setUser(user)
      localStorage.setItem('token', userResponse.jwtToken)
      // eslint-disable-next-line fp/no-mutation
      document.cookie = `token=${userResponse.jwtToken}`
      this.props.history.push(getRedirectUrl(this.props, user))
    }

    onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      this.handleLogin(this.state.login.email, this.state.login.password)
    }

    render() {
      return (
        <LoginBoxView
          onSubmit={this.onSubmit}
          onFieldChange={this.onFieldChange}
          login={this.state.login}
          errors={this.state.errors}
        />
      )
    }
  }
)
