import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { MutationUpdateSalesArgs, RevenueInput } from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/updateSales.graphql'

export const updateSales = async (adviceRequestId: string, revenueId: string, revenue: RevenueInput) => {
  try {
    await request<MutationUpdateSalesArgs>(ADVICE_REQUESTS_ENDPOINT, query, {
      adviceRequestId,
      revenueId,
      revenue,
    })
  } catch (error) {
    throw error
  }
}
