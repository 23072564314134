export { customPartners, customPartnerIds } from './customPartners'
export * from './closingReasons'

export const CONTACT_TYPE_LABELS = {
  REDIRECT: 'Anruf',
  CALLBACK: 'Rückruf',
  EMAIL: 'E-Mail',
  MAIL: 'E-Mail',
  NOT_SET: 'Nicht gesetzt',
}
