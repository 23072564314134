import React, { FC } from 'react'
import { Table } from 'reactstrap'

import { ADVICE_DETAIL } from 'common/constants/routes'
import { useNavigate } from 'common/hooks'
import { formatDate } from 'common/utils'
import { personName, partnerName, address, email, phone, chancelleryName, fieldOfLawName } from 'common/utils/label'

import { getNextWorkday } from '../../chancellery-search/utils/getCallbackTimes'
import { SearchAdviceRequest } from '../interfaces'

interface Props {
  adviceRequests: Array<SearchAdviceRequest>
}

const padLeft = (text: string | number, filler = '0', length = 2) => {
  let result = text.toString()
  while (result.length < length) {
    result = `${filler}${result}`
  }
  return result
}

const getForwardTime = (input?: string | Date) => {
  if (input) {
    const date = new Date(input)
    const limit = getNextWorkday(date)
    limit.setHours(date.getHours())
    return limit
  }
  return undefined
}

const formatForwardTime = (limit?: Date) => {
  if (limit) {
    return `Spätestens ${padLeft(limit.getDate())}.${padLeft(limit.getMonth() + 1)}.${limit.getFullYear()} ${Math.min(
      Math.max(12, limit.getHours()),
      22
    )}:00 Uhr`
  }
  return false
}

export const OpenEmailRequets: FC<Props> = ({ adviceRequests }) => {
  const toAdviceDetail = useNavigate(ADVICE_DETAIL)
  if (adviceRequests.length === 0) {
    return null
  }
  return (
    <>
      <div className="table-heading">
        <h4 className="title">Ausstehende Emails</h4>
      </div>
      <Table hover responsive>
        <thead>
          <tr>
            <th>Beantworten bis</th>
            <th>ID</th>
            <th>Rechtsgebiet</th>
            <th>Name</th>
            <th>Partner</th>
            <th>Anschrift</th>
            <th>Tel-Nr.</th>
            <th>E-Mail</th>
            <th>Kanzlei</th>
            <th>Erstellt</th>
          </tr>
        </thead>
        <tbody>
          {adviceRequests.map((adviceRequest, index) => {
            const forwardTime = getForwardTime(adviceRequest.forwardedAt)
            return (
              <tr key={index} className="row-hover" onClick={() => toAdviceDetail({ adviceId: adviceRequest.adviceId })}>
                <td className="text-center">{adviceRequest.forwardedAt ? formatForwardTime(forwardTime) : '-'}</td>
                <th scope="row" className="text-center">
                  {adviceRequest.adviceId}
                </th>
                <td className="text-center">{fieldOfLawName(adviceRequest)}</td>
                <td className="text-center">{personName(adviceRequest)}</td>
                <td className="text-center">{partnerName(adviceRequest)}</td>
                <td className="text-center">{address(adviceRequest)}</td>
                <td className="text-center">{phone(adviceRequest)}</td>
                <td className="text-center">{email(adviceRequest)}</td>
                <td className="text-center">{chancelleryName(adviceRequest)}</td>
                <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}
