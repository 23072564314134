import { useFormikContext } from 'formik'
import { useEffect, FunctionComponent, useState } from 'react'

import { PARTNERS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils/requests'

import partnerFormDataQuery from '../../graphql/getPartner.graphql'
import { PartnerFormValues } from '../../interfaces/formSchemas'
import { GetPartnerQuery } from '../../interfaces/schemaDefinition'

type GetPartnerAPIDataProps = {
  id?: string
  setLoading?: ReturnType<typeof useState>[1]
}

// eslint-disable-next-line fp/no-rest-parameters
export const mapPartnerAPIDataToForm = ({ partnerValidation, ...partner }: GetPartnerQuery['partner']): PartnerFormValues => ({
  ...partner,
  phone: partner.phone ?? '',
  insurer: partner.insurer ?? '',
  customerIdValidation: {
    pattern: partnerValidation?.regex ?? '',
    description: partnerValidation?.intro ?? '',
  },
})

export const GetPartnerAPIData: FunctionComponent<GetPartnerAPIDataProps> = ({ id, setLoading }) => {
  const { setValues } = useFormikContext<PartnerFormValues>()

  useEffect(() => {
    if (!id) {
      setLoading?.(false)
      return
    }

    setLoading?.(true)

    request<GetPartnerQuery>(PARTNERS_ENDPOINT, partnerFormDataQuery, { id })
      .then(({ partner }) => {
        setValues(mapPartnerAPIDataToForm(partner))
      })
      .catch(() => undefined) // TODO: Do we need to do anything real here?
      .then(() => setLoading?.(false))
  }, [id, setValues, setLoading])
  return null
}
