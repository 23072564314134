import { createContext } from 'react'

import { UserContextProps } from './interfaces'

const UserContext = createContext<UserContextProps>({
  user: {
    id: '',
    name: '',
    email: '',
    organisation: '',
    roles: [],
    accessBitSet: {},
  },
  setUser: () => {},
  signOut: () => {},
  userProfile: () => {},
  onConfirmSignOut: () => {},
  confirmSignOut: false,
  onCancelSignOut: () => {},
})

const { Consumer, Provider } = UserContext

export { UserContext }
export { Consumer as UserConsumer }
export { Provider as UserProvider }
