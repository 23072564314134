import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AsyncFunction, request } from 'common/utils'
import { ConfirmAdviceMutation, ConfirmAdviceMutationVariables } from 'packages/lawyers/interfaces'

import query from '../graphql/confirmAdvice.graphql'

export const confirmAdvice: AsyncFunction<string, string> = async adviceRequestId => {
  const variables = { adviceRequestId }
  const response = await request<ConfirmAdviceMutation, ConfirmAdviceMutationVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )
  return response.confirmAdvice
}
