// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LXIINsm8edzbuSIs1g8V{display:flex;flex-wrap:wrap}.LXIINsm8edzbuSIs1g8V>ul{width:50%;padding-inline-start:16px;margin:0}.LXIINsm8edzbuSIs1g8V>ul>li{margin-bottom:.5rem}", "",{"version":3,"sources":["webpack://./src/packages/lawyers/components/ProduktPanel/ProductPanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CAEA,yBACE,SAAA,CACA,yBAAA,CACA,QAAA,CAEA,4BACE,mBAAA","sourcesContent":[".flex {\n  display: flex;\n  flex-wrap: wrap;\n\n  > ul {\n    width: 50%;\n    padding-inline-start: 16px;\n    margin: 0;\n\n    > li {\n      margin-bottom: 0.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex": "LXIINsm8edzbuSIs1g8V"
};
export default ___CSS_LOADER_EXPORT___;
