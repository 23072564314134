import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Badge,
  CenterBox,
  FilterOperator,
  HeaderButtonGroup,
  MuiDataGrid,
  PageInfoline,
  PageLayout,
  useGridData,
} from 'common/components-mui'
import { USERS_ENDPOINT } from 'common/constants'

import query from '../graphql/getUsersFiltered.graphql'
import { GetUserListFilteredQuery } from '../interfaces/schemaDefinition'

export const UsersListPage: FunctionComponent = () => {
  const history = useHistory()
  const dataObjectName = 'userList'
  const { data, error, tableState, actions } = useGridData<typeof dataObjectName, GetUserListFilteredQuery>(
    USERS_ENDPOINT,
    query,
    dataObjectName,
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      filters: [
        {
          name: 'deleted',
          operator: FilterOperator.Equals,
          value: false,
        },
      ],
    }
  )

  type FromArray<A> = A extends Array<infer U> ? U : never
  type Roles = Exclude<FromArray<GetUserListFilteredQuery['userList']['list']>, null>['roles'] | null

  const columns: Array<GridColDef> = [
    /* eslint-disable @typescript-eslint/consistent-type-assertions, react/display-name */
    { field: 'name', headerName: 'Name', flex: 0.3 },
    { field: 'email', headerName: 'E-Mail', flex: 0.3 },
    {
      field: 'roles',
      headerName: 'Benutzerrollen',
      flex: 0.3,
      valueFormatter: (params: GridValueFormatterParams) => (params.value as Roles)?.map(r => r.name).join(', '),
    },
    {
      field: 'active',
      headerName: 'Status',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => {
        const active: boolean = params.value as boolean
        return (
          <CenterBox>
            <Badge color={active ? 'success' : 'default'}>{active ? 'Aktiv' : 'Inaktiv'}</Badge>
          </CenterBox>
        )
      },
    },
    /* eslint-enable @typescript-eslint/consistent-type-assertions, react/display-name */
  ]

  type ExtractArrayType<T> = T extends Array<infer U> ? U : never

  // Since the API returns sadly a list of Maybe<User> we have to filter it first before giving to the table.
  const tableEntries = data?.list.filter(
    (e): e is Exclude<ExtractArrayType<GetUserListFilteredQuery['userList']['list']>, null> => e !== null
  )

  return (
    <PageLayout error={error} heading="Nutzerverwaltung" spacing="table">
      <HeaderButtonGroup>
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        <Button onClick={() => history.push('/users/new')}>
          <AddCircleOutlineIcon titleAccess="Eintrag hinzufügen" />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>Insgesamt {data?.total ?? 0} Einträge</PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        columns={columns || []}
        rows={tableEntries ?? []}
        loading={!data}
        rowCount={data?.total}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={r => (r.row.id ? history.push(`/users/edit/${r.row.id}`) : undefined)}
      />
    </PageLayout>
  )
}
