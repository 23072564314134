import React, { ChangeEventHandler, FC } from 'react'
import { Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import { DateTimePicker } from 'common/components/DateTimePicker'
import { ButtonPrimary } from 'common/ui'

import { AdviceRequest } from '../../interfaces'
import SVGBack from '../images/back.svg'
import SVGExit from '../images/exit.svg'
import SVGPotentialMandate from '../images/icon_potentielle_mandate.svg'
import SVGPospone from '../images/zurueckstellen.svg'

interface DatePickerModalProps {
  adviceDetail: AdviceRequest
  open: boolean
  loading: boolean
  success: boolean
  onCancelClick: () => void
  onAppointmentClick: () => void
  onCompleteClick: () => void
  onAppointmentChange: (reminderDate: Date) => void
  setNotes: ChangeEventHandler<HTMLInputElement>
  errors?: any
}

export const DatePickerModal: FC<DatePickerModalProps> = ({
  adviceDetail,
  open,
  loading,
  success,
  onCancelClick,
  onAppointmentClick,
  onCompleteClick,
  onAppointmentChange,
  setNotes,
  errors = {},
}) => {
  if (loading || !open) {
    return (
      <Modal isOpen={open} className="new-modal">
        <ModalBody>
          <Spinner center />
        </ModalBody>
      </Modal>
    )
  }

  if (success) {
    return (
      <Modal toggle={onCompleteClick} isOpen={open} className="success-modal" id="AppointmentSuccessModal">
        <ModalBody>
          <SVGPotentialMandate height="160" width="200" />
          <Label>
            Dieser Antrag wurde erfolgreich zurückgestellt und ist ab sofort unter <span>“Wiedervorlagen“</span> zu finden.
          </Label>
        </ModalBody>
        <ModalFooter>
          <ButtonPrimary onClick={onCompleteClick} iconLeft={<SVGBack width="16" height="16" />}>
            Zurück zu Ihren Anträgen
          </ButtonPrimary>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} className="new-modal medium-size" id="AppointmentModal">
      <ModalBody>
        <div className="btn-cancel close-modal" onClick={onCancelClick}>
          <SVGExit height="14" width="14" />
        </div>
        <Form className="form">
          <FormGroup>
            <h3 className="h3 small">
              Bitte erstellen Sie sowohl eine Notiz weshalb der Antrag zurückgestellt wurde, sowie ein Erinnerungsdatum.
            </h3>
          </FormGroup>
          <FormGroup>
            <Input type="textarea" value={adviceDetail.lawyerNotes} name="lawyerNotes" onChange={setNotes} className="m-b-10" />
            <DateTimePicker
              name="reminderDate"
              value={adviceDetail.reminderDate}
              onChange={onAppointmentChange}
              minDate={new Date()}
              disableClock
            />
            {!!errors.reminderDate && <FormFeedback>{errors.reminderDate}</FormFeedback>}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary onClick={onAppointmentClick} iconLeft={<SVGPospone width="20" height="15" />}>
          Antrag zurückstellen
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  )
}
