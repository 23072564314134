import React, { Component, MouseEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { MONTHS, URLs } from 'common/constants'
import { UserContextProps, withUser } from 'common/user-context'
import { Dashboard } from 'packages/dashboard/components/DashboardView'

import { getChancelleryLocations, getCallbacksCount, getOpenRequestsCount } from '../actions'

type DashboardProps = RouteComponentProps<{}> & UserContextProps

interface DashboardState {
  isMounted: boolean
  userName: string
  actualDay: string
  actualMonth: string
  openCount: string | number
  callbackCount: string | number
}

export const DashboardContainer = withUser(
  class DashboardContainer extends Component<DashboardProps, DashboardState> {
    state: DashboardState = {
      isMounted: true,
      userName: '',
      actualDay: '',
      actualMonth: '',
      openCount: '',
      callbackCount: '',
    }

    async componentDidMount() {
      const { user } = this.props
      if (user) {
        this.setState({ userName: user.name })
        const day = new Date().getDate()
        const month = MONTHS[new Date().getMonth()].substring(0, 3)
        const actualDay = `${day}`
        const actualMonth = `${month}`
        this.setState({ actualDay, actualMonth })

        const chancelleryLocations = await getChancelleryLocations()
        const [openCount, callbackCount] = await Promise.all([
          getOpenRequestsCount(user, chancelleryLocations),
          getCallbacksCount(user, chancelleryLocations),
        ])
        this.setState({ openCount, callbackCount })
      }
    }

    componentWillUnmount() {
      this.setState({ isMounted: false })
    }

    redirectClick = (event: MouseEvent<HTMLButtonElement>) => {
      switch (event.currentTarget.name) {
        case 'callback':
          this.props.history.push({
            pathname: URLs.chancellery.adviceRequests.openRequests,
            state: { callback: true },
          })
          break
        case 'call':
          this.props.history.push(URLs.chancellery.adviceRequests.openRequests)
          break
        case 'support':
          this.props.history.push(URLs.help.home)
          break
        default:
      }
    }

    render() {
      const { userName, actualDay, actualMonth, openCount, callbackCount } = this.state
      return (
        <Dashboard
          userName={userName}
          redirectClick={this.redirectClick}
          actualDay={actualDay}
          actualMonth={actualMonth}
          openCount={openCount}
          callbackCount={callbackCount}
        />
      )
    }
  }
)
