import { Location } from 'history'

export function getQueryParam(location: Location, param: string) {
  const segments = location.search
    .substr(1)
    .split('&')
    .map(segment => {
      const paramSegments = segment.split('=')
      return {
        param: paramSegments[0],
        value: paramSegments[1],
      }
    })
  const result = segments.find(segment => segment.param === param)

  return result ? result.value : undefined
}

function reduceSearch(search: string): any {
  return search.split('&').reduce((result: any, segment: any) => {
    const keyValue = segment.split('=')
    return {
      ...result,
      [keyValue[0]]: keyValue[1],
    }
  }, {})
}

export function parseQuery(search: string): any {
  if (!search) {
    return {}
  }
  return reduceSearch(search.startsWith('?') ? search.substr(1) : search)
}
