import classnames from 'classnames'
import React from 'react'

import './StaticField.scss'

interface StaticFieldProps {
  label: string
  value: React.ReactNode
  className?: string
}

export const StaticField = (props: StaticFieldProps) => {
  const staticFieldClass = classnames('static-field', props.className)
  return (
    <div className={staticFieldClass}>
      <div className="static-field__value">{props.value}</div>
      <div className="static-field__label">{props.label}</div>
    </div>
  )
}
