import * as Yup from 'yup'

import { Group } from 'common/interfaces/Group'
import { Role } from 'common/interfaces/Role'

import { hasRoleChannel, hasRoleLawyer } from '../utils'

export const userValidationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Benutzername zu kurz.').max(50, 'Benutzername zu lang.').required('Benutzername erforderlich.'),
  email: Yup.string().email('E-Mail Adresse ungültig').required('E-Mail Adresse erforderlich.'),
  id: Yup.string(),
  password: Yup.string().when('id', (id: string, passwordSchema: ReturnType<typeof Yup.string>) =>
    id
      ? Yup.string().min(8, 'Das Password muss mindestens 8 Zeichen lang sein.')
      : passwordSchema
          .min(8, 'Das Password muss mindestens 8 Zeichen lang sein.')
          .required('Für neue Benutzer muss ein Passwort vergeben werden.')
  ),
  passwordRepeat: Yup.string().when('password', (password: string, pwRepeatSchema: ReturnType<typeof Yup.string>) =>
    password ? pwRepeatSchema.equals([Yup.ref('password')], 'Die Passwörter müssen übereinstimmen.').required() : pwRepeatSchema
  ),
  roles: Yup.array(),
  channel: Yup.string().when('roles', {
    is: (val: FormUser['roles']) => hasRoleChannel(val),
    then: Yup.string().required('Ein Rechtsberater Nutzer muss einem Kanal zugewiesen werden.'),
  }),
  organisation: Yup.string().when('roles', {
    is: (val: FormUser['roles']) => hasRoleLawyer(val),
    then: Yup.string().required('Kanzlei erforderlich.'),
  }),
})

export const initialValues = {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  name: '',
  email: '',
  deleted: false,
  id: '',
  active: true,
  roles: [] as Array<Role>,
  groups: [] as Array<Group>,
  channel: '',
  rights: [] as Array<string>,
  organisation: '',
  organisationType: '',
  parentId: '',
  password: '',
  passwordRepeat: '',
  sendRegisterEmail: undefined,
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

export type FormUser = typeof initialValues
