import { createClient, Client } from 'graphql-ws'

import { startsWith, remove, ensureEndsWith } from 'common/utils'

const API_BASE = process.env.API_BASE || '/api'

export const createWebSocket = (): Client => {
  const token = localStorage.getItem('token')
  const apiHost = startsWith('http', API_BASE) ? API_BASE : window.location.origin
  const proto = startsWith('https', apiHost) ? 'wss:' : 'ws:'
  const url = `${proto}//${ensureEndsWith('api/', ensureEndsWith('/', remove(/https?:\/\//, apiHost)))}subscriptions`
  const client = createClient({
    url,
    connectionParams: {
      'x-access-token': token,
    },
  })
  return client
}
