import React, { FC } from 'react'

import { Panel } from 'common/ui'
import { PanelSegment } from 'common/ui/Panel/PanelSegment'

import styles from './ConsultationPanel.module.scss'

export const ConsultationPanel: FC = ({ children }) => (
  <Panel>
    <PanelSegment>
      <div className={styles.flex}>{children}</div>
    </PanelSegment>
  </Panel>
)
