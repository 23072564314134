import React, { useState, useCallback, FC } from 'react'
import { FormFeedback } from 'reactstrap'

import { FieldRow, TextAreaField } from 'common/components/Form'
import SVGAnhang from 'common/icons/anhang.svg'
import SVGSecureEuro from 'common/icons/secure-euro.svg'
import { ButtonPrimary, ButtonSecondary } from 'common/ui/Button'

import { FilesModal } from '../../../components/FilesModal'
import SVGBack from '../../../components/images/back.svg'
import { AdviceRequest } from '../../../interfaces'
import { useSendCoverageRequest } from '../../effects/sendCoverageRequest'

import './CoverageForm.scss'

interface InvoiceFormProps {
  adviceRequest: AdviceRequest
  onSuccess: () => void
}

const ALLOWED_FILES = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf']
const MAX_FILES_SIZE = 26214400

const validateFiles = (files: Array<File>): string => {
  if (files.length > 0) {
    const fileSize = files.reduce((sum, file) => sum + file.size, 0)
    if (fileSize >= MAX_FILES_SIZE) {
      return 'Die Größe der angehängten Dateien darf 25 MB nicht überschreiten'
    }

    if (!!files.find(file => !ALLOWED_FILES.includes(file.type))) {
      return 'Es dürfen nur Dateiem im Format pdf, jpeg, jpg oder png angehangen werden'
    }

    if (!!files.find(file => /\d{14}\.\w+/.test(file.name))) {
      return 'Dateien mit einer Schadensnummer als Namen sind dürfen nicht angenhangen werden'
    }
    return ''
  } else {
    return 'Es muss mindestens eine Rechnung/ Vorschuss angehangen werden.'
  }
}

const InvoiceForm: FC<InvoiceFormProps> = ({ adviceRequest, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [fileError, setFileError] = useState('')
  const [errors, setErrors] = useState<{ [name: string]: string }>({})
  const [message, setMessage] = useState('')
  const [filesOpen, setFilesOpen] = useState(false)
  const [files, setFiles] = useState<Array<File>>([])

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMessage(event.currentTarget.value)
    },
    [setMessage]
  )

  const onAddFile: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.currentTarget.files) {
        const newFiles = files.concat([...event.currentTarget.files])
        setFiles(newFiles)
        setFileError(validateFiles(newFiles))
      }
    },
    [setFiles, files]
  )

  const onRemoveFile = useCallback(
    (name: string): void => {
      const index = files.findIndex(file => file.name === name)
      const newFiles = [...files.slice(0, index), ...files.slice(index + 1)]
      setFiles(newFiles)
      setFileError(validateFiles(newFiles))
    },
    [files, setFiles]
  )

  const toggleFiles = useCallback(() => {
    setFilesOpen(!filesOpen)
  }, [setFilesOpen, filesOpen])

  const onCloseFiles = useCallback(() => {
    setFilesOpen(false)
  }, [setFilesOpen])

  const sendCoverageRequest = useSendCoverageRequest()

  const input = {
    adviceRequest,
    attachments: files,
    coverageDecription: message,
  }

  const submitInvoiceForm = async (): Promise<void> => {
    setLoading(true)
    try {
      await sendCoverageRequest(input, {})
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setLoading(false)
      setErrors({ message: error.message })
    }
  }

  return success ? (
    <>
      <SVGSecureEuro width="48px" height="48px" />
      <h3 className="h3 small" style={{ marginBottom: '2rem' }}>
        Ihre Anfrage wurde erfolgreich zugestellt.
      </h3>
      <ButtonPrimary onClick={onSuccess} iconLeft={<SVGBack width="16" height="16" />}>
        Zurück
      </ButtonPrimary>
    </>
  ) : (
    <>
      <>
        <SVGSecureEuro width="48px" height="48px" />
        <h3 className="h3 small" style={{ marginBottom: '0' }}>
          Um eine Rechnung oder einen Vorschuss einzureichen, hängen Sie bitte ein entsprechendes Dokument an.
        </h3>
        <h3 className="h3 small" style={{ marginBottom: '0' }}>
          Die Falldaten und die Schadennummer werden automatisch an die Versicherung übertragen.
        </h3>
        <h3 className="h3 small" style={{ marginBottom: '0' }}>
          Ergänzende Informationen können Sie in nachfolgendem Freifeld eintragen:
        </h3>

        <FieldRow>
          <TextAreaField
            theme="white"
            placeholder="Nachricht"
            id="corageRequestTextArea"
            name="coverageRequest"
            value={message}
            onChange={onChange}
            label=""
          />
        </FieldRow>
        <ButtonSecondary onClick={toggleFiles} iconLeft={<SVGAnhang width="11" height="22" />}>
          Anhang hinzufügen
        </ButtonSecondary>
        <p className="attachments-label">{files.length === 1 ? <>1 Anhang</> : <>{files.length} Anhänge</>}</p>
        {!!errors.files && <FormFeedback>{errors.files}</FormFeedback>}
        <div style={{ marginTop: '2rem' }}>
          <ButtonPrimary onClick={submitInvoiceForm} loading={loading} disabled={files.length === 0}>
            Anfrage absenden
          </ButtonPrimary>
        </div>
      </>

      <FilesModal
        open={filesOpen}
        onAddFiles={onAddFile}
        onRemoveFile={onRemoveFile}
        onCancelClick={onCloseFiles}
        error={fileError}
        files={files}
        accept="image/*, .pdf"
      />
    </>
  )
}

export { InvoiceForm }
